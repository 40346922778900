export default [
  {
    text: '系統資訊 瀏覽者',
    value: 'system_info-read'
  },
  {
    text: '系統資訊 編輯者',
    value: 'system_info-update'
  },
  {
    text: '系統資訊 管理者',
    value: 'system_info-admin'
  },
]