<template>
  <div
    v-if="state.year&&state.month&&state.date"
    class="ws-calendar-month"
    :style="[
      {'height':`${height}px`}
    ]"
  >
    <WsCalendarMonthTopBar
      ref="WsCalendarMonthTopBar"
      @prev="$_onClickMonthPrev()"
      @next="$_onClickMonthNext()"
      :year="state.year"
      :month="state.month"
      :date="state.date"
      @update:year="state.year=$event"
      @update:month="$_onUpdateMonth($event)"
    />
    <div class="ws-calendar-month__container">
      <div class="ws-calendar-month__container__table">
        <WsCalendarMonthTableHeader
          ref="tableHeader"
          :showWeekdays="state.showWeekdays"
        />
        <div class="ws-calendar-month__container__table__content">
          <WsCalendarMonthTableWeek
            v-for="weekN in _displayWeekDayCount"
            :key="`week-${weekN}`"
            :showWeekdays="state.showWeekdays"
            :startDateWithShowWeekdays="$_getStartDateWithShowWeekdays(weekN)"
            :todayMoment="_todayMoment"
            :stateMoment="_stateMoment"
            :eventsInKey="_eventsInKey"
            :eventHeight="eventHeight"
            :maxEventDisplayCount="_maxEventDisplayCount"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dim: {
        tableHeader: {
          height: null,
        },
        topBar: {
          height: null,
        },
      },
      state: {
        year: null,
        month: null,
        date: null,
        showWeekdays: [1, 2, 3, 4, 5, 6, 7],
        displayMode: "month",
      },
    };
  },
  methods: {
    $_getStartDateWithShowWeekdays(weekN) {
      return this.$moment(this._stateStartMonthDateWithshowWeekdays).add(
        (weekN - 1) * 7,
        "day"
      );
    },
    $_dimSet() {
      this.dim.topBar.height =
        this.$refs.WsCalendarMonthTopBar.$el.offsetHeight;
      this.dim.tableHeader.height = this.$refs.tableHeader.$el.offsetHeight;
    },
    $_onUpdateYear($event) {
      this.state.year = $event;
    },
    $_onUpdateMonth($event) {
      this.state.month = $event;
    },
    $_onClickMonthPrev() {
      if (this.state.month > 1) {
        this.state.month = parseInt(this.state.month) - 1;
      } else {
        this.state.month = 12;
        this.state.year = parseInt(this.state.year) - 1;
      }
    },
    $_onClickMonthNext() {
      if (this.state.month < 12) {
        this.state.month = parseInt(this.state.month) + 1;
      } else {
        this.state.month = 1;
        this.state.year = parseInt(this.state.year) + 1;
      }
    },
    $_init() {
      this.$_initStateMoment();
      this.$_initShowWeekdays();
      this.$_initDisplayMode();
    },
    $_initStateMoment() {
      if (this.value) {
        this.$_setStateMoment(this.$moment(this.value));
      } else {
        this.$_setStateMoment(this._todayMoment);
      }
    },
    $_initShowWeekdays() {
      if (this.showWeekdays) {
        this.state.showWeekdays = this.showWeekdays;
      }
    },
    $_initDisplayMode() {
      if (this.displayMode) {
        this.state.displayMode = this.displayMode;
      }
    },
    $_setStateMoment($m) {
      this.state.year = $m.get("year");
      this.state.month = $m.get("month") + 1;
      this.state.date = $m.get("date");
    },
  },
  computed: {
    _maxEventDisplayCount() {
      return Math.floor(this._weekHeight / this.eventHeight);
    },
    _dateItemHeight() {
      return this.height - this.dim.tableHeader.height - this.dim.topBar.height;
    },
    _weekHeight() {
      return this._dateItemHeight / this._displayWeekDayCount;
    },
    _eventsInKey() {
      const _eventsInKey = {};
      for (let i = 0; i < this._displayDayCount; i++) {
        const _date = this.$moment(
          this._stateStartMonthDateWithshowWeekdays
        ).add(i, "day");
        _eventsInKey[_date.format("YYYY-MM-DD")] = {
          events: [],
        };
      }
      this.events.forEach((event) => {
        const _diffDaysCount =
          this.$moment(event.end_date).diff(event.start_date, "days") + 1;
        for (let i = 0; i < _diffDaysCount; i++) {
          const _date = this.$moment(event.start_date).add(i, "day");
          if (!_eventsInKey[_date.format("YYYY-MM-DD")]) {
            continue;
          }
          _eventsInKey[_date.format("YYYY-MM-DD")].events.push(event);
        }
      });
      return _eventsInKey;
    },
    _itemsYear() {
      const _itemsYear = [];
      for (let i = 0; i < 10; i++) {
        _itemsYear.push(this.state.year + 5 - i);
      }
      return _itemsYear;
    },
    _todayYear() {
      return this._todayMoment.get("year");
    },
    _todayMonth() {
      return this._todayMoment.get("month");
    },
    _todayDate() {
      return this._todayMoment.get("date");
    },
    _todayMoment() {
      return this.$moment();
    },
    _stateMoment() {
      return this.$moment(
        `${this.state.year}-${this.state.month}-${this.state.date}`
      );
    },
    _stateStartMonthDate() {
      return this.$moment(this._stateMoment).startOf("month");
    },
    _stateStartMonthDateWithshowWeekdays() {
      if (!this.state.showWeekdays || !this.state.showWeekdays.length) {
        return;
      }
      return this.$moment(this._stateStartMonthDate).isoWeekday(
        this.state.showWeekdays[0]
      );
    },
    _stateEndMonthDate() {
      return this.$moment(this._stateMoment).endOf("month");
    },
    _stateEndMonthDateWithshowWeekdays() {
      if (!this.state.showWeekdays || !this.state.showWeekdays.length) {
        return;
      }
      return this.$moment(this._stateEndMonthDate).isoWeekday(
        this.state.showWeekdays[this.state.showWeekdays.length - 1]
      );
    },
    _stateStartDate() {
      const _stateStartDate = this.$moment(this._stateMoment);
      return _stateStartDate.startOf("month");
    },
    _stateEndDate() {
      const _stateEndDate = this.$moment(this._stateMoment);
      return _stateEndDate.endOf("month");
    },
    _displayWeekDayCount() {
      if (Number.isInteger(this._displayDayCount / 7)) {
        return this._displayDayCount / 7;
      } else {
        return 0;
      }
    },
    _displayDayCount() {
      return (
        this._stateEndMonthDateWithshowWeekdays.diff(
          this._stateStartMonthDateWithshowWeekdays,
          "days"
        ) + 1
      );
    },
  },
  props: {
    value: {},
    showWeekdays: {},
    displayMode: {},
    height: {},
    eventHeight: {
      type: [Number, String],
      default: 24,
    },
    events: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    height: {
      handler() {
        this.$_dimSet();
      },
    },
  },
  mounted() {
    this.$_init();
    this.$nextTick(() => {
      this.$_dimSet();
    });
  },
};
</script>

<style>
</style>