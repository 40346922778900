<template>
  <div>
    <WsInfoForm
      :fields="$store.state.stone_model.xc_project.fields"
      :value="xc_project"
    ></WsInfoForm>
    <WsBtn
      v-if="$o_o.$h._m.xc_project.hasScope(['edit','admin'],xc_project.admin_scope)"
      class="mt-40"
      :to="`/xc_project/${this.xc_project.id}/update`"
    >編輯專案資訊</WsBtn>
  </div>
</template>

<script>
export default {
  props: {
    xc_project: {},
  },
};
</script>

<style>
</style>