import axios from 'axios'
export default {
  async check_and_update_version() {
    return new Promise((resolve, reject) => {
      axios
        .post(`check_and_update_version`)
        .then(res => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}