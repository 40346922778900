<template>
  <div class="ws-calendar-month__container__table__week__events__date">
    <div
      class="ws-calendar-month__container__table__week__events__date__item"
      v-for="(event,eventIndex) in _evts"
      :key="eventIndex"
      :style="[
        {'height':`${eventHeight}px`},
        {'top':`${eventIndex*eventHeight}px`},
      ]"
    >
      <div
        class="ws-calendar-month__container__table__week__events__date__item-label"
        :style="[
          {'background-color':`${event.color}`}
        ]"
      >
        {{event.label}}
      </div>
    </div>
    <div class="ws-calendar-month__container__table__week__events__date__more">

    </div>
  </div>
</template>

<script>
export default {
  computed: {
    _evts() {
      return this.events.slice(0, this.maxEventDisplayCount);
    },
  },
  props: {
    events: {},
    eventHeight: {},
    maxEventDisplayCount: {},
  },
};
</script>

<style>
</style>