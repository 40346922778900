import H_Router from '@/__stone/helpers/router'
import config from '@/__config'

const routes = [
  ...H_Router.getResourceRoutes({
    routes: ['index'],
    modelName: 'admin',
    pathName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
    viewsPath: '__modules/admin/views',
    meta: {
      middleware: 'auth',
      requiredScopes: ['admin-admin'],
    },
  }),
]

if (config.wsmodule.admin.admin_group) {
  routes.push(
    ...[
      ...H_Router.getResourceRoutes({
        routes: ['index'],
        modelName: 'admin_group',
        pathName: config.wsmodule.admin.admin_blur ? 'cmser_group' : 'admin_group',
        viewsPath: '__modules/admin/views',
        meta: {
          middleware: 'auth',
          requiredScopes: ['admin-admin'],
        },
      }),
    ],
  );
}

if (config.wsmodule.admin.admin_role) {
  routes.push(
    ...[
      ...H_Router.getResourceRoutes({
        routes: ['index'],
        modelName: 'admin_role',
        pathName: config.wsmodule.admin.admin_blur ? 'cmser_role' : 'admin_role',
        viewsPath: '__modules/admin/views',
        meta: {
          middleware: 'auth',
          requiredScopes: ['admin-admin'],
        },
      }),
    ],
  );
}

if (config.wsmodule.admin.calc) {
  routes.push(
    {
      path: '/calc/admin',
      name: 'calc_admin',
      component: () =>
        import('@/__modules/admin/views/Calc.vue'),
    }
  );
}

export default routes