<template>
  <div
    class="ws-state-radios"
    :class="[flexDirection]"
  >
    <button
      v-for="(item, itemIndex) in _items"
      :key="itemIndex"
      class="ws-state-radio mx-8"
      :class="[
        {active:$_getActive(value,item)}
      ]"
      :value="$_activeCheck(value,item.value)"
      @click.stop="$_onClick(item.value)"
    >
      <WsIcon
        class="ws-state-radio__icon true"
        name="icon-md-radio-button-checked"
      ></WsIcon>
      <WsIcon
        class="ws-state-radio__icon false"
        name="icon-md-radio-button-unchecked"
      ></WsIcon>
      <WsText class="ws-state-radio__title">{{item.label}}</WsText>
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    $_onClick(itemValue) {
      this.$emit("input", itemValue);
    },
    $_activeCheck(value, itemValue) {
      return value == itemValue;
    },
    $_getActive(value, item) {
      return item.value == value;
    },
  },
  computed: {
    _items() {
      const _items = [...this.items];
      if (this.nullable) {
        _items.unshift({
          value: null,
          label: "無",
        });
      }
      return _items;
    },
  },
  props: {
    value: {
      type: [String, Number, Boolean],
    },
    items: {
      type: Array,
    },
    nullable: {
      type: Boolean,
      default: true,
    },
    flexDirection: {
      type: String,
      default: "row",
    },
  },
};
</script>

<style>
</style>