<template>
  <div
    v-tooltip.top-center="tooltip"
    @click="$emit('click',$event)"
    class="ws-icon-btn"
    :style="[
      {width:`${size}px`},
      {height:`${size}px`},
      {'background-color':backgroundColor},
    ]"
    :class="[
      {'no-border':noBorder},
      {'no-radius':noRadius},
      {'is-round':isRound},
      {disabled:disabled},
      {hide:hide},
    ]"
  >
    <RouterLink
      v-if="to"
      class="ws-icon-btn__link"
      :to="to"
    ></RouterLink>
    <WsText
      size="14"
      v-if="text"
    >
      <slot></slot>
    </WsText>
    <WsIcon
      v-else
      :size="_iconSize"
      :name="name"
      :color="iconColor"
    />
  </div>
</template>

<script>
export default {
  computed: {
    _iconSize() {
      if (this.iconSize) {
        return this.iconSize;
      } else {
        return this.size / 2;
      }
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null,
    },
    tooltipPosition: {
      type: String,
      default: "bottom",
    },
    iconColor: {
      type: String,
      default: "textSecondary",
    },
    noBorder: {},
    iconSize: {},
    color: {
      type: String,
      default: "border",
    },
    size: {
      type: [Number, String],
      default: 32,
    },
    backgroundColor: {
      type: String,
    },
    name: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    hide: {
      type: Boolean,
      default: false,
    },
    noRadius: {},
    isRound: {},
  },
};
</script>