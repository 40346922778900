import H_Router from '@/__stone/helpers/router'

const routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: [
      'index',
      'read',
      'create',
      'update',
      'index_my',
      'create_my',
    ],
    modelName: 'xc_work_overtime',
    middleware: 'auth',
  }),
]
export default routes