<template>
  <WsUpdate
    label="通用設定"
    :fields="_fields"
    :primary="_primary"
    getUrl="/system_setting/general"
    :completeRedirect="false"
    :postEncode="false"
  >
  </WsUpdate>
</template>

<script>
export default {

  computed: {
    _fields() {
      return this.$o_o.$h.module.extendModuleHelperAction(
        "system_setting",
        "system_setting.js",
        "getSystemSettingGenrealFields",
        {}
      );
    },
    _primary() {
      return [
        {
          type: "stateCard",
          floors: [
            {
              fields: this.$o_o.$h.module.extendModuleHelperAction(
                "system_setting",
                "system_setting.js",
                "getSystemSettingGenrealShowFields",
                {}
              ),
            },
          ],
        },
      ];
    },
  },
};
</script>

<style>
</style>