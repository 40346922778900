<template>
  <WsFlex class="flex-full">
    <div class="w-12 flex-0">
      <CalendarControllPanel
        @prev="$refs.WsCmsCalendar.prev()"
        @next="$refs.WsCmsCalendar.next()"
        @goDate="$refs.WsCmsCalendar.goDate($event)"
        @changeView="$refs.WsCmsCalendar.changeView($event)"
      ></CalendarControllPanel>
      <WsStateForm
        v-model="filter.state"
        :fields="filter.fields"
      ></WsStateForm>
    </div>
    <WsCmsCalendar
      ref="WsCmsCalendar"
      :hasToolbar="false"
      height="100%"
      :extendModelsdatas="extendModelsdatas"
      :params="_params"
    >
    </WsCmsCalendar>
  </WsFlex>
</template>

<script>
export default {
  data() {
    return {
      extendModelsdatas: {
        xc_task: [],
      },
      filter: {
        fields: {
          // xc_project: {
          //   type: "belongsTo",
          //   label: "專案",
          //   textKey: "name",
          //   modelName: "xc_project",
          // },
        },
        state: {
          // xc_project: this.xc_project,
        },
      },
    };
  },
  computed: {
    _filterParams() {
      return this.$o_o.$h.model.getFormatedStates(
        this.filter.fields,
        this.filter.state
      );
    },
    _params() {
      return {
        xc_project: this.xc_project.id,
      };
    },
  },
  props: {
    xc_project: {},
  },
};
</script>

<style>
</style>