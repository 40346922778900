<template>
  <div
    class="ws-task-status-label"
    :class="[_statusText]"
  >{{_statusText}}</div>
</template>

<script>
export default {
  props: {
    status: {},
    value: {},
  },
  computed: {
    _status() {
      if (this.status) {
        return this.status;
      } else {
        return this.value;
      }
    },
    _statusText() {
      const _items = this.$store.state.stone_model.xc_task.fields.status.items;
      const _item = _items.find((e) => {
        return String(e.value) == String(this._status);
      });
      return _item ? _item.label : "-";
      // switch (String(this._status)) {
      //   case "1":
      //     return "Stay";
      //   case "2":
      //     return "TR";
      //   case "3":
      //     return "Ready";
      //   case "4":
      //     return "Open";
      //   case "5":
      //     return "Done";
      //   case "6":
      //     return "Close";
      //   case "11":
      //     return "Done-Reject";
      //   case "12":
      //     return "Pending";

      //   default:
      //     return "-";
      // }
    },
  },
};
</script>

<style lang="scss">
.ws-task-status-label {
  font-size: 0.75rem;
  line-height: 1;
  background-color: var(--gray);
  padding: 4px 8px;
  border-radius: 20px;
  &.Stay {
    background-color: var(--gray4d);
  }
  &.TR {
    background-color: var(--danger);
  }
  &.Ready {
    background-color: var(--gray);
  }
  &.Open {
    background-color: var(--gray);
  }
  &.Done {
    background-color: var(--primary);
  }
  &.Done-Reject {
    background-color: var(--danger);
  }
  &.Close {
    background-color: var(--gray4d);
  }
  &.Pending {
    background-color: var(--black4l);
  }
}
</style>