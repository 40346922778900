<template>
  <WsAnalyticsCard :title="title">
    <template v-slot:titleright>
      <WsStateForm
        v-model="filter"
        :fields="fields"
      ></WsStateForm>
    </template>
    <template v-slot:content>
      <WsActionApi
        :fetchUrl="fetchUrl"
        :params="_params"
        v-slot="{ res, isFetching }"
      >
        <WsLoading v-if="isFetching"></WsLoading>
        <WsChartLine
          v-else
          :height="180"
          :labels="$_getLabels(res)"
          :datasets="$_getDatasetss(res)"
        ></WsChartLine>
      </WsActionApi>
    </template>
  </WsAnalyticsCard>
</template>

<script>
export default {
  components: {},
  methods: {
    $_getLabels(res) {
      if (!res) {
        return [];
      }
      const _labels = [];
      if (res.range_unit == "day") {
        for (const trendingKey in res.trending) {
          _labels.push(this.$moment(trendingKey).format("YY-M-D"));
        }
      } else if (res.range_unit == "month") {
        for (const trendingKey in res.trending) {
          _labels.push(this.$moment(trendingKey).format("Y-M"));
        }
      }
      return _labels;
    },
    $_getDatasetss(res) {
      const datasets = [];
      const datas = [];
      if (!res) {
        return [];
      }
      for (const trendingKey in res.trending) {
        const _trendingValue = res.trending[trendingKey];
        datas.push(_trendingValue);
      }
      datasets.push({
        label: this.title,
        borderColor: this.$color.primary4l,
        backgroundColor: this.$color.primary4l,
        color: this.$color.primary4l,
        data: datas,
      });
      return datasets;
    },
  },
  data() {
    return {
      filter: {},
    };
  },
  computed: {
    _params() {
      return {
        ...this.$o_o.$h.model.getFormatedStates(this.fields, this.filter),
        ...this.params,
      };
    },
  },
  mounted() {},
  props: {
    title: {},
    fields: {},
    fetchUrl: {},
    params: {},
  },
};
</script>