<template>
  <WsText size="14">{{$moment(value).format('HH:mm:ss')}}</WsText>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
};
</script>

<style>
</style>