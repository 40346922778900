import H_Router from '@/__stone/helpers/router'

const routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: [
      'index',
      'read',
      'create',
      'update',
    ],
    modelName: 'xc_customer',
    middleware: 'auth',
    requireScopes: [
      'xc_customer-admin'
    ]
  }),
]
export default routes