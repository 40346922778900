<template>
  <div class="ws-info-link">
    <WsText v-if="displayInText">{{value}}</WsText>
    <a
      v-else
      class="ws-info-link__a"
      :href="value"
      target="_blank"
    >
      <WsIcon
        v-if="value"
        size="24"
        name="icon-md-link"
      />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    displayInText: {
      type: Boolean,
    },
  },
};
</script>