var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ws-avatar",style:([
    {'background-image':`url(${_vm._src})`},
    {'width':`${_vm.size}px`},
    {'height':`${_vm.size}px`},
    {'border-color':_vm.borderColor},
    {'border-width':`${_vm.borderWidth}px`},
  ])},[(_vm.to)?_c('RouterLink',{staticClass:"ws-avatar__link",attrs:{"to":_vm.to}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }