<template>
  <div class="ws-info-locale-message">
    <!-- <WsRow
      v-for="(itemKey, itemIndex) in items"
      :key="itemIndex"
    >
      <WsCol class="col-6">
        <WsText>{{itemKey}}</WsText>
      </WsCol>
      <WsCol class="col-6">
        <WsText>{{value[itemKey]}}</WsText>
      </WsCol>
    </WsRow> -->
    <table>
      <tr
        v-for="(itemKey, itemIndex) in items"
        :key="itemIndex"
      >
        <td>
          <WsText>{{itemKey}}</WsText>
        </td>
        <td>
          <WsText>{{value[itemKey]}}</WsText>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style>
</style>