<template>
  <div
    class="ws-tooltip"
    v-tooltip.top-center="value"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
    },
  },
};
</script>