<template>
  <div class="ws-state-date">
    <DatePicker
      ref="input"
      :lang="lang"
      class="ws-state-date-picker"
      :value="_value"
      @input="$_onInput($event)"
      :placeholder="placeholder"
      :format="format"
      :clearable="clearable"
      :disabled-date="$_disabledDate"
      :disabled="disabled"
      range
      :shortcuts="_shortcuts"
    >
      <slot name="icon-calendar">
        <i class="icon-calendar"></i>
      </slot>
    </DatePicker>
  </div>
</template>

<script>
export default {
  name: "WsStateDate",
  data: () => ({
    lang: {
      formatLocale: {
        firstDayOfWeek: 7,
      },
    },
  }),

  props: {
    disabledDate: {
      type: Function,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    value: {},
    placeholder: {
      type: String,
      default: "選擇日期",
    },
    format: {
      type: String,
      default: "YYYY-MM-DD",
    },
    startDate: {
      type: String,
    },
    modelData: {
      type: Object,
    },
    shortcuts: {
      type: Array,
      default() {
        return []
      }
    }
  },

  computed: {
    _value() {
      if (this.value) {
        return this.value.map((item) => {
          return this.$moment(item).toDate();
        });
      } else {
        return null;
      }
    },
    _shortcuts() {
      let shortcuts = []
      this.shortcuts.forEach(item => {
        let shortcut
        let start = new Date()
        let end = new Date()
        switch (item) {
          case 'Last_7_Days':
            shortcut = {
              text: '近 7 日',
              onClick() {
                start.setTime(end.getTime() - 7 * 24 * 3600 * 1000)
                return [start, end]
              }
            }
            break;
          case 'Last_14_Days':
            shortcut = {
              text: '近 14 日',
              onClick() {
                start.setTime(end.getTime() - 14 * 24 * 3600 * 1000)
                return [start, end]
              }
            }
            break;
          case 'Last_30_Days':
            shortcut = {
              text: '近 30 日',
              onClick() {
                start.setTime(end.getTime() - 30 * 24 * 3600 * 1000)
                return [start, end]
              }
            }
            break;
          case 'This_Month':
            shortcut = {
              text: '本月',
              onClick() {
                start = new Date(end.getFullYear(), end.getMonth(), 1)
                return [start, end]
              }
            }
            break;
          case 'Last_Month':
            shortcut = {
              text: '上個月',
              onClick() {
                start = new Date(end.getFullYear(), end.getMonth() - 1, 1)
                const endOfMonth = new Date(end.getFullYear(), end.getMonth(), 0)
                return [start, endOfMonth]
              }
            }
            break;
          case 'This_Season':
            shortcut = {
              text: '本季',
              onClick() {
                const quarter = Math.floor(end.getMonth() / 3)
                start = new Date(end.getFullYear(), quarter * 3, 1)
                const endOfQuarter = new Date(end.getFullYear(), quarter * 3 + 3, 0)
                return [start, endOfQuarter]
              }
            }
            break;
          case 'Last_Season':
            shortcut = {
              text: '上一季',
              onClick() {
                const quarter = Math.floor(end.getMonth() / 3) - 1
                start = new Date(end.getFullYear(), quarter * 3, 1)
                const endOfLastQuarter = new Date(end.getFullYear(), quarter * 3 + 3, 0)
                return [start, endOfLastQuarter]
              }
            }
            break;
          case 'This_Year':
            shortcut = {
              text: '今年',
              onClick() {
                start = new Date(new Date().getFullYear(), 0, 1)
                end = new Date(new Date().getFullYear(), 11, 31)
                return [start, end]
              }
            }
            break;
          default:
            break;
        }
        shortcuts.push(shortcut)
      })
      return shortcuts
    }
  },

  methods: {
    $_disabledDate(date) {
      let check = false;
      if (this.disabledDate) {
        check = this.disabledDate(date);
      }
      if (!check && this.startDate) {
        if (this.$moment(date).isBefore(this.startDate)) {
          check = true;
        }
      } else if (typeof this.disabledDate == "function") {
        return this.disabledDate(this.modelData, date);
      } else {
        return false;
      }
      return check;
    },
    $_onInput($event) {
      if (!$event || !$event.length) {
        this.$emit("input", null);
        return;
      }
      if (
        !this.$moment($event[0]).isValid() ||
        !this.$moment($event[1]).isValid()
      ) {
        this.$emit("input", null);
        return;
      }
      this.$emit("input", [
        this.$moment($event[0]).format(this.format),
        this.$moment($event[1]).format(this.format),
      ]);
    },
  },
};
</script>