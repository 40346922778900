const model = {
  options: {
    id: true,
    timestamp: true,
    tags: true,
  },
  modelName: "xc_customer",
  label: "客戶",
  fields: {
    name: {
      type: 'text',
      label: '名稱',
      autofocus: true
    },
    score: {
      type: "number",
      label: "分數",
      defaultValue: 5
    },
    uniform_numbers: {
      type: 'text',
      label: '統一編號',
    },
    email: {
      type: 'email',
      label: 'Email',
    },
    tel: {
      type: 'text',
      label: '電話',
    },
    address: {
      type: 'text',
      label: '地址'
    },
    officialsite_url: {
      type: "link",
      label: "官網連結",
    },
    remark: {
      type: 'textarea',
      label: '備註',
    },
    contact_windows: {
      type: "list",
      label: "聯絡窗口",
      fields: {
        name: {
          type: 'text',
          label: '名稱'
        },
        mobile: {
          type: 'text',
          label: '手機'
        },
        email: {
          type: 'email',
          label: 'Email'
        },
        line: {
          type: 'text',
          label: 'LINE'
        },
        facebook: {
          type: 'text',
          label: 'Facebook'
        },
        remark: {
          type: 'textarea',
          label: '備註'
        },
      }
    },
    status: {
      type: 'radio',
      label: '狀態',
      items: [
        { label: '尚未合作', value: 1 },
        { label: '已合作', value: 2 },
      ],
      defaultValue: 1
    },
    source: {
      type: 'radio',
      label: '來源',
      items: [
        { label: 'Google搜尋', value: 'googlesearch' },
        { label: '廣告', value: 'googleads' },
        { label: '轉介', value: 'referral' },
      ],
    },
    source_remark: {
      type: 'textarea',
      label: '來源備註'
    }
  },
  crudSetting: {
    index: {
      filterTabs: [
        {
          text: "已合作",
          value: '2',
          params: {
            status: 2,
          },
        },
        {
          text: "尚未合作",
          value: '1',
          params: {
            status: 1,
          },
        },
        {
          text: "全部",
          value: 'all',
          params: {},
        }
      ],
      showFields: [
        'name',
        'status',
        'uniform_numbers',
        'email',
        'tel',
        'officialsite_url',
        'tags',
      ],
      pageMode: false,
    },
  }
}

export default model