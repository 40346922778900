import config from '@/__config'
import i18n from '@/i18n';

const menuAdmin = [
  {
    title: i18n.t('後台使用者'),
    link: config.wsmodule.admin.admin_blur ? '/cmser' : '/admin',
    titleInLocale: true,
  },
];

if (config.wsmodule.admin) {
  if (config.wsmodule.admin.admin_group) {
    menuAdmin.push({
      title: i18n.t('後台使用者分群'),
      link: config.wsmodule.admin.admin_blur ? '/cmser_group' : '/admin_group',
    });
  }
  if (config.wsmodule.admin.admin_role) {
    menuAdmin.push({
      title: i18n.t('後台使用者角色管理'),
      link: config.wsmodule.admin.admin_blur ? '/cmser_role' : '/admin_role',
    });
  }
}

const _menu = {
}

if (config.wsmodule.admin?.calc) {
  _menu.admin_calc = {
    icon: 'icon-md-trending-up',
    title: '統計分析',
    link: '/calc/admin',
    scopes: ['admin-admin'],
  }
}

_menu.admin = {
  icon: 'icon-md-groups',
  title: i18n.t('後台使用者管理'),
  titleInLocale: true,
  menu: menuAdmin,
  scopes: ['admin-admin'],
}

export default _menu