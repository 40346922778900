export default {
  getFileNameByUrl(url) {
    if (!url) {
      return ''
    }
    return url.substring(url.lastIndexOf('/') + 1);
  },
  getBase64FromFile(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = function (e) {
        resolve(e.target.result);
      };

      reader.onerror = function (error) {
        reject(error);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    });
  },
  getGeneralFileTypeFromFileType(fileType) {
    switch (fileType) {
      case "image/png":
        return "image";
      case "image/jpeg":
        return "image";
      case "image/gif":
        return "image";
      case "image/svg+xml":
        return "image";
      case "image/*":
        return "image";
      case "video/mp4":
        return "video";
      case "video/quicktime":
        return "video";
      default:
        return "file";
    }
  },
  getDurationFromUrl(url) {
    return new Promise((resolve, reject) => {
      var audio = new Audio();
      const onLoadedMetadata = () => {
        audio.removeEventListener('loadedmetadata', onLoadedMetadata);
        resolve(audio.duration);
      };
      audio.addEventListener('loadedmetadata', onLoadedMetadata);
      audio.src = url;
      audio.onerror = () => {
        audio.removeEventListener('loadedmetadata', onLoadedMetadata);
        reject(new Error("Error loading audio file."));
      };
    });
  },
  getFileExtension(filename) {
    if (!filename) {
      return '';
    }

    const parts = filename.split('.');

    if (parts.length < 2) {
      return '';
    }

    return parts.pop();
  }
}