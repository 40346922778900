<template>
  <div class="ws-calendar__container__table__header">
    <div
      class="ws-calendar__container__table__header__item"
      v-for="showWeekday in showWeekdays"
      :key="`weekday-tr-${showWeekday}`"
      :style="[
            ]"
    >
      {{$moment().isoWeekday(showWeekday).format('ddd')}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showWeekdays: {},
  },
};
</script>

<style>
</style>