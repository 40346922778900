var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm._htmlTag,{tag:"component",staticClass:"ws-icon",class:[
    _vm._iconClass,
    {button:_vm._hasClickListener}
  ],style:([
    {'font-size':`${_vm.size}px`},
    {'color':_vm.color},
  ]),attrs:{"to":_vm.to},on:{"click":function($event){return _vm.$emit('click',$event)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }