// 將 static/icomoon/style.css 複製過來後
// 用 command shift L 快速建立 icon array
export default {
  icon: [
    'icon-ws-outline-zoom-out',
    'icon-ws-outline-zoom-out-map',
    'icon-ws-outline-zoom-in',
    'icon-ws-outline-warning',
    'icon-ws-outline-upgrade',
    'icon-ws-outline-unfold-more',
    'icon-ws-outline-unfold-less',
    'icon-ws-outline-training',
    'icon-ws-outline-time',
    'icon-ws-outline-staff-outline',
    'icon-ws-outline-staff-filled',
    'icon-ws-outline-settings-outline',
    'icon-ws-outline-send',
    'icon-ws-outline-search',
    'icon-ws-outline-role-setting',
    'icon-ws-outline-risk-medium',
    'icon-ws-outline-risk-low',
    'icon-ws-outline-risk-high',
    'icon-ws-outline-retry',
    'icon-ws-outline-reminder',
    'icon-ws-outline-refresh',
    'icon-ws-outline-reduce',
    'icon-ws-outline-photo',
    'icon-ws-outline-phone',
    'icon-ws-outline-outline-perm-identity',
    'icon-ws-outline-opinion',
    'icon-ws-outline-more',
    'icon-ws-outline-menu',
    'icon-ws-outline-lock',
    'icon-ws-outline-link',
    'icon-ws-outline-light',
    'icon-ws-outline-license',
    'icon-ws-outline-library',
    'icon-ws-outline-info',
    'icon-ws-outline-help-outline',
    'icon-ws-outline-hammer',
    'icon-ws-outline-filter-outline',
    'icon-ws-outline-factory',
    'icon-ws-outline-eye-open',
    'icon-ws-outline-eye-close',
    'icon-ws-outline-enterprise',
    'icon-ws-outline-email',
    'icon-ws-outline-edit',
    'icon-ws-outline-edit-pencil',
    'icon-ws-outline-earth',
    'icon-ws-outline-drag-vertical',
    'icon-ws-outline-drag-horizontal',
    'icon-ws-outline-disable',
    'icon-ws-outline-delete',
    'icon-ws-outline-copy',
    'icon-ws-outline-computer',
    'icon-ws-outline-cloud-upload',
    'icon-ws-outline-cloud-download',
    'icon-ws-outline-close',
    'icon-ws-outline-circle',
    'icon-ws-outline-chevron-up',
    'icon-ws-outline-chevron-forward',
    'icon-ws-outline-chevron-forward-end',
    'icon-ws-outline-chevron-down',
    'icon-ws-outline-chevron-back',
    'icon-ws-outline-chevron-back-start',
    'icon-ws-outline-check',
    'icon-ws-outline-check-circle-outline',
    'icon-ws-outline-check-circle-outline-uncheck',
    'icon-ws-outline-camera',
    'icon-ws-outline-calendar-extension',
    'icon-ws-outline-calendar-duedate',
    'icon-ws-outline-calendar-datestart',
    'icon-ws-outline-calendar-date',
    'icon-ws-outline-bookmark',
    'icon-ws-outline-barchart-outline',
    'icon-ws-outline-attachment',
    'icon-ws-outline-arrow-up',
    'icon-ws-outline-arrow-up-and-down',
    'icon-ws-outline-arrow-right',
    'icon-ws-outline-arrow-left',
    'icon-ws-outline-arrow-go',
    'icon-ws-outline-arrow-down',
    'icon-ws-outline-archive',
    'icon-ws-outline-approved',
    'icon-ws-outline-advisory',
    'icon-ws-outline-advisory-simple',
    'icon-ws-outline-add',
    'icon-ws-officialweb-share',
    'icon-ws-officialweb-send',
    'icon-ws-officialweb-programs',
    'icon-ws-officialweb-menu',
    'icon-ws-officialweb-mail',
    'icon-ws-officialweb-location',
    'icon-ws-officialweb-design',
    'icon-ws-officialweb-close',
    'icon-ws-officialweb-client',
    'icon-ws-officialweb-choven-up',
    'icon-ws-officialweb-choven-right',
    'icon-ws-officialweb-choven-left',
    'icon-ws-officialweb-choven-down',
    'icon-ws-officialweb-call',
    'icon-ws-officialweb-arrow-upward',
    'icon-ws-officialweb-arrow-forward',
    'icon-ws-officialweb-arrow-downward',
    'icon-ws-officialweb-arrow-back',
    'icon-ws-officialweb-about',
    'icon-ws-officialweb-Inquiry',
    'icon-ws-filled-warning',
    'icon-ws-filled-settings',
    'icon-ws-filled-setting',
    'icon-ws-filled-send',
    'icon-ws-filled-risk-medium',
    'icon-ws-filled-risk-low',
    'icon-ws-filled-risk-high',
    'icon-ws-filled-logout',
    'icon-ws-filled-info',
    'icon-ws-filled-indeterminate-check-box',
    'icon-ws-filled-help',
    'icon-ws-filled-flag',
    'icon-ws-filled-earth',
    'icon-ws-filled-check-circle',
    'icon-ws-filled-check-box',
    'icon-ws-filled-chat-bubble',
    'icon-ws-filled-cancel',
    'icon-ws-filled-bookmark',
    'icon-ws-filled-approved',
    'icon-ws-filled-alert',
    'icon-ws-filled-alert-cancel',
    'icon-ws-filled-add-circle',
    'icon-sm-youtube',
    'icon-sm-twitter',
    'icon-sm-linkin',
    'icon-sm-line',
    'icon-sm-instagram',
    'icon-sm-facebook',
    'icon-md-zoom-out',
    'icon-md-zoom-out-map',
    'icon-md-zoom-in',
    'icon-md-youtube-searched-for',
    'icon-md-wrap-text',
    'icon-md-work',
    'icon-md-wifi',
    'icon-md-wifi-tethering',
    'icon-md-wifi-lock',
    'icon-md-widgets',
    'icon-md-weekend',
    'icon-md-web',
    'icon-md-web-asset',
    'icon-md-wc',
    'icon-md-wb-sunny',
    'icon-md-wb-iridescent',
    'icon-md-wb-incandescent',
    'icon-md-wb-cloudy',
    'icon-md-wb-auto',
    'icon-md-watch',
    'icon-md-watch-later',
    'icon-md-warning',
    'icon-md-wallpaper',
    'icon-md-vpn-lock',
    'icon-md-vpn-key',
    'icon-md-volume-up',
    'icon-md-volume-off',
    'icon-md-volume-mute',
    'icon-md-volume-down',
    'icon-md-voicemail',
    'icon-md-voice-chat',
    'icon-md-visibility',
    'icon-md-visibility-off',
    'icon-md-vignette',
    'icon-md-view-week',
    'icon-md-view-stream',
    'icon-md-view-quilt',
    'icon-md-view-module',
    'icon-md-view-list',
    'icon-md-view-headline',
    'icon-md-view-day',
    'icon-md-view-compact',
    'icon-md-view-comfy',
    'icon-md-view-column',
    'icon-md-view-carousel',
    'icon-md-view-array',
    'icon-md-view-agenda',
    'icon-md-videogame-asset',
    'icon-md-videocam',
    'icon-md-videocam-off',
    'icon-md-video-library',
    'icon-md-video-label',
    'icon-md-video-call',
    'icon-md-vibration',
    'icon-md-vertical-align-top',
    'icon-md-vertical-align-center',
    'icon-md-vertical-align-bottom',
    'icon-md-verified-user',
    'icon-md-usb',
    'icon-md-update',
    'icon-md-unfold-more',
    'icon-md-unfold-less',
    'icon-md-undo',
    'icon-md-unarchive',
    'icon-md-tv',
    'icon-md-turned-in',
    'icon-md-turned-in-not',
    'icon-md-tune',
    'icon-md-trending-up',
    'icon-md-trending-flat',
    'icon-md-trending-down',
    'icon-md-translate',
    'icon-md-transform',
    'icon-md-transfer-within-a-station',
    'icon-md-tram',
    'icon-md-train',
    'icon-md-traffic',
    'icon-md-track-changes',
    'icon-md-toys',
    'icon-md-touch-app',
    'icon-md-tonality',
    'icon-md-toll',
    'icon-md-today',
    'icon-md-toc',
    'icon-md-title',
    'icon-md-timer',
    'icon-md-timer-off',
    'icon-md-timer-3',
    'icon-md-timer-10',
    'icon-md-timeline',
    'icon-md-timelapse',
    'icon-md-time-to-leave',
    'icon-md-thumbs-up-down',
    'icon-md-thumb-up',
    'icon-md-thumb-down',
    'icon-md-theaters',
    'icon-md-texture',
    'icon-md-textsms',
    'icon-md-text-fields',
    'icon-md-terrain',
    'icon-md-tap-and-play',
    'icon-md-tag-faces',
    'icon-md-tablet',
    'icon-md-tablet-mac',
    'icon-md-tablet-android',
    'icon-md-tab',
    'icon-md-tab-unselected',
    'icon-md-system-update',
    'icon-md-system-update-alt',
    'icon-md-sync',
    'icon-md-sync-problem',
    'icon-md-sync-disabled',
    'icon-md-switch-video',
    'icon-md-swap-vertical-circle',
    'icon-md-swap-vert',
    'icon-md-swap-horiz',
    'icon-md-swap-calls',
    'icon-md-surround-sound',
    'icon-md-supervisor-account',
    'icon-md-subway',
    'icon-md-subtitles',
    'icon-md-subscriptions',
    'icon-md-subject',
    'icon-md-subdirectory-arrow-right',
    'icon-md-subdirectory-arrow-left',
    'icon-md-style',
    'icon-md-strikethrough-s',
    'icon-md-streetview',
    'icon-md-straighten',
    'icon-md-store',
    'icon-md-store-mall-directory',
    'icon-md-storage',
    'icon-md-stop',
    'icon-md-stop-screen-share',
    'icon-md-stay-primary-portrait',
    'icon-md-stay-primary-landscape',
    'icon-md-stay-current-portrait',
    'icon-md-stay-current-landscape',
    'icon-md-stars',
    'icon-md-star',
    'icon-md-star-half',
    'icon-md-star-border',
    'icon-md-spellcheck',
    'icon-md-speaker',
    'icon-md-speaker-phone',
    'icon-md-speaker-notes',
    'icon-md-speaker-notes-off',
    'icon-md-speaker-group',
    'icon-md-space-bar',
    'icon-md-sort',
    'icon-md-sort-by-alpha',
    'icon-md-snooze',
    'icon-md-sms',
    'icon-md-sms-failed',
    'icon-md-smartphone',
    'icon-md-slow-motion-video',
    'icon-md-slideshow',
    'icon-md-skip-previous',
    'icon-md-skip-next',
    'icon-md-sim-card',
    'icon-md-sim-card-alert',
    'icon-md-signal-wifi-statusbar-null',
    'icon-md-signal-wifi-statusbar-connected-no-internet-4-26x24px',
    'icon-md-signal-wifi-off',
    'icon-md-signal-wifi-4-bar',
    'icon-md-signal-wifi-4-bar-lock',
    'icon-md-signal-cellular-off',
    'icon-md-signal-cellular-null',
    'icon-md-signal-cellular-no-sim',
    'icon-md-signal-cellular-connected-no-internet-4-bar',
    'icon-md-signal-cellular-4-bar',
    'icon-md-shuffle',
    'icon-md-show-chart',
    'icon-md-short-text',
    'icon-md-shopping-cart',
    'icon-md-shopping-cart-remove',
    'icon-md-shopping-cart-add',
    'icon-md-shopping-basket',
    'icon-md-shop',
    'icon-md-shop-two',
    'icon-md-share',
    'icon-md-share-ios',
    'icon-md-settings',
    'icon-md-settings-voice',
    'icon-md-settings-system-daydream',
    'icon-md-settings-remote',
    'icon-md-settings-power',
    'icon-md-settings-phone',
    'icon-md-settings-overscan',
    'icon-md-settings-input-svideo',
    'icon-md-settings-input-hdmi',
    'icon-md-settings-input-component',
    'icon-md-settings-input-antenna',
    'icon-md-settings-ethernet',
    'icon-md-settings-cell',
    'icon-md-settings-brightness',
    'icon-md-settings-bluetooth',
    'icon-md-settings-backup-restore',
    'icon-md-settings-applications',
    'icon-md-send',
    'icon-md-select-all',
    'icon-md-security',
    'icon-md-search',
    'icon-md-search-no-results',
    'icon-md-sd-storage',
    'icon-md-sd-card',
    'icon-md-screen-share',
    'icon-md-screen-rotation',
    'icon-md-screen-lock-rotation',
    'icon-md-screen-lock-portrait',
    'icon-md-screen-lock-landscape',
    'icon-md-schedule',
    'icon-md-scanner',
    'icon-md-save',
    'icon-md-satellite',
    'icon-md-rv-hookup',
    'icon-md-rss-feed',
    'icon-md-rowing',
    'icon-md-router',
    'icon-md-rounded-corner',
    'icon-md-rotate-right',
    'icon-md-rotate-left',
    'icon-md-rotate-90-degrees-ccw',
    'icon-md-ring-volume',
    'icon-md-restore',
    'icon-md-restore-page',
    'icon-md-restaurant',
    'icon-md-restaurant-menu',
    'icon-md-report',
    'icon-md-report-problem',
    'icon-md-reply',
    'icon-md-reply-all',
    'icon-md-replay',
    'icon-md-replay-5',
    'icon-md-replay-30',
    'icon-md-replay-10',
    'icon-md-repeat',
    'icon-md-repeat-one',
    'icon-md-reorder',
    'icon-md-remove',
    'icon-md-remove-red-eye',
    'icon-md-remove-from-queue',
    'icon-md-remove-circle',
    'icon-md-remove-circle-outline',
    'icon-md-refresh',
    'icon-md-redo',
    'icon-md-redeem',
    'icon-md-record-voice-over',
    'icon-md-recent-actors',
    'icon-md-receipt',
    'icon-md-rate-review',
    'icon-md-radio',
    'icon-md-radio-button-unchecked',
    'icon-md-radio-button-checked',
    'icon-md-queue',
    'icon-md-queue-play-next',
    'icon-md-question-answer',
    'icon-md-query-builder',
    'icon-md-publish',
    'icon-md-priority-high',
    'icon-md-print',
    'icon-md-present-to-all',
    'icon-md-pregnant-woman',
    'icon-md-power',
    'icon-md-power-settings-new',
    'icon-md-power-input',
    'icon-md-portrait',
    'icon-md-portable-wifi-off',
    'icon-md-polymer',
    'icon-md-playlist-play',
    'icon-md-playlist-add',
    'icon-md-playlist-add-check',
    'icon-md-play-for-work',
    'icon-md-play-circle-outline',
    'icon-md-play-circle-filled',
    'icon-md-play-arrow',
    'icon-md-place',
    'icon-md-pin-drop',
    'icon-md-pie-chart',
    'icon-md-pie-chart-outlined',
    'icon-md-picture-in-picture',
    'icon-md-picture-in-picture-alt',
    'icon-md-picture-as-pdf',
    'icon-md-photo',
    'icon-md-photo-size-select-small',
    'icon-md-photo-size-select-large',
    'icon-md-photo-size-select-actual',
    'icon-md-photo-library',
    'icon-md-photo-filter',
    'icon-md-photo-album',
    'icon-md-phonelink',
    'icon-md-phonelink-setup',
    'icon-md-phonelink-ring',
    'icon-md-phonelink-off',
    'icon-md-phonelink-lock',
    'icon-md-phonelink-erase',
    'icon-md-phone-paused',
    'icon-md-phone-missed',
    'icon-md-phone-locked',
    'icon-md-phone-iphone',
    'icon-md-phone-in-talk',
    'icon-md-phone-forwarded',
    'icon-md-phone-bluetooth-speaker',
    'icon-md-phone-android',
    'icon-md-pets',
    'icon-md-personal-video',
    'icon-md-person-pin',
    'icon-md-person-pin-circle',
    'icon-md-perm-scan-wifi',
    'icon-md-perm-phone-msg',
    'icon-md-perm-media',
    'icon-md-perm-identity',
    'icon-md-perm-device-information',
    'icon-md-perm-data-setting',
    'icon-md-perm-contact-calendar',
    'icon-md-payment',
    'icon-md-pause',
    'icon-md-pause-circle-outline',
    'icon-md-pause-circle-filled',
    'icon-md-panorama',
    'icon-md-panorama-wide-angle',
    'icon-md-panorama-vertical',
    'icon-md-panorama-horizontal',
    'icon-md-panorama-fish-eye',
    'icon-md-pan-tool',
    'icon-md-palette',
    'icon-md-pageview',
    'icon-md-open-with',
    'icon-md-open-in-new',
    'icon-md-open-in-browser',
    'icon-md-opacity',
    'icon-md-ondemand-video',
    'icon-md-offline-pin',
    'icon-md-notifications',
    'icon-md-notifications-paused',
    'icon-md-notifications-off',
    'icon-md-notifications-none',
    'icon-md-notifications-active',
    'icon-md-note',
    'icon-md-note-add',
    'icon-md-not-interested',
    'icon-md-no-sim',
    'icon-md-no-encryption',
    'icon-md-nfc',
    'icon-md-next-week',
    'icon-md-new-releases',
    'icon-md-network-locked',
    'icon-md-network-check',
    'icon-md-near-me',
    'icon-md-navigation',
    'icon-md-navigate-next',
    'icon-md-navigate-before',
    'icon-md-nature',
    'icon-md-nature-people',
    'icon-md-my-location',
    'icon-md-music-video',
    'icon-md-music-queue',
    'icon-md-music-note',
    'icon-md-music-library',
    'icon-md-multiline-chart',
    'icon-md-movie',
    'icon-md-movie-filter',
    'icon-md-movie-creation',
    'icon-md-move-to-inbox',
    'icon-md-mouse',
    'icon-md-motorcycle',
    'icon-md-more',
    'icon-md-more-vert',
    'icon-md-more-horiz',
    'icon-md-monochrome-photos',
    'icon-md-money-off',
    'icon-md-monetization-on',
    'icon-md-mode-edit',
    'icon-md-mode-comment',
    'icon-md-mms',
    'icon-md-mic',
    'icon-md-mic-off',
    'icon-md-mic-non',
    'icon-md-message',
    'icon-md-merge-type',
    'icon-md-menu',
    'icon-md-memory',
    'icon-md-markunread',
    'icon-md-markunread-mailbox',
    'icon-md-map',
    'icon-md-mail',
    'icon-md-mail-outline',
    'icon-md-loyalty',
    'icon-md-low-priority',
    'icon-md-loupe',
    'icon-md-loop',
    'icon-md-looks',
    'icon-md-looks-two',
    'icon-md-looks-one',
    'icon-md-looks-6',
    'icon-md-looks-5',
    'icon-md-looks-4',
    'icon-md-looks-3',
    'icon-md-logout',
    'icon-md-login',
    'icon-md-lock',
    'icon-md-lock-outline',
    'icon-md-lock-open',
    'icon-md-location-searching',
    'icon-md-location-on',
    'icon-md-location-off',
    'icon-md-location-edit',
    'icon-md-location-disabled',
    'icon-md-location-add',
    'icon-md-local-taxi',
    'icon-md-local-shipping',
    'icon-md-local-see',
    'icon-md-local-printshop',
    'icon-md-local-post-office',
    'icon-md-local-play',
    'icon-md-local-pizza',
    'icon-md-local-phone',
    'icon-md-local-pharmacy',
    'icon-md-local-parking',
    'icon-md-local-offer',
    'icon-md-local-movies',
    'icon-md-local-mall',
    'icon-md-local-library',
    'icon-md-local-laundry-service',
    'icon-md-local-hotel',
    'icon-md-local-hospital',
    'icon-md-local-grocery-store',
    'icon-md-local-gas-station',
    'icon-md-local-florist',
    'icon-md-local-drink',
    'icon-md-local-dining',
    'icon-md-local-convenience-store',
    'icon-md-local-car-wash',
    'icon-md-local-cafe',
    'icon-md-local-bar',
    'icon-md-local-atm',
    'icon-md-local-airport',
    'icon-md-local-activity',
    'icon-md-live-tv',
    'icon-md-live-help',
    'icon-md-list',
    'icon-md-link',
    'icon-md-linear-scale',
    'icon-md-line-weight',
    'icon-md-line-style',
    'icon-md-lightbulb-outline',
    'icon-md-library-books',
    'icon-md-library-add',
    'icon-md-lens',
    'icon-md-leak-remove',
    'icon-md-leak-add',
    'icon-md-layers',
    'icon-md-layers-clear',
    'icon-md-launch',
    'icon-md-last-page',
    'icon-md-laptop',
    'icon-md-laptop-windows',
    'icon-md-laptop-mac',
    'icon-md-laptop-chromebook',
    'icon-md-language',
    'icon-md-landscape',
    'icon-md-label',
    'icon-md-label-outline',
    'icon-md-keyboard',
    'icon-md-keyboard-voice',
    'icon-md-keyboard-tab',
    'icon-md-keyboard-return',
    'icon-md-keyboard-hide',
    'icon-md-keyboard-capslock',
    'icon-md-iso',
    'icon-md-invert-colors',
    'icon-md-invert-colors-off',
    'icon-md-insert-photo',
    'icon-md-insert-link',
    'icon-md-insert-invitation',
    'icon-md-insert-emoticon',
    'icon-md-insert-drive-file',
    'icon-md-insert-comment',
    'icon-md-insert-chart',
    'icon-md-input',
    'icon-md-info',
    'icon-md-info-outline',
    'icon-md-indeterminate-check-box',
    'icon-md-inbox',
    'icon-md-important-devices',
    'icon-md-import-export',
    'icon-md-import-contacts',
    'icon-md-image',
    'icon-md-image-aspect-ratio',
    'icon-md-http',
    'icon-md-hourglass-full',
    'icon-md-hourglass-empty',
    'icon-md-hotel',
    'icon-md-home',
    'icon-md-history',
    'icon-md-highlight',
    'icon-md-highlight-off',
    'icon-md-high-quality',
    'icon-md-help',
    'icon-md-help-outline',
    'icon-md-hearing',
    'icon-md-healing',
    'icon-md-headset',
    'icon-md-headset-mic',
    'icon-md-hdr-weak',
    'icon-md-hdr-strong',
    'icon-md-hdr-on',
    'icon-md-hdr-off',
    'icon-md-hd',
    'icon-md-groups',
    'icon-md-group',
    'icon-md-group-work',
    'icon-md-group-add',
    'icon-md-grid-on',
    'icon-md-grid-off',
    'icon-md-graphic-eq',
    'icon-md-grain',
    'icon-md-gradient',
    'icon-md-grade',
    'icon-md-gps-off',
    'icon-md-gps-not-fixed',
    'icon-md-gps-fixed',
    'icon-md-gif',
    'icon-md-get-app',
    'icon-md-gesture',
    'icon-md-gavel',
    'icon-md-games',
    'icon-md-g-translate',
    'icon-md-functions',
    'icon-md-fullscreen',
    'icon-md-fullscreen-exit',
    'icon-md-forward',
    'icon-md-forward-5',
    'icon-md-forward-30',
    'icon-md-forward-10',
    'icon-md-forum',
    'icon-md-format-underlined',
    'icon-md-format-textdirection-r-to-l',
    'icon-md-format-textdirection-l-to-r',
    'icon-md-format-text',
    'icon-md-format-strikethrough',
    'icon-md-format-size',
    'icon-md-format-shapes',
    'icon-md-format-quote',
    'icon-md-format-paint',
    'icon-md-format-list-numbered',
    'icon-md-format-list-bulleted',
    'icon-md-format-line-spacing',
    'icon-md-format-italic',
    'icon-md-format-indent-increase',
    'icon-md-format-indent-decrease',
    'icon-md-format-color-text',
    'icon-md-format-color-reset',
    'icon-md-format-color-fill',
    'icon-md-format-clear',
    'icon-md-format-bold',
    'icon-md-format-align-right',
    'icon-md-format-align-left',
    'icon-md-format-align-justify',
    'icon-md-format-align-center',
    'icon-md-font-download',
    'icon-md-folder',
    'icon-md-folder-special',
    'icon-md-folder-shared',
    'icon-md-folder-open',
    'icon-md-flip',
    'icon-md-flip-to-front',
    'icon-md-flip-to-back',
    'icon-md-flight',
    'icon-md-flight-takeoff',
    'icon-md-flight-land',
    'icon-md-flash-on',
    'icon-md-flash-off',
    'icon-md-flash-auto',
    'icon-md-flare',
    'icon-md-flag',
    'icon-md-first-page',
    'icon-md-fingerprint',
    'icon-md-find-replace',
    'icon-md-find-in-page',
    'icon-md-filter',
    'icon-md-filter-vintage',
    'icon-md-filter-tilt-shift',
    'icon-md-filter-none',
    'icon-md-filter-list',
    'icon-md-filter-hdr',
    'icon-md-filter-frames',
    'icon-md-filter-drama',
    'icon-md-filter-center-focus',
    'icon-md-filter-b-and-w',
    'icon-md-filter-9',
    'icon-md-filter-9-plus',
    'icon-md-filter-8',
    'icon-md-filter-7',
    'icon-md-filter-6',
    'icon-md-filter-5',
    'icon-md-filter-4',
    'icon-md-filter-3',
    'icon-md-filter-2',
    'icon-md-filter-1',
    'icon-md-file-upload',
    'icon-md-file-download',
    'icon-md-fiber-smart-record',
    'icon-md-fiber-pin',
    'icon-md-fiber-new',
    'icon-md-fiber-manual-record',
    'icon-md-fiber-dvr',
    'icon-md-feedback',
    'icon-md-featured-video',
    'icon-md-featured-play-list',
    'icon-md-favorite',
    'icon-md-favorite-border',
    'icon-md-fast-rewind',
    'icon-md-fast-forward',
    'icon-md-face',
    'icon-md-extension',
    'icon-md-exposure',
    'icon-md-exposure-zero',
    'icon-md-exposure-plus-2',
    'icon-md-exposure-plus-1',
    'icon-md-exposure-neg-2',
    'icon-md-exposure-neg-1',
    'icon-md-explore',
    'icon-md-explicit',
    'icon-md-exit-to-app',
    'icon-md-event-seat',
    'icon-md-ev-station',
    'icon-md-euro-symbol',
    'icon-md-error',
    'icon-md-error-outline',
    'icon-md-equalizer',
    'icon-md-enhanced-encryption',
    'icon-md-email',
    'icon-md-eject',
    'icon-md-edit',
    'icon-md-dvr',
    'icon-md-drive-eta',
    'icon-md-drag-handle',
    'icon-md-drafts',
    'icon-md-donut-small',
    'icon-md-donut-large',
    'icon-md-done',
    'icon-md-done-all',
    'icon-md-dock',
    'icon-md-do-not-disturb',
    'icon-md-do-not-disturb-on',
    'icon-md-do-not-disturb-off',
    'icon-md-do-not-disturb-alt',
    'icon-md-dns',
    'icon-md-disc-full',
    'icon-md-directions',
    'icon-md-directions-walk',
    'icon-md-directions-transit',
    'icon-md-directions-run',
    'icon-md-directions-railway',
    'icon-md-directions-car',
    'icon-md-directions-bus',
    'icon-md-directions-boat',
    'icon-md-directions-bike',
    'icon-md-dialpad',
    'icon-md-dialer-sip',
    'icon-md-devices',
    'icon-md-devices-other',
    'icon-md-device-hub',
    'icon-md-developer-mode',
    'icon-md-developer-board',
    'icon-md-details',
    'icon-md-desktop-windows',
    'icon-md-desktop-mac',
    'icon-md-description',
    'icon-md-delete',
    'icon-md-delete-sweep',
    'icon-md-delete-forever',
    'icon-md-dehaze',
    'icon-md-date-range',
    'icon-md-date-note',
    'icon-md-date-event',
    'icon-md-date-busy',
    'icon-md-date-available',
    'icon-md-data-usage',
    'icon-md-dashboard',
    'icon-md-crop',
    'icon-md-crop-square',
    'icon-md-crop-rotate',
    'icon-md-crop-portrait',
    'icon-md-crop-original',
    'icon-md-crop-landscape',
    'icon-md-crop-free',
    'icon-md-crop-din',
    'icon-md-crop-7-5',
    'icon-md-crop-5-4',
    'icon-md-crop-3-2',
    'icon-md-crop-16-9',
    'icon-md-credit-card',
    'icon-md-create',
    'icon-md-create-new-folder',
    'icon-md-copyright',
    'icon-md-control-point',
    'icon-md-control-point-duplicate',
    'icon-md-content-paste',
    'icon-md-content-cut',
    'icon-md-content-copy',
    'icon-md-contacts',
    'icon-md-contact-phone',
    'icon-md-contact-mail',
    'icon-md-confirmation-number',
    'icon-md-computer',
    'icon-md-compare',
    'icon-md-compare-arrows',
    'icon-md-comment',
    'icon-md-colorize',
    'icon-md-color-lens',
    'icon-md-collections',
    'icon-md-collections-bookmark',
    'icon-md-code',
    'icon-md-cloud',
    'icon-md-cloud-upload',
    'icon-md-cloud-queue',
    'icon-md-cloud-off',
    'icon-md-cloud-download',
    'icon-md-cloud-done',
    'icon-md-cloud-circle',
    'icon-md-closed-caption',
    'icon-md-close',
    'icon-md-clear',
    'icon-md-clear-all',
    'icon-md-class',
    'icon-md-chrome-reader-mode',
    'icon-md-chevron-up',
    'icon-md-chevron-right',
    'icon-md-chevron-left',
    'icon-md-chevron-down',
    'icon-md-check',
    'icon-md-check-circle',
    'icon-md-check-box',
    'icon-md-check-box-outline-blank',
    'icon-md-chat',
    'icon-md-chat-bubble',
    'icon-md-chat-bubble-outline',
    'icon-md-change-history',
    'icon-md-center-focus-weak',
    'icon-md-center-focus-strong',
    'icon-md-cast',
    'icon-md-cast-connected',
    'icon-md-card-travel',
    'icon-md-card-membership',
    'icon-md-card-giftcard',
    'icon-md-cancel',
    'icon-md-camera',
    'icon-md-camera-switch',
    'icon-md-camera-roll',
    'icon-md-camera-rear',
    'icon-md-camera-photo',
    'icon-md-camera-mic',
    'icon-md-camera-linked',
    'icon-md-camera-front',
    'icon-md-camera-enhance',
    'icon-md-camera-alt',
    'icon-md-call',
    'icon-md-call-to-action',
    'icon-md-call-split',
    'icon-md-call-received',
    'icon-md-call-missed',
    'icon-md-call-missed-outgoing',
    'icon-md-call-merge',
    'icon-md-call-made',
    'icon-md-call-end',
    'icon-md-calculate',
    'icon-md-cached',
    'icon-md-business',
    'icon-md-burst-mode',
    'icon-md-build',
    'icon-md-bug-report',
    'icon-md-bubble-chart',
    'icon-md-brush',
    'icon-md-broken-image',
    'icon-md-brightness-medium',
    'icon-md-brightness-low',
    'icon-md-brightness-high',
    'icon-md-brightness-auto',
    'icon-md-brightness-7',
    'icon-md-brightness-6',
    'icon-md-brightness-5',
    'icon-md-brightness-4',
    'icon-md-brightness-3',
    'icon-md-brightness-2',
    'icon-md-brightness-1',
    'icon-md-branding-watermark',
    'icon-md-border-vertical',
    'icon-md-border-top',
    'icon-md-border-style',
    'icon-md-border-right',
    'icon-md-border-outer',
    'icon-md-border-left',
    'icon-md-border-inner',
    'icon-md-border-horizontal',
    'icon-md-border-color',
    'icon-md-border-clear',
    'icon-md-border-bottom',
    'icon-md-border-all',
    'icon-md-bookmark',
    'icon-md-bookmark-border',
    'icon-md-book',
    'icon-md-blur-on',
    'icon-md-blur-off',
    'icon-md-blur-linear',
    'icon-md-blur-circular',
    'icon-md-bluetooth',
    'icon-md-bluetooth-searching',
    'icon-md-bluetooth-disabled',
    'icon-md-bluetooth-connected',
    'icon-md-bluetooth-audio',
    'icon-md-block',
    'icon-md-beenhere',
    'icon-md-battery-unknown',
    'icon-md-battery-std',
    'icon-md-battery-full',
    'icon-md-battery-charging-full',
    'icon-md-battery-alert',
    'icon-md-backup',
    'icon-md-backspace',
    'icon-md-av-timer',
    'icon-md-autorenew',
    'icon-md-audiotrack',
    'icon-md-attachment',
    'icon-md-attach-money',
    'icon-md-attach-file',
    'icon-md-assistant',
    'icon-md-assistant-photo',
    'icon-md-assignment',
    'icon-md-assignment-turned-in',
    'icon-md-assignment-returned',
    'icon-md-assignment-return',
    'icon-md-assignment-late',
    'icon-md-assignment-ind',
    'icon-md-assessment',
    'icon-md-aspect-ratio',
    'icon-md-art-track',
    'icon-md-arrow-upward',
    'icon-md-arrow-forward',
    'icon-md-arrow-drop-up',
    'icon-md-arrow-drop-up-circle',
    'icon-md-arrow-drop-down',
    'icon-md-arrow-drop-down-circle',
    'icon-md-arrow-downward',
    'icon-md-arrow-back',
    'icon-md-archive',
    'icon-md-apps',
    'icon-md-announcement',
    'icon-md-android',
    'icon-md-all-out',
    'icon-md-album',
    'icon-md-alarm',
    'icon-md-alarm-on',
    'icon-md-alarm-off',
    'icon-md-alarm-add',
    'icon-md-airplay',
    'icon-md-airplanemode-inactive',
    'icon-md-airplanemode-active',
    'icon-md-airline-seat-recline-normal',
    'icon-md-airline-seat-recline-extra',
    'icon-md-airline-seat-legroom-reduced',
    'icon-md-airline-seat-legroom-normal',
    'icon-md-airline-seat-legroom-extra',
    'icon-md-airline-seat-individual-suite',
    'icon-md-airline-seat-flat',
    'icon-md-airline-seat-flat-angled',
    'icon-md-adjust',
    'icon-md-add',
    'icon-md-add-to-queue',
    'icon-md-add-to-photos',
    'icon-md-add-circle',
    'icon-md-add-circle-outline',
    'icon-md-add-box',
    'icon-md-add-alert',
    'icon-md-add-alarm',
    'icon-md-add-a-photo',
    'icon-md-adb',
    'icon-md-account-circle',
    'icon-md-account-box',
    'icon-md-account-balance',
    'icon-md-account-balance-wallet',
    'icon-md-accessible',
    'icon-md-accessibility',
    'icon-md-access-time',
    'icon-md-access-alarms',
    'icon-md-access-alarm',
    'icon-md-3d-rotation',
    'icon-ll-risk-shutdown',
    'icon-ll-risk-fine',
    'icon-ll-risk-deposit',
    'icon-ll-risk-criminal',
    'icon-ll-risk-compensation',
    'icon-ll-nav-training-outline',
    'icon-ll-nav-sos',
    'icon-ll-nav-pie-outline',
    'icon-ll-nav-pie-filled',
    'icon-ll-nav-law-outline',
    'icon-ll-nav-law-filled',
    'icon-ll-nav-filemanage',
    'icon-ll-nav-filemanage-open',
    'icon-ll-nav-event-outline',
    'icon-ll-nav-event-filled',
    'icon-ll-nav-entry-outline',
    'icon-ll-nav-entry-filled',
    'icon-ll-nav-checksheet-outline',
    'icon-ll-nav-checksheet-filled',
    'icon-ll-nav-change-outline',
    'icon-ll-nav-change-filled',
    'icon-ll-nav-board',
    'icon-ll-nav-board-outline',
    'icon-ll-nav-audit-outline',
    'icon-ll-nav-audit-filled',
    'icon-ll-nav-assignment',
    'icon-ll-nav-assignment-outline',
    'icon-ll-nav-assignment-filled',
    'icon-ll-nav-app-menu',
    'icon-ll-nav-alert-outline',
    'icon-ll-nav-alert-filled',
    'icon-ll-nav-add-audit',
    'icon-ll-nav-add-assignment',
    'icon-ll-event-ticket',
    'icon-ll-event-error',
    'icon-ll-event-audit',
    'icon-ll-event-accident',
    'icon-ll-esh-water',
    'icon-ll-esh-toxicity',
    'icon-ll-esh-specialized',
    'icon-ll-esh-solid',
    'icon-ll-esh-safety',
    'icon-ll-esh-safe',
    'icon-ll-esh-poison',
    'icon-ll-esh-noise',
    'icon-ll-esh-labor',
    'icon-ll-esh-injury',
    'icon-ll-esh-impact',
    'icon-ll-esh-health',
    'icon-ll-esh-food-safety',
    'icon-ll-esh-firefighting',
    'icon-ll-esh-file',
    'icon-ll-esh-factory',
    'icon-ll-esh-evironment',
    'icon-ll-esh-earth',
    'icon-ll-esh-common',
    'icon-ll-esh-clean',
    'icon-ll-esh-chemistry',
    'icon-ll-esh-canteen',
    'icon-ll-esh-air',
    'icon-fa-video',
    'icon-fa-utensils',
    'icon-fa-score',
    'icon-fa-play',
    'icon-fa-menu',
    'icon-fa-list-ul',
    'icon-fa-download',
    'icon-fa-cloud-download-alt',
    'icon-fa-close',
    'icon-fa-clock',
    'icon-fa-calendar-custom',
  ]
}