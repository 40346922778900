<template>
  <div
    class="ws-content-tab-bar"
    :class="{height:height,'mt-Lg':mtLg}"
  >
    <WsContentTab
      v-for="(tab, tab_k) in contentTabs"
      :key="tab_k"
      :text-label="tab.textLabel"
      :link="tab.link"
      :num="tab.num"
      :activiated="$_getIsActiviated(tab)"
      :content-width="contentWidth"
      :wide="wide"
      :done-icon="tab.doneIcon"
      @click.native="$_contentTabOnclick(tab)"
    />
  </div>
</template>

<script>
export default {
  name: "WsContentTabBar",
  props: {
    doneIcon: {
      type: Boolean,
      default: false,
    },
    marginTop: {
      type: Number,
      default: 0,
    },
    selectedTabId: {
      type: String,
      default: "",
    },
    contentTabs: {
      type: Array,
      default() {
        return [];
      },
    },
    height: {
      type: Boolean,
      default: false,
    },
    contentWidth: {
      type: Number,
      default: 140,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    mtLg: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    $_getIsActiviated(tab) {
      return this.selectedTabId === tab.id;
    },
    $_contentTabOnclick(tab) {
      this.$emit("click", tab);
    },
  },
};
</script>