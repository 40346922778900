export default {
  system_event: {
    icon: 'icon-fa-calendar-custom',
    title: '系統行日曆項目',
    menu: [
      {
        title: '額外工作日',
        link: '/additional_on_day',
      },
      {
        title: '額外休息日',
        link: '/additional_off_day',
      },
    ],
    scopes: ['system_event-admin'],
  },
};