const model = {
  fields: {
    xc_service_orders: {
      type: 'hasMany',
      label: '訂單',
      textKey: "name",
      modelName: "xc_service_order",
      relationPopup: true,
      showFields: [
      ]
    }
  },
  crudSetting: {
    index: {
      showFields: [
      ]
    },
    read: {
      leftFields: [
        "xc_service_orders",
      ],
    },
  }
}
export default model