export default {
  $_returnMyBoardComponent() {
    return [
      {
        keepLocation: 'left', 
        componentName: 'filesListBoard',
        sq: 4
      }
    ]
  }
}