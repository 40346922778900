<template>
  <div
    class="ws-navigation-drawer"
    :class="[{active:$store.state.app.sideMenuOpen}]"
  >
    <div
      @click="$_close()"
      class="bg"
    ></div>
    <div
      class="ws-navigation-drawer__container"
      :style="[{'background-color':backgroundColor}]"
    >
      <RouterLink
        to="/"
        class="ws-navigation-drawer__logo"
      >
        <img
          src="@/__stone/img/wasajon.svg"
          alt=""
        >
        <WsText
          class="ml-10"
          bold
        >管理後台</WsText>
      </RouterLink>
      <div class="ws-navigation-drawer__menu">
        <WsMenuGroup :items="_menu"></WsMenuGroup>
      </div>
    </div>
  </div>
</template>

<script>
import S_APP_Cms from "@/__stone/service/app/cms";
export default {
  computed: {
    _mainScope() {
      return S_APP_Cms.getMainScope();
    },
    _menu() {
      return this.$o_o.$h.menu.getCmsMenu(this.$store.state.app.topic);
    },
  },

  methods: {
    $_close() {
      this.$store.dispatch("app/menuClose");
    },
  },

  props: {
    icon: {
      type: String,
      default: null,
    },
    contentPadding: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
    },
  },
};
</script>