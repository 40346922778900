import config from '@/__config';

export default {
  options: {
    // id: true,
    timestamp: true,
  },
  modelName: "xc_project_member",
  label: "專案成員",
  fields: {
    scopes: {
      type: 'radio',
      label: '權限',
      items: [
        {
          label: '瀏覽者',
          value: 'read'
        },
        {
          label: '編輯者',
          value: 'edit'
        },
        {
          label: '管理者',
          value: 'admin'
        },
      ]
    },
    admin: {
      type: 'belongsTo',
      label: '人員',
      textKey: 'name',
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
      extendParams: {
        is_active: 1
      }
    },
  },
  crudSetting: {
    index: {
      inRowBtnRead: false,
    }
  }
}