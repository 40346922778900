  <template>
  <div v-show="false"></div>
</template>

  <script>
export default {
  methods: {
    $_setWindow() {
      this.$store.dispatch("app/setWindow", window);
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    $_setCursor($event) {
      this.$store.dispatch("app/setCursor", $event);
    },
    $_resetCursor() {
      this.$store.dispatch("app/setCursor", {
        pageX: 0,
        pageY: 0,
      });
    },
    $_setBodyScrollTop() {
      const bodyScrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.$store.dispatch("app/setBodyScrollTop", bodyScrollTop);
    },
    $_handleResize() {
      this.$_setWindow();
      this.$_resetCursor();
    },
    $_handleMousemove($event) {
      this.$_setCursor($event);
    },
    $_handleScroll() {
      this.$_setBodyScrollTop();
    },
    $_handleTouchstart($event) {
      const touch = this.$_getTouches($event)[0];
      this.$store.dispatch("app/setTouch", {
        clientX: touch.clientX,
        clientY: touch.clientY,
      });
    },
    $_handleTouchmove($event) {
      const touch = this.$_getTouches($event)[0];
      this.$store.dispatch("app/setTouch", {
        clientX: touch.clientX,
        clientY: touch.clientY,
      });
    },
    $_handleTouchend() {
      this.$store.dispatch("app/setTouch", {
        clientX: null,
        clientY: null,
      });
    },
    $_getTouches($event) {
      return $event.touches || $event.originalEvent.touches;
    },
  },

  mounted() {
    this.$_setWindow();
  },

  props: {
    body: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    window.addEventListener("resize", this.$_handleResize);
    window.addEventListener("scroll", this.$_handleScroll);
    window.addEventListener("mousemove", this.$_handleMousemove);
    window.addEventListener("touchstart", this.$_handleTouchstart, false);
    window.addEventListener("touchmove", this.$_handleTouchmove, false);
    window.addEventListener("touchend", this.$_handleTouchend, false);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.$_handleResize);
    window.removeEventListener("scroll", this.$_handleScroll);
    window.removeEventListener("mousemove", this.$_handleMousemove);
    window.removeEventListener("touchstart", this.$_handleTouchstart);
    window.removeEventListener("touchmove", this.$_handleTouchmove);
    window.removeEventListener("touchend", this.$_handleTouchend);
  },
};
</script>

  <style>
</style>