<template>
  <WsMain>
    <FileSystemDriveIndex></FileSystemDriveIndex>
  </WsMain>
</template>

<script>
export default {};
</script>

<style>
</style>