export default {
  locales: {
    zh_tw: [
      'zh_tw',
      'tw',
      'zh-TW',
    ]
  },
  getLocaleCodeForI18n(lang) {
    let code = lang
    for (const key in this.locales) {
      const _localeCodes = this.locales[key]
      if (_localeCodes.includes(lang)) {
        code = key
      }
    }
    return code
  }
}