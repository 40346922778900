import config from '@/__config';

export default {
  modelName: "admin_event",
  label: "",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    admin: {
      type: "belongsTo",
      label: "人員",
      textKey: "name",
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
    },
    status: {
      type: 'select',
      label: '狀態',
      readonly: true,
      defauleValue: 1,
      items: [
        {
          text: '申請中',
          value: 1
        },
        {
          text: 'OK',
          value: 2
        },
        {
          text: 'No',
          value: 3
        },
      ]
    },
    name: {
      type: 'text',
      label: '名稱',
    },
    start_date: {
      type: 'date',
      label: '日期'
    },
    days: {
      type: 'number',
      label: '天數'
    },
    start_at: {
      type: 'datetime',
      label: '開始時間 (不足一日填寫)'
    },
    hours: {
      type: 'number',
      label: '時數 (不足一日填寫)'
    },
    note: {
      type: 'textarea',
      label: '備註'
    },
  },
}
