import H_Router from '@/__stone/helpers/router'

const routes = [
  ...H_Router.getResourceRoutes({
    routes: ['index'],
    modelName: 'xc_rfq',
    viewsPath: '__modules/xc_rfq/views',
    meta: {
      middleware: 'auth',
    },
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['read', 'create', 'update'],
    modelName: 'xc_rfq',
    middleware: 'auth',
    meta: {},
  }),
];
export default routes