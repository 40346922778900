<template>
  <WsMain>
    <WsFilePicker></WsFilePicker>
  </WsMain>
</template>

<script>
export default {

}
</script>

<style>

</style>