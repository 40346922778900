import axios from 'axios'
export default {
  async index_my(params) {
    return new Promise((resolve, reject) => {
      axios
        .get('my/punch', {
          params: params
        })
        .then(res => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async punch_in() {
    return new Promise((resolve, reject) => {
      axios
        .post('punch_in')
        .then(res => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async punch_out() {
    return new Promise((resolve, reject) => {
      axios
        .post('punch_out')
        .then(res => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}