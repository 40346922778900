<template>
  <div>
    <StateQuotation
      v-model="state"
    ></StateQuotation>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          @click="$router.go(-1)"
          outlined
        >{{ $t('cancel') }}</WsBtn>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{ $t('submit') }}</WsBtn>
      </template>
    </WsBottomNav>
  </div>
</template>


<script>
export default {
  data() {
    return {
      state: {}
    }
  },
  methods: {
    async $_onSubmit() {
      this.$store.dispatch("app/startPageLoading");
      try {
        await this.$o_o.$s._m.quotation.create(this.state)
        this.$router.go(-1)
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    }
  },
}
</script>