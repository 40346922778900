<template>
  <div>
    <WsModelPickerBtn
      v-if="pickFromReceivers"
      @submit="$_onModelPickerSubmit($event)"
      modelName="email_receiver"
      :showFields="showFields.email_receiver"
      class="mb-8"
    ></WsModelPickerBtn>
    <WsStateTags
      inputType="email"
      :placeholder="placeholder"
      :value="value"
      @input="$_onInput($event)"
    >
    </WsStateTags>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showFields: {
        email_receiver: ["name", "email", "tags"],
      },
    };
  },
  methods: {
    $_onModelPickerSubmit($event) {
      const _maileFromPicker = $event.map((item) => item.email);
      let _value = this.value ? [...this.value] : [];
      _value = [..._value, ..._maileFromPicker];
      this.$emit("input", _value);
    },
    $_onInput($event) {
      this.$emit("input", $event);
    },
  },
  props: {
    value: {},
    pickFromReceivers: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "輸入後按 Enter 以新增多筆",
    },
  },
};
</script>

<style>
</style>