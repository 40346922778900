<template>
  <WsMain>
    <WsImg
      v-if="state.url"
      :url="state.url"
      alt=""
    />
    <WsState
      class="mt-20"
      label="連結"
      v-model="state.url"
    ></WsState>
    <!-- <WsState
      label="名稱"
      v-model="state.name"
    ></WsState> -->
  </WsMain>
</template>

<script>
import $_PocketImage from "@/__stone/service/api/v1/pocket_image";
export default {
  data: () => ({
    state: {
      name: "",
      url: "",
    },
  }),

  methods: {
    submit() {
      return new Promise((resolve, reject) => {
        const _url = this.state.url;
        const _name = this.$o_o.$h.file.getFileNameByUrl(this.state.url);
        $_PocketImage
          .create({
            // name: this.state.name,
            name: _name,
            url: _url,
            signed: 0,
          })
          .then((res) => {
            resolve(res);
            this.$emit("submit", res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
</script>

<style>
</style>