<template>
  <WsCard>
    <WsText size="16" class="mb-8">待我簽核的公出單</WsText>
    <WsModelFetchIndex
      modelName="admin_event"
      :params="_params"
      v-slot="{modeldatas}"
    >
      <WsModelIndexTable
        :modeldatas="modeldatas"
        v-bind="$props"
        :fields="_fields"
        :showFields="showFields"
        :readable="true"
        :inRowBtnRead="true"
      />
    </WsModelFetchIndex>
  </WsCard>
</template>

<script>
export default {
  data() {
    return {
      showFields: [
        'name',
        'start_date',
        'status',
      ]
    }
  },
  computed: {
    _params() {
      return {
        type: 'off_day_break,off_day_bereave,off_day_something,off_day_ill',
      }
    },
    _fields() {
      return this.$store.state.stone_model.admin_event.fields
    }
  },
}
</script>
