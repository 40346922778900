import axios from 'axios'
import moment from 'moment'

export default {
  open(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`xc_task/${id}/open`)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  done(id, finish_hour) {
    return new Promise((resolve, reject) => {
      axios
        .post(`xc_task/${id}/done`, {
          finish_hour: finish_hour
        })
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  reject(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`xc_task/${id}/reject`)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  close(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`xc_task/${id}/close`)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  pending(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`xc_task/${id}/pending`)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  due_date(id, due_date) {
    return new Promise((resolve, reject) => {
      axios
        .post(`xc_task/${id}/due_date`, {
          due_date: moment(due_date).format('YYYY-MM-DD')
        })
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  start_at(id, start_at) {
    return new Promise((resolve, reject) => {
      axios
        .post(`xc_task/${id}/start_at`, {
          start_at: start_at,
          due_date: moment(start_at).format('YYYY-MM-DD')
        })
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  date_reset(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`xc_task/${id}/date_reset`)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  tr_request(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`xc_task/${id}/tr_request`)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  tr(id, hour) {
    return new Promise((resolve, reject) => {
      axios
        .post(`xc_task/${id}/tr`, {
          hour: hour
        })
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  show_my(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`my/xc_task/${id}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  reopen(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`xc_task/${id}/reopen`)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  taker_status_in_progress(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`xc_task/${id}/taker_status/in_progress`)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  taker_status_stay(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`xc_task/${id}/taker_status/stay`)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}