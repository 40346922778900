import S_APP_General from "@/__stone/service/app/general";
// import $c from '@/__config'
import store from '@/store'
import H_Scope from '@/__stone/helpers/scope'

export default {
  getScopeKeys() {
    const _scopeKeys = [];
    H_Scope.getAdminScopes().forEach((scope) => {
      _scopeKeys.push(scope.value);
    });
    return _scopeKeys;
  },
  getMainScope() {
    if (
      !store.state.auth.currentUser ||
      !store.state.auth.scopes
    ) {
      return null;
    }
    const _mainScope = S_APP_General.getPrimaryMatch(
      store.state.auth.scopes,
      this.getScopeKeys()
    );
    return _mainScope;
  },
}