<template>
  <div class="ws-pocket-image-picker">
    <WsDialog
      ref="WsDialog"
      :value="value"
      @input="$emit('input',$event)"
      :title="$t('pick_image')"
      :contentHasPadding="false"
      @close="$_onDialogClose()"
    >
      <template v-slot:content>
        <WsTabs
          v-model="focusTab"
          :items="_tabItems"
        ></WsTabs>
        <WsPocketImagePickerPocket
          v-if="focusTab=='pocket'"
          ref="WsPocketImagePickerPocket"
          @submit="$_onInputSubmit($event)"
          :multiple="multiple"
        />
        <WsPocketImagePickerUpload
          v-else-if="focusTab=='upload'"
          ref="WsPocketImagePickerUpload"
          :getUploadUrl="getUploadUrl"
          @submit="$_onInputSubmit($event)"
          :signed="signed"
        />
        <WsPocketImagePickerLink
          v-else-if="focusTab=='link'"
          ref="WsPocketImagePickerLink"
          @submit="$_onInputSubmit($event)"
        />
      </template>
      <template v-slot:rightActions>
        <WsBtn
          @click="$_onCancel()"
          :minWidth="100"
          color="textSecondary"
          outlined
        >{{$t('cancel')}}</WsBtn>
        <WsBtn
          @click="$_onSubmit()"
          :minWidth="100"
          :loading="loading.submit"
          color="primary"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsDialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: {
        submit: false,
      },
      dialog: false,
      focusTab: "pocket",
    };
  },
  mounted() {
    this.focusTab = this._tabItems[0].value;
  },
  methods: {
    $_onInputSubmit($event) {
      if (this.multiple) {
        if (this.focusTab == "pocket") {
          this.$emit("submit", $event);
        } else {
          this.$emit("submit", [$event]);
        }
      } else {
        this.$emit("submit", $event);
      }
      this.close();
    },
    close() {
      this.$emit("input", false);
    },
    $_onDialogClose() {
      this.close();
    },
    $_onCancel() {
      this.close();
    },
    async $_onSubmit() {
      this.loading.submit = true;
      try {
        if (this.focusTab == "pocket") {
          this.$refs.WsPocketImagePickerPocket.submit();
        } else if (this.focusTab == "upload") {
          await this.$refs.WsPocketImagePickerUpload.submit();
        } else if (this.focusTab == "link") {
          await this.$refs.WsPocketImagePickerLink.submit();
        }
      } finally {
        this.loading.submit = false;
      }
    },
  },
  computed: {
    _tabItems() {
      let _arr = [];
      if (this.pocketable) {
        _arr.push({
          text: this.$t("image_library"),
          value: "pocket",
        });
      }
      if (this.uploadable) {
        _arr.push({
          text: this.$t("upload_image"),
          value: "upload",
        });
      }
      if (this.linkable) {
        _arr.push({
          text: this.$t("source"),
          value: "link",
        });
      }
      return _arr;
    },
  },
  props: {
    multiple: {
      type: Boolean,
    },
    value: {
      type: Boolean,
      default: false,
    },
    signed: {
      type: Boolean,
      default: false,
    },
    getUploadUrl: {
      type: String,
      default: undefined,
    },
    pocketable: {
      type: Boolean,
      default: true,
    },
    uploadable: {
      type: Boolean,
      default: true,
    },
    linkable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>
</style>