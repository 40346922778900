<template>
  <div class="ws-file-state-image">
    <div v-if="value && value.length">
      <div
        v-for="(valueItem, valueItemIndex) in value"
        :key="valueItemIndex"
        class="ws-file-state-image"
      >
        <img
          v-lazy="valueItem"
          alt=""
          class="mb-10"
          :style="`max-width:150px;max-height:150px`"
        />
        <WsIconBtn
          @click="$_onClear(valueItemIndex)"
          class="remove-btn"
          backgroundColor="#000"
          name="icon-md-delete"
        />
      </div>
    </div>
    <FileSystemFilePickerFile
      :signed="signed"
      :storageTarget="storageTarget"
      ref="FileSystemFilePickerFile"
      @submit="$_onSubmit($event)"
      multiple
    >
    </FileSystemFilePickerFile>
    <WsBtn @click="$_onPickDialogOpen()">選擇檔案</WsBtn>
    <WsIconBtn
      @click="$_onClear()"
      v-if="value"
      class="remove-btn"
      backgroundColor="#000"
      name="icon-md-delete"
    />
  </div>
</template>

<script>
export default {
  data: () => ({
  }),

  methods: {
    $_onSubmit($event) {
      const _value = this.value ? [...this.value] : []
      this.$emit('input', [
        ..._value,
        ...$event
      ])
    },
    $_onClear(valueIndex) {
      const _value = [...this.value]
      _value.splice(valueIndex, 1)
      this.$emit('input', _value)
    },
    $_onPickDialogOpen() {
      this.$refs.FileSystemFilePickerFile.open()
    },
  },

  props: {
    value: {},
    signed: {
      type: Boolean,
      default: false,
    },
    getUploadUrl: {
      type: String,
      default: undefined,
    },
    previewMaxWidth: {
      type: String,
      default: "100%",
    },
    previewMaxHeight: {
      type: String,
      default: "100%",
    },
    storageTarget: {}
  },
};
</script>

<style></style>