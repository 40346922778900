import H_Component from '@/__stone/helpers/component'

export default (app) => {

  const layouts = require.context('@/__stone/layouts/', true, /\.vue$/i);
  layouts.keys().map(key => app.component(key.split('/').pop().split('.')[0], layouts(key).default));

  const components_general = require.context('@/__stone/components/', true, /\.vue$/i);
  components_general.keys().map(key => app.component(key.split('/').pop().split('.')[0], components_general(key).default));

  const components_model = require.context('@/__stone/components/model/', true, /\.vue$/i);
  components_model.keys().map(key => app.component(key.split('/').pop().split('.')[0], components_model(key).default));

  const components_action = require.context('@/__stone/components/action/', true, /\.vue$/i);
  components_action.keys().map(key => app.component(key.split('/').pop().split('.')[0], components_action(key).default));

  const components_app = require.context('@/__stone/components/app/', true, /\.vue$/i);
  components_app.keys().map(key => app.component(key.split('/').pop().split('.')[0], components_app(key).default));

  H_Component.loadModulesComponents(app)
  H_Component.loadExtendModulesComponents(app)
}