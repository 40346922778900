<template>
  <WsMain>
    <WsState
      class="mt-20"
      :label="$t('link')"
      v-model="state.url"
    ></WsState>
    <WsState
      :label="$t('name')"
      v-model="state.name"
    ></WsState>
  </WsMain>
</template>

<script>
import Service_App_File from "@/__stone/service/app/file";
import Service_Api_V1_PocketFile from "@/__stone/service/api/v1/pocket_file";
export default {
  data: () => ({
    state: {
      name: "",
      url: "",
    },
  }),

  methods: {
    submit() {
      return new Promise((resolve, reject) => {
        Service_Api_V1_PocketFile.create({
          name: this.state.name,
          url: this.state.url,
          signed: 0,
        })
          .then((res) => {
            resolve(res);
            this.$emit("submit", res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },

  watch: {
    "state.url": {
      handler() {
        this.state.name = Service_App_File.getFileNameByUrl(this.state.url);
      },
    },
  },
};
</script>

<style>
</style>