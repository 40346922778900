<template>
  <WsMain>
    <WsVideo :src="src"></WsVideo>
    <div>
      <WsBtn @click="$_onUploadClick()">{{$t('上傳音樂')}}</WsBtn>
    </div>
    <input
      v-show="false"
      @change="$_onChange($event)"
      type="file"
      ref="fileInput"
      accept="audio/*"
    >
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          @click="$router.go(-1)"
          outlined
        >{{$t('cancel')}}</WsBtn>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      src: null,
      file: null,
      url: null,
    };
  },
  methods: {
    async $_onChange($event) {
      this.file = $event.target.files[0];
      this.src = URL.createObjectURL(this.file);
    },
    $_onUploadClick() {
      this.$refs.fileInput.click();
    },
    async $_onSubmit() {
      try {
        if (!this.file) {
          return;
        }
        this.$store.dispatch("app/startPageLoading");
        const res = await this.$o_o.$s._m.general_audio.upload(
          this.file,
          this.file.name
        );
        this.$emit("submit", res);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
};
</script>

<style>
</style>