<template>
  <WsPopup
    :title="$t('new_image')"
    ref="imagePopup"
    :contentHasPadding="false"
  >
    <template v-slot:content>
      <WsTabs
        v-model="state.tab"
        :items="tabItems"
      ></WsTabs>
      <WsMain>
        <template v-if="state.tab=='upload'">
          <div>
            <croppa
              :width="croppa.width"
              :height="croppa.height"
              :placeholder="$t('pick_image')"
              v-model="myCroppa"
              :prevent-white-space="true"
            ></croppa>
          </div>
        </template>
        <template v-else-if="state.tab=='url'">
          <div>
            <WsStateInput
              v-model="state.link"
              autofocus
              full
            ></WsStateInput>
          </div>
        </template>
      </WsMain>
    </template>
    <template v-slot:rightActions>
      <WsBtn
        @click="$_onCancel()"
        :minWidth="100"
        color="textSecondary"
        outlined
      >{{$t('cancel')}}</WsBtn>
      <WsBtn
        @click="$_onSubmit()"
        :minWidth="100"
        color="primary"
      >{{$t('submit')}}</WsBtn>
    </template>
  </WsPopup>
</template>

<script>
import S_App_Image from "@/__stone/service/app/image";
export default {
  data: () => ({
    myCroppa: {},
    croppa: {
      width: 480,
      height: 270,
    },
    state: {
      tab: "upload",
      link: "",
    },
    tabItems: [
      {
        text: "上傳圖片",
        value: "upload",
      },
      {
        text: "貼上圖片連結",
        value: "url",
      },
    ],
  }),

  methods: {
    resizeBase64(src, w, h) {
      return new Promise((resolve, reject) => {
        // Create and initialize two canvas
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const canvasCopy = document.createElement("canvas");
        const copyContext = canvasCopy.getContext("2d");

        // Create original image
        const img = new Image();
        img.src = src;

        img.onload = () => {
          // Determine new ratio based on max size
          let ratio = 1;

          if (img.width > w) {
            ratio = w / img.width;
          } else if (img.height > h) {
            ratio = h / img.height;
          }

          // Draw original image in second canvas
          canvasCopy.width = img.width;
          canvasCopy.height = img.height;
          copyContext.drawImage(img, 0, 0);

          // Copy and resize second canvas to first canvas
          canvas.width = img.width * ratio;
          canvas.height = img.height * ratio;
          ctx.drawImage(
            canvasCopy,
            0,
            0,
            canvasCopy.width,
            canvasCopy.height,
            0,
            0,
            canvas.width,
            canvas.height
          );

          resolve(canvas.toDataURL("image/png"));
        };

        img.onerror = () => {
          reject();
        };
      });
    },
    $_onCancel() {
      this.close();
    },
    async $_onSubmit() {
      if (this.state.tab == "url") {
        this.$emit("submit-url", this.state.link);
      } else if (this.state.tab == "upload") {
        let url = this.myCroppa.generateDataUrl("image/png");
        url = await S_App_Image.resizeBase64(
          url,
          this.croppa.width,
          this.croppa.height
        );
        this.$emit("submit-upload", url);
      }
      this.$_clear();
      this.close();
    },
    $_clear() {
      this.state.url = "";
    },
    open() {
      this.$refs.imagePopup.open();
    },
    close() {
      this.$refs.imagePopup.close();
    },
  },
};
</script>

<style>
</style>