<template>
  <div
    class="ws-crud-table-more-btn"
    v-click-outside="$_onClickOutside"
  >
    <WsMoreBtn
      :items="_items"
      :noBorder="true"
      :isRound="true"
      type="horiz"
    ></WsMoreBtn>
    <!-- <WsIconBtn
      @click="$_onClick()"
      name="icon-md-more-horiz"
      :noBorder="true"
      :isRound="true"
    ></WsIconBtn> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
    };
  },
  methods: {
    $_onClick() {
      this.$store.dispatch("app/setModalMenuItems", this._items);
      setTimeout(() => {
        this.$store.dispatch("app/openModalMenu");
      }, 0);
    },
    $_onClickOutside() {
      this.active = false;
    },
    $_getCustomActionDisabled(customTableAction, item) {
      if (customTableAction.getDisabled) {
        return customTableAction.getDisabled(item);
      } else {
        return customTableAction.disabled;
      }
    },
    $_customActionDisplayChecek(customTableAction, item) {
      if (customTableAction.displayCheck) {
        return customTableAction.displayCheck(item);
      } else {
        return true;
      }
    },
    $_getCustomActionTitle(customTableAction) {
      if (customTableAction.title) {
        return customTableAction.title;
      } else if (customTableAction.text) {
        return customTableAction.text;
      } else {
        return;
      }
    },
  },
  computed: {
    _items() {
      const _items = [];
      if (this.readable) {
        _items.push({
          title: "瀏覽",
          onClick: () => {
            this.$emit("read");
          },
        });
      }
      if (this.versionable) {
        _items.push({
          title: "版本",
          onClick: () => {
            this.$emit("version");
          },
        });
      }
      if (this.updatable) {
        _items.push({
          title: "編輯",
          onClick: () => {
            this.$emit("update");
          },
        });
      }
      if (this.duplicatable) {
        _items.push({
          title: "複製",
          onClick: () => {
            this.$emit("duplicate");
          },
        });
      }
      if (this.deletable) {
        _items.push({
          title: "刪除",
          onClick: () => {
            this.$emit("delete");
          },
        });
      }
      if (this.customTableActions) {
        this.customTableActions.forEach((customTableAction) => {
          if (!this.$_customActionDisplayChecek(customTableAction, this.item)) {
            return;
          }
          _items.push({
            title: this.$_getCustomActionTitle(customTableAction),
            onClick: () => {
              this.$emit("custom-table-action", customTableAction);
            },
          });
        });
      }
      return _items;
    },
  },
  props: {
    item: {},
    readable: {},
    updatable: {},
    deletable: {},
    duplicatable: {},
    versionable: {},
    customTableActions: {},
  },
};
</script>

<style lang="scss">
.ws-crud-table-more-btn {
}
</style>