<template>
  <WsCard>
    <WsText>{{xc_task_execute_ref_item.name}}</WsText>
    <WsInfoHtml
      v-if="xc_task_execute_ref_item.content"
      class="mt-20"
      :value="xc_task_execute_ref_item.content"
    ></WsInfoHtml>
  </WsCard>
</template>

<script>
export default {
  props: {
    xc_task_execute_ref_item: {},
  },
};
</script>