export default {
  toCalendarEvent(
    xc_meeting,
  ) {
    const _event = {}
    if (!xc_meeting) {
      return
    }
    _event.title = `[會議] ${xc_meeting.name}`
    _event.color = '#0dbaa9'
    _event.start = xc_meeting.start_at
    _event.end = xc_meeting.end_at
    _event.extendedProps = {
      ...xc_meeting,
      modelName: 'xc_meeting'
    }
    return _event
  }
}