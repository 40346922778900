import config from '@/__config';
const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'punch',
  label: "打卡記錄",
  fields: {
    punch_at: {
      type: 'datetime',
      label: '打卡時間',
    },
    admin: {
      type: 'belongsTo',
      label: "打卡人",
      textKey: "name",
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
    },
    type: {
      type: 'select',
      label: '類型',
      items: [
        {
          value: 0,
          text: '上班'
        },
        {
          value: 1,
          text: '下班'
        },
      ]
    }
  },
  crudSetting: {
    index: {
      liveSearching: true,
      showFields: [
        "type",
        "punch_at",
        "admin",
      ],
      infiniteScroll: true,
      expandable: false,
      inRowBtnRead: true,
      orderBySq: true,
    },
  }
}

export default model