export default {
  xc_meeting: {
    icon: 'icon-md-groups',
    title: '會議',
    link: '/xc_meeting',
    titleInLocale: true,
    scopes: ['xc_meeting-admin'],
  },
  my_xc_meeting: {
    icon: 'icon-md-groups',
    title: '我的 會議',
    link: '/my/xc_meeting',
  },
}