<template>
  <div class="ws-group-state">
    <WsGroupStateOrder
      v-bind="$props"
      v-if="type=='order'"
      @input="$emit('input',$event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    type: {},
    value: {},
    items: {},
  },
};
</script>