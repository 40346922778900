<template>
  <WsIconBtn
    @click="$_onClick()"
    :name="`icon-md-more-${type}`"
    :noBorder="noBorder"
    :isRound="isRound"
  ></WsIconBtn>
</template>

<script>
export default {
  methods: {
    $_onClick() {
      this.$store.dispatch("app/setModalMenuItems", this.items);
      setTimeout(() => {
        this.$store.dispatch("app/openModalMenu");
      }, 0);
    },
  },
  props: {
    items: {},
    noBorder: {},
    isRound: {},
    type: {
      default: "vert",
    },
  },
};
</script>