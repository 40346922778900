<template>
  <div class="ws-state-file">
    <div
      v-if="value"
      class="ws-state-file__upload-btns"
    >
      <WsStateFileItem
        :loading="loading"
        :percent="percent"
        :url="value"
        @remove="$_onFileRemove()"
        class="mb-2"
      ></WsStateFileItem>
    </div>
    <WsBtn
      v-else
      outlined
      color="textSecondary"
      @click="$_onClick()"
    >
      <WsIcon name="icon-md-backup" /> 上傳附件
    </WsBtn>
    <input
      v-show="false"
      @change="$_onChange($event)"
      type="file"
      ref="fileInput"
    >
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    percent: 0,
  }),
  props: {
    value: {
      type: String,
      default: null,
    },
    uploadUrl: {
      type: String,
      required: true,
    },
  },
  mounted() {},
  watch: {},
  methods: {
    $_onFileRemove() {
      this.$emit("input", null);
    },
    $_handleUploadProgress(progressEvent) {
      this.percent = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
    },
    async $_onChange($event) {
      const file = $event.target.files[0];
      if (!file) {
        return;
      }
      this.loading = true;
      this.percent = 0;
      this.$axios
        .put(`${this.uploadUrl}${file.name}`, file, {
          onUploadProgress: (progressEvent) =>
            this.$_handleUploadProgress(progressEvent),
        })
        .then((res) => {
          this.$emit("input", res.data.signed_url);
        })
        .catch((error) => {
          console.error(error);
          alert("上傳錯誤");
        })
        .finally(() => {
          this.percent = 0;
          this.loading = false;
        });
    },
    $_onClick() {
      this.$refs.fileInput.click();
    },
  },
};
</script>