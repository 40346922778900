import i18n from '@/i18n.js';

export default {
  getShopOrderSourceLabel(source) {
    if (!source) {
      return '-'
    }
    if (source === 'web-pc') {
      return 'PC';
    } else if (source === 'app') {
      return 'APP';
    } else if (source === 'web-mobile') {
      return 'WEB';
    } else {
      return
    }
  },
  getShopOrderPayTypeLabel(pay_type) {
    if (!pay_type) {
      return '-'
    }
    if (pay_type === 'Credit') {
      return i18n.t('信用卡');
    } else if (pay_type === 'CVS') {
      return 'CVS';
    } else if (pay_type === 'ATM') {
      return 'ATM';
    } else if (pay_type === 'BARCODE') {
      return i18n.t('超商條碼');
    } else if (pay_type === 'line_pay') {
      return 'LINE Pay';
    } else {
      return '';
    }
  }
}