<template>
  <LayoutModelCreate
    v-bind="$store.state.stone_model.xc_project"
    returnUrl="/my/project"
  ></LayoutModelCreate>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style>
</style>