<template>
  <WsMain>
    <WsModelMyIndex
      :modelName="$store.state.stone_model.admin_event.modelName"
      label="我的請假"
      :fields="_fields"
      :inRowBtnRead="true"
      :updatable="false"
      :showFields="showFields"
      :params="_params"
    ></WsModelMyIndex>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      showFields: ["start_date", "status"],
    };
  },
  methods: {},
  computed: {
    _params() {
      return {
        type: "off_day_break,off_day_bereave,off_day_something",
      };
    },
    _fields() {
      return {
        type: {
          type: "select",
          label: "請假類型",
          defaultValue: "off_day_break",
          items: [
            {
              text: "休假",
              value: "off_day_break",
            },
            {
              text: "病假",
              value: "off_day_ill",
            },
            {
              text: "事假",
              value: "off_day_something",
            },
            {
              text: "生理假",
              value: "off_day_menstrual",
            },
          ],
        },
        ...this.$o_o.$h.model.getFieldsByKeys(
          this.$store.state.stone_model.admin_event.fields,
          ["id", "status", "start_date", "days", "start_at", "hours", "note"]
        ),
      };
    },
  },
};
</script>

<style>
</style>