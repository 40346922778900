<template>
  <WsMain>
    <WsFlex justifyContent="flex-end">
      <slot name="headerLeft"></slot>
      <WsBtn @click="$_onCreate()">
        <WsIcon name="icon-ws-outline-add" />
        <div class="ml-6">{{$t('new')}}</div>
      </WsBtn>
    </WsFlex>

    <WsActionApi
      ref="WsActionApi"
      :fetchUrl="_fetchUrl"
      :params="_params"
      :globalLoading="true"
      @onFetch="$_onFetch"
    >
    </WsActionApi>
    <WsCmsCrudClassifyItemGroup
      class="mt-20"
      :hasIsActive="hasIsActive"
      :modelName="modelName"
      :label="label"
      :classItems="state.classItems"
      :updateFields="updateFields"
      :childLayerCount="maxLayerCount"
      :extendPostData="extendPostData"
      :childKeyName="childKeyName"
      :customTableActions="customTableActions"
      @update:classItems="$_onUpdateClassItems($event)"
      @custom-table-action="$emit('custom-table-action', $event)"
    />
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      state: {
        classItems: [],
      },
    };
  },
  methods: {
    $_fetchData() {
      this.$refs.WsActionApi.$_fetch()
    },
    $_onFetch($event) {
      this.state.classItems = $event.data;
    },
    $_onUpdateClassItems($event) {
      this.$_onUpdateOrder($event);
      this.state.classItems = $event;
    },
    async $_onUpdateOrder($event) {
      this.$axios.patch(this._orderPatchUrl, {
        order: $event,
      });
    },
    async $_onCreate() {
      if (this.emitCreate) {
        this.$emit("onCreate");
        return;
      }
      try {
        this.$store.dispatch("app/startPageLoading");
        const res = await this.$axios.post(`/${this._postCreateUrl}`, {
          name: `${this.label} ${this.state.classItems.length + 1}`,
          is_active: 1,
          ...this.extendPostData,
        });
        this.state.classItems.push({
          ...res.data.data,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _orderPatchUrl() {
      if (this.orderPatchUrl) {
        return this.orderPatchUrl;
      } else {
        return `/${this.modelName}/order`;
      }
    },
    _params() {
      return {
        ...this.params,
      };
    },
    _postCreateUrl() {
      if (this.postCreateUrl) {
        return this.postCreateUrl;
      } else {
        return `/${this.modelName}`;
      }
    },
    _fetchUrl() {
      if (this.fetchUrl) {
        return this.fetchUrl;
      } else {
        return `/${this.modelName}`;
      }
    },
  },
  mounted() {},
  props: {
    hasIsActive: {},
    fetchUrl: {},
    postCreateUrl: {},
    orderPatchUrl: {},
    modelName: {},
    label: {},
    updateFields: {},
    maxLayerCount: {},
    emitCreate: {
      type: Boolean,
      default: false,
    },
    params: {},
    extendPostData: {},
    childKeyName: String,
    customTableActions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style>
</style>