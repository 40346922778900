<template>
  <div class="ws-state-list">
    <div class="ws-row full-width">
      <div class="ws-col col-6">
        <WsFlexWrap>
          <WsBtn @click="$_onCreate()">{{$t('new')}} {{label}}</WsBtn>
          <WsStateSortDialog
            :value="value"
            :textKey="textKey"
            :textKeyType="_textKeyType"
            @input="$emit('input',$event)"
          ></WsStateSortDialog>
        </WsFlexWrap>
      </div>
      <slot name="extendCols"></slot>
    </div>
    <WsCrudTable
      :headers="_tableHeaders"
      :displayFields="_displayFields"
      :fields="fields"
      :items="value"
      :paginate="false"
      :showExpand="false"
      :expandable="false"
      :inRowBtnRead="inRowBtnRead"
      :inRowBtnUpdate="inRowBtnUpdate"
      :inRowBtnDelete="inRowBtnDelete"
      :showDataTotalCount="false"
      @read="$_onRead($event)"
      @update="$_onUpdate($event)"
      @delete="$_onDelete($event)"
    ></WsCrudTable>
    <WsCreateDialog
      ref="createDialog"
      :label="label"
      :fields="_createFields"
      @submit="$_onCreateSubmit($event)"
      :postEncode="false"
    />
    <WsReadDialog
      ref="readDialog"
      :fields="_displayFields"
      @delete="$_onDelete($event)"
      @update="$_onUpdate($event)"
    ></WsReadDialog>
    <WsModelUpdateDialog
      ref="updateDialog"
      :label="label"
      :fields="_updateFields"
      @submit="$_onUpdateSubmit($event)"
      :postEncode="false"
    />
    <WsAlert
      ref="deleteAlert"
      :title="$t('sure_to_delete')"
      :description="$t('delete_cannot_be_recovered')"
      @submit="$_onDeleteSubmit($event)"
    ></WsAlert>
  </div>
</template>

<script>
import S_APP_General from "@/__stone/service/app/general";
export default {
  data: () => ({
    readingIndex: null,
    updatingIndex: null,
    deletingIndex: null,
  }),
  methods: {
    $_onRead($event) {
      this.readingIndex = $event.itemIndex;
      this.$refs.readDialog.open($event.item);
    },
    $_onUpdate($event) {
      const tarIndex =
        $event.itemIndex || $event.itemIndex == 0
          ? $event.itemIndex
          : this.readingIndex;
      const _item = this.value[tarIndex];
      this.updatingIndex = tarIndex;
      this.$refs.readDialog.close();
      this.$refs.updateDialog.open(_item);
    },
    $_onDelete($event) {
      this.$refs.deleteAlert.open({
        item: $event.item,
        itemIndex: $event.itemIndex,
      });
    },
    $_onCreate() {
      this.$refs.createDialog.open();
    },
    $_onUpdateSubmit($event) {
      const _value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      _value.splice(this.updatingIndex, 1);
      _value.splice(this.updatingIndex, 0, JSON.parse(JSON.stringify($event)));
      this.$emit("input", _value);
      this.$refs.updateDialog.close();
    },
    $_onCreateSubmit($event) {
      const _value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      _value.push(JSON.parse(JSON.stringify($event)));
      this.$emit("input", _value);
      this.$refs.createDialog.close();
    },
    $_onDeleteSubmit($event) {
      const _value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      _value.splice($event.itemIndex, 1);
      this.$emit("input", _value);
      this.$refs.deleteAlert.close();
    },
  },
  computed: {
    _createFields() {
      return S_APP_General.geCreateFields(this.fields, this.createHideFields);
    },
    _updateFields() {
      return S_APP_General.getUpdateFields(this.fields, this.updateHideFields);
    },
    _displayFields() {
      return S_APP_General.getDisplayFields(this.fields);
    },
    _tableHeaders() {
      let _tableHeaders = [];
      this._showFields.forEach((showFieldKey) => {
        if (showFieldKey in this._displayFields) {
          const field = this._displayFields[showFieldKey];
          if (field.type == "list" || field.type == "evaluationStage") {
            return;
          }

          if (
            field.type == "image" ||
            field.type == "tags" ||
            field.type == "password" ||
            field.type == "link" ||
            field.type == "editor"
          ) {
            _tableHeaders.push({
              value: showFieldKey,
              text: field.label,
              width: field.width,
              sortable: false,
            });
            return;
          }

          _tableHeaders.push({
            value: showFieldKey,
            text: field.label,
            width: field.width,
          });
        }
      });
      return _tableHeaders;
    },
    _showFields() {
      if (this.showFields) {
        return this.showFields;
      } else {
        const _showFields = [];
        for (let fieldKey in this.showFields) {
          _showFields.push(fieldKey);
        }
        return _showFields;
      }
    },
    _textKeyType() {
      return this.fields[this.textKey]?.type;
    },
  },
  props: {
    showFields: {
      type: Array,
    },
    label: {
      type: String,
      default: null,
    },
    textKey: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Array],
    },
    fields: {
      type: Object,
      required: true,
    },
    inRowBtnRead: {
      type: Boolean,
      default: true,
    },
    inRowBtnUpdate: {
      type: Boolean,
      default: true,
    },
    inRowBtnDelete: {
      type: Boolean,
      default: true,
    },
  },
};
</script>