<template>
  <WsInfiniteScroll
    v-slot="{items}"
    modelName="pocket_file"
    class="ws-pocjet-file-picker-pocket"
    :params="params"
  >
    <WsFlexGallery>
      <WsFlexGalleryItem
        v-for="(pocketFile, itemIndex) in items"
        :key="itemIndex"
        @click.native="$_onFilePickerClick(pocketFile,itemIndex)"
        :class="{selected:$_isSelected(pocketFile)}"
        class="ws-pocjet-file-picker-pocket__item"
      >
        <div class="ws-pocjet-file-picker-pocket__item__image">
          <WsText>{{pocketFile.name}}</WsText>
        </div>
      </WsFlexGalleryItem>
    </WsFlexGallery>
  </WsInfiniteScroll>
</template>

<script>
export default {
  data: () => ({
    focusIndex: -1,
    focusItem: null,
    params: {
      order_by: "updated_at",
      order_way: "desc",
    },
  }),

  props: {
    multiple: {
      type: Boolean,
    },
  },


  methods: {
    $_isSelected(pocketFile) {
      if (this.multiple) {
        if (!this.focusItem || !this.focusItem.length) {
          return false;
        }
        const _item = this.focusItem.find((e) => {
          return e.id == pocketFile.id;
        });
        if (_item) {
          return true;
        } else {
          return false;
        }
      } else {
        if (!this.focusItem) {
          return false;
        }
        if (this.focusItem.id == pocketFile.id) {
          return true;
        } else {
          return false;
        }
      }
    },
    $_onFilePickerClick(pocketFile, pocketFileIndex) {
      if (this.multiple) {
        if (!this.focusItem) {
          this.focusItem = [];
        }
        const _itemIndex = this.focusItem.findIndex((e) => {
          return e.id == pocketFile.id;
        });
        if (_itemIndex >= 0) {
          this.focusItem.splice(_itemIndex, 1);
        } else {
          this.focusItem.push(pocketFile);
        }
      } else {
        this.focusItem = pocketFile;
        this.focusIndex = pocketFileIndex;
      }
    },
    $_getUrl(pocketFile) {
      if (pocketFile.signed) {
        return pocketFile.signed_url;
      } else {
        return pocketFile.url;
      }
    },
    submit() {
      if (this.focusItem.signed) {
        this.$emit("submit", this.focusItem);
      } else {
        this.$emit("submit", this.focusItem);
      }
    },
  },
};
</script>