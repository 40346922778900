<template>
  <component
    :is="_htmlTag"
    :to="_to"
    :href="_href"
    class="ws-card"
    :class="[{center:center}]"
    :style="[{'max-width':`${maxWidth}px`},{'width':`${width}px`},{'background-color':color}]"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  computed: {
    _to() {
      return this.to;
    },
    _href() {
      if (this.to) {
        return this.to;
      } else {
        return this.href;
      }
    },
    _htmlTag() {
      if (this.to) {
        if (this.to.startsWith("http://") || this.to.startsWith("https://")) {
          return "a";
        } else {
          return "RouterLink";
        }
      } else if (this.href) {
        return "a";
      } else {
        return "div";
      }
    },
  },
  props: {
    center: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: null,
    },
    maxWidth: {
      type: [Number, String],
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    to: {
      type: String,
    },
    href: {
      type: String,
    },
  },
};
</script>