<template>
  <fade-transition :duration="200">
    <div
      v-if="$store.state.app.pageLoading"
      class="ws-app-loading"
      @dblclick="$_onDbClick()"
    >
      <WsLoading></WsLoading>
    </div>
  </fade-transition>
</template>

<script>
export default {
  methods: {
    $_onDbClick() {
      this.$store.dispatch("app/stopPageLoading");
    },
  },
};
</script>

<style>
</style>