<template>
  <WsMain>
    <WsImagePicker></WsImagePicker>
  </WsMain>
</template>

<script>
export default {

}
</script>

<style>

</style>