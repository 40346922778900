export default {
  // mode: 'classic-1',
  mode: 'dark',
  // mode: 'dark-table',
  bodyOverscroll: false,
  hasDrawer: true,
  hasHeader: true,
  color: {
    // primary: '#6f9630',
    primary: '#4b6b16',
    black: '#000000',
    white: '#ffffff',
    danger: '#ce3829',
    link: '#959595',
    success: '#41cf5f',
  },
};
