<template>
  <div class="ws-menu-view">
    <div class="ws-menu-view__menu">
      <WsMenuGroup :items="menu"></WsMenuGroup>
    </div>
    <div class="ws-menu-view__container">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menu: {},
  },
};
</script>

<style>
</style>