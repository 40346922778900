<template>
  <WsUpdate
    label="系統設定 SMTP"
    :fields="fields"
    :primary="primary"
    getUrl="/system_setting/smtp"
    :completeRedirect="false"
    :postEncode="false"
  >
  </WsUpdate>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        host: {
          type: "text",
          label: "Host",
        },
        port: {
          type: "number",
          label: "Port",
        },
        username: {
          type: "text",
          label: "Username",
        },
        password: {
          type: "text",
          label: "Password",
        },
        from_address: {
          type: "email",
          label: "From Address (Need to be verified by SMTP service)",
        },
        from_name: {
          type: "text",
          label: "From Name",
        },
      },
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: [
                "host",
                "port",
                "username",
                "password",
                "from_address",
                "from_name",
              ],
            },
          ],
        },
      ],
    };
  },
};
</script>

<style>
</style>