<template>
  <WsMain v-if="isMounted">
    <WsStateForm
      :fields="fields"
      v-model="state.content"
    ></WsStateForm>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          :loading="loading.submit"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
import S_SystemInfo from "@/__modules/system/services/system_info";
export default {
  data() {
    return {
      isMounted: false,
      state: {
        content: {},
      },
      loading: {
        submit: false,
      },
      fields: {
        version_web: {
          label: "前台網站版本",
        },
        version_api: {
          label: "前台 API 版本",
        },
        version_cms: {
          label: "後台版本",
        },
        version_cms_api: {
          label: "後台 API 版本",
        },
      },
    };
  },
  methods: {
    async $_onSubmit() {
      this.loading.submit = true;
      const postData = {
        ...this.state,
      };
      postData.content = btoa(
        unescape(encodeURIComponent(JSON.stringify(postData.content)))
      );
      await S_SystemInfo.update(postData);
      this.loading.submit = false;
    },
    async $_valueSet() {
      const res = await S_SystemInfo.show();
      this.state.content = res.content;
      this.isMounted = true;
    },
  },
  mounted() {
    this.$_valueSet();
  },
};
</script>

<style>
</style>