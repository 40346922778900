import H_Router from '@/__stone/helpers/router'

const routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: [
      'index',
      'read',
      'create',
      'update',
    ],
    modelName: 'xc_service_order',
    middleware: 'auth',
    requireScopes: [
      'xc_service_order-admin'
    ]
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: [
      'index',
      'read',
    ],
    modelName: 'xc_service_order_stage',
    middleware: 'auth',
    requireScopes: [
      'xc_service_order-admin'
    ]
  }),
  {
    path: '/calc/xc_service_order',
    name: 'calc_xc_service_order',
    component: () =>
      import('@/__modules/xc_service_order/views/Calc.vue'),
  },
]
export default routes