import config from '@/__config';

export default {
  getSystemSettingCmsFields(data, res) {
    let _res
    if (res) {
      _res = res
    } else {
      _res = {}
    }
    _res = {
      ..._res,
      gitlab_merge_request_notify_admins: {
        type: "belongsToMany",
        label: "CR 發送通知人員",
        modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
      },
    }
    return _res
  },
  getSystemSettingCmsShowFields(data, res) {
    let _res
    if (res) {
      _res = res
    } else {
      _res = []
    }
    _res = [
      ..._res,
      "gitlab_merge_request_notify_admins",
    ]
    return _res
  }
}