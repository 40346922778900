import Rainbow from "rainbowvis.js";
import config from '@/__config'

export default {
  getCmsColors() {
    const _cmsColors = {}
    const primary = config.theme.color.primary ? config.theme.color.primary : '#96b364'
    const black = config.theme.color.black ? config.theme.color.black : '#000000'
    const white = config.theme.color.white ? config.theme.color.white : '#ffffff'
    const danger = config.theme.color.danger ? config.theme.color.danger : 'red'
    const link = config.theme.color.link ? config.theme.color.link : '#14ffb5'
    const success = config.theme.color.success ? config.theme.color.success : '#41cf5f'
    const primaryLights = this.getColors(13, primary, '#ffffff')
    const primaryDarks = this.getColors(13, primary, '#000000')
    const dangerLights = this.getColors(13, danger, '#ffffff')
    const dangerDarks = this.getColors(13, danger, '#000000')
    const linkLights = this.getColors(13, link, '#ffffff')
    const linkDarks = this.getColors(13, link, '#000000')
    const whiteDarks = this.getColors(25, '#ffffff', '#000000')

    _cmsColors.primary = primary
    _cmsColors.danger = danger
    _cmsColors.black = black
    _cmsColors.white = white
    _cmsColors.link = link
    _cmsColors.gray = whiteDarks[12]
    _cmsColors.success = success

    for (let index = 1; index < 6; index++) {
      _cmsColors[`white${index}d`] = whiteDarks[index]
    }
    for (let index = 6; index < 12; index++) {
      _cmsColors[`gray${12 - index}l`] = whiteDarks[index]
    }
    for (let index = 13; index < 19; index++) {
      _cmsColors[`gray${index - 12}d`] = whiteDarks[index]
    }
    for (let index = 24; index > 19; index--) {
      _cmsColors[`black${25 - index}l`] = whiteDarks[index]
    }

    primaryLights.forEach((primaryLight, primaryLightIndex) => {
      if (primaryLightIndex == 0) {
        return
      }
      _cmsColors[`primary${primaryLightIndex}l`] = primaryLight
    });
    primaryDarks.forEach((primaryDark, primaryDarkIndex) => {
      if (primaryDarkIndex == 0) {
        return
      }
      _cmsColors[`primary${primaryDarkIndex}d`] = primaryDark
    });
    dangerLights.forEach((dangerLight, dangerLightIndex) => {
      if (dangerLightIndex == 0) {
        return
      }
      _cmsColors[`danger${dangerLightIndex}l`] = dangerLight
    });
    dangerDarks.forEach((dangerDark, dangerDarkIndex) => {
      if (dangerDarkIndex == 0) {
        return
      }
      _cmsColors[`danger${dangerDarkIndex}d`] = dangerDark
    });
    linkLights.forEach((linkLight, linkLightIndex) => {
      if (linkLightIndex == 0) {
        return
      }
      _cmsColors[`link${linkLightIndex}l`] = linkLight
    });
    linkDarks.forEach((linkDark, linkDarkIndex) => {
      if (linkDarkIndex == 0) {
        return
      }
      _cmsColors[`link${linkDarkIndex}d`] = linkDark
    });

    const root = document.documentElement;
    for (let key in _cmsColors) {
      root.style.setProperty(`--${key}`, _cmsColors[key]);
    }
    return _cmsColors
  },
  getColors(range, start, end) {
    const rainbow = new Rainbow()
    rainbow.setNumberRange(1, range);
    rainbow.setSpectrum(start, end);
    const _colors = []
    for (let i = 1; i <= range; i++) {
      _colors.push(`#${rainbow.colorAt(i)}`)
    }
    return _colors
  },
  hexToRgb(hex, alpha) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    var toString = function () {
      if (this.alpha == undefined) {
        return "rgb(" + this.r + ", " + this.g + ", " + this.b + ")";
      }
      if (this.alpha > 1) {
        this.alpha = 1;
      } else if (this.alpha < 0) {
        this.alpha = 0;
      }
      return (
        "rgba(" +
        this.r +
        ", " +
        this.g +
        ", " +
        this.b +
        ", " +
        this.alpha +
        ")"
      );
    };
    if (alpha == undefined) {
      return result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          toString: toString
        }
        : null;
    }
    if (alpha > 1) {
      alpha = 1;
    } else if (alpha < 0) {
      alpha = 0;
    }
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        alpha: alpha,
        toString: toString
      }
      : null;
  },
}