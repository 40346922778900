<template>
  <WsMain class="xs-pa-0">
    <WsCrud
      ref="WsCrud"
      :modelName="$store.state.stone_model.admin_event.modelName"
      label="請假"
      :fields="_fields"
      :params="_params"
      :customTableActions="_customTableActions"
      :showFields="showFields"
      @status-ok="$_onStatusOk($event)"
      @status-no="$_onStatusNo($event)"
      :inRowBtnRead="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      showFields: ["admin", "start_date", "status"],
    };
  },
  methods: {
    async $_onStatusOk($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.admin_event.ok($event.item.id);
        this.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "2",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onStatusNo($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.admin_event.no($event.item.id);
        this.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "3",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _customTableActions() {
      return [
        {
          emit: "status-ok",
          text: "OK",
          displayCheck: (item) => {
            return item.status == 1 || item.status == 3;
          },
        },
        {
          emit: "status-no",
          text: "No",
          displayCheck: (item) => {
            return item.status == 1 || item.status == 2;
          },
        },
      ];
    },
    _params() {
      return {
        type: "off_day_break,off_day_bereave,off_day_something,off_day_ill",
      };
    },
    _fields() {
      return {
        type: {
          type: "select",
          label: "請假類型",
          defaultValue: "off_day_break",
          items: [
            {
              text: "休假",
              value: "off_day_break",
            },
            {
              text: "病假",
              value: "off_day_ill",
            },
            {
              text: "事假",
              value: "off_day_something",
            },
            {
              text: "生理假",
              value: "off_day_menstrual",
            },
          ],
        },
        ...this.$o_o.$h.model.getFieldsByKeys(
          this.$store.state.stone_model.admin_event.fields,
          [
            "id",
            "admin",
            "status",
            "start_date",
            "days",
            "start_at",
            "hours",
            "note",
          ]
        ),
      };
    },
  },
};
</script>