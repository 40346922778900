<template>
  <WsMain>
    <WsAnalyticsGroup :items="analytics"></WsAnalyticsGroup>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      analytics: [
        {
          title: "Done Task 數量",
          type: "sum",
          fetchUrl: "/calc/sum/xc_task_record/my_done_count",
          fields: {
            created_at: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "Done Task 數量",
          type: "trending",
          fetchUrl: "/calc/trending/xc_task_record/my_done_count",
          fields: {
            created_at: {
              type: "date-range",
              defaultValue: [
                this.$moment().add(-6, 'month').startOf('month').format('YYYY-MM-DD'),
                this.$moment().endOf('month').format('YYYY-MM-DD'),
              ],
            },
          },
        },
        {
          title: "實際執行時間總計",
          type: "sum",
          fetchUrl: "/calc/sum/xc_task/my_done_time_amount",
          fields: {
            done_at: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "實際執行時間總計",
          type: "trending",
          fetchUrl: "/calc/trending/xc_task/my_done_time_amount",
          fields: {
            done_at: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "預估執行時間總計",
          type: "sum",
          fetchUrl: "/calc/sum/xc_task/my_tr_time_amount",
          fields: {
            tr_at: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "預估執行時間總計",
          type: "trending",
          fetchUrl: "/calc/trending/xc_task/my_tr_time_amount",
          fields: {
            tr_at: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
      ],
    };
  },
};
</script>