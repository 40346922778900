<template>
  <div
    class="ws-infinite-scroll"
    @scroll="$_onScroll($event)"
  >
    <slot :items="items"></slot>
    <WsLoading v-if="fetching"></WsLoading>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [],
    page: 1,
    lastPage: 100,
    fetching: false,
  }),

  methods: {
    reset() {
      this.$_clear();
      this.$_fetchItems();
    },
    getItem(id) {
      const _item = this.items.find((e) => {
        return e.id == id;
      });
      return _item;
    },
    removeItem(id) {
      const _items = [...this.items];
      const _index = _items.findIndex((e) => {
        return e.id == id;
      });
      if (_index >= 0) {
        _items.splice(_index, 1);
        this.items = _items;
      }
    },
    addItem(item) {
      const _items = [...this.items];
      _items.push(item);
      this.items = _items;
    },
    updateItem(item) {
      const _items = [...this.items];
      const _index = _items.findIndex((e) => {
        return e.id == item.id;
      });
      if (_index >= 0) {
        _items.splice(_index, 1);
        _items.splice(_index, 0, item);
        this.items = _items;
      }
    },
    $_onScroll($event) {
      this.$_scrollFetchCheck($event);
    },
    $_scrollFetchCheck($event) {
      let scrollTop;
      let height;
      let scrollHeight;
      if (this.bodyScroll) {
        scrollTop = this.$store.state.app.body.scrollTop;
        height = this.$store.state.app.window.height;
        scrollHeight = this.$_getBodyHeight();
      } else {
        scrollTop = $event.srcElement.scrollTop;
        height = $event.srcElement.offsetHeight;
        scrollHeight = $event.srcElement.scrollHeight;
      }

      if (height + scrollTop + this.scrollCheckDis >= scrollHeight) {
        this.$_fetchItems();
      }
    },
    $_getBodyHeight() {
      var body = document.body,
        html = document.documentElement;
      var height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      return height;
    },
    async $_fetchItems() {
      if (this.fetching) {
        return;
      }
      if (this.page > this.lastPage) {
        return;
      }
      try {
        this.fetching = true;
        const _params = {
          ...this.params,
          page: this.page,
        };
        const res = await this.$axios.get(this._fetchUrl, {
          params: _params,
        });
        this.lastPage = res.data.meta.last_page;
        this.page++;
        this.items.push(...res.data.data);
        setTimeout(() => {
          this.$_scrollFetchCheck();
        }, 100);
      } catch (error) {
        alert(`發生錯誤 ${error}`);
      } finally {
        this.fetching = false;
      }
    },
    $_clear() {
      this.items = [];
      this.page = 1;
      this.lastPage = 100;
    },
  },

  mounted() {
    this.$_fetchItems();
  },

  computed: {
    _fetchUrl() {
      if (this.fetchUrl) {
        return this.fetchUrl;
      } else {
        return this.modelName;
      }
    },
  },

  watch: {
    "$store.state.app.body.scrollTop": {
      handler() {
        if (this.bodyScroll) {
          this.$_onScroll();
        }
      },
    },
    // params: {
    //   handler() {
    //     this.reset();
    //   },
    // },

    params: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.reset();
        }
      },
    },
  },

  beforeDestroy() {
    this.$_clear();
  },

  props: {
    bodyScroll: {},
    scrollCheckDis: {
      type: Number,
      default: 200,
    },
    fetchUrl: {
      type: String,
    },
    modelName: {
      type: String,
    },
    params: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style></style>