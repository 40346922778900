<template>
  <div>
    <audio
      :src="value"
      controls
    >
      <source
        :src="value"
        type="audio/ogg"
      >
      <source
        :src="value"
        type="audio/mpeg"
      >
      <source
        :src="value"
        type="audio/wav"
      >
    </audio>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    hasControls: {},
  },
};
</script>

<style>
</style>