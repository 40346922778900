<template>
  <WsFlex class="flex-full">
    <div class="w-12 flex-0">
      <CalendarControllPanel
        @prev="$refs.WsFullCalendar.prev()"
        @next="$refs.WsFullCalendar.next()"
        @goDate="$refs.WsFullCalendar.goDate($event)"
        @changeView="$refs.WsFullCalendar.changeView($event)"
      ></CalendarControllPanel>
      <WsStateForm
        class="mt-16"
        v-model="state.filter"
        :fields="fields.filter"
      ></WsStateForm>
    </div>
    <WsFullCalendar
      ref="WsFullCalendar"
      height="100%"
    >
      <template v-slot:searchSection>
        <slot name="searchSection"></slot>
      </template>
    </WsFullCalendar>
  </WsFlex>
</template>

<script>
export default {
  data() {
    return {
      state: {
        filter: {
          xc_project: this.xc_project,
        },
      },
      fields: {
        filter: {
          xc_project: {
            type: "belongsTo",
            // label: "專案",
            textKey: "name",
            modelName: "xc_project",
          },
        },
      },
    };
  },
  props: {
    xc_project: {},
  },
};
</script>

<style>
</style>