const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_financial_second_grade",
  label: "二級科目",
  fields: {
    code: {
      type: 'text',
      label: '代碼',
    },
    name: {
      type: 'text',
      label: '名稱',
    },
  },
}

export default model