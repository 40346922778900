<template>
  <div>
    <WsStateInput
      class="py-4"
      v-for="(valueItem,valueIndex) in _valueDisplay"
      :key="valueIndex"
      :value="valueItem"
      @input="$_onInput($event,valueIndex)"
    ></WsStateInput>
  </div>
</template>

<script>
export default {
  methods: {
    $_onInput($event, valueIndex) {
      let _value = [...this.value];
      if (_value[valueIndex] == undefined && $event) {
        _value.push($event);
      } else {
        if ($event == "") {
          _value.splice(valueIndex, 1);
        } else {
          _value[valueIndex] = $event;
        }
      }
      this.$emit("input", _value);
    },
  },
  computed: {
    _valueDisplay() {
      const _valueDisplay = [...this.value];
      let showNewInput = false;
      if (_valueDisplay.length == 0) {
        showNewInput = true;
      } else if (_valueDisplay[_valueDisplay.length - 1] != "") {
        showNewInput = true;
      }
      if (showNewInput) {
        _valueDisplay.push("");
      }
      return _valueDisplay;
    },
  },
  props: {
    value: {
      default() {
        return [];
      },
    },
  },
};
</script>

<style>
</style>