<template>
  <div class="ws-info-image">
    <WsImg
      v-if="_value"
      :url="_value"
      :wireBackground="true"
      alt=""
      :previewMaxWidth="previewMaxWidth"
      :previewMaxHeight="previewMaxHeight"
    />
  </div>
</template>

<script>
import PocketImageService from "@/__stone/service/api/v1/pocket_image";
export default {
  computed: {
    _value() {
      return PocketImageService.getSrc(this.value);
    },
  },

  props: {
    value: {},
    previewMaxWidth: {
      type: String,
    },
    previewMaxHeight: {
      type: String,
    },
  },
};
</script>

<style>
</style>