<template>
  <WsMain>
    <XcTaskRead :id="_id"></XcTaskRead>
  </WsMain>
</template>

<script>
export default {
  computed: {
    _id() {
      return this.$route.params.id;
    },
  },
  props: {},
};
</script>