import config from '@/__config'

const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_billing_statement",
  label: "請款單",
  fields: {
    invoice_type: {
      type: 'select',
      label: '憑證類型',
      items: [
        { value: 0, text: '紙本發票' },
        { value: 1, text: '電子發票' },
        { value: 2, text: 'Invoice' },
      ],
      defaultValue: 0
    },
    images: {
      type: "file_images",
      label: "附件圖片",
      storageTarget: 'xc_billing_statement'
    },
    pay_type: {
      type: 'select',
      label: '付款類型',
      items: [
        { value: 0, text: '現金' },
        { value: 1, text: '信用卡-需附明細截圖' },
      ],
      defaultValue: 0
    },
    amount: {
      type: 'currency',
      label: '金額',
      rules: 'required'
    },
    pay_at: {
      type: 'datetime',
      label: '付款時間'
    },
    // review_at: {
    //   type: 'datetime',
    //   label: '覆核時間'
    // },
    remark: {
      type: 'textarea',
      label: '備註',
    },
    review_status: {
      type: 'select',
      label: '覆核狀態',
      defaultValue: 0,
      items: [
        {
          value: 0,
          text: '申請中'
        },
        {
          value: 1,
          text: 'OK，待付款'
        },
        {
          value: 2,
          text: '打妹'
        },
        {
          value: 3,
          text: '已付款'
        },
      ]
    },
    review_remark: {
      type: 'textarea',
      label: '覆核備註'
    },
    admin: {
      type: "belongsTo",
      label: "CMS使用者",
      textKey: "name",
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
      readonly: true,
    },
    reviewer: {
      type: "belongsTo",
      label: "覆核人",
      textKey: "name",
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
      readonly: true,
    },
  },
  crudSetting: {
    index: {
      showFields: [
        'id',
        'pay_type',
        'amount',
        'pay_at',
        'admin',
        'review_status',
      ],
      pageMode: false,
      creatable: false,
      showFieldsUpdate: [
        "review_status",
        "review_remark",
      ]
      // updatable: false,
    },
    index_my: {
      showFields: [
        'id',
        'pay_type',
        'amount',
        'pay_at',
        'admin',
        'review_status',
      ],
      pageMode: true,
      updatable: false,
      dialogRead: true,
    },
    create_my: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'invoice_type',
                'images',
                'pay_type',
                'amount',
                'remark',
              ],
            },
          ],
        },
      ],
    },
    read: {

    }
  }
}

export default model