<template>
  <div>
    <WsCalendarMonth
      :height="height"
      :events="_eventsSorted"
    ></WsCalendarMonth>
  </div>
  <!-- <div class="ws-calendar">
    <div class="ws-calendar__top-bar">
      <button @click="$_onClickMonthPrev()">上個月</button>
      <div class="ws-calendar__top-bar__center">
        <select v-model="state.year">
          <option
            v-for="n in 10"
            :key="`year-${n}`"
            :value="state.year-5+n"
          >
            {{state.year-5+n}}
          </option>
        </select>
        <p>年</p>
        <select v-model="state.month">
          <option
            v-for="n in 12"
            :key="`month-${n}`"
          >
            {{n}}
          </option>
        </select>
        <p>月</p>
      </div>
      <button @click="$_onClickMonthNext()">下個月</button>
    </div>
    <div class="ws-calendar__container">
      <table>
        <tr>
          <td
            class="ws-calendar__container__weekday"
            v-for="showWeekday in state.showWeekdays"
            :key="`weekday-tr-${showWeekday}`"
            :style="[
            ]"
          >{{$moment().isoWeekday(showWeekday).format('ddd')}}</td>
        </tr>
        <tr
          v-for="weekN in _displayDayCount/7"
          :key="`week-${weekN}`"
        >
          <td
            v-for="showWeekday in state.showWeekdays"
            :key="`week-weekday-tr-${showWeekday}`"
          >
            <div
              class="ws-calendar__container__item"
              :class="[
                {'invisible-reduce':$_checkInvisibleReduce($_getCalendarItemMoment(weekN,showWeekday))}
              ]"
            >
              <div class="ws-calendar__container__item__date">{{$_getCalendarItemMoment(weekN,showWeekday).format('DD')}}</div>
              <div class="ws-calendar__container__item__events">

              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div> -->
</template>

<script>
export default {
  computed: {
    _eventsSorted() {
      const _events = [...this.events];
      _events.sort((a, b) => {
        if (b.start_date < a.start_date) {
          return 1;
        } else {
          return -1;
        }
      });
      return _events;
    },
  },
  // data() {
  //   return {
  //     state: {
  //       year: null,
  //       month: null,
  //       date: null,
  //       showWeekdays: [1, 2, 3, 4, 5, 6, 7],
  //       // showWeekdays: [1, 2, 3, 4, 5, 6, 7],
  //       displayMode: "month",
  //     },
  //   };
  // },
  // methods: {
  //   $_onClickMonthPrev() {
  //     if (this.state.month > 1) {
  //       this.state.month = this.state.month - 1;
  //     } else {
  //       this.state.month = 12;
  //       this.state.year = this.state.year - 1;
  //     }
  //   },
  //   $_onClickMonthNext() {
  //     if (this.state.month < 12) {
  //       this.state.month = this.state.month + 1;
  //     } else {
  //       this.state.month = 1;
  //       this.state.year = this.state.year + 1;
  //     }
  //   },
  //   $_checkInvisibleReduce(itemMoment) {
  //     if (itemMoment.get("month") + 1 == this.state.month) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   },
  //   $_getCalendarItemMoment(weekN, showWeekday) {
  //     return this.$moment(this._stateStartMonthDateWithshowWeekdays).add(
  //       (weekN - 1) * 7 +
  //         showWeekday -
  //         this._stateStartMonthDateWithshowWeekdays.isoWeekday(),
  //       "day"
  //     );
  //   },
  //   $_init() {
  //     this.$_initStateMoment();
  //     this.$_initShowWeekdays();
  //     this.$_initDisplayMode();
  //   },
  //   $_initStateMoment() {
  //     if (this.value) {
  //       this.$_setStateMoment(this.$moment(this.value));
  //     } else {
  //       this.$_setStateMoment(this._todayMoment);
  //     }
  //   },
  //   $_initShowWeekdays() {
  //     if (this.showWeekdays) {
  //       this.state.showWeekdays = this.showWeekdays;
  //     }
  //   },
  //   $_initDisplayMode() {
  //     if (this.displayMode) {
  //       this.state.displayMode = this.displayMode;
  //     }
  //   },
  //   $_setStateMoment($m) {
  //     this.state.year = $m.get("year");
  //     this.state.month = $m.get("month") + 1;
  //     this.state.date = $m.get("date");
  //   },
  // },
  // computed: {
  //   _itemsYear() {
  //     const _itemsYear = [];
  //     for (let i = 0; i < 10; i++) {
  //       _itemsYear.push(this.state.year + 5 - i);
  //     }
  //     return _itemsYear;
  //   },
  //   _todayYear() {
  //     return this._todayMoment.get("year");
  //   },
  //   _todayMonth() {
  //     return this._todayMoment.get("month");
  //   },
  //   _todayDate() {
  //     return this._todayMoment.get("date");
  //   },
  //   _todayMoment() {
  //     return this.$moment();
  //   },
  //   _stateMoment() {
  //     return this.$moment(
  //       `${this.state.year}-${this.state.month}-${this.state.date}`
  //     );
  //   },
  //   _stateStartMonthDate() {
  //     return this.$moment(this._stateMoment).startOf("month");
  //   },
  //   _stateStartMonthDateWithshowWeekdays() {
  //     if (!this.state.showWeekdays.length) {
  //       return;
  //     }
  //     return this.$moment(this._stateStartMonthDate).isoWeekday(
  //       this.state.showWeekdays[0]
  //     );
  //   },
  //   _stateEndMonthDate() {
  //     return this.$moment(this._stateMoment).endOf("month");
  //   },
  //   _stateEndMonthDateWithshowWeekdays() {
  //     if (!this.state.showWeekdays.length) {
  //       return;
  //     }
  //     return this.$moment(this._stateEndMonthDate).isoWeekday(
  //       this.state.showWeekdays[this.state.showWeekdays.length - 1]
  //     );
  //   },
  //   _stateStartDate() {
  //     const _stateStartDate = this.$moment(this._stateMoment);
  //     return _stateStartDate.startOf("month");
  //   },
  //   _stateEndDate() {
  //     const _stateEndDate = this.$moment(this._stateMoment);
  //     return _stateEndDate.endOf("month");
  //   },
  //   _displayDayCount() {
  //     return (
  //       this._stateEndMonthDateWithshowWeekdays.diff(
  //         this._stateStartMonthDateWithshowWeekdays,
  //         "days"
  //       ) + 1
  //     );
  //   },
  // },
  // props: {
  //   value: {},
  //   showWeekdays: {},
  //   displayMode: {},
  //   events: {
  //     type: Array,
  //     default() {
  //       return [];
  //     },
  //   },
  // },
  // mounted() {
  //   this.$_init();
  // },
  props: {
    events: {},
    height: {
      type: [Number, String],
      default: 650,
    },
  },
};
</script>

<style>
</style>