<template>
  <WsFlex class="ws-error-message">
    <WsIcon name="icon-md-info" />
    <WsText
      size="12"
      class="ml-6"
    >
      <slot></slot>
    </WsText>
  </WsFlex>
</template>

<script>
export default {};
</script>