<template>
  <div class="ws-state-rules">
    <WsStateCheckboxs
      :value="_value"
      @input="$_onInput($event)"
      :items="items"
    ></WsStateCheckboxs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text: "required",
          value: "required",
        },
        {
          text: "max length 20",
          value: "max:20",
        },
      ],
    };
  },

  methods: {
    $_onInput($event) {
      this.$emit("input", $event.join("|"));
    },
  },

  computed: {
    _value() {
      if (!this.value) {
        return [];
      } else {
        return this.value.split("|");
      }
    },
  },

  props: {
    value: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
</style>