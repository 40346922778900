<template>
  <tbody
    class="ws-crud-table-item"
    tag="tbody"
  >
    <tr :class="[
        { selectedBackground: isSelected },
        { 'row-click-read': rowClickRead }
      ]">
      <td
        v-if="selectable"
        class="check"
      >
        <WsState
          type="checkbox"
          :value="isSelected"
          @input="$emit('select-check')"
        />
      </td>
      <td
        v-for="(headersItem, headersIndex) in headers"
        :key="headersIndex"
      >
        <WsState
          v-if="fields[headersItem.value] && fields[headersItem.value].updatable"
          :value="item[headersItem.value]"
          v-bind="fields[headersItem.value]"
          :label="undefined"
          @submit="$_onSubmit($event,headersItem.value,item)"
        />
        <WsInfo
          v-else
          :value="item[headersItem.value]"
          v-bind="fields[headersItem.value]"
          :label="undefined"
          :hasControls="false"
        />
      </td>
      <td
        class="actions d-xs-none"
        :class="[{ selectedBackground: isSelected }]"
        :style="[{ width: `${_actionWidth}px` }, { 'min-width': `${_actionWidth}px` }, { 'max-width': `${_actionWidth}px` },]"
      >
        <template v-if="customTableActions">
          <div
            v-for="(customTableAction, customTableActionIndex) in customTableActions"
            :key="customTableActionIndex"
          >
            <div v-if="$_customActionDisplayChecek(customTableAction, item)">
              <WsIconBtn
                @click.stop="$emit('custom-table-action', customTableAction)"
                :to="$_getCustomActionUrl(customTableAction, item)"
                :tooltip="$_getCustomActionTooltip(customTableAction, item)"
                :disabled="$_getCustomActionDisabled(customTableAction, item)"
                :text="customTableAction.text?true:false"
                :name="$_getCustomActionIcon(customTableAction, item)"
              >{{ customTableAction.text }}</WsIconBtn>
            </div>
          </div>
        </template>
        <template v-if="readable">
          <WsIconBtn
            tooltip="Preview"
            :to="_readUrl"
            v-if="pageMode && !dialogRead"
            name="icon-ws-outline-eye-open"
          />
          <WsIconBtn
            tooltip="Preview"
            v-else
            @click.stop="$emit('read')"
            name="icon-ws-outline-eye-open"
          />
        </template>
        <template v-if="updatable">
          <WsIconBtn
            tooltip="Edit"
            :to="_updateUrl"
            v-if="pageMode && !dialogUpdate"
            name="icon-md-edit"
          />
          <WsIconBtn
            tooltip="Edit"
            v-else
            @click.stop="$emit('update')"
            name="icon-md-edit"
          />
        </template>
        <template v-if="duplicatable">
          <WsIconBtn
            tooltip="Duplicate"
            @click.stop="$emit('duplicate')"
            name="icon-md-content-copy"
          />
        </template>
        <template v-if="deletable">
          <WsIconBtn
            tooltip="Delete"
            @click.stop="$emit('delete')"
            name="icon-md-delete"
          />
        </template>
      </td>
      <th class="actions-xs d-none d-xs-table-cell">
        <WsCrudTableMoreBtn
          v-bind="$props"
          @read="$_moreBtnOnRead()"
          @update="$_moreBtnOnUpdate()"
          @delete="$emit('delete')"
          @duplicate="$emit('duplicate')"
          @custom-table-action="$emit('custom-table-action', $event)"
        />
      </th>
    </tr>
  </tbody>
</template>

<script>
export default {
  methods: {
    $_moreBtnOnRead() {
      if (this.pageMode && !this.dialogRead) {
        this.$router.push(this._readUrl);
      } else {
        this.$emit("read");
      }
    },
    $_moreBtnOnUpdate() {
      if (this.pageMode && !this.dialogUpdate) {
        this.$router.push(this._updateUrl);
      } else {
        this.$emit("update");
      }
    },
    $_getCustomActionIcon(customTableAction, item) {
      if (customTableAction.getIcon) {
        return customTableAction.getIcon(item);
      } else {
        return customTableAction.icon;
      }
    },
    $_getCustomActionTooltip(customTableAction, item) {
      if (customTableAction.getTooltip) {
        return customTableAction.getTooltip(item);
      } else {
        return customTableAction.tooltip;
      }
    },
    $_getCustomActionUrl(customTableAction, item) {
      if (customTableAction.getUrl) {
        return customTableAction.getUrl(item);
      } else {
        return null;
      }
    },
    $_getCustomActionDisabled(customTableAction, item) {
      if (customTableAction.getDisabled) {
        return customTableAction.getDisabled(item);
      } else {
        return customTableAction.disabled;
      }
    },
    $_customActionDisplayChecek(customTableAction, item) {
      if (customTableAction.displayCheck) {
        return customTableAction.displayCheck(item);
      } else {
        return true;
      }
    },
    $_onSubmit($event, fieldKey) {
      this.$axios.patch(`/${this.modelName}/${this.item.id}`, {
        [fieldKey]: $event,
      });
      this.$emit("update", {
        [fieldKey]: $event,
      });
    },
  },
  computed: {
    _actionWidth() {
      if (this.actionWidth) {
        return this.actionWidth;
      }
      let _actionWidth = 10;
      let count = 0;

      if (this.inRowBtnRead && this.readable) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.inRowBtnUpdate && this.updatable) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.inRowBtnDelete && this.deletable) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.duplicatable) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.inRowBtnComplete) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.customTableActions) {
        this.customTableActions.forEach(() => {
          _actionWidth += 40.1;
          count++;
        });
      }
      _actionWidth += count * 8;
      return _actionWidth;
    },
    _updateUrl() {
      if (this.getUpdateUrl) {
        return this.getUpdateUrl(this.item);
      } else {
        return `/${this._urlModelName}/${this.item.id}/update`;
      }
    },
    _readUrl() {
      if (this.getReadUrl) {
        return this.getReadUrl(this.item);
      } else {
        return `/${this._urlModelName}/${this.item.id}`;
      }
    },
    _urlModelName() {
      return this.urlModelName ? this.urlModelName : this.modelName;
    },
  },
  props: {
    modelName: {},
    urlModelName: {},
    isSelected: {},
    rowClickRead: {},
    selectable: {},
    headers: {},
    fields: {},
    item: {},
    customTableActions: {},
    inRowBtnRead: {},
    pageMode: {},
    dialogRead: {},
    inRowBtnUpdate: {},
    getReadUrl: {},
    getUpdateUrl: {},
    dialogUpdate: {},
    duplicatable: {},
    inRowBtnDelete: {},
    readable: {},
    updatable: {},
    deletable: {},
    actionWidth: {},
  },
};
</script>