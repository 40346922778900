<template>
  <CalendarScheduling :params="_params"></CalendarScheduling>
</template>

<script>
export default {
  computed: {
    _params() {
      return {
        xc_project: this.$route.params.id,
      };
    },
  },
};
</script>

<style></style>