<template>
  <WsMain>
    <WsStateForm
      v-model="state"
      :fields="fields"
    ></WsStateForm>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{ $t('submit') }}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      state: {},
      fields: {
        to: {
          type: "email",
          label: "測試信件收件人",
        },
      },
    };
  },
  methods: {
    async $_onSubmit() {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$axios.post('system_setting/smtp/mailtest', this.state)
        this.$store.dispatch("app/addSnack", `發送成功`);
      } catch (error) {
        console.error(error);
        this.$store.dispatch("app/addSnack", `發生錯誤`);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    }
  }
};
</script>

<style></style>