export default {
  // drive: {
  //   icon: 'icon-md-cloud',
  //   title: '山葵 Drive',
  //   link: '/drive',
  // },
  file_system: {
    icon: 'icon-ll-nav-filemanage-open',
    title: '系統檔案管理',
    scopes: ['file_system-admin'],
    menu: [
      {
        title: '檔案',
        link: '/file',
      },
      {
        title: '圖片',
        link: '/image',
      },
      {
        title: '影片',
        link: '/video',
      },
      {
        title: '音樂',
        link: '/audio',
      },
    ],
  },
}