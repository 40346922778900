<template>
  <fade-transition :duration="200">
    <div
      class="ws-modal"
      v-if="active"
    >
      <component
        :is="transition"
        :duration="200"
      >
        <div
          v-if="activeContainer"
          class="ws-modal__container"
        >
          <slot name="content"></slot>
        </div>
      </component>
      <div
        class="ws-modal__bg"
        @click="$_onBgClick()"
      ></div>
    </div>
  </fade-transition>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      activeContainer: false,
    };
  },
  methods: {
    close() {
      this.activeContainer = false;
      this.$emit("close");
      this.$emit("input", false);
      document.body.classList.remove("scroll-disabled");
      setTimeout(() => {
        this.active = false;
      }, 100);
    },
    open() {
      this.active = true;
      this.$emit("open");
      this.$emit("input", true);
      document.body.classList.add("scroll-disabled");
      setTimeout(() => {
        this.activeContainer = true;
      }, 100);
    },
    $_onBgClick() {
      this.close();
    },
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.open();
        } else {
          this.close();
        }
      },
    },
    active: {
      handler() {
        this.$emit("input", this.active);
        if (!this.active) {
          this.$emit("close");
        }
      },
    },
  },
  props: {
    value: {},
    transition: {
      type: String,
      default: "slide-y-down-transition",
    },
  },

  mounted() {
    if (this.value) {
      this.active = this.value;
    }
  },
};
</script>

<style lang="scss">
.ws-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 878;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .ws-modal__container {
    position: relative;
    z-index: 1;
    background-color: var(--white1d);
  }
  .ws-modal__bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--black2l);
    opacity: 0.7;
  }
}
</style>