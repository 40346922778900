<template>
  <WsMain>
    <div>
      <WsBtn
        :loading="loading.punchIn"
        v-ripple.300
        verybig
        full
        @click="$_onPunchIn()"
      >我上班囉</WsBtn>
    </div>
    <div class="mt-20">
      <WsBtn
        :loading="loading.punchOut"
        v-ripple.300
        verybig
        full
        :color="$color.black5l"
        @click="$_onPunchOut()"
      >我下班囉</WsBtn>
    </div>
    <WsInfiniteScroll
      ref="WsInfiniteScroll"
      class="mt-40"
      fetchUrl="my/punch"
      v-slot="{ items }"
    >
      <WsCrudTable
        :showDataTotalCount="false"
        :headers="tableHeaders"
        :items="items"
        :fields="fields"
        :paginate="false"
        :showExpand="false"
        :expandable="false"
        :inRowBtnRead="false"
        :inRowBtnUpdate="false"
        :inRowBtnDelete="false"
      ></WsCrudTable>
    </WsInfiniteScroll>
  </WsMain>
</template>

<script>
import S_Punch from "@/__modules/punch/services/punch";
export default {
  data() {
    return {
      ...this.$store.state.stone_model.punch,
      tableHeaders: [
        {
          value: "punch_at",
          text: "打卡時間",
        },
        {
          value: "type",
          text: "",
        },
      ],
      loading: {
        punchIn: false,
        punchOut: false,
      },
      punches: [],
    };
  },
  methods: {
    async $_onPunchIn() {
      try {
        this.loading.punchIn = true;
        await S_Punch.punch_in();
      } catch (error) {
        // alert("發生錯誤，請重新再試");
        alert(this.$o_o.$h.http.getErrorMessageFromRes(error));
      } finally {
        this.loading.punchIn = false;
        this.$_resetIndex();
      }
    },
    async $_onPunchOut() {
      try {
        this.loading.punchOut = true;
        await S_Punch.punch_out();
      } catch (error) {
        // alert("發生錯誤，請重新再試");
        alert(this.$o_o.$h.http.getErrorMessageFromRes(error));
      } finally {
        this.loading.punchOut = false;
        this.$_resetIndex();
      }
    },
    $_resetIndex() {
      this.$refs.WsInfiniteScroll.reset();
    },
  },
};
</script>

<style></style>