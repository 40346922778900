<template>
  <WsText
    class="ws-des"
    :color="_color"
    :size="size"
  >
    <slot></slot>
  </WsText>
</template>

<script>
export default {
  computed: {
    _color() {
      if (this.$store.state.theme == "light") {
        return this.$color.gray3l;
      } else {
        return this.$color.gray3d;
      }
    },
  },
  props: {
    size: {
      type: [String, Number],
      default: 12,
    },
  },
};
</script>

<style>
</style>