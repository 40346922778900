<template>
  <div class="ws-state-locales">
    <div
      v-if="showLocalePicker"
      class="locales-select-section"
    >
      <v-icon color="textPrimary">mdi-web</v-icon>
      <div class="locales-select-section__title textPrimary--text">你正在瀏覽的語言是</div>
      <WsStateSelect
        :minWidth="130"
        v-model="selectingLocale"
        :items="_localeItems"
        round
        evevate
      ></WsStateSelect>
    </div>
    <v-row v-if="_localesValue&&_localesValue[selectingLocale]">
      <v-col
        v-for="(field, fieldKey) in _fields"
        cols="12"
        :sm="field.sm ? field.sm : 12"
        :key="fieldKey"
      >
        <WsInfo
          :placeholder="`請輸入${field.label}`"
          :type="field.type"
          :label="field.label"
          :fields="field.fields"
          :displayLabel="field.displayLabel"
          :rules="field.rules"
          :autofocus="field.autofocus"
          :value="_localesValue[selectingLocale][fieldKey]"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    selectingLocale: null,
  }),
  computed: {
    _fields() {
      const _fields = {};
      if (!this.hideOnNull) {
        return this.fields;
      } else {
        for (let fieldKey in this.fields) {
          const field = this.fields[fieldKey];
          const _value = this._localesValue[this.selectingLocale][fieldKey];
          if (!(_value == undefined || _value.length == 0)) {
            _fields[fieldKey] = field;
          }
        }
        return _fields;
      }
    },
    _localeItems() {
      const _localeItems = [];
      const locales = this.$store.state.locale.locales;
      if (!locales) {
        return null;
      }
      locales.forEach((locale) => {
        _localeItems.push({
          value: locale.code,
          text: locale.name,
        });
      });
      return _localeItems;
    },
    _localesValue() {
      let _localesValue;
      const initValue = {};
      const locales = this.$store.state.locale.locales;
      locales.forEach((locale) => {
        initValue[locale.code] = {};
        for (let fieldKey in this.fields) {
          const field = this.fields[fieldKey];
          if (
            this.value &&
            this.value[locale.code] &&
            this.value[locale.code][fieldKey]
          ) {
            initValue[locale.code][fieldKey] = this.value[locale.code][
              fieldKey
            ];
          } else if (field.defaultValue !== undefined) {
            initValue[locale.code][fieldKey] = field.defaultValue;
          } else {
            initValue[locale.code][fieldKey] = null;
          }
        }
      });
      _localesValue = initValue;
      return _localesValue;
    },
  },
  methods: {
    $_onInput($event, fieldKey) {
      this.localesValue[this.selectingLocale][fieldKey] = $event;
    },
  },
  mounted() {
    if (this.locale) {
      this.selectingLocale = this.locale;
    } else {
      const locales = this.$store.state.locale.locales;
      this.selectingLocale = locales[0].code;
    }
  },
  props: {
    hideOnNull: {
      type: Boolean,
      default: true,
    },
    showLocalePicker: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      default: null,
    },
    fields: Object,
    value: {
      type: Object,
      default: null,
    },
  },
  watch: {
    locale: {
      handler() {
        this.selectingLocale = this.locale;
      },
    },
  },
};
</script>