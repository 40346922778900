import moment from 'moment'

const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'hr_recruit',
  label: "人員面試",
  fields: {
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required",
      maxlength: 30,
    },
    nickname: {
      type: "text",
      label: "別名",
      maxlength: 30,
    },
    gender: {
      type: 'select',
      label: '性別',
      rules: "required",
      defaultValue: 0,
      items: [
        {
          value: 0,
          text: '女'
        },
        {
          value: 1,
          text: '男'
        },
      ]
    },
    resume_link: {
      type: 'link',
      label: '履歷連結'
    },
    hr_position: {
      label: "面試職位",
      items: [
        {
          value: 'it-vue',
          text: '前端工程師 (Vue.js)'
        },
        {
          value: 'it-rn',
          text: '前端工程師 (React, ReactNative)'
        },
        {
          value: 'it-laravel',
          text: '後端工程師 (PHP, Laravel)'
        },
        {
          value: 'ui',
          text: 'UI介面視覺設計師 (GUI Designer)'
        },
        {
          value: 'pm',
          text: '專案管理師PM (Project Manager)'
        },
      ]
    },
    birth: {
      type: 'date',
      label: '生日'
    },
    apply_date: {
      type: 'date',
      label: '履歷投遞日期'
    },
    interview_date: {
      type: 'date',
      label: '面試日期',
      defaultValue: moment().format('YYYY-MM-DD')
    },
    interview_remark: {
      type: 'textarea',
      label: '面試筆記'
    },
    gossip: {
      type: 'textarea',
      label: '評價打聽'
    },
    education: {
      type: 'textarea',
      label: '學歷',
    },
    experience: {
      type: 'textarea',
      label: '經歷',
    },
    mobile: {
      type: 'text',
      label: '手機'
    },
    line: {
      type: 'text',
      label: 'Line'
    },
    facebook: {
      type: 'text',
      label: 'FB'
    },
    github: {
      type: 'text',
      label: 'Github'
    },
    website: {
      type: 'link',
      label: '網站'
    },
    relate_links: {
      type: "list",
      label: "相關連結",
      fields: {
        name: {
          type: "text",
          label: "名稱",
          rules: "required"
        },
        link: {
          type: "link",
          label: "連結",
          rules: "required"
        },
        remark: {
          type: "textarea",
          label: "備註"
        }
      },
      showFields: ["name", 'link', 'remark']
    },
  },
  crudSetting: {
    index: {
      showFields: [
        "name",
        "interview_date",
      ],
    },
  },
};

export default model;
