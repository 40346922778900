<template>
  <WsCard>
    <WsFlex>
      <WsIcon
        name="icon-md-info"
        size="20"
      />
      <div class="ml-10">
        <WsText
          v-if="title"
          size="14"
          class="mb-10"
        >{{title}}</WsText>
        <WsLink>{{link}}</WsLink>
      </div>
    </WsFlex>
  </WsCard>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
  },
};
</script>