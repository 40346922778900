<template>
  <div>
    <LayoutModelIndex
      v-bind="$store.state.stone_model.xc_project_member"
      :params="_params"
      :createData="_createData"
      :updateData="_updateData"
    ></LayoutModelIndex>
  </div>
</template>

<script>
export default {
  props: {
    xc_project: {},
  },
  computed: {
    _params() {
      return {
        xc_project: this.xc_project.id,
      };
    },
    _createData() {
      return {
        xc_project: this.xc_project.id,
      };
    },
    _updateData() {
      return {
        xc_project: this.xc_project.id,
      };
    },
  },
};
</script>

<style>
</style>