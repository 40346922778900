import H_Router from '@/__stone/helpers/router'

const routes = [
  ...H_Router.getResourceRoutes({
    routes: ['index', 'create', 'update', 'read'],
    modelName: 'tag',
    viewsPath: '__modules/tag/views',
    meta: {
      middleware: 'auth',
      requiredScopes: ['tag-admin'],
    },
  }),
]
export default routes