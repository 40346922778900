<template>
  <div>
    <WsDialog
      ref="WsDialog"
      :title="$t('匯入')"
      @submit="$_onSubmit()"
      @close="$_onDialogClose()"
      :loadingSubmit="loading.submit"
    >
      <template #content>
        <!-- <WsState
          type="file"
          v-model="state.file"
        ></WsState> -->
        <!-- <WsPocketFilePickerUpload></WsPocketFilePickerUpload> -->
        <WsText
          v-if="state.file"
          class="mb-20"
        >{{state.file.name}}</WsText>
        <WsBtn @click="$_onUploadClick()">上傳檔案</WsBtn>
        <input
          v-show="false"
          @change="$_onChange($event)"
          type="file"
          ref="fileInput"
          accept="*/*"
        >
      </template>
      <template #rightActions>
        <WsBtn
          outlined
          @click="close()"
        >{{$t('取消')}}</WsBtn>
        <WsBtn
          @click="$_onSubmit()"
          :loading="loading.submit"
        >{{$t('送出')}}</WsBtn>
      </template>
    </WsDialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {
        file: null,
      },
      loading: {
        submit: false,
      },
    };
  },
  methods: {
    async $_onChange($event) {
      this.state.file = $event.target.files[0];
    },
    $_onUploadClick() {
      this.$refs.fileInput.click();
    },
    $_clear() {
      this.state.file = null;
    },
    $_onDialogClose() {
      this.$_clear();
      this.$emit("close");
    },
    async $_onSubmit() {
      try {
        this.$store.dispatch("app/startPageLoading");
        const formData = new FormData();
        formData.append("file", this.state.file);
        const res = await this.$axios.post(
          `/${this.modelName}/import`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (res.data?.errors?.length) {
          this.$store.dispatch("app/setAlert", {
            title: "以下資料匯入失敗",
            description: JSON.stringify(res.data.errors),
            action: () => {},
            data: {},
          });
        }
        this.close();
        this.$emit("complete");
      } catch (error) {
        // console.error(error);
        alert(this.$o_o.$h.http.getErrorMessageFromRes(error));
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    close() {
      this.$refs.WsDialog.close();
    },
    open() {
      this.$refs.WsDialog.open();
    },
  },
  props: {
    modelName: {},
  },
  computed: {},
};
</script>

<style>
</style>