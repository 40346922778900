export default [
  {
    text: '網站設定 - Header',
    value: 'system_setting_web_header-admin'
  },
  {
    text: '網站設定 - Footer',
    value: 'system_setting_web_footer-admin'
  },
  {
    text: '網站設定 - 其他',
    value: 'system_setting_web-admin'
  },
  {
    text: '系統設定 - 後台',
    value: 'system_setting_cms-admin'
  },
  {
    text: '系統設定 - SMTP',
    value: 'system_setting_smtp-admin'
  },
  {
    text: '網站設定 - 網頁環境設定',
    value: 'system_setting_web_config-admin'
  },
  {
    text: '網站設定 - 系統資源設定',
    value: 'system_setting_resource-admin'
  },
  {
    text: '系統設定 - 通用設定',
    value: 'system_setting_general-admin'
  },
]