<template>
  <div class="ws-full-calendar">
    <WsFlex
      v-if="hasToolbar"
      class="ws-full-calendar__toolbar"
    >
      <WsFlex gap="4px">
        <WsIconBtn
          tooltip="往前"
          name="icon-ws-outline-chevron-back"
          @click="prev()"
        ></WsIconBtn>
        <WsIconBtn
          tooltip="往後"
          name="icon-ws-outline-chevron-forward"
          @click="next()"
        ></WsIconBtn>
        <WsIconBtn
          tooltip="回到今天"
          name="icon-md-refresh"
          @click="goDate($moment().toDate())"
        ></WsIconBtn>
      </WsFlex>
      <WsSpacer></WsSpacer>
      <WsFlex gap="4px">
        <WsIconBtn
          :text="true"
          @click="changeView('timeGridDay')"
        >日</WsIconBtn>
        <WsIconBtn
          :text="true"
          @click="changeView('timeGridWeek')"
        >週</WsIconBtn>
        <WsIconBtn
          :text="true"
          @click="changeView('dayGridMonth')"
        >月</WsIconBtn>
      </WsFlex>
    </WsFlex>
    <FullCalendar
      ref="FullCalendar"
      class='ws-full-calendar__calendar'
      :options='_calendarOptions'
    >
    </FullCalendar>
  </div>
</template>

<script>
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import FullCalendar from "@fullcalendar/vue";
export default {
  data() {
    return {
      calendar: null,
      viewToFullCalendarView: {
        week: "timeGridWeek",
      },
    };
  },
  components: {
    FullCalendar,
  },
  methods: {
    eventsSet($event) {
      this.$refs.FullCalendar.getApi().eventsSet($event);
    },
    changeView($event) {
      this.$refs.FullCalendar.getApi().changeView($event);
    },
    getView() {
      return this.$refs.FullCalendar.getApi().view;
    },
    goDate($event) {
      this.$refs.FullCalendar.getApi().gotoDate($event);
    },
    next() {
      this.$refs.FullCalendar.getApi().next();
    },
    prev() {
      this.$refs.FullCalendar.getApi().prev();
    },
    addEvent(event) {
      this.$refs.FullCalendar.getApi().addEvent(event);
    },
  },
  computed: {
    _calendarOptions() {
      return {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: this.viewToFullCalendarView[this.defaultView],
        locale: this.locale,
        height: this.height,
        selectable: false,
        editable: this.editable,
        droppable: this.droppable,
        eventDurationEditable: false,
        buttonText: this.buttonText,
        allDayText: this.allDayText,
        slotMinTime: this.minTime,
        slotMaxTime: this.maxTime,
        dragRevertDuration: 100,
        weekends: this.calendarWeekends,
        events: this.events,
        hiddenDays: this.hiddenDays,
        slotDuration: this.slotDuration,
        slotLabelInterval: { hours: 4 },
        dateClick: ($event) => {
          this.$emit("dateClick", $event);
        },
        datesSet: ($event) => {
          this.$emit("datesSet", $event);
        },
        eventDragStart: ($event) => {
          this.$emit("eventDragStart", $event);
        },
        eventDragStop: ($event) => {
          this.$emit("eventDragStop", $event);
        },
        eventResize: ($event) => {
          this.$emit("eventResize", $event);
        },
        eventDrop: ($event) => {
          this.$emit("eventDrop", $event);
        },
        eventClick: ($event) => {
          this.$emit("eventClick", $event);
        },
        drop: ($event) => {
          this.$emit("drop", $event);
        },
        eventRemove: ($event) => {
          this.$emit("eventRemove", $event);
        },
        eventReceive: ($event) => {
          this.$emit("eventReceive", $event);
        },
        headerToolbar: {
          start: "",
          end: "",
        },
      };
    },
  },
  props: {
    hiddenDays: {},
    events: {
      default() {
        return [];
      },
    },
    minTime: {
      type: String,
      default: "09:00:00",
    },
    maxTime: {
      type: String,
      default: "18:00:00",
    },
    header: {
      type: Object,
      default() {
        return {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        };
      },
    },
    calendarWeekends: {
      type: Boolean,
      default: true,
    },
    allDayText: {
      type: String,
      default: "",
    },
    defaultView: {
      type: String,
      default: "week",
    },
    locale: {
      type: String,
      default: "zh-tw",
    },
    height: {
      type: [String, Number],
      default: "auto",
    },
    editable: {
      type: Boolean,
      default: false,
    },
    droppable: {
      type: Boolean,
      default: false,
    },
    slotDuration: {
      type: String,
      default: "00:30:00",
    },
    hasToolbar: {
      default: true,
    },
    buttonText: {
      type: Object,
      default() {
        return {
          today: "今天",
          month: "月",
          week: "週",
          day: "日",
          list: "列表",
        };
      },
    },
  },
};
</script>

<style></style>