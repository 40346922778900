<template>
  <div class="ws-section-primary-secondary">
    <div
      v-if="$slots.primary"
      class="ws-section-primary-secondary__primary"
    >
      <slot name="primary"></slot>
    </div>
    <div
      v-if="$slots.secondary"
      class="ws-section-primary-secondary__secondary"
    >
      <slot name="secondary"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>