import moment from 'moment';
export default {
  getPrimaryMatch(array1, array2) {
    const match = array1.find((item) => {
      return array2.includes(item);
    });
    return match;
  },
  getFieldByFieldKey(fieldKey, fields) {
    const keyArr = fieldKey.split('.');
    let target = fields;
    for (let i = 0; i < keyArr.length; i++) {
      const keyItem = keyArr[i];
      if (i == 0) {
        if (target[keyItem] !== undefined) {
          target = target[keyItem];
        } else {
          return null;
        }
      } else {
        if (target.fields[keyItem] !== undefined) {
          target = target.fields[keyItem];
        } else {
          return null;
        }
      }
    }
    return target;
  },
  getLabelByHideLabelFields(fieldKey, fields, hideLabelFields) {
    if (!hideLabelFields) {
      return this.getFieldByFieldKey(fieldKey, fields).label;
    } else {
      if (hideLabelFields.indexOf(fieldKey) >= 0) {
        return null;
      } else {
        return this.getFieldByFieldKey(fieldKey, fields).label;
      }
    }
  },
  getDisplayFields(fields, showFieldsRead = null) {
    let _fields = {};
    for (let fieldKey in fields) {
      if (showFieldsRead) {
        if (!showFieldsRead.includes(fieldKey)) {
          continue;
        }
      }
      const field = fields[fieldKey];
      if (field.type == 'last_version') {
        _fields = {
          ...this.getVersionsFields(field.fields),
          ..._fields,
        };
        // @Q@
      } else if (field.type == 'shop_order_shop_product') {
        _fields = {
          ...this.getOrderProductFields(field.fields),
          ..._fields,
        };
      } else if (field.type == 'locales') {
        _fields = {
          ...this.getLocalesFields(field.fields),
          ..._fields,
        };
      } else {
        _fields[fieldKey] = field;
      }
    }
    return _fields;
  },
  getVersionsFields(versionsFields, keyPre = '', labelPre = '') {
    let versions_fields = {};
    for (let versionsFieldKey in versionsFields) {
      const versionsField = versionsFields[versionsFieldKey];
      if (versionsField.type == 'locales') {
        versions_fields = {
          ...this.getLocalesFields(
            versionsField.fields,
            `${keyPre}last_version__`,
            '最新版本-',
          ),
          ...versions_fields,
        };
      } else {
        versions_fields[`${keyPre}last_version__${versionsFieldKey}`] = {
          ...versionsField,
          label: `${labelPre}最新版本-${versionsField.label}`,
          showOnly: true,
        };
      }
    }
    return versions_fields;
  },
  getOrderProductFields(orderProductFields, keyPre = '', labelPre = '') {
    let orderProduct_fields = {};
    for (let orderProductFieldKey in orderProductFields) {
      const OrderProductField = orderProductFields[orderProductFieldKey];
      if (OrderProductField.type == 'locales') {
        orderProduct_fields = {
          ...this.$_CMS_getLocalesFields(
            OrderProductField.fields,
            `${keyPre}last_version__`,
            '最新版本-',
          ),
          ...orderProduct_fields,
        };
      } else if (OrderProductField.type == 'last_version') {
        orderProduct_fields[
          `${keyPre}last_version__${orderProductFieldKey}`
        ] = {
          ...OrderProductField,
          label: `${labelPre}最新版本-${OrderProductField.label}`,
          showOnly: true,
        };
      } else {
        orderProduct_fields[
          `${keyPre}shop_order_shop_product__${orderProductFieldKey}`
        ] = {
          ...OrderProductField,
          label: `${labelPre}${OrderProductField.label}`,
          showOnly: true,
        };
      }
    }
    return orderProduct_fields;
  },
  getLocalesFields(localesFields, keyPre = '', labelPre = '') {
    let locales_fields = {};
    for (let localesFieldKey in localesFields) {
      const localesField = localesFields[localesFieldKey];
      locales_fields[`${keyPre}locales_main__${localesFieldKey}`] = {
        ...localesField,
        label: `主語言-${labelPre}${localesField.label}`,
        showOnly: true,
      };
      const tarLocale = this.$store.state.locale.locales.find((e) => {
        return e.code == this.$store.state.locale.selectingLocale;
      });
      locales_fields[
        `${keyPre}locales_${this.$store.state.locale.selectingLocale}__${localesFieldKey}`
      ] = {
        ...localesField,
        label: `${labelPre}${tarLocale.name}-${localesField.label}`,
        showOnly: true,
      };
    }
    return locales_fields;
  },
  geCreateFields(fields, createHideFields) {
    let _fields = {};
    for (let fieldKey in fields) {
      if (createHideFields && createHideFields.includes(fieldKey)) {
        continue;
      }
      const field = fields[fieldKey];
      if (field.readonly) {
        continue;
      } else if (field.type == 'custom') {
        continue;
      } else if (field.type == 'hasMany') {
        continue;
      } else {
        const _field = { ...field }
        if (
          typeof field.rules == 'object' &&
          field.rules !== null &&
          field.rules.create !== undefined
        ) {
          _field.rules = field.rules.create
        }
        _fields[fieldKey] = _field;
      }
    }
    return _fields;
  },
  getUpdateFields(fields, updateHideFields, showFieldsUpdate = null) {
    let _fields = {};
    if (showFieldsUpdate) {
      showFieldsUpdate.forEach(fieldKey => {
        const field = fields[fieldKey];
        if (!field) {
          return
        }
        if (field.readonly) {
          return;
        } else if (field.type == 'custom') {
          return;
        } else {
          const _field = { ...field }
          if (
            typeof field.rules == 'object' &&
            field.rules !== null &&
            field.rules.update !== undefined
          ) {
            _field.rules = field.rules.update
          }
          _fields[fieldKey] = _field;
        }
      });
    } else {
      for (let fieldKey in fields) {
        if (updateHideFields && updateHideFields.includes(fieldKey)) {
          continue;
        }
        const field = fields[fieldKey];
        if (field.readonly) {
          continue;
        } else if (field.type == 'custom') {
          continue;
        } else {
          const _field = { ...field }
          if (
            typeof field.rules == 'object' &&
            field.rules !== null &&
            field.rules.update !== undefined
          ) {
            _field.rules = field.rules.update
          }
          _fields[fieldKey] = _field;
        }
      }
    }
    return _fields;
  },
  hexToRgb(hex, alpha) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    var toString = function () {
      if (this.alpha == undefined) {
        return 'rgb(' + this.r + ', ' + this.g + ', ' + this.b + ')';
      }
      if (this.alpha > 1) {
        this.alpha = 1;
      } else if (this.alpha < 0) {
        this.alpha = 0;
      }
      return (
        'rgba(' +
        this.r +
        ', ' +
        this.g +
        ', ' +
        this.b +
        ', ' +
        this.alpha +
        ')'
      );
    };
    if (alpha == undefined) {
      return result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          toString: toString,
        }
        : null;
    }
    if (alpha > 1) {
      alpha = 1;
    } else if (alpha < 0) {
      alpha = 0;
    }
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        alpha: alpha,
        toString: toString,
      }
      : null;
  },
  formatItemVersions(item, itemPre, versionsFields, keyPre = '') {
    for (let versionsFieldKey in versionsFields) {
      const versionsField = versionsFields[versionsFieldKey];
      this.formatItem(
        item,
        itemPre.last_version,
        versionsField,
        versionsFieldKey,
        `${keyPre}last_version__`,
      );
    }
  },
  formatItemLocales(item, itemPre, localesFields, keyPre = '') {
    for (let localesFieldKey in localesFields) {
      item[`${keyPre}locales_main__${localesFieldKey}`] = itemPre
        ? itemPre[localesFieldKey]
        : null;
      const locales = this.$store.state.locale.locales;
      locales.forEach((locale) => {
        item[`${keyPre}locales_${locale.code}__${localesFieldKey}`] =
          itemPre && itemPre.locales && itemPre.locales[locale.code]
            ? itemPre.locales[locale.code][localesFieldKey]
            : null;
      });
    }
  },
  formatItemBelongsTo(
    item,
    itemPre,
    fieldKey,
    keyPre,
    textKey = 'text',
    selectMode = false,
  ) {
    if (!itemPre || !itemPre[fieldKey]) {
      return;
    }
    if (selectMode) {
      return;
    }
    item[`${keyPre}${fieldKey}`] = itemPre[fieldKey][textKey];
  },
  formatItemHasMany(
    item,
    itemPre,
    fieldKey,
    keyPre = '',
    textKey = 'text',
    selectMode = false,
  ) {
    if (!itemPre || !itemPre[fieldKey]) {
      return;
    }
    if (selectMode) {
      return;
    }
    let hasManyValue = '';
    itemPre[fieldKey].forEach((hasmanyItem) => {
      if (hasManyValue != '') {
        hasManyValue += ', ';
      }
      hasManyValue += hasmanyItem[textKey];
    });
    item[`${keyPre}${fieldKey}`] = hasManyValue;
  },
  formatItemTags(item, itemPre, fieldKey, keyPre = '') {
    if (!itemPre || !itemPre[fieldKey]) {
      return;
    }
    let _value = '';
    itemPre[fieldKey].forEach((tag) => {
      if (_value != '') {
        _value += ', ';
      }
      _value += tag;
    });
    item[`${keyPre}${fieldKey}`] = _value;
  },
  formatItemDate(item, itemPre, fieldKey, keyPre = '') {
    if (!itemPre || !itemPre[fieldKey]) {
      return;
    }
    item[`${keyPre}${fieldKey}`] = moment(itemPre[fieldKey]).format(
      'YYYY.MM.DD',
    );
  },
  formatItemLink(item, itemPre, fieldKey, keyPre = '') {
    if (!itemPre || !itemPre[fieldKey]) {
      return;
    }
    item[`${keyPre}${fieldKey}`] = `
      <WsIcon name="icon-md-link" />
    `;
  },
  formatItemDatetime(item, itemPre, fieldKey, keyPre = '') {
    if (!itemPre || !itemPre[fieldKey]) {
      return;
    }
    item[`${keyPre}${fieldKey}`] = moment(itemPre[fieldKey]).format(
      'YYYY.MM.DD HH:mm:ss',
    );
  },
  formatItemCustom(item, itemPre, fieldKey, keyPre = '', getValue) {
    // if (!itemPre || !itemPre[fieldKey]) {
    //   return;
    // }
    item[`${keyPre}${fieldKey}`] = getValue(item);
  },
  formatItemSwitch(
    item,
    itemPre,
    fieldKey,
    keyPre = '',
    activeText = '有',
    inactiveText = '無',
  ) {
    if (!itemPre) {
      return;
    }
    item[`${keyPre}${fieldKey}`] = itemPre[fieldKey]
      ? activeText
      : inactiveText;
  },
  formatItemSelect(
    item,
    itemPre,
    fieldKey,
    keyPre = '',
    textKey = 'text',
    items = [],
  ) {
    if (!itemPre || itemPre[fieldKey] == undefined) {
      return;
    }
    const tarItem = items.find((e) => {
      return e.value == item[`${keyPre}${fieldKey}`];
    });
    if (!tarItem) {
      return;
    }
    item[`${keyPre}${fieldKey}`] = tarItem[textKey];
  },
  formatItemOther(item, itemPre, fieldKey, keyPre) {
    if (!itemPre || !itemPre[fieldKey]) {
      return;
    }
    item[`${keyPre}${fieldKey}`] = itemPre ? itemPre[fieldKey] : null;
  },
  formatItem(item, itemPre, field, fieldKey, keyPre = '') {
    if (field.type == 'last_version') {
      this.formatItemVersions(item, itemPre, field.fields, keyPre);
    } else if (field.type == 'locales') {
      this.formatItemLocales(item, itemPre, field.fields, keyPre);
    } else if (field.type == 'select') {
      this.formatItemSelect(
        item,
        itemPre,
        fieldKey,
        keyPre,
        field.textKey,
        field.items,
      );
    } else if (field.type == 'switch') {
      this.formatItemSwitch(
        item,
        itemPre,
        fieldKey,
        keyPre,
        field.activeText,
        field.inactiveText,
      );
    } else if (field.type == 'belongsTo') {
      this.formatItemBelongsTo(
        item,
        itemPre,
        fieldKey,
        keyPre,
        field.textKey,
        field.selectMode,
      );
    } else if (field.type == 'hasMany') {
      this.formatItemHasMany(
        item,
        itemPre,
        fieldKey,
        keyPre,
        field.textKey,
        field.selectMode,
      );
    } else if (field.type == 'tags') {
      this.formatItemTags(item, itemPre, fieldKey, keyPre);
    } else if (field.type == 'date') {
      this.formatItemDate(item, itemPre, fieldKey, keyPre);
    } else if (field.type == 'link') {
      this.formatItemLink(item, itemPre, fieldKey, keyPre);
    } else if (field.type == 'datetime') {
      this.formatItemDatetime(item, itemPre, fieldKey, keyPre);
    } else if (field.type == 'custom') {
      this.formatItemCustom(item, itemPre, fieldKey, keyPre, field.getValue);
    } else {
      this.formatItemOther(item, itemPre, fieldKey, keyPre);
    }
  },
  getFormatedTableItems(items, fields) {
    if (!items) {
      return [];
    }
    const _items = JSON.parse(JSON.stringify(items));
    _items.forEach((item) => {
      for (let fieldKey in fields) {
        const field = fields[fieldKey];
        this.formatItem(item, item, field, fieldKey);
      }
    });
    return _items;
  },
  getValueByFieldKey(fieldKey, valueItem) {
    const keyArr = fieldKey.split('.');
    let target = valueItem;
    for (let i = 0; i < keyArr.length; i++) {
      const keyItem = keyArr[i];
      let targetKey = keyItem;
      if (targetKey == 'locales' && keyArr.length > 1) {
        continue;
      }
      if (target[targetKey] !== undefined) {
        target = target[targetKey];
      } else {
        return null;
      }
    }
    return target;
  },
};
