const model = {
  fields: {
    xc_customer: {
      type: 'belongsTo',
      label: '客戶',
      textKey: 'name',
      modelName: 'xc_customer'
    },
  },
}
export default model