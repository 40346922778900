const menu = [
  {
    title: "報價單",
    link: '/quotation',
    scopes: ["quotation-admin"]
  },
  {
    title: "報價單說明項目公版",
    link: '/quotation_des_item_template',
    scopes: ["quotation-admin"]
  },
  {
    title: "報價單執行項目公版",
    link: '/quotation_execute_item_template',
    scopes: ["quotation-admin"]
  },
];
export default {
  quotation: {
    icon: 'icon-md-build',
    title: "報價",
    scopes: ['quotation-admin'],
    menu: menu,
  },
};