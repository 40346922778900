<template>
  <div
    class="ws-avatar"
    :style="[
      {'background-image':`url(${_src})`},
      {'width':`${size}px`},
      {'height':`${size}px`},
      {'border-color':borderColor},
      {'border-width':`${borderWidth}px`},
    ]"
  >
    <RouterLink
      v-if="to"
      :to="to"
      class="ws-avatar__link"
    ></RouterLink>
  </div>
</template>

<script>
export default {
  computed: {
    _src() {
      return this.src ? this.src : require("@/__stone/img/wasajon.svg");
    },
  },
  props: {
    borderColor: {},
    borderWidth: {},
    src: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    size: {
      type: [String, Number],
    },
  },
};
</script>