const scopes = [
  {
    text: 'Project 新增',
    value: 'xc_project-create',
    tags: ['專案']
  },
  {
    text: 'Project 管理者 (可管理所有專案)',
    value: 'xc_project-admin',
    tags: ['專案']
  },
]

export default scopes