<template>
  <WsModal
    class="ws-app-modal-menu"
    ref="WsModal"
    title="123123"
    :value="$store.state.app.modalMenu.active"
    @close="$_onClose()"
  >
    <template #content>
      <WsMenuGroup :items="_items"></WsMenuGroup>
    </template>
  </WsModal>
</template>

<script>
export default {
  computed: {
    _items() {
      if (!this.$store.state.app.modalMenu.items) {
        return [];
      }
      const _items = [];
      this.$store.state.app.modalMenu.items.forEach((item) => {
        _items.push({
          ...item,
          onClick: () => {
            this.$store.dispatch("app/closeModalMenu");
            item.onClick();
          },
        });
      });
      return _items;
    },
  },
  watch: {
    "$store.state.app.modalMenu.active": {
      handler() {
        if (this.$store.state.app.modalMenu.active) {
          document.body.classList.add("scroll-disabled");
        } else {
          document.body.classList.remove("scroll-disabled");
        }
      },
    },
  },
  methods: {
    $_onClose() {
      this.$store.dispatch("app/closeModalMenu");
    },
  },
};
</script>