<template>
  <WsMain>
    <WsModelMyIndex
      :modelName="$store.state.stone_model.admin_event.modelName"
      label="我的一日宅宅"
      :fields="_fields"
      :readable="true"
      :inRowBtnRead="true"
      :updatable="false"
      :showFields="showFields"
      :paramsOnCrud="_paramsOnCrud"
    ></WsModelMyIndex>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      showFields: ["start_date", "status"],
    };
  },
  methods: {},
  computed: {
    _paramsOnCrud() {
      return {
        type: "wfh",
      };
    },
    _fields() {
      return this.$o_o.$h.model.getFieldsByKeys(
        this.$store.state.stone_model.admin_event.fields,
        ["id", "status", "start_date", "note"]
      );
    },
  },
};
</script>

<style>
</style>