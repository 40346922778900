export default {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_out_resource",
  label: "外部資源",
  fields: {
    name: {
      type: 'text',
      label: '名稱',
      rules: 'required'
    },
    address: {
      type: 'text',
      label: '地址'
    },
    agency: {
      type: 'text',
      label: '單位'
    },
    tel: {
      type: 'text',
      label: '電話'
    },
    email: {
      type: 'email',
      label: '信箱'
    },
    fb: {
      type: 'text',
      label: 'Facebook'
    },
    line: {
      type: 'text',
      label: 'LINE'
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    relate_links: {
      type: 'list',
      label: "相關連結",
      fields: {
        name: {
          type: 'text',
          label: '名稱'
        },
        link: {
          type: 'link',
          label: '連結'
        }
      }
    },
  },
  crudSetting: {
    index: {
      showFields: [
        'name',
        'tel',
        'email',
      ],
    },
  }
}