import H_Router from '@/__stone/helpers/router'

const routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index', 'create', 'update', 'read'],
    modelName: 'xc_double_entry_record',
    middleware: 'auth',
    meta: {
      requiredScopes: ['xc_financial-admin'],
    },
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index', 'read', 'create', 'update'],
    modelName: 'xc_financial_first_grade',
    middleware: 'auth',
    meta: {
      requiredScopes: ['xc_financial-admin'],
    },
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index','create', 'update', 'read'],
    modelName: 'xc_financial_item',
    middleware: 'auth',
    meta: {
      requiredScopes: ['xc_financial-admin'],
    },
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['read'],
    modelName: 'xc_financial_second_grade',
    middleware: 'auth',
    meta: {
      requiredScopes: ['xc_financial-admin'],
    },
  }),
  {
    path: '/calc/xc_financial',
    name: 'calc_xc_financial',
    component: () => import('@/__modules/xc_financial/views/Calc.vue'),
  },
];

export default routes