// import H_Menu from './menu'
import config from '@/__config'

export default {
  getModulesContext() {
    try {
      return require.context('@' + '/__modules/', true, /\.js/i);
    } catch (error) {
      return
    }
  },
  getModulesJson() {
    const modules = this.getModulesContext()
    if (!modules) {
      return
    }
    const moduleFiles = {}
    modules.keys().forEach(modulePath => {
      const keyArr = modulePath.split('/')
      const key = keyArr[1]
      if (!moduleFiles[key]) {
        moduleFiles[key] = {}
      }
      if (!moduleFiles[key][keyArr[2]]) {
        moduleFiles[key][keyArr[2]] = {}
      }
      if (keyArr[2] == 'init.js') {
        moduleFiles[key].init = modules(modulePath).default
      }
    });
    return moduleFiles
  },
  getModulesFilesHelpers() {
    const modules = this.getModulesContext()
    if (!modules) {
      return
    }
    const moduleFiles = {}
    modules.keys().forEach(modulePath => {
      const keyArr = modulePath.split('/')
      const key = keyArr[1]
      if (!moduleFiles[key]) {
        moduleFiles[key] = {}
      }
      if (!moduleFiles[key][keyArr[2]]) {
        moduleFiles[key][keyArr[2]] = {}
      }
      if (keyArr[2] == 'init.js') {
        moduleFiles[key].init = modules(modulePath).default
      }
    });
    return moduleFiles
  },
  getModuleInits() {
    const moduleInits = []
    const moduleFiles = this.getModulesJson()
    if (!moduleFiles) {
      return
    }
    for (const moduleKey in moduleFiles) {
      if (!moduleFiles[moduleKey].init) {
        continue
      }
      if (!config.wsmodule[moduleKey]) {
        continue
      }
      moduleInits.push(
        moduleFiles[moduleKey].init
      )
    }
    return moduleInits
  },
  async init() {
    const moduleInits = this.getModuleInits()
    const tasks = []
    moduleInits.forEach(moduleInit => {
      tasks.push(moduleInit('asdf'))
    });
    await Promise.all(tasks)
  },
  extendModuleHelperAction(moduleName, fileName, methodName, data, res) {
    if (res === undefined) {
      res = null;
    }
    const modules = this.getModulesContext()
    if (!modules) {
      return
    }
    const moduleFiles = {}
    modules.keys().forEach(modulePath => {
      const keyArr = modulePath.split('/')
      const key = keyArr[1]
      if (!moduleFiles[key]) {
        moduleFiles[key] = {}
      }
      if (keyArr[2] != 'extend_modules') {
        return
      }
      if (keyArr[3] != moduleName) {
        return
      }
      if (keyArr[4] != 'helpers') {
        return
      }
      if (keyArr[5] != fileName) {
        return
      }
      const _file = modules(modulePath).default
      if (!_file[methodName]) {
        return
      }
      res = _file[methodName](data, res)
    });
    return res;
  }
}