<template>
  <div>
    <WsTable
      :headers="headers"
      :items="admin_settings"
    >
      <template v-slot:td="{item,headersItem,itemIndex}">
        <div v-if="headersItem.value=='admin'">
          {{item.admin.name}}
        </div>
        <div v-if="headersItem.value=='value'">
          <WsState
            :type="valueType"
            :value="item.value"
            :showText="false"
            @input="$_onInput($event,item,headersItem.value,itemIndex)"
          ></WsState>
        </div>
      </template>
    </WsTable>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          :loading="loading.submit"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: {
        submit: false,
      },
      admin_settings: [],
      items: [
        {
          a: "1",
          b: "1",
        },
        {
          a: "2",
          b: "2",
        },
        {
          a: "3",
          b: "3",
        },
      ],
      headers: [
        {
          text: "後台管理員",
          value: "admin",
        },
        {
          text: "Value",
          value: "value",
        },
      ],
    };
  },
  methods: {
    async $_onSubmit() {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$axios.post(this._url, {
          admin_settings: this._state,
        });
        this.$store.dispatch("app/addSnack", "內容儲存成功");
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onInput($event, item, valueKey, itemIndex) {
      this.admin_settings[itemIndex][valueKey] = $event;
    },
    async $_fetchData() {
      const res = await this.$axios.get(this._url);
      this.admin_settings = res.data.admin_settings;
    },
  },
  computed: {
    _state() {
      const _state = [];
      this.admin_settings.forEach((admin_setting) => {
        _state.push({
          admin_id: admin_setting.admin_id,
          value: admin_setting.value,
        });
      });
      return _state;
    },
    _url() {
      return `/admin_setting/${this.module}/${this.type}`;
    },
  },
  mounted() {
    this.$_fetchData();
  },
  props: {
    module: {},
    type: {},
    valueType: {},
  },
};
</script>

<style>
</style>