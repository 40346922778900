<template>
  <button
    @click.stop="$_onClick()"
    class="ws-state-checkbox"
    :class="[{active:value},{indeterminate:value=='-'}]"
  >
    <WsIcon
      class="ws-state-checkbox__icon true"
      name="icon-md-check-box"
    />
    <WsIcon
      class="ws-state-checkbox__icon false"
      name="icon-md-check-box-outline-blank"
    />
    <WsIcon
      class="ws-state-checkbox__icon indeterminate"
      name="icon-ws-filled-indeterminate-check-box"
    />
    <WsText class="ws-state-checkbox__title">{{text}}</WsText>
  </button>
</template>

<script>
export default {
  methods: {
    $_onClick() {
      let _value;
      if (!this.value) {
        _value = true;
      } else if (this.value == "-") {
        _value = true;
      } else {
        _value = false;
      }
      this.$emit("input", _value);
    },
  },

  props: {
    text: {
      type: String,
    },
    value: {
      type: [Boolean, String],
      default: false,
    },
  },
};
</script>

<style>
</style>