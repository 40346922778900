<template>
  <XcTaskRead
    :hasScopeEdit="_hasScopeEdit"
    :xc_project="xc_project"
    :id="_xc_task_id"
  ></XcTaskRead>
</template>

<script>
export default {
  computed: {
    _xc_task_id() {
      return this.$route.params.xc_task_id;
    },
    _hasScopeEdit() {
      return this.$o_o.$h._m.xc_project.hasScope(
        ["edit", "admin"],
        this.xc_project.admin_scope
      );
    },
  },
  props: {
    xc_project: {},
  },
};
</script>