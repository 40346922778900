import H_Router from '@/__stone/helpers/router'

export default [
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'quotation',
    meta: {
      middleware: 'auth',
      requiredScopes: ['quotation-admin'],
    },
  }),
  {
    path: '/quotation/create',
    name: 'quotation_create',
    component: () =>
      import('@/__modules/quotation/views/Quotation/Create.vue'),
  },
  {
    path: '/quotation/:id/update',
    name: 'quotation_update',
    component: () =>
      import('@/__modules/quotation/views/Quotation/Update.vue'),
  },
  {
    path: '/quotation/:id',
    name: 'quotation_read',
    component: () =>
      import('@/__modules/quotation/views/Quotation/Read.vue'),
  },
  {
    path: '/print/quotation/:id',
    name: 'print_quotation_read',
    component: () =>
      import('@/__modules/quotation/views/Quotation/Read.vue'),
    meta: {
      template: 'print',
      middleware: 'auth',
      requiredScopes: [
        'quotation-admin',
      ],
    },
  },
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'quotation_des_item_template',
    meta: {
      middleware: 'auth',
      requiredScopes: ['quotation-admin'],
    },
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'quotation_execute_item_template',
    meta: {
      middleware: 'auth',
      requiredScopes: ['quotation-admin'],
    },
  }),
]