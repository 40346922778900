<template>
  <div class="ws-search-bar">
    <WsIcon name="icon-md-search"></WsIcon>
    <WsState
      v-model="search"
      @submit="$_submit()"
    ></WsState>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
    };
  },
  methods: {
    $_submit() {},
  },
};
</script>