<template>
  <div
    class="ws-state-date"
    :class="{'is-dislabled':true}"
  >
    <DatePicker
      ref="input"
      type="time"
      :lang="lang"
      class="ws-state-date-picker"
      :value="_value"
      @input="$_onInput($event)"
      :placeholder="placeholder"
      :clearable="clearable"
      :disabled-date="$_disabledDate"
    >
      <slot name="icon-calendar">
        <i class="icon-calendar"></i>
      </slot>
    </DatePicker>
  </div>
</template>

<script>
export default {
  name: "WsStateDate",

  data: () => ({
    lang: {
      formatLocale: {
        firstDayOfWeek: 7,
      },
    },
  }),

  props: {
    disabledDate: {
      type: Function,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    value: {},
    placeholder: {
      type: String,
      default: "選擇日期",
    },
    format: {
      type: String,
      default: "HH:mm:ss",
    },
    startDate: {
      type: String,
    },
  },

  computed: {
    _value() {
      if (this.value) {
        return new Date(this.value);
        // return new Date(`${this.$moment().format("YYYY-MM-DD")} ${this.value}`);
      } else {
        return null;
      }
    },
  },

  methods: {
    $_disabledDate(date) {
      if (this.startDate) {
        if (this.$moment(date).isBefore(this.startDate)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    $_onInput($event) {
      // this.$emit("input", this.$moment($event).format(this.format));
      if (!$event) {
        return this.$emit("input", null);
      }
      this.$emit("input", this.$moment($event));
    },
    customFormatter(date) {
      return this.$moment(date).format(this.format);
    },
  },
};
</script>