export default {
  modelName: 'tag',
  label: '標籤',
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "標籤名稱",
      rules: 'required',
      labelInLocale: true,
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      readonly: true
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      showFields: ['id', 'name', 'updated_at', 'created_at'],
      infiniteScroll: true
    }
  }
}