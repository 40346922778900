<template>
  <div
    class="ws-logo"
    :style="[
      {width:`${size}px`},
      {height:`${size}px`},
    ]"
  >
    <img
      :src="_logo"
      alt=""
      :style="[
        {width:`${size}px`},
        {height:`${size}px`},
      ]"
    >
  </div>
</template>

<script>
export default {
  computed: {
    _logo() {
      if (this.$config.theme.logo) {
        return this.$config.theme.logo;
      } else {
        return require("@/__stone/img/logo.svg");
      }
    },
  },
  props: {
    size: {
      type: [Number, String],
      default: 40,
    },
  },
};
</script>

<style>
</style>