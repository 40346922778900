const model = {
  fields: {
    gitlab_name: {
      type: 'text',
      label: 'Gitlab Name'
    },
    gitlab_email: {
      type: 'email',
      label: 'Gitlab Email'
    },
  },
  crudSetting: {
    index: {
      showFields: [
      ]
    }
  }
}
export default model