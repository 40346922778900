<template>
  <div
    class="ws-state-icon-picker ws-state-select"
    v-click-outside="$_onClickOutside"
  >
    <WsBtn
      maxWidth="unset"
      outlined
      @click="$_onOpenClick()"
    >
      <WsFlex
        class="w-full"
        justifyContent="space-between"
        alignItems="center"
      >
        <template>
          <WsIcon class="ws-state-icon-picker_value" :name="value"/>
        </template>
        <WsIcon
          v-if="_clearable"
          @click.stop="$_onClear()"
          name="icon-md-clear"
        />
        <WsIcon name="icon-md-arrow-drop-down" />
      </WsFlex>
    </WsBtn>
    <div
      v-if="menuActive"
      class="ws-state-select__menu"
    >
      <WsState
        type="search"
        :placeholder="`${$t('search')}...`"
        :value="searching"
        @input="$_onSearching($event)"
        autofocus
      />
      <div class="ws-state-select__options">
        <div
          v-for="(item, itemIndex) in _items"
          :key="itemIndex"
          :class="['ws-state-select__icon_option', {'active': item == value}]"
          @click="$_onOptionClick(item)"
        >
          <WsIcon :name="item"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icon_library from '@/static/icon-library.js';
export default {
  data: () => ({
    menuActive: false,
    searching: '',
    items: null
  }),
  mounted() {
    this.items = icon_library.icon
  },
  methods: {
    $_onOpenClick() {
      this.menuActive = !this.menuActive
    },
    $_onOptionClick(item) {
      this.$emit('input', item)
      this.menuActive = false
    },
    $_onClickOutside() {
      this.menuActive = false
    },
    $_onSearching($event) {
      this.searching = $event
    },
    $_onClear() {
      this.$emit('input', null)
    },
  },
  computed: {
    _items() {
      if (this.items?.length > 0) {
        let searchString = this.searching.toLowerCase()
        return this.items.filter(item => item.toLowerCase().includes(searchString))
      } else {
        return []
      }
    },
    _clearable() {
      if (this.value != null)
        return true
      else 
        return false
    }
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  }
}
</script>