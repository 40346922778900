<template>
  <WsMain>
    <WsImg
      v-if="url"
      :url="url"
      alt=""
      @click="$_onUploadClick()"
      :wireBackground="true"
    />
    <div>
      <WsBtn @click="$_onUploadClick()">{{$t('upload_image')}}</WsBtn>
    </div>
    <input
      v-show="false"
      @change="$_onChange($event)"
      type="file"
      ref="fileInput"
      accept="image/*"
    >
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      file: null,
      url: null,
    };
  },
  methods: {
    async $_onChange($event) {
      this.file = $event.target.files[0];
      this.url = await this.$o_o.$h.file.getBase64FromFile(this.file);
    },
    $_onUploadClick() {
      this.$refs.fileInput.click();
    },
    async submit() {
      if (!this.file) {
        return;
      }
      const res = await this.$o_o.$s._m.general_image.upload(
        this.file,
        this.file.name
      );
      this.$emit("submit", res);
    },
  },
};
</script>

<style>
</style>