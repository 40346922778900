<template>
  <component
    :is="_component"
    class="ws-menu-item"
    :to="link"
    v-ripple.300
    :class="[
      {'has-icon':icon}
    ]"
    @click="$_onClick()"
  >
    <WsIcon
      v-if="icon"
      :name="icon"
    />
    <WsText
      v-if="titleInLocale"
      size="14"
    >{{$t(title)}}</WsText>
    <WsText
      v-else
      size="14"
    >{{title}}</WsText>
  </component>
</template>

<script>
export default {
  methods: {
    $_onClick() {
      if (this.onClick) {
        this.onClick();
      }
    },
  },
  computed: {
    _component() {
      if (this.link) {
        return "RouterLink";
      } else {
        return "a";
      }
    },
  },
  props: {
    link: {
      type: String,
      // required: true,
    },
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    titleInLocale: {
      type: Boolean,
      default: true,
    },
    onClick: {},
  },
};
</script>