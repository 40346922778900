const routes = [
  {
    path: '/my/off_day',
    name: 'my_off_day',
    component: () => import('@/__modules/admin_event/views/MyOffDay/Index.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/my/wfh',
    name: 'my_wfh',
    component: () => import('@/__modules/admin_event/views/MyWfh/Index.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/off_day',
    name: 'off_day',
    component: () => import('@/__modules/admin_event/views/OffDay/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['admin_event-admin'],
    }
  },
  {
    path: '/wfh',
    name: 'wfh',
    component: () => import('@/__modules/admin_event/views/Wfh/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['admin_event-admin'],
    }
  },
]

export default routes