<template>
  <div
    class="ws-table"
    :class="styleType"
  >
    <div class="ws-table__table-container">
      <table
        cellspacing="0"
        cellpadding="0"
      >
        <tr>
          <th
            v-for="(field,fieldKey) in _fields"
            :key="fieldKey"
          >
            <span>{{field.label}}</span>
          </th>
        </tr>
        <tr
          :key="valueIndex"
          v-for="(valueItem,valueIndex) in _value"
        >
          <td
            v-for="(field,fieldKey) in _fields"
            :key="fieldKey"
            :style="`width: ${field.width}`"
          >
            <WsInfo
              v-bind="field"
              :label="undefined"
              :value="valueItem[fieldKey]"
              :modelData="valueItem"
            ></WsInfo>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fields: {
      type: Object,
    },
    value: {
      type: Array,
    },
    styleType: {
      type: String,
      default: "",
    },
  },
  computed: {
    _value() {
      return this.value ? this.value : [];
    },
    _fields() {
      return this.fields ? this.fields : [];
    },
  },
};
</script>

<style>
</style>