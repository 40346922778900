<template>
  <WsText size="14">{{ _value }}</WsText>
</template>

<script>
export default {
  computed: {
    _value() {
      const _value = this.value || [];
      return _value.join(", ");
    },
  },
  props: {
    value: {},
  },
};
</script>

<style>
</style>