<template>
  <WsMain>
    <WsText
      v-if="file"
      class="mb-20"
    >{{file.name}}</WsText>
    <div>
      <WsBtn @click="$_onUploadClick()">{{$t('upload')}}</WsBtn>
    </div>
    <input
      v-show="false"
      @change="$_onChange($event)"
      type="file"
      ref="fileInput"
      accept="image/*"
    >
  </WsMain>
</template>

<script>
export default {
  data: () => ({
    file: null,
    url: null,
  }),

  methods: {
    async $_onChange($event) {
      this.file = $event.target.files[0];
    },
    $_onUploadClick() {
      this.$refs.fileInput.click();
    },
    submit() {
    },
  },

  props: {
    signed: {
      type: Boolean,
      default: false,
    },
    getUploadUrl: {
      type: String,
      default: "/file/general/upload_url",
    },
  },
};
</script>

<style>
</style>