<template>
  <div class="xc-task-status-bar">
    <XcTaskStatusLabel :status="xc_task.status"></XcTaskStatusLabel>
    <WsText
      class="xc-task-status-bar__text"
      size="12"
    >{{ _title }}</WsText>
  </div>
</template>

<script>
export default {
  props: {
    xc_task: {},
  },
  computed: {
    _title() {
      let _title = this.$o_o.$h.time.getTimeText(this.xc_task.created_at);
      if (this.xc_task?.creator) {
        _title += ` Created by ${this.xc_task.creator?.name}`;
      }
      return _title;
    },
  },
};
</script>

<style lang="scss">
.xc-task-status-bar {
  display: flex;
  // align-items: "center";
  align-items: flex-start;
  .xc-task-status-bar__text {
    padding: 2px;
    margin-left: 8px;
  }
}
</style>