<template>
  <div
    class="ws-crud"
    :class="{ sticky: headerSticky }"
  >
    <WsText
      size="18"
      v-if="title"
    >{{ title }}</WsText>
    <WsCrudTitleBar
      v-if="label || creatable || exportable || _titleBarCustomBtns"
      class="mb-20"
      :importable="importable"
      :modelName="modelName"
      :urlModelName="urlModelName"
      :label="label"
      :params="_params"
      :pageMode="pageMode"
      :dialogCreate="dialogCreate"
      :createUrl="createUrl"
      :orderable="_orderable"
      :creatable="creatable"
      :exportable="exportable"
      :labelInLocale="labelInLocale"
      :customBtns="_titleBarCustomBtns"
      :customDropdownMenu="titleCustomDropdownMenu"
      @create="$_onCreate()"
      @customBtnClick="$emit('title-bar-custom-btn-click', $event)"
      @title-custom-dropdown-menu-click="$emit('title-custom-dropdown-menu-click', $event)"
      @import="$_onImport()"
    ></WsCrudTitleBar>
    <WsTabs
      class="d-xs-none"
      v-if="filterTabs"
      :items="filterTabs"
      v-model="filterTabsData"
      @input="$_onFilterInput($event)"
    >
    </WsTabs>
    <WsCrudTabsModel
      v-if="filterTabsModelProps"
      v-bind="filterTabsModelProps"
      v-model="C_params"
    ></WsCrudTabsModel>
    <WsCard>
      <div class="ws-crud-filter-container">
        <WsCrudFilter
          v-if="hasFilter"
          ref="filter"
          :searching="searching"
          :orderBy="filter.orderBy"
          :orderWay="filter.orderWay"
          :order="C_order"
          :orderItems="_orderItems"
          @search="$_onQueryUpdate($event, 'searching', 'search')"
          @update:order="$_onQueryUpdate($event, 'C_order', 'order_by')"
          @update:searching="$_onQueryUpdate($event, 'searching', 'search')"
        >
        </WsCrudFilter>
        <WsStateForm
          v-if="hasFilterSelect"
          ref="WsStateForm"
          :fields="filterSelects"
          :value="filterSelectsData"
          @input="$_onFilterSelectFormInput($event)"
        ></WsStateForm>
      </div>
      <WsCrudTable
        v-if="_tableDisplay"
        ref="WsCrudTable"
        :loading="modeldataLoading"
        :inRowBtnComplete="inRowBtnComplete"
        :inRowBtnRead="_inRowBtnRead"
        :inRowBtnUpdate="_inRowBtnUpdate"
        :inRowBtnDelete="_inRowBtnDelete"
        :dialogRead="_dialogRead"
        :sortOption="sortOption"
        :dialogUpdate="dialogUpdate"
        :actionWidth="actionWidth"
        :dialogDelete="dialogDelete"
        :items="modelDatas"
        :showFields="_showFields"
        :itemsPerPage="itemsPerPage"
        :fields="_indexFields"
        :displayFields="_displayFields"
        :headers="_tableHeaders"
        :pageMode="pageMode"
        :modelName="modelName"
        :urlModelName="urlModelName"
        :dataTotalCount="dataTotalCount"
        :currentPage="page"
        :lastPage="lastPage"
        :expandable="expandable"
        :orderBy.sync="filter.orderBy"
        :orderWay.sync="filter.orderWay"
        :orderByDefault="_orderByDefault"
        :orderWayDefault="_orderWayDefault"
        :scrollTop="scrollTop"
        :headerSticky="headerSticky"
        :showDataTotalCount="showDataTotalCount"
        :checkUpdatable="checkUpdatable"
        :checkDeletable="checkDeletable"
        :readable="readable"
        :updatable="updatable"
        :deletable="deletable"
        :duplicatable="duplicatable"
        @read="$_onRead($event)"
        @pageto="$_onPageto($event)"
        @update="$_onUpdate($event)"
        @delete="$_onDelete($event)"
        @duplicate="$_onDuplicate($event)"
        @sort-update="$_onSortUpdate($event)"
        @custom-table-action="$_onCustomTableAction($event)"
        @updateAlert="$_onUpdateModelDatas($event)"
        @update:order="$_onUpdateOrder($event)"
        :paginate="_paginate"
        :getUpdateUrl="getUpdateUrl"
        :getReadUrl="getReadUrl"
        :customTableActions="customTableActions"
        :selectable="selectable"
        :modelDataKey="modelDataKey"
        :hasMaxHeight="hasSticky"
        :rowClickRead="rowClickRead"
        :value="selected"
        @input="$_onSelect($event)"
      ></WsCrudTable>
      <WsLoading v-if="modeldataLoading"></WsLoading>
      <!-- </div> -->
    </WsCard>
    <WsCreateDialog
      ref="createDialog"
      :label="label"
      :fields="_createFields"
      :getFields="getCreateFields"
      :modelName="modelName"
      :errorMessages="createErrorMessages"
      @submit="$_onCreateSubmit($event)"
    />
    <WsReadDialog
      ref="readDialog"
      :fields="_readFields"
      :titleKey="titleKey"
      @delete="$_onDelete($event)"
      @update="$_onUpdate($event)"
      @copy="$_onCopy($event)"
      :pageMode="pageMode"
      :dialogUpdate="dialogUpdate"
      :modelName="modelName"
      :urlModelName="urlModelName"
      :copyable="copyable"
      :deletable="deletable"
      :updatable="updatable"
      :fetchOnOpen="fetchOnOpenRead"
      :getUpdateUrl="getUpdateUrl"
      :getReadFetchUrl="getReadFetchUrl"
    >
      <template
        v-if="$scopedSlots.readDialogContent"
        v-slot:content="readContentSlotProps"
      >
        <slot
          name="readDialogContent"
          :item="readContentSlotProps.item"
        ></slot>
      </template>
      <template v-slot:leftActions="readLeftActionsSlotProps">
        <slot
          name="readDialogLeftActions"
          :item="readLeftActionsSlotProps.item"
        ></slot>
      </template>
      <template v-slot:rightActions="readRightActionsSlotProps">
        <slot
          name="readDialogRightActions"
          :item="readRightActionsSlotProps.item"
        ></slot>
      </template>
    </WsReadDialog>
    <WsModelUpdateDialog
      ref="updateDialog"
      :label="label"
      :fields="_fields"
      :updateFields="_updateFields"
      :getFields="getUpdateFields"
      :modelName="modelName"
      :getReadUrl="getUpdateReadUrl"
      :fetchOnOpen="fetchOnOpenUpdate"
      :getReadFetchUrl="getReadFetchUrl"
      :errorMessages="updateErrorMessages"
      @submit="$_onUpdateSubmit($event)"
    />
    <WsImportDialog
      ref="WsImportDialog"
      :modelName="modelName"
      @complete="$_onCompleteImport()"
    ></WsImportDialog>
    <WsAlert
      ref="deleteAlert"
      :title="$t('sure_to_delete')"
      :description="$t('delete_cannot_be_recovered')"
      @submit="$_onDeleteSubmit($event)"
    ></WsAlert>
  </div>
</template>

<script>
import S_APP_General from "@/__stone/service/app/general";
export default {
  data: () => ({
    page: 1,
    customFieldsFromApi: [],
    filter: {
      orderBy: "created_at",
      orderWay: "desc",
    },
    C_params: {},
    filterTabsData: null,
    isMounted: false,
    modeldataLoading: false,
    modelDatas: [],
    lastPage: null,
    dataTotalCount: null,
    searching: "",
    filterSelectsData: {},
    C_order: null,
    createErrorMessages: null,
    updateErrorMessages: null,
    currentStickyOffset: 0,
    filterHide: true,
    scrollTop: 0,
    // >_< Axios
    // cancelTokenSource deprecate from 0.22
    cancelTokenSource: null,
    // focusReadIndex: null,
  }),
  methods: {
    $_release() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
    },
    $_onFilterInput() {},
    $_onCompleteImport() {
      this.$_reFetchData();
    },
    $_onImport() {
      this.$refs.WsImportDialog.open();
    },
    updateModelData(modelData) {
      this.$_updateModelData(modelData);
    },
    $_updateModelData(modelData) {
      const _modelDatas = [...this.modelDatas];
      const _index = _modelDatas.findIndex((e) => {
        return e.id == modelData.id;
      });
      if (_index >= 0) {
        _modelDatas.splice(_index, 1);
        _modelDatas.splice(_index, 0, modelData);
      }
      this.modelDatas = _modelDatas;
    },
    async $_onUpdateOrder($event) {
      this.modelDatas = $event;
      setTimeout(() => {
        const _order = [];
        this.modelDatas.forEach((modelData, modelDataIndex) => {
          _order.push({
            id: modelData.id,
            sq: String(modelDataIndex).padStart(this.sequenceLength, "0"),
          });
        });
        if (this.sortOption && this.sortOption.patchUrl) {
          this.$axios
            .patch(this.sortOption.patchUrl, {
              order: _order,
            })
            .then(() => {
              this.$store.dispatch("app/addSnack", this.$t("排序更新"));
            });
        }
      }, 0);
    },
    $_getFilterTabsParams() {
      if (!this.filterTabsData) {
        return {};
      }
      const _filterTab = this.filterTabs.find((e) => {
        return e.value == this.filterTabsData;
      });
      return _filterTab.params;
    },
    $_onSelect($event) {
      this.$emit("update:selected", $event);
    },
    $_onCustomTableAction($event) {
      this.$emit($event.emit, $event.data);
      this.$emit("custom-table-action", $event);
    },
    reset() {
      this.page = 1;
      this.modelDatas = [];
    },
    $_handleScroll() {
      if (!document) {
        return;
      }
      if (this.headerSticky) {
        const extendTop = 168;
        this.scrollTop = window.scrollY - extendTop;
      }
      if (!this.infiniteScroll) {
        return;
      }
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - this.startLoadingDis
      ) {
        if (this.modeldataLoading) {
          return;
        } else {
          if (this.lastPage > this.page) {
            this.$_onPageto(this.page + 1);
          }
        }
      }
    },
    $_initOrder() {
      let orderQuery = this.$_getOrderUrl("created_at");
      if (this._query && this._query.order_by) {
        this.C_order = this._query.order_by;
        orderQuery = this.$_getOrderUrl(this._query.order_by);
      } else if (this.orderBySq) {
        this.C_order = "sq";
        orderQuery = this.$_getOrderUrl("sq");
      } else if (this.order) {
        this.C_order = this.order;
        orderQuery = this.$_getOrderUrl(this.order);
      }
      this.filter = {
        orderBy: orderQuery.order_by,
        orderWay: orderQuery.order_way,
      };
    },
    $_initFilterTabs() {
      if (this.filterTabs && this.filterTabs.length) {
        this.filterTabsData = this.filterTabs[0].value;
      }
    },
    $_initSearch() {
      if (this._query?.search) {
        this.searching = this._query.search;
      }
    },
    async $_initCustomFields() {
      if (!this.hasCustomFieldsFromApi) {
        return;
      }
      const res = await this.$axios.get("custom_field", {
        params: {
          getall: 1,
          target: this.modelName,
        },
      });
      this.$_setCustomFieldsFromRes(res);
    },
    $_setCustomFieldsFromRes(res) {
      const _fields = {};
      res.data.data.forEach((item) => {
        _fields[item.code_name] = {
          label: item.name,
          type: item.type,
        };
      });
      this.customFieldsFromApi = _fields;
    },
    $_defaultSet() {
      this.$_initOrder();
      this.$_initFilterTabs();
      this.$_initSearch();
      this.$_initCustomFields();
      setTimeout(() => {
        this.isMounted = true;
      }, 0);
    },
    $_formatErrorMessages(errorMessages) {
      const formeted_error_messages = {};
      for (let key in errorMessages) {
        const errorMessage = errorMessages[key][0];
        if (this.$locale[errorMessage]) {
          formeted_error_messages[key] = this.$locale[errorMessage];
        } else {
          formeted_error_messages[key] = errorMessage;
        }
      }
      return formeted_error_messages;
    },
    $_getOrderUrl(order) {
      const orderItem = this._orderItems.find((e) => {
        return e.value == order;
      });
      return orderItem;
    },
    $_onOrderChange(order) {
      const orderQuery = this.$_getOrderUrl(order);
      this.filter = {
        orderBy: orderQuery.order_by,
        orderWay: orderQuery.order_way,
      };
    },
    $_onPageto($event) {
      this.page = $event;
      this.$router.push({
        query: {
          ...this.$route.query,
          page: Number($event),
        },
      });
      this.fetchData();
    },
    async fetchData() {
      if (this.$refs.WsCrudTable) {
        this.$refs.WsCrudTable.expandReset();
      }
      if (this.$route.query?.page) {
        this.page = this.$route.query.page;
      }
      this.modeldataLoading = true;
      try {
        let getUrl;
        if (this.getUrl) {
          getUrl = this.getUrl;
        } else if (this.parentModelName) {
          getUrl = `/${this.parentModelName}/${this.parentId}/${this.modelName}`;
        } else {
          getUrl = `/${this.modelName}`;
        }
        this.$emit("params", this._params);
        if (this.cancelTokenSource) {
          this.cancelTokenSource.cancel();
        }
        this.cancelTokenSource = this.$axios.CancelToken.source();
        const res = await this.$axios.get(getUrl, {
          params: {
            ...this._params,
            ...this.C_params,
            ...this.extendParams,
          },
          cancelToken: this.cancelTokenSource.token,
        });
        if (res.data.meta && res.data.meta.last_page) {
          this.lastPage = res.data.meta.last_page;
        }
        if (res.data.meta && res.data.meta.total) {
          this.dataTotalCount = res.data.meta.total;
        }
        if (this.infiniteScroll) {
          this.modelDatas = [...this.modelDatas, ...res.data.data];
        } else {
          this.modelDatas = res.data.data;
        }
        this.$emit("input", this.modelDatas);
        this.modeldataLoading = false;
      } catch (error) {
        if (error.__CANCEL__) {
          return;
        }
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          alert(this.$t(error.response.data.message));
        } else {
          alert(this.$t("存取資料錯誤，請重新嘗試"));
        }
      }
    },
    $_onQueryUpdate($event, type, queryName) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          [queryName]: $event,
        },
      });
      this[type] = $event;
    },
    $_onCreate() {
      this.createErrorMessages = null;
      this.$refs.createDialog.open();
    },
    $_onRead($event) {
      const _item = this.modelDatas[$event.itemIndex];
      this.$refs.readDialog.open(_item, $event.itemIndex);
    },
    $_onUpdate($event) {
      this.updateErrorMessages = null;
      const _item = this.modelDatas[$event.itemIndex];
      this.$refs.readDialog.close();
      this.$refs.updateDialog.open(_item);
    },
    $_onDelete($event) {
      this.$refs.deleteAlert.open({
        item: $event.item,
        itemIndex: $event.itemIndex,
      });
    },
    $_onDuplicate($event) {
      this.$store.dispatch("app/setAlert", {
        title: "確定複製？",
        description: "",
        action: this.$_onDuplicateSubmit,
        data: {
          id: $event.item.id,
        },
      });
    },
    $_onCopy($event) {
      this.updateErrorMessages = null;
      const _item = this.modelDatas[$event.itemIndex];
      this.$refs.readDialog.close();
      this.$refs.createDialog.open(_item);
    },
    copyOpen(id) {
      const _item = this.modelDatas.find((e) => {
        return e.id == id;
      });
      this.$refs.readDialog.close();
      this.$refs.createDialog.open(_item);
    },
    async $_onCreateSubmit($event) {
      this.$store.dispatch("app/startPageLoading");
      try {
        let postData = {
          ...$event,
          ...this.createData,
          ...this.extendPostData,
          ...this.paramsOnCrud,
        };
        let _postUrl;
        if (this.dialogCreateSubmitUrl) {
          _postUrl = this.dialogCreateSubmitUrl;
        } else if (this.parentModelName) {
          _postUrl = `/${this.parentModelName}/${this.parentId}/${this.modelName}`;
        } else {
          _postUrl = `/${this.modelName}`;
        }
        await this.$axios.post(_postUrl, postData);
        this.$_reFetchData();
        this.$refs.createDialog.close();
      } catch (error) {
        alert(this.$o_o.$h.http.getErrorMessageFromRes(error));
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onUpdateSubmit($event) {
      this.$store.dispatch("app/startPageLoading");
      try {
        let postData = {
          ...$event,
          ...this.updateData,
          ...this.extendPostData,
          ...this.paramsOnCrud,
        };
        let _postUrl;
        if (this.getDialogUpdateSibmitUrl) {
          _postUrl = this.getDialogUpdateSibmitUrl($event);
        } else {
          _postUrl = `/${this.modelName}/${$event.id}`;
        }
        const res = await this.$axios.patch(_postUrl, postData);
        this.$_updateModelData(res.data.data);
        setTimeout(() => {
          this.$refs.updateDialog.close();
        }, 0);
      } catch (error) {
        this.updateErrorMessages =
          this.$o_o.$h.http.getErrorMessageFromRes(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onDeleteSubmit($event) {
      this.$store.dispatch("app/startPageLoading");
      try {
        let _deleteUrl;
        if (this.getDeleteSubmitUrl) {
          _deleteUrl = this.getDeleteSubmitUrl($event.item);
        } else {
          _deleteUrl = `/${this.modelName}/${$event.item.id}`;
        }
        await this.$axios.delete(_deleteUrl);
        this.$_reFetchData();
      } catch (error) {
        alert("刪除發生錯誤");
      } finally {
        this.$store.dispatch("app/stopPageLoading");
        this.$refs.deleteAlert.close();
        this.$refs.readDialog.close();
      }
    },
    async $_onDuplicateSubmit(data) {
      this.$store.dispatch("app/startPageLoading");
      try {
        let _duplicateUrl = `/${this.modelName}/${data.id}/duplicate`;
        await this.$axios.post(_duplicateUrl);
        this.$_reFetchData();
      } catch (error) {
        alert("複製發生錯誤");
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onFilterSelectFormInput($event) {
      this.filterSelectsData = { ...this.filterSelectsData, ...$event };
      this.$emit("update:filter-select-form", this.filterSelectsData);
    },
    $_onUpdateModelDatas($event) {
      let _modelDatas = [...this.modelDatas];
      for (let i = 0; i < _modelDatas.length; i++) {
        _modelDatas[i] = { ..._modelDatas[i], ...$event[i] };
      }
      this.modelDatas = _modelDatas;
    },
    $_filterHideToggle() {
      this.filterHide = !this.filterHide;
    },
    $_reFetchData() {
      if (!this.isMounted) {
        return;
      }
      this.reset();
      this.fetchData();
    },
  },
  mounted() {
    this.$_defaultSet();
    setTimeout(() => {
      this.reset();
      this.fetchData();
    }, 0);
  },

  watch: {
    _params: {
      handler() {
        this.$emit("update:params", this._params);
      },
    },
    value: {
      handler() {
        if (this.value) {
          this.modelDatas = this.value;
        }
      },
      deep: true,
    },
    filterTabsData: {
      handler() {
        this.$_reFetchData();
      },
      deep: true,
    },
    C_params: {
      handler() {
        this.$_reFetchData();
      },
      deep: true,
    },
    filterSelectsData: {
      handler() {
        this.$_reFetchData();
      },
      deep: true,
    },
    filter: {
      handler() {
        this.$_reFetchData();
      },
      deep: true,
    },
    C_order: {
      handler() {
        if (!this.isMounted) {
          return;
        }
        this.$_onOrderChange(this.C_order);
      },
    },
    searching: {
      handler() {
        this.$_reFetchData();
        // this.reset();
        // this.fetchData();
      },
    },
  },

  computed: {
    _dialogRead() {
      if (!this.readable) {
        return false;
      } else {
        return this.dialogRead;
      }
    },
    _inRowBtnRead() {
      if (!this.readable) {
        return false;
      } else {
        return this.inRowBtnRead;
      }
    },
    _query() {
      return this.$route.query;
    },
    _titleBarCustomBtns() {
      if (!this.titleBarCustomBtns) {
        return undefined;
      }
      const _titleBarCustomBtns = [];
      this.titleBarCustomBtns.forEach((titleBarCustomBtn) => {
        if (titleBarCustomBtn.requireScopes) {
          if (!this.$o_o.$h.auth.hasScope(titleBarCustomBtn.requireScopes)) {
            return;
          }
        }
        _titleBarCustomBtns.push(titleBarCustomBtn);
      });
      return _titleBarCustomBtns;
    },
    _showFields() {
      let _showFields = [];
      if (this.showFields) {
        _showFields = [...this.showFields];
      } else {
        for (const fieldKey in this.fields) {
          _showFields.push(fieldKey);
        }
      }
      let modelName = this.modelName;
      // @Q@
      if (modelName == "cmser") {
        modelName = "admin";
      }
      if (modelName == "cmser_role") {
        modelName = "admin_role";
      }
      // const model = this.$store.state.stone_model[modelName];
      // if (model && model.options && model.options.site_region) {
      //   _showFields.push("site_region");
      // }
      return _showFields;
    },
    _tableDisplay() {
      if (this.infiniteScroll) {
        return true;
      } else {
        return !this.modeldataLoading;
      }
    },
    _items() {
      if (this.value) {
        return this.value;
      } else {
        return this.modelDatas;
      }
    },
    _params() {
      let _params = {
        ...this.params,
        ...this.extendPostData,
        ...this.paramsOnCrud,
        page: this.page,
        search: this.searching,
        order_way: this.filter.orderWay,
        order_by: this.filter.orderBy,
      };
      if (this.$config.locale.api && this.$config.locale.lang) {
        _params.lang = this.$store.state.locale.selectingLocale;
      }
      if (this.fetchQuery) {
        _params = { ..._params, ...this.fetchQuery };
      }

      // filterSelectsData
      if (JSON.stringify(this.filterSelectsData) !== "{}") {
        let filterParams = null;
        filterParams = this.$o_o.$h.model.getFormatedStates(
          // this._fields,
          this.filterSelects,
          this.filterSelectsData
        );
        // for (let key in filterParams) {
        //   let keepParam = false;
        //   for (let selectKey in this.filterSelects) {
        //     if (selectKey == key) {
        //       keepParam = true;
        //     }
        //     if (
        //       this.filterSelects[selectKey] &&
        //       this.filterSelects[selectKey].type === "date-range"
        //     ) {
        //       if (
        //         this.filterSelects[selectKey].startKey === key ||
        //         this.filterSelects[selectKey].endKey === key
        //       ) {
        //         keepParam = true;
        //       }
        //     }
        //   }
        //   if (!keepParam) {
        //     delete filterParams[key];
        //   }
        // }
        for (let key in filterParams) {
          const filterParamsItem = filterParams[key];
          if (filterParamsItem == null || filterParamsItem == undefined) {
            continue;
          } else {
            if (Array.isArray(filterParamsItem)) {
              filterParams[key] = filterParamsItem.join(",");
            } else {
              filterParams[key] = filterParamsItem;
            }
            _params = { ..._params, ...filterParams };
          }
        }
      }

      _params = {
        ..._params,
        ...this.$_getFilterTabsParams(),
      };

      return _params;
    },
    _inRowBtnUpdate() {
      if (!this.updatable) {
        return false;
      } else {
        return this.inRowBtnUpdate;
      }
    },
    _inRowBtnDelete() {
      if (!this.deletable) {
        return false;
      } else {
        return this.inRowBtnDelete;
      }
    },
    _orderByDefault() {
      const orderQuery = this.$_getOrderUrl(this.order);
      return orderQuery.order_by;
    },
    _orderWayDefault() {
      const orderQuery = this.$_getOrderUrl(this.order);
      return orderQuery.order_way;
    },
    _paginate() {
      if (this.hasPaginate != undefined) {
        return this.hasPaginate;
      } else if (this.infiniteScroll) {
        return false;
      } else if (this.dataTotalCount != null) {
        return true;
      } else {
        return false;
      }
    },
    _orderItems() {
      // orderBySq
      let _orderItems = [];
      if (this.orderBySq) {
        _orderItems = [
          ..._orderItems,
          {
            value: "sq",
            text: this.$t("排序設定"),
            order_by: "sq",
            order_way: "asc",
          },
        ];
      }
      _orderItems = [..._orderItems, ...this._baseOrderItems];
      if (this.extendOrderItems) {
        _orderItems = [..._orderItems, ...this.extendOrderItems];
      }
      return _orderItems;
    },
    _baseOrderItems() {
      return [
        {
          value: "last_update",
          text: this.$t("更新時間近至遠"),
          order_by: "updated_at",
          order_way: "desc",
        },
        {
          value: "last_created",
          text: this.$t("建立時間近至遠"),
          order_by: "created_at",
          order_way: "desc",
        },
      ];
    },
    _fields() {
      return {
        ...this.$o_o.$h.model.getScopesFilteredFields(
          this.$store.state.auth.scopes,
          this.fields
        ),
        ...this.customFieldsFromApi,
      };
    },
    _showFieldsUpdate() {
      if (!this.showFieldsUpdate) {
        return;
      }
      const _showFieldsUpdate = [...this.showFieldsUpdate];
      if (
        this.$o_o.$h.auth.hasScope(["site_region_cross-admin"]) &&
        this._fields.site_region
      ) {
        _showFieldsUpdate.push("site_region");
      }
      return _showFieldsUpdate;
    },
    _indexFields() {
      if (this.indexFields) {
        return this.indexFields;
      } else {
        return this._fields;
      }
    },
    _createFields() {
      if (this.createFields) {
        return this.createFields;
      } else {
        return S_APP_General.geCreateFields(
          this._fields,
          this.createHideFields
        );
      }
    },
    _updateFields() {
      if (this.updateFields) {
        return this.updateFields;
      } else {
        return S_APP_General.getUpdateFields(
          this._fields,
          this.updateHideFields,
          this._showFieldsUpdate
        );
      }
    },
    _displayFields() {
      return S_APP_General.getDisplayFields(this._fields);
    },
    _readFields() {
      return S_APP_General.getDisplayFields(this._fields, this.showFieldsRead);
    },
    _tableHeaders() {
      let _tableHeaders = [];
      this._showFields.forEach((showFieldKey) => {
        if (showFieldKey in this._displayFields) {
          const field = this._displayFields[showFieldKey];
          const _label = field.labelInLocale
            ? this.$t(field.label)
            : field.label;
          if (field.type == "list" || field.type == "evaluationStage") {
            return;
          }
          if (
            field.type == "image" ||
            field.type == "tags" ||
            field.type == "password" ||
            field.type == "link" ||
            field.type == "editor"
          ) {
            _tableHeaders.push({
              value: showFieldKey,
              text: _label,
              width: field.width,
              sortable: false,
            });
            return;
          }

          _tableHeaders.push({
            value: showFieldKey,
            text: _label,
            width: field.width,
            sortable: field.sortable,
          });
        }
      });
      return _tableHeaders;
    },
    _orderable() {
      if (this.orderable) {
        return true;
      } else if (this.orderLayerFields) {
        return true;
      } else {
        return false;
      }
    },
  },

  props: {
    getCreateFields: {},
    getUpdateFields: {},
    getUpdateReadUrl: {},
    indexFields: {},
    readable: {
      type: Boolean,
      default: true,
    },
    updateFields: {},
    createFields: {},
    checkUpdatable: {
      type: Function,
    },
    checkDeletable: {
      type: Function,
    },
    fetchOnOpenUpdate: {
      type: Boolean,
      default: true,
    },
    fetchOnOpenRead: {
      type: Boolean,
      default: true,
    },
    sequenceLength: {
      type: Number,
      default: 3,
    },
    sortOption: {
      type: Object,
    },
    title: {
      type: String,
    },
    selected: {
      type: Array,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    titleBarCustomBtns: {
      type: Array,
    },
    titleCustomDropdownMenu: {
      type: Array,
    },
    extendParams: {
      type: Object,
      default() {
        return {};
      },
    },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    customTableActions: {
      type: Array,
      default: null,
    },
    startLoadingDis: {
      type: Number,
      default: 300,
    },
    infiniteScroll: {
      type: Boolean,
      default: false,
    },
    dialogCreate: {
      type: Boolean,
      default: false,
    },
    dialogRead: {
      type: Boolean,
      default: false,
    },
    dialogUpdate: {
      type: Boolean,
      default: false,
    },
    dialogDelete: {
      type: Boolean,
      default: false,
    },
    fetchQuery: {
      type: Object,
      default: null,
    },
    creatable: {
      type: Boolean,
      default: true,
    },
    updatable: {
      type: Boolean,
      default: true,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    copyable: {
      type: Boolean,
      default: false,
    },
    order: {
      type: String,
      default: "last_created",
    },
    extendOrderItems: {
      type: Array,
      default: null,
    },
    inRowBtnComplete: {
      type: Boolean,
      default: false,
    },
    createUrl: {
      type: String,
      default: null,
    },
    dialogCreateSubmitUrl: {},
    getUpdateUrl: {
      type: Function,
      default: null,
    },
    getReadUrl: {
      type: Function,
      default: null,
    },
    createHideFields: {
      type: Array,
      default: null,
    },
    updateHideFields: {
      type: Array,
      default: null,
    },
    rowClickRead: {
      type: Boolean,
      default: false,
    },
    filterSelects: {
      type: Object,
      default: null,
    },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
    expandable: {
      type: Boolean,
      // default: true,
    },
    importRoute: {
      type: String,
      default: null,
    },
    pageMode: {
      type: Boolean,
      default: false,
    },
    titleKey: {
      type: String,
      default: "name",
    },
    showFieldsRead: {
      type: Array,
    },
    showFieldsUpdate: {
      type: Array,
    },
    showFields: {
      type: Array,
    },
    inRowBtnRead: {
      type: Boolean,
      default: false,
    },
    inRowBtnUpdate: {
      type: Boolean,
      default: true,
    },
    inRowBtnDelete: {
      type: Boolean,
      default: true,
    },
    liveSearching: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object,
      required: true,
    },
    modelName: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    importable: {
      type: Boolean,
      default: false,
    },
    parentModelName: {
      type: String,
    },
    parentId: {
      type: [String, Number],
    },
    labelInLocale: {
      type: Boolean,
      default: false,
    },
    createData: {
      type: Object,
    },
    updateData: {
      type: Object,
    },
    modelDataKey: {
      type: String,
      default: "id",
    },
    filterUniqueSection: {
      type: Boolean,
      default: false,
    },
    hasFilter: {
      type: Boolean,
      default: true,
    },
    hasFilterSelect: {
      type: Boolean,
      default: true,
    },
    urlModelName: {
      type: String,
    },
    orderable: {},
    orderLayerFields: {
      type: Array,
    },
    orderBySq: {
      type: Boolean,
    },
    hasSticky: {
      type: Boolean,
      default: false,
    },
    hasToggleFilter: {
      type: Boolean,
      default: false,
    },
    swiperNoSwiping: {
      type: Boolean,
      default: true,
    },
    exportable: {
      type: Boolean,
    },
    filterTabs: {
      type: Array,
    },
    filterTabsModelProps: {
      type: Object,
    },
    headerSticky: {
      type: Boolean,
    },
    getUrl: {
      type: String,
    },
    showDataTotalCount: {
      type: Boolean,
    },
    getReadFetchUrl: {},
    getDialogUpdateSibmitUrl: {},
    getDeleteSubmitUrl: {},
    extendPostData: {},
    hasPaginate: {
      default: true,
    },
    actionWidth: {},
    duplicatable: {},
    hasCustomFieldsFromApi: {},
    paramsOnCrud: {},
  },

  created() {
    window.addEventListener("scroll", this.$_handleScroll);
  },

  beforeDestroy() {
    this.$_release();
    window.removeEventListener("scroll", this.$_handleScroll);
  },
};
</script>