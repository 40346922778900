<template>
  <WsCrud
    label="編輯歷程"
    :fields="$store.state.stone_model.cms_log_target.fields"
    :showFields="['created_at', 'action', 'admin']"
    :liveSearching="false"
    :infiniteScroll="false"
    :hasFilter="false"
    :hasFilterSelect="false"
    :updatable="false"
    :deletable="false"
    :creatable="false"
    :hasPaginate="false"
    :getUrl="_getUrl"
  />
</template>

<script>
export default {
  computed: {
    _getUrl() {
      return `cms_log/index/target?target=${this.target}&target_id=${this.targetId}`
    }
  },
  props: {
    target: String,
    targetId: String,
  },
}
</script>