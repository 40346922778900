<template>
  <div class="ws-crud-filter">
    <WsGroupState>
      <WsState
        type="search"
        ref="searchInput"
        v-model="state.search"
        :placeholder="`${$t('search')}...`"
        :searchBtnText="`${$t('search')}...`"
        class="search"
        @search="$_onSearch($event)"
      ></WsState>
      <slot name="filters"></slot>
    </WsGroupState>
    <slot name="filterSelects"></slot>
    <WsBtn
      v-if="clearable"
      class="ml-10"
      @click="$_onClearClick()"
      outlined
    >{{$t('clear')}}</WsBtn>
    <WsState
      class="order-select ml-10 sm-ml-0 sm-mt-6"
      type="select"
      :value="state.order"
      :items="orderItems"
      @input="$_onInputOrder($event)"
    ></WsState>
  </div>
</template>

<script>
export default {
  data: () => ({
    state: {
      search: "",
      order: "",
    },
  }),
  props: {
    clearable: {
      type: Boolean,
      default: false,
    },
    value: {},
    orderItems: {
      type: Array,
    },
  },
  mounted() {
    this.$_init();
  },
  watch: {},
  methods: {
    $_init() {
      this.$_initSearch();
      this.$_initOrder();
    },
    $_initSearch() {
      if (this.value.search) {
        this.state.search = this.value.search;
      }
    },
    $_onSearch($event) {
      this.state.search = $event;
      const _value = {
        ...this.value,
        search: $event,
      };
      if (_value.search != undefined && !$event) {
        delete _value.search;
      }
      this.$emit("input", _value);
    },
    $_getOrderItemFromValue(value) {
      const _target = this.orderItems.find((e) => {
        return e.order_by == value.order_by && e.order_way == value.order_way;
      });
      return _target;
    },
    $_getOrderSettingsFromValue(value) {
      const _target = this.$_getOrderItemFromValue(value);
      if (!_target) {
        return {};
      } else {
        return {
          order_by: _target.order_by,
          order_way: _target.order_way,
        };
      }
    },
    $_getOrderSettingsFromItemValue(itemValue) {
      const _target = this.$_getOrderItemFromItemValue(itemValue);
      if (!_target) {
        return {};
      } else {
        return {
          order_by: _target.order_by,
          order_way: _target.order_way,
        };
      }
    },
    $_initOrder() {
      const _targetItem = this.$_getOrderItemFromValue(this.value);
      if (!_targetItem || (!_targetItem.order_by && !_targetItem.order_way)) {
        const _item = this.orderItems[0];
        this.$_setOrderValueFromItem(_item);
      } else {
        this.state.order = _targetItem.value;
      }
    },
    $_setOrderValueFromItem(item) {
      this.state.order = item.value;
      const _orderSettings = this.$_getOrderSettingsFromItemValue(item.value);
      let _value = {
        ...this.value,
        ..._orderSettings,
      };
      this.$emit("input", _value);
    },
    $_getOrderItemFromItemValue(value) {
      return this.orderItems.find((e) => {
        return e.value == value;
      });
    },
    $_onInputOrder($event) {
      const _item = this.$_getOrderItemFromItemValue($event);
      this.$_setOrderValueFromItem(_item);
    },
    // $_getOrderQuerys(order) {
    //   const orderQuerys = {
    //     order_way: "",
    //     order_by: "",
    //   };
    //   const orderArr = order.split("&");
    //   orderArr.forEach((orderItem) => {
    //     const orderItemArr = orderItem.split("=");
    //     if (orderItemArr[0] in orderQuerys) {
    //       orderQuerys[orderItemArr[0]] = orderItemArr[1];
    //     }
    //   });
    //   return orderQuerys;
    // },
    // getValue() {
    //   return {
    //     search: this.searching,
    //     order: this.order,
    //   };
    // },
    // $_onClearClick() {
    //   this.C_searching = "";
    //   this.$emit("search");
    // },
  },
};
</script>