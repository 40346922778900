import moment from 'moment'

export default {
  getTimeText(time) {
    const now = moment()
    const _time = moment(time)
    const _newTime = now.diff(_time, 'seconds')
    if (_newTime < 60) {
      return _newTime + '秒前'
    } else if (_newTime > 60 && _newTime < 3600) {
      return Math.round(_newTime / 60) + '分鐘前'
    } else if (_newTime > 3600 && _newTime < 86400) {
      return Math.round(_newTime / 3600) + '小時前'
    } else if (_newTime > 86400) {
      return Math.round(_newTime / 86400) + '天前'
    }
  },
  getMessageTimeText(time) {
    const _moment = moment(time)
    const aFormat = _moment.format('A')
    let aFormatText
    const hour = _moment.get('hour')
    if (aFormat == 'AM') {
      aFormatText = '上午'
    } else if (aFormat == 'PM') {
      if (hour >= 18) {
        aFormatText = '晚上'
      } else {
        aFormatText = '下午'
      }
    }
    return `${aFormatText} ${_moment.format('h:mm')}`
  },
  getDurationTextFromHour(hour) {
    if (!hour) {
      return
    }
    const numString = hour.toString();
    let durationPre = numString;
    if (numString.length === 1) {
      durationPre = `0${numString}`;
    }
    return `${durationPre}:00`
  },
  getEndDateFromStartDate(
    startDate,
    days,
    additionalCountDays = [],
    additionalSkipDays = [],
    skipWeekDays = [0, 6],
  ) {
    if (!days) {
      return startDate
    }
    const _currentDate = moment(startDate)
    const _currentDay = _currentDate.day();
    let _days = days
    while (_days > 0) {
      let _count = false
      const _currentDateString = _currentDate.format('YYYY-MM-DD')
      if (additionalCountDays.includes(_currentDateString)) {
        _count = true
      } else if (additionalSkipDays.includes(_currentDateString)) {
        _count = false
      } else if (skipWeekDays) {
        if (skipWeekDays.includes(_currentDay)) {
          _count = false
        } else {
          _count = true
        }
      }
      _currentDate.add(1, 'day');
      if (_count) {
        _days--
      }
    }
    return _currentDate.format('YYYY-MM-DD')
  },
  checkDropAble(
    start,
    additionalCountDays,
    additionalSkipDays,
    dayMinTime,
    dayMaxTime,
    // skipWeekDays,
    // skipTimesRange,
  ) {
    let _able = true
    const _start_m = moment(start)
    const _currentStartAtString = _start_m.format('HH:mm:ss')
    if (_currentStartAtString < dayMinTime) {
      _able = false
    } else if (_currentStartAtString > dayMaxTime) {
      _able = false
    }
    return _able
  },
  checkStartAble(
    start,
    additionalCountDays,
    additionalSkipDays,
    dayMinTime,
    dayMaxTime,
    skipWeekDays,
    skipTimesRange,
  ) {
    let _able = true
    const _start_m = moment(start)
    const _currentStartAtString = _start_m.format('HH:mm:ss')
    if (
      _currentStartAtString >= skipTimesRange[0] &&
      _currentStartAtString < skipTimesRange[1]
    ) {
      _able = false
    } else if (_currentStartAtString < dayMinTime) {
      _able = false
    } else if (_currentStartAtString > dayMaxTime) {
      _able = false
    }
    return _able
  },
  checkEndAble(
    end,
    additionalCountDays,
    additionalSkipDays,
    dayMinTime,
    dayMaxTime,
    skipWeekDays,
    skipTimesRange,
  ) {
    let _able = true
    const _end_m = moment(end)

    const _currentEndDateString = _end_m.format('YYYY-MM-DD')
    const _currentEndAtString = _end_m.format('HH:mm:ss')
    const _currentEndDay = _end_m.day();
    if (additionalCountDays.includes(_currentEndDateString)) {
      _able = true
    } else if (additionalSkipDays.includes(_currentEndDateString)) {
      _able = false
    } else if (skipWeekDays.includes(_currentEndDay)) {
      _able = false
    }

    if (_able) {
      if (
        _currentEndAtString > skipTimesRange[0] &&
        _currentEndAtString <= skipTimesRange[1]
      ) {
        _able = false
      } else if (_currentEndAtString > dayMaxTime) {
        _able = false
      } else if (_currentEndAtString < dayMinTime) {
        _able = false
      }
    }
    return _able
  },
  getEndAtFromStartAt(
    start_at,
    hours,
    additionalCountDays = [],
    additionalSkipDays = [],
    dayMinTime = "09:00:00",
    dayMaxTime = "18:00:00",
    skipWeekDays = [0, 6],
    skipTimesRange = ["12:00:00", "13:00:00"],
  ) {
    if (!hours) {
      return start_at
    }
    const _currentAt = moment(start_at)
    let _hours = parseInt(hours)
    let _max = 500
    while (_hours > 0 && _max > 0) {
      let _count = true
      const _currentEnd = moment(_currentAt).add(1, 'hour')
      const _startAble = this.checkStartAble(
        _currentAt,
        additionalCountDays,
        additionalSkipDays,
        dayMinTime,
        dayMaxTime,
        skipWeekDays,
        skipTimesRange,
      )
      const _endAble = this.checkEndAble(
        _currentEnd,
        additionalCountDays,
        additionalSkipDays,
        dayMinTime,
        dayMaxTime,
        skipWeekDays,
        skipTimesRange,
      )
      if (!_startAble || !_endAble) {
        _count = false
      }
      _currentAt.add(1, 'hour');
      if (_count) {
        _hours--
      }
      _max--
    }
    return _currentAt.toDate()
  },
  $_combined_XDate_YTime(x, y) {
    const XDate = moment(x)
    const YTime = moment(y)
    const combinedDateTime = 
      XDate.set({
        hour: YTime.get('hour'),
        minute: YTime.get('minute'),
        second: YTime.get('second'),
        millisecond: YTime.get('millisecond')
      })
    return combinedDateTime
  }
}