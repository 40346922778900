import moment from 'moment'

const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_service_order_stage",
  label: "訂單付款階段",
  fields: {
    stage_name: {
      type: 'text',
      label: '階段名稱',
      autofocus: true
    },
    price: {
      type: 'currency',
      label: '銷項金額',
    },
    // status: {
    //   type: 'number',
    //   label: '狀態',
    //   items: [
    //     // {
    //     //   text: '未完成',
    //     //   value: 1
    //     // },
    //     // {
    //     //   text: '完成',
    //     //   value: 2
    //     // },
    //     // {
    //     //   text: '呆帳',
    //     //   value: 99
    //     // },
    //   ]
    // },
    xc_service_order: {
      type: 'belongsTo',
      label: '訂單',
      relationPopup: true,
      textKey: 'name',
      modelName: 'xc_service_order',
    },
    expect_invoice_date: {
      type: 'date',
      label: '預計發票日期'
    },
    invoice_date: {
      type: 'date',
      label: '發票日期'
    },
    invoice_number: {
      type: 'text',
      label: '發票號碼'
    },
    invoice_photos: {
      type: 'images',
      label: '發票照片'
    },
    invoice_type: {
      type: 'select',
      label: '發票類型',
      defaultValue: 3,
      items: [
        {
          text: '三聯式',
          value: 3
        },
        {
          text: '二聯式',
          value: 2
        },
        {
          text: 'Invoice',
          value: 1
        },
      ]
    },
    expect_transfer_date: {
      type: 'date',
      label: "預計收款日期",
      defaultValue: moment().add(1, 'months').format('YYYY-MM-DD')
    },
    transfer_date: {
      type: "date",
      label: "到款日期",
    },
    remark: {
      type: "textarea",
      label: "備註"
    },
    invoice_receiver_name: {
      type: 'text',
      label: '發票收件人'
    },
    invoice_address: {
      type: 'text',
      label: '發票地址'
    },
    invoice_uniform_numbers: {
      type: 'text',
      label: '統編'
    },
    tax_type: {
      type: "select",
      label: "應稅",
      readonly: true,
      defaultValue: 1,
      items: [
        {
          value: 1,
          text: '應稅'
        },
        {
          value: 2,
          text: '未稅'
        },
      ]
    },
    tax_amount: {
      label: "稅金",
      type: 'currency',
      readonly: true,
    },
    price_after_tax: {
      type: 'currency',
      label: '稅後金額',
      readonly: true,
    },
  },
  crudSetting: {
    index: {
      exportable: true,
      showFields: [
        "stage_name",
        "price",
        "xc_service_order",
        "price_after_tax",
        "invoice_date",
        "invoice_number",
        "transfer_date",
        "expect_transfer_date",
      ],
      filterTabs: [
        {
          text: "全部",
          value: 1,
          params: {
          },
        },
        {
          text: "未開發票",
          value: 2,
          params: {
            invoice_number: 'null',
          },
        },
        {
          text: "未到款",
          value: 3,
          params: {
            transfer_date: 'null',
          },
        },
        {
          text: "已開發票未到款",
          value: 4,
          params: {
            invoice_number: 'not_null',
            transfer_date: 'null',
          },
        },
      ],
      filterSelects: {
        expect_invoice_date: {
          type: "date-range",
          placeholder: "預期開票時間",
          col: 3,
        },
        expect_transfer_date: {
          type: "date-range",
          placeholder: "預期到款時間",
          col: 3,
        },
        transfer_date: {
          type: "date-range",
          placeholder: "到款時間",
          col: 3,
        },
        invoice_date: {
          type: "date-range",
          placeholder: "開票時間",
          col: 3,
        },
      },
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "stage_name",
                "price",
                // "status",
                "expect_invoice_date",
                "invoice_date",
                "invoice_number",
                "invoice_photos",
                "invoice_type",
                "expect_transfer_date",
                "transfer_date",
                "remark",
                "invoice_receiver_name",
                "invoice_address",
                "invoice_uniform_numbers",
              ],
            },
          ],
        },
      ],
    },
    update: {
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "stage_name",
                "price",
                // "status",
                "expect_invoice_date",
                "invoice_date",
                "invoice_number",
                "invoice_photos",
                "invoice_type",
                "expect_transfer_date",
                "transfer_date",
                "remark",
                "invoice_receiver_name",
                "invoice_address",
                "invoice_uniform_numbers",
              ],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: [
        "xc_service_order",
        "stage_name",
        "price",
        "tax_type",
        "tax_amount",
        "price",
        "price_after_tax",
        // "status",
        "expect_invoice_date",
        "invoice_date",
        "invoice_number",
        "invoice_photos",
        "invoice_type",
        "expect_transfer_date",
        "transfer_date",
        "remark",
        "invoice_receiver_name",
        "invoice_address",
        "invoice_uniform_numbers",
      ],
      rightFields: [
      ],
      titleKey: 'stage_name',
    }
  }
}

export default model