<template>
  <div>
    <WsModelUpdateDialog
      ref="WsModelUpdateDialog"
      v-bind="$props"
      @submit="$_onSubmit($event)"
      :errorMessages="errorMessages"
    ></WsModelUpdateDialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errorMessages: null,
    };
  },
  methods: {
    async $_onSubmit($event) {
      this.$store.dispatch("app/startPageLoading");
      try {
        let postData = {
          ...$event,
          ...this.paramsOnCrud,
        };
        let _postUrl;
        _postUrl = `/${this.modelName}`;
        const res = await this.$axios.post(_postUrl, postData);
        this.$emit("complete", res.data.data);
        setTimeout(() => {
          this.close();
        }, 0);
      } catch (error) {
        this.errorMessages = this.$o_o.$h.http.getErrorMessageFromRes(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    close() {
      this.$refs.WsModelUpdateDialog.close();
    },
    open() {
      this.$refs.WsModelUpdateDialog.open();
    },
  },
  props: {
    label: {},
    modelName: {},
    fields: {},
    paramsOnCrud: {},
  },
};
</script>