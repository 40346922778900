export default {
  'validate-min': 'Length should more than{length}',
  'validate-max': 'Length should less than {length}',
  'validate-required': 'Required.',
  'validate-email': 'Format not correct.',
  'find no email.': 'Find no email.',
  'password not correct.': 'Password not correct',
  'internet error.': 'Internet error.',
  'The value has been taken.': 'Value has been taken.',
  'index error.': 'Index error.',
  'index locale error.': 'Index locale error.',
}