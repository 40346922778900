<template>
  <div class="xc-task-card-list">
    <WsInfiniteScroll
      ref="WsInfiniteScroll"
      v-slot="{ items }"
      :fetchUrl="fetchUrl"
      :params="params"
    >
      <div class="xc-task-card-list__container">
        <XcTaskCard
          v-for="(task, taskIndex) in items"
          :key="taskIndex"
          :task="task"
          :updatable="updatable"
          :draggable="draggable"
          @update="$_onUpdateTask(task, taskIndex)"
          class="cursor-pointer"
          :data-task-id="task.id"
          @click.native="$_onClickTask(task, taskIndex)"
        >
        </XcTaskCard>
      </div>
    </WsInfiniteScroll>
    <WsModelUpdateDialog
      class="short-panel"
      ref="updateDialog"
      :fields="_fields"
      :updateFields="_updateFields"
      modelName="xc_task"
      @submit="$_onUpdateSubmit($event)"
    />
    <WsDialogCRUDRead
      class="short-panel"
      ref="WsDialogCRUDRead"
      :fields="$store.state.stone_model.xc_task.fields"
      :showFields="dialogShowFields"
      :pageReadRedirect="true"
      modelName="xc_task"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogShowFields: [
        "name",
        "due_date",
        "start_at",
        "reviewed_at",
        "status",
        "hour",
        "finish_hour",
        "time_review_at",
        "xc_project",
        "creator",
        "taker",
        "content",
      ],
    };
  },
  methods: {
    $_onClickTask($event) {
      // this.$emit("clicktask", task);
      this.$refs.WsDialogCRUDRead.open($event.id);
    },
    async $_onUpdateSubmit($event) {
      this.$store.dispatch("app/startPageLoading");
      try {
        const _postUrl = `/xc_task/${$event.id}`;
        const res = await this.$axios.patch(_postUrl, $event);
        this.$refs.WsInfiniteScroll.updateItem(res.data.data);
        setTimeout(() => {
          this.$refs.updateDialog.close();
        }, 0);
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onUpdateTask(task) {
      this.$refs.updateDialog.open(task);
    },
    removeTask(id) {
      this.$refs.WsInfiniteScroll.removeItem(id);
    },
    addTask(task) {
      this.$refs.WsInfiniteScroll.addItem(task);
    },
    getTask(id) {
      return this.$refs.WsInfiniteScroll.getItem(id);
    },
  },
  computed: {
    _updateFields() {
      // if (!this.xc_project) {
      //   return {};
      // }
      const _updateFields = {
        name: this.$store.state.stone_model.xc_task.fields.name,
        taker: {
          ...this.$store.state.stone_model.xc_task.fields.taker,
          // fetchUrl: `/xc_project/${this.xc_project.id}/member`,
          is_active: true,
        },
        content: this.$store.state.stone_model.xc_task.fields.content,
        tags: this.$store.state.stone_model.xc_task.fields.tags,
      };
      return _updateFields;
    },
    _fields() {
      const _fields = { ...this.$store.state.stone_model.xc_task.fields };
      // _fields.taker.fetchUrl = `/xc_project/${this.xc_project.id}/member`;
      // delete _fields.xc_project;
      return _fields;
    },
  },
  props: {
    // tasks: {},
    draggable: {},
    params: {},
    fields: {},
    xc_project: {},
    updatable: {},
    fetchUrl: {
      type: String,
      default: "/xc_task",
    },
  },
};
</script>

<style lang="scss">
.xc-task-card-list {
  .xc-task-card-list__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
</style>