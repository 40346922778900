<template>
  <WsPopup
    :active="_active"
    @close="$_onClose()"
    ref="WsPopup"
  >
    <template #content>
      <WsImg v-if="_imageUrl" :url="_imageUrl"  style="height: 80vh;object-fit: contain;"/>
    </template>
    <template #rightActions>
      <WsBtn outlined @click="$_onClose()">關閉</WsBtn>
    </template>
  </WsPopup>
</template>

<script>
export default {
  computed: {
    _active() {
      return this.$store.state.app.imagePreviewPopup.active
    },
    _imageUrl() {
      return this.$store.state.app.imagePreviewPopup.imageUrl
    }
  },
  methods: {
    $_onClose() {
      this.$store.dispatch('app/clearImagePreviewPopup')
    },
  },
  watch: {
    _active() {
      if (this._active) {
        this.$refs.WsPopup.open()
      } else {
        this.$refs.WsPopup.close()
      }
    }
  }
}
</script>
