<template>
  <WsUpdate
    label="CMS 系統設定"
    :fields="_fields"
    :primary="_primary"
    getUrl="/system_setting/cms"
    :completeRedirect="false"
    :postEncode="false"
  >
  </WsUpdate>
</template>

<script>
export default {
  computed: {
    _fields() {
      return {
        ...this.fields,
        ...(this.$o_o.$h.module.extendModuleHelperAction(
          "system_setting",
          "system_setting.js",
          "getSystemSettingCmsFields",
          {}
        ) || {}),
      };
    },
    _primary() {
      return [
        {
          type: "stateCard",
          floors: [
            {
              fields: [
                ...this.primary[0].floors[0].fields,
                ...(this.$o_o.$h.module.extendModuleHelperAction(
                  "system_setting",
                  "system_setting.js",
                  "getSystemSettingCmsShowFields",
                  {}
                ) || []),
              ],
            },
          ],
        },
      ];
    },
  },
  data() {
    return {
      fields: {
        slack_app_token: {
          type: "text",
          label: "Slack App Token (啟用 Slack 相關功能)",
        },
      },
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["slack_app_token"],
            },
          ],
        },
      ],
    };
  },
};
</script>

<style>
</style>