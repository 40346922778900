export default {
  // xc_project: {
  //   icon: 'icon-md-videogame-asset',
  //   title: '專案',
  //   link: '/xc_project',
  //   scopes: ['xc_project-admin'],
  // },
  my_xc_project: {
    icon: 'icon-md-videogame-asset',
    title: '我的 專案',
    link: '/xc_project',
  },
}