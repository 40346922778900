<template>
  <div
    class="ws-flex"
    :class="[
      {'is-wrap':isWrap},
      {'is-inline':isInline},
    ]"
    :style="[
      {'flex-direction':flexDirection},
      {'align-items':alignItems},
      {'justify-content':justifyContent},
      {'gap':gap}
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    flexDirection: {
      type: String,
    },
    isInline: {
      type: Boolean,
    },
    isWrap: {
      type: Boolean,
      default: false,
    },
    alignItems: {
      type: String,
    },
    justifyContent: {
      type: String,
    },
    gap: {
      type: String,
    },
  },
};
</script>