<template>
  <div>
    <WsStateSwitch
      v-if="switchInInfo"
      v-bind="$props"
      @input="$_onInput($event)"
      :value="C_value"
    ></WsStateSwitch>
    <WsText
      v-else
      size="14"
    >
      {{_switchText}}
    </WsText>
  </div>
</template>

<script>
export default {
  data() {
    return {
      C_value: null,
    };
  },
  computed: {
    _switchText() {
      if (this.value && this.value != "0") {
        return this.activeText;
      } else {
        return this.inactiveText;
      }
    },
    _patchUrl() {
      if (this.patchUrl) {
        return this.patchUrl;
      } else if (this.modelData) {
        return `/${this.modelName}/${this.modelData.id}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    $_onInput($event) {
      this.C_value = $event;
      this.$axios.patch(this._patchUrl, {
        is_active: $event,
      });
    },
    $_setValue() {
      this.C_value = this.value;
    },
  },
  watch: {
    value: {
      handler() {
        this.$_setValue();
      },
    },
  },
  mounted() {
    this.$_setValue();
  },
  props: {
    modelName: {
      type: String,
    },
    activeText: {
      type: String,
      default: "Y",
    },
    inactiveText: {
      type: String,
      default: "N",
    },
    value: {
      type: [Boolean, Number, String],
      default: false,
    },
    switchInInfo: {
      type: Boolean,
    },
    modelData: {},
    patchUrl: {},
  },
};
</script>

<style>
</style>