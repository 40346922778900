<template>
  <div>
    <WsTabs
      v-model="state.tabs"
      :items="_itemsTab"
    ></WsTabs>
    <WsAudioPickerFiles
      v-if="state.tabs=='files'"
      @submit="$_onPickerSubmit($event,'files')"
    ></WsAudioPickerFiles>
    <WsAudioPickerUpload
      v-else-if="state.tabs=='upload'"
      ref="WsAudioPickerUpload"
      @submit="$_onPickerSubmit($event,'upload')"
    ></WsAudioPickerUpload>

  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {
        tabs: "upload",
      },
      items: {
        tabs: [],
      },
    };
  },
  methods: {
    $_onPickerSubmit($event) {
      this.$emit("submit", $event);
    },
    // async submit() {
    //   if (this.state.tabs == "upload") {
    //     await this.$refs.WsAudioPickerUpload.submit();
    //   }
    // },
  },
  computed: {
    _itemsTab() {
      const _items = [];
      if (this.filesable) {
        _items.push({
          text: "從檔案庫選擇",
          value: "files",
        });
      }
      if (this.uploadable) {
        _items.push({
          text: "上傳音樂",
          value: "upload",
        });
      }
      return _items;
    },
  },
  props: {
    filesable: {
      type: Boolean,
      default: true,
    },
    uploadable: {
      type: Boolean,
      default: true,
    },
    linkable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>
</style>