<template>
  <WsMain>
    <CalendarScheduling></CalendarScheduling>
  </WsMain>
</template>

<script>
export default {
  computed: {
    _params() {
      return {};
    },
  },
};
</script>

<style></style>