<template>
  <div
    class="ws-sheet"
    :style="[
      {'background-color':backgroundColor}
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    backgroundColor: {
      type: String,
    },
  },
};
</script>

<style>
</style>