<template>
  <div>
    <WsState
      type="search"
      v-model="state.search"
      class="mb-16"
    ></WsState>
    <WsRow>
      <WsCol
        v-for="(item, itemIndex) in _items"
        :key="itemIndex"
        cols="3"
      >
        <WsCard
          style="min-height:100px"
          :to="item.to"
          :href="item.href"
        >
          <WsText>{{item.label}}</WsText>
          <WsDes
            class="mt-8"
            v-if="item.des"
          >{{item.des}}</WsDes>
        </WsCard>
      </WsCol>
    </WsRow>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {
        search: "",
      },
    };
  },
  computed: {
    _items() {
      if (!this.state.search) {
        return this.items;
      } else {
        const _items = [...this.items];
        const _filterdItems = _items.filter((e) => {
          let check = 0;
          if (
            e.label.includes(this.state.search) ||
            e.to.includes(this.state.search) ||
            e.des.includes(this.state.search)
          ) {
            check = 1;
          }
          if (e.tags) {
            e.tags.forEach((tag) => {
              if (tag.includes(this.state.search)) {
                check = 1;
              }
            });
          }
          return check;
        });
        return _filterdItems;
      }
    },
  },
  props: {
    items: {},
    linkNeedTranslate: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>
</style>