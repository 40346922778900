<template>
  <WsCard class="my-off-day-board">
    <WsText size="16" class="mb-8">我的請假單</WsText>
    <WsModelIndexTabs
      :items="filterTabs"
      :value="filter"
      class="mb-8"
    />
    <WsModelFetchIndex
      modelName="admin_event"
      :params="_params"
      v-slot="{modeldatas}"
    >
      <WsModelIndexTable
        :modeldatas="modeldatas"
        v-bind="$props"
        :fields="_fields"
        :showFields="showFields"
        :readable="true"
        :inRowBtnRead="true"
        :showIndexSelects="false"
      />
    </WsModelFetchIndex>
  </WsCard>
</template>

<script>
export default {
  data() {
    return {
      filter: {},
      filterTabs: [
      {
          text: '簽核中',
          value: 1,
          params: {},
        },
        {
          text: '已核准',
          value: 2,
          params: {},
        },
        {
          text: '不同意',
          value: 3,
          params: {},
        },
        {
          text: '撤回',
          value: 4,
          params: {},
        },
      ],
      showFields: [
        'name',
        'start_date',
        'status',
      ]
    }
  },
  computed: {
    _params() {
      return {
        type: 'off_day_break,off_day_bereave,off_day_something,off_day_ill',
      }
    },
    _fields() {
      return this.$store.state.stone_model.admin_event.fields
    }
  },
}
</script>

<style lang="scss">
.my-off-day-board {
  .ws-model-index__tabs {
    background-color: transparent !important;
    .ws-model-index__tabs__container {
      padding: 0 !important;
    }
  }
}
</style>