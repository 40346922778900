<template>
  <WsVideo
    :src="value"
    :hasControls="hasControls"
  ></WsVideo>
</template>

<script>
export default {
  props: {
    value: {},
    hasControls: {},
  },
};
</script>

<style>
</style>