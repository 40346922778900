<template>
  <div>
    
  </div>
</template>
<script>
export default {
  props: {
    fields: {
      type: Object,
    },
    value: {
      type: Array,
    },
  },
};
</script>