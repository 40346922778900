import config from '@/__config'
import i18n from '@/i18n.js';


export default {
  options: {
    id: true,
    timestamp: true,
    site_region: true,
  },
  modelName: config.wsmodule.admin.admin_blur ? 'cmser_role' : "admin_role",
  label: i18n.t("後台使用者角色"),
  fields: {
    name: {
      type: "text",
      label: i18n.t("名稱"),
      autofocus: true,
      rules: "required",
      maxlength: 30
    },
    is_default: {
      type: 'switch',
      label: i18n.t('預設角色'),
      activeText: '是',
      inactiveText: '否',
      readonly: true
    },
    scopes: {
      type: 'scope',
      label: i18n.t('Scopes'),
      searchable: true,
      selectAll: true,
    },
    updated_admin: {
      type: "belongsTo",
      label: i18n.t("最後更新人"),
      textKey: "name",
      readonly: true,
    },
  },
}
