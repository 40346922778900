import H_Router from '@/__stone/helpers/router'

export default [
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'hr_recruit',
    middleware: 'auth',
    meta: {
      requiredScopes: ['hr_recruit-admin'],
    },
  }),
]