<template>
  <WsInfoAudio
    v-if="value"
    :value="value.public_url"
  ></WsInfoAudio>
</template>

<script>
export default {
  props: {
    value: {},
  },
};
</script>

<style>
</style>