<template>
  <tr>
    <th
      v-if="selectable"
      class="check no-select"
    >
      <WsState
        type="checkbox"
        :value="checkValue"
        @input="$emit('check')"
      ></WsState>
    </th>
    <WsModelIndexTableHeaderItem
      v-for="(headersItem, headersIndex) in headers"
      :key="headersIndex"
      v-bind="headersItem"
    />
    <th
      v-if="inRowBtnRead || inRowBtnUpdate || inRowBtnDelete || inRowBtnComplete || (customTableActions && customTableActions.length)"
      class="actions d-xs-none"
    >
    </th>
    <th class="actions-xs d-none d-xs-table-cell no-select"></th>
  </tr>
</template>

<script>
export default {
  computed: {},
  props: {
    selectable: {},
    checkValue: {},
    showFields: {},
    fields: {},
    headers: {},
    inRowBtnRead: {},
    inRowBtnUpdate: {},
    inRowBtnDelete: {},
    inRowBtnComplete: {},
    customTableActions: {},
  },
};
</script>

<style lang="scss">
</style>