import auth from './auth'
import helper from './helper'
import http from './http'
import locale from './locale'
import menu from './menu'
import model from './model'
import module from './module'
import router from './router'
import scope from './scope'
import shop from './shop'
import state from './state'
import file from './file'
import time from './time'
import str from './str'

export default {
  auth,
  helper,
  http,
  locale,
  menu,
  model,
  module,
  router,
  scope,
  shop,
  state,
  file,
  time,
  str,
  _m: helper.getModuleHelpers()
}