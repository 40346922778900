<template>
  <div>
    <WsRow>
      <WsCol class="col-2">
      </WsCol>
      <WsCol class="col-5">
        <WsFlex alignItems="flex-end">
          <WsText>{{$t('開始')}}</WsText>
          <WsDes
            class="pb-2 pl-5"
            size="12"
          > {{$t('格式')}}(0000)</WsDes>
        </WsFlex>
      </WsCol>
      <WsCol class="col-5">
        <WsFlex alignItems="flex-end">
          <WsText>{{$t('結束')}}</WsText>
          <WsDes
            class="pb-2 pl-5"
            size="12"
          > {{$t('格式')}}(0000)</WsDes>
        </WsFlex>
      </WsCol>
    </WsRow>
    <WsRow
      v-for="n in 7"
      :key="n"
    >
      <WsCol class="col-2">
        {{$moment().locale($i18n.locale).startOf('week').add(n,'day').format('ddd')}}
      </WsCol>
      <WsCol class="col-5">
        <WsState
          type="text"
          maxlength="4"
          placeholder="0900"
          @input="$_onTimeInput($event,n*2-2)"
          :value="$_getValue(n*2-2)"
        ></WsState>
      </WsCol>
      <WsCol class="col-5">
        <WsState
          type="text"
          maxlength="4"
          placeholder="1800"
          @input="$_onTimeInput($event,n*2-1)"
          :value="$_getValue(n*2-1)"
        ></WsState>
      </WsCol>
    </WsRow>
  </div>
</template>

<script>
export default {
  methods: {
    $_onTimeInput($event, index) {
      let _value;
      if (!this.value || this.value.length != 14) {
        _value = [];
        for (let i = 0; i < 14; i++) {
          _value.push("");
        }
      } else {
        _value = [...this.value];
      }
      _value[index] = $event;
      this.$emit("input", _value);
    },
    $_getValue(index) {
      if (!this.value || !this.value[index]) {
        return null;
      } else {
        return this.value[index];
      }
    },
  },
  props: {
    value: {
      type: Array,
    },
  },
};
</script>

<style>
</style>