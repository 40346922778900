import config from '@/__config'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import $h_router from '@/__stone/helpers/router'
import i18n from '@/i18n';

export default {

  removeToken() {
    localStorage.removeItem("access_token");
    axios.defaults.headers.common[
      "Authorization"
    ] = null
  },

  getToken() {
    const token = localStorage.getItem('access_token');
    return token ? token : null
  },

  setToken(token) {
    localStorage.setItem("access_token", token);
    axios.defaults.headers.common[
      "Authorization"
    ] = token;
  },

  getUser() {
    return new Promise((resolve, reject) => {
      const endpoints = $h_router.getApiUrl()
      const token = this.getToken()
      const route = config.auth.user && config.auth.user.url ? config.auth.user.url : '/auth/user'
      axios.get(`${endpoints}${route}`, {
        headers: { Authorization: token },
      })
        .then((res) => {
          store.dispatch('auth/setCurrentUser', res.data);
          if (
            config.cms.i18n &&
            res.data.user &&
            res.data.user.site_region
          ) {
            i18n.locale = res.data.user.site_region.code
          }
          resolve(res.data.user)
        })
        .catch((err) => {
          reject(err)
        });
    })
  },

  login({ email, password }) {
    return new Promise((resolve, reject) => {
      const endpoints = $h_router.getApiUrl()
      const route = '/auth/signin'
      axios.post(`${endpoints}${route}`, {
        email: email,
        password: password,
      })
        .then(loginRes => {
          this.setToken(`Bearer ${loginRes.data.access_token}`)
          this.getUser().then(userRes => {
            setTimeout(() => {
              router.push('/')
            }, 0);
            resolve(userRes)
          })
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  oauth_login(access_token) {
    return new Promise((resolve, reject) => {
      this.setToken(`Bearer ${access_token}`)
      this.getUser().then(userRes => {
        setTimeout(() => {
          router.push('/')
        }, 0)
        resolve(userRes)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  logout() {
    return new Promise((resolve, reject) => {
      const endpoints = $h_router.getApiUrl()
      const route = '/auth/signout'
      axios.post(`${endpoints}${route}`)
        .then(res => {
          this.removeToken()
          store.dispatch('auth/removeCurrentUser');
          setTimeout(() => {
            router.push('/login')
          }, 0);
          resolve(res)
        })
        .catch(err => {
          setTimeout(() => {
            router.push('/login')
          }, 0);
          reject(err)
        })
    })
  }
}