<template>
  <div class="ws-tabs">
    <WsText
      v-for="(item, itemIndex) in items"
      :key="itemIndex"
      class="ws-tab"
      :class="{active:item.value==value}"
      @click.native="$_onItemClick(item)"
    >{{ item.text }}</WsText>
  </div>
</template>

<script>
export default {
  mounted() {
    if (this.autoInit) {
      this.$_init()
    }
  },
  methods: {
    $_onItemClick(item) {
      if (item.value === this.value) return;
      this.$emit("input", item.value);
      if (this.scrollToAnchor)
        this.$_scrollToAnchor(item.value)
    },
    $_init() {
      if (this.items?.length > 0) {
        this.$emit('input', this.items[0].value)
      }
    },
    $_scrollToAnchor(value) {
      let el = document.getElementById(value)
      if (el) {
        window.scrollTo({
          top: el.offsetTop - 60,
          behavior: 'smooth',
        })
      }
    },
  },
  props: {
    value: {
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    scrollToAnchor: {
      type: Boolean,
      default: false
    },
    autoInit: {
      type: Boolean,
      default: false
    },
  },
};
</script>

<style>
</style>