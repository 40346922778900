<template>
  <div>
    <video
      v-for="(video,index) in value"
      :key="index"
      width="320"
      height="320"
      controls
    >
      <source
        :src="value"
        type="video/mp4"
      >
    </video>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
  },
};
</script>

<style>
</style>