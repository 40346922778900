const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_financial_item",
  label: "自訂帳款項目",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: 'text',
      label: '名稱',
      autofocus: true,
      rules: 'required',
    },
     updated_at: {
      type: "date",
      label: "更新時間",
      readonly: true,
      labelInLocale: true,
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      readonly: true,
      labelInLocale: true,
    },
    debit_xc_financial_first_grade: {
      type: "belongsTo",
      label: "借方一級科目",
      textKey: "name",
      modelName: 'xc_financial_first_grade',
      extendParams: {
        type: 1
      },
      col: 3,
      rules: 'required',
    },
    debit_xc_financial_second_grade: {
      type: "belongsTo",
      label: "借方二級科目",
      textKey: "name",
      modelName: 'xc_financial_second_grade',
      col: 3,
      rules: 'required',
    },
    credit_xc_financial_first_grade: {
      type: "belongsTo",
      label: "貸方一級科目",
      textKey: "name",
      modelName: 'xc_financial_first_grade',
      extendParams: {
        type: 2
      },
      col: 3,
      rules: 'required',
    },
    credit_xc_financial_second_grade: {
      type: "belongsTo",
      label: "貸方二級科目",
      textKey: "name",
      modelName: 'xc_financial_second_grade',
      col: 3,
      rules: 'required',
    },
    remark: {
      type: 'textarea',
      label: '備註',
    },
  },
  crudSetting: {
    index: {
      showFields: [
        'name',
        'debit_xc_financial_first_grade',
        'debit_xc_financial_second_grade',
        'credit_xc_financial_first_grade',
        'credit_xc_financial_second_grade',
      ],
      pageMode: true,
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              getFields: (formValue) => {
                return model.getFieldsFromFormValue(formValue);
              },
            },
          ],
        },
      ],
    },
    update: {
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
                'debit_xc_financial_first_grade',
                'debit_xc_financial_second_grade',
                'credit_xc_financial_first_grade',
                'credit_xc_financial_second_grade',
                'remark',
              ],
            },
          ],
        },
      ],
    },
    read: {
      titleKey: 'id',
      leftFields: [
        'id',
        'created_at',
        'updated_at',
        'date',
        'name',
        'debit_xc_financial_first_grade',
        'debit_xc_financial_second_grade',
        'credit_xc_financial_first_grade',
        'credit_xc_financial_second_grade',
        'remark',
      ],
    },
  },
  getFieldsFromFormValue(formValue) {
    let _fields = [
      'name',
      'debit_xc_financial_first_grade',
      'debit_xc_financial_second_grade',
      'credit_xc_financial_first_grade',
      'credit_xc_financial_second_grade',
      'remark',
    ];
    if (!formValue.debit_xc_financial_first_grade) {
      model.fields.debit_xc_financial_first_grade.col = 12;
      const index = _fields.indexOf('debit_xc_financial_second_grade');
      if (index > -1) {
        _fields.splice(index, 1);
      }
    } else {
      model.fields.debit_xc_financial_first_grade.col = 3;
      model.fields.debit_xc_financial_second_grade.col = 9;
      model.fields.debit_xc_financial_second_grade.extendParams = {
        xc_financial_first_grade: formValue.debit_xc_financial_first_grade.id,
      };
    }
    if (!formValue.credit_xc_financial_first_grade) {
      model.fields.credit_xc_financial_first_grade.col = 12;
      const index = _fields.indexOf('credit_xc_financial_second_grade');
      if (index > -1) {
        _fields.splice(index, 1);
      }
    } else {
      model.fields.credit_xc_financial_first_grade.col = 3;
      model.fields.credit_xc_financial_second_grade.col = 9;
      model.fields.credit_xc_financial_second_grade.extendParams = {
        xc_financial_first_grade: formValue.credit_xc_financial_first_grade.id,
      };
    }
    return _fields;
  },
};

export default model;
