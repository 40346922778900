import H_color from '@/__stone/helpers/color'
import H_time from '@/__stone/helpers/time'
import moment from 'moment'
const cmsColors = H_color.getCmsColors()

export default {
  toCalendarEvent(system_event, opa) {
    const _event = {}
    if (!system_event) {
      return
    }
    let preTitle = ''
    let skipWeekDays = []
    if (system_event.type == "additional_off_day") {
      preTitle = "😆😆 "
      skipWeekDays = [0, 6]
    } else if (system_event.type == 'additional_on_day') {
      preTitle = "🥲🥲 "
      skipWeekDays = [1, 2, 3, 4, 5]
    }
    _event.title = preTitle + system_event.name
    _event.color = H_color.hexToRgb(cmsColors.primary, opa)
    _event.extendedProps = {
      ...system_event,
      modelName: 'system_event'
    }
    _event.start = system_event.start_date
    _event.end = H_time.getEndDateFromStartDate(
      system_event.start_date,
      system_event.days,
      [],
      [],
      skipWeekDays
    )
    _event.allDay = true
    _event.editable = false
    return _event
  },
  getDateArrFromSystemEvents(system_events, type) {
    const _dateArr = []
    system_events.forEach((system_event) => {
      if (type && system_event.type != type) {
        return;
      }
      if (system_event.days) {
        for (let i = 0; i < system_event.days; i++) {
          _dateArr.push(moment(system_event.start_date).add(i, 'day').format('YYYY-MM-DD'))
        }
      } else {
        _dateArr.push(system_event.start_date)
      }
    })
    return _dateArr
  },
}