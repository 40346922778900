import H_color from '@/__stone/helpers/color'
import H_time from '@/__stone/helpers/time'
const cmsColors = H_color.getCmsColors()

export default {
  toCalendarEvent(
    admin_event,
    additionalOnDays,
    additionalOffDays,
  ) {
    const _event = {}
    if (!admin_event) {
      return
    }
    let skipWeekDays = [0, 6]
    if (
      admin_event.type == 'off_day_break' ||
      admin_event.type == 'off_day_bereave' ||
      admin_event.type == 'off_day_something' ||
      admin_event.type == 'off_day_ill'
    ) {
      _event.title = "[ 請假 ] " + admin_event.admin.name
    }
    _event.color = H_color.hexToRgb(admin_event.admin.color ? admin_event.admin.color : cmsColors.gray, .3)
    _event.extendedProps = {
      ...admin_event,
      modelName: 'admin_event'
    }
    _event.start = admin_event.start_date
    _event.end = H_time.getEndDateFromStartDate(
      admin_event.start_date,
      admin_event.days,
      additionalOnDays,
      additionalOffDays,
      skipWeekDays
    )
    _event.allDay = true
    _event.editable = false
    return _event
  }
}