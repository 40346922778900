<template>
  <WsCard
    class="xc-task-card"
    :color="_color"
    :class="[
      { 'draggable': draggable }
    ]"
  >
    <div class="xc-task-card__bg"></div>
    <WsFlex>
      <WsText
        class="flex-1"
        size="12"
      ><span v-if="task.hour">[ {{ task.hour }}h ]
        </span>{{ task.taker ? task.taker.name : '未指派人員' }}</WsText>
      <WsIcon
        v-if="updatable"
        name="icon-md-edit"
        @click.stop="$_onClick()"
      ></WsIcon>
    </WsFlex>
    <WsFlex>
    </WsFlex>
    <WsFlex
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <WsText
        size="14"
        class="mt-8"
      >{{ task.name }}</WsText>
      <XcTaskStatusLabel :value="task.status"></XcTaskStatusLabel>
    </WsFlex>
  </WsCard>
</template>

<script>
export default {
  methods: {
    $_onClick() {
      this.$emit("update");
    },
  },
  computed: {
    _color() {
      return this.task.taker ? this.task.taker.color : this.$color.gray;
    },
  },
  props: {
    task: {},
    draggable: {},
    updatable: {},
  },
};
</script>

<style lang="scss">
.xc-task-card {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;

  &.draggable {
    cursor: move;
  }

  .xc-task-card__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>