var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ws-btn",class:[
    {'is-focus':_vm.isFocus},
    {outlined:_vm.outlined},
    {small:_vm.small},
    {alert:_vm.alert},
    {full:_vm.full},
    {disabled:_vm.disabled},
    {verybig:_vm.verybig},
    {'is-active':_vm.isActive},
    `style-${_vm.styleType}`,
  ],style:([
    {'min-width':`${_vm.minWidth}px`},
    {'max-width':`${_vm._maxWidth}`},
    {'min-height':_vm.minHeight},
    {'background-color':_vm._color},
    {'has-hover-effect':_vm.hasHoverEffect},
    {'border-width':_vm._borderWidth},
    {'border-color':_vm._borderColor}
  ])},[(_vm.loading)?_c('WsLoading'):_c(_vm._component,{tag:"component",staticClass:"ws-btn__button",class:[{small:_vm.small}],style:([{color:_vm.textColor}]),attrs:{"type":_vm._type,"disabled":_vm.disabled,"to":_vm.to,"target":_vm.target},on:{"click":function($event){return _vm.onClick()},"mouseover":function($event){return _vm.onMouseover()},"focus":_vm.$_onFocus,"blur":_vm.$_onBlur}},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }