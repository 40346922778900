<template>
  <WsPopup
    :title="_title"
    max-width="720"
    ref="alertPopup"
  >
    <template v-slot:content>
      <slot name="content">
        {{_description}}
      </slot>
    </template>
    <template v-slot:rightActions>
      <WsBtn
        @click="$_onCancel()"
        :minWidth="100"
        color="textSecondary"
        outlined
      >{{$t('cancel')}}</WsBtn>
      <WsBtn
        @click="$_onSubmit()"
        :minWidth="100"
        alert
        :loading="loading"
      >{{submitBtnText}}</WsBtn>
    </template>
  </WsPopup>
</template>

<script>
export default {
  computed: {
    _title() {
      return this.C_title ? this.C_title : this.title;
    },
    _description() {
      return this.C_description ? this.C_description : this.description;
    },
  },
  data: () => ({
    loading: false,
    target: null,
    C_title: "",
    C_description: "",
  }),

  methods: {
    $_clear() {
      this.target = null;
    },
    close() {
      this.$refs.alertPopup.close();
      this.loadingStop();
      this.$_clear();
    },
    open($event, title, description) {
      this.target = $event;
      if (title) {
        this.C_title = title;
      }
      if (description) {
        this.C_description = description;
      }
      this.$refs.alertPopup.open();
    },
    $_onCancel() {
      this.close();
    },
    async $_onSubmit() {
      this.$emit("submit", this.target);
    },
    loadingStart() {
      this.loading = true;
    },
    loadingStop() {
      this.loading = false;
    },
  },

  props: {
    title: {
      type: String,
      default: "警告",
    },
    description: {
      type: String,
      default: "是否確定刪除？",
    },
    submitBtnText: {
      type: String,
      default() {
        return this.$t("confirm");
      },
    },
  },
};
</script>

<style>
</style>