<template>
  <div
    class="ws-row"
    :class="[
      {'no-gutters':noGutters}
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    noGutters: {
      type: Boolean,
    },
  },
};
</script>

<style>
</style>