import axios from 'axios'
import config from '@/__config'
import S_Image from './image'

export default {
  async create(data) {
    return new Promise((resolve, reject) => {
      const _data = {}
      _data.name = data.name
      _data.signed = data.signed
      let _url = data.url
      if (config.general.postEncode) {
        _url = btoa(_url)
      }
      if (_data.signed) {
        _data.signed_url = _url
      } else {
        _data.url = _url
      }
      axios
        .post('pocket_image', _data)
        .then(res => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async upload({
    file,
    fileName,
    signed = false,
    getUploadUrl,
  }) {
    return new Promise((resolve, reject) => {

      S_Image.upload({
        file,
        fileName,
        getUploadUrl,
      }).then(_url => {

        const createData = {
          name: fileName,
          signed: signed,
          url: _url,
        }

        this
          .create(createData)
          .then(res => {
            if (!this.signed && config.storage.service == 'gcs') {
              axios
                .patch(`pocket_image/public_url/${res.id}`)
                .then(() => {
                  resolve(res)
                })
            } else {
              resolve(res)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })


      // axios
      //   .get(getUploadUrl, {
      //     params: {
      //       name: fileName,
      //     }
      //   })
      //   .then(res => {
      //     const uploadUrl = res.data
      //     axios
      //       .put(uploadUrl, file, {
      //         transformRequest: [(data, headers) => {
      //           delete headers.common.Authorization;
      //           return data
      //         }]
      //       })
      //       .finally(() => {
      //         const _url = uploadUrl.split("?")[0]
      //         const createData = {
      //           name: fileName,
      //           signed: signed,
      //           url: _url,
      //         }
      //         this
      //           .create(createData)
      //           .then(res => {
      //             if (!this.signed && config.storage.service == 'gcs') {
      //               axios
      //                 .patch(`pocket_image/public_url/${res.id}`)
      //                 .then(() => {
      //                   resolve(res)
      //                 })
      //             } else {
      //               resolve(res)
      //             }
      //           })
      //           .catch((err) => {
      //             reject(err)
      //           })
      //       })
      //   })
      //   .catch((err) => {
      //     reject(err)
      //   })
    })
  },
  getSrc(pocketImage) {
    if (!pocketImage) {
      return null;
    } else {
      if (pocketImage.signed) {
        return pocketImage.signed_url;
      } else {
        return pocketImage.url;
      }
    }
  }
}