<template>
  <WsInfiniteScroll
    v-slot="{ items }"
    fetchUrl="/drive/file"
    :bodyScroll="true"
  >
    <table class="w-full">
      <component
        :is="_listItemComponent"
        v-for="(item, itemIndex) in items"
        :key="itemIndex"
        v-bind="item"
      ></component>
    </table>
  </WsInfiniteScroll>
</template>

<script>
export default {
  data() {
    return {
      layoutMode: "list",
    };
  },
  computed: {
    _listItemComponent() {
      if (this.layoutMode === "list") {
        return "FileSystemDriveListItem";
      } else {
        return "FileSystemDriveGridItem";
      }
    },
  },
};
</script>

<style>
</style>