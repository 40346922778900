<template>
  <WsStateInput
    ref="WsStateInput"
    @keyup="$emit('keyup', $event)"
    :autofocus="autofocus"
    :maxWidth="maxWidth"
    :rules="rules"
    :type="type"
    :name="name"
    :errors="errors"
    :placeholder="placeholder"
    :note="note"
    :isOk="isOk"
    :errorMessage="errorMessage"
    :disabled="disabled"
    :value="value"
    :maxlength="maxlength"
    :stateData="stateData"
    :suffixText="suffixText"
    @input="$_onInput($event)"
    @enter="$_onSearch()"
    @after-btn-click="$_onSearch()"
    :beforeIcon="_beforeIcon"
  >
    <template
      v-if="mode=='default'"
      v-slot:after-btn
    >
      <WsIcon
        name="icon-md-search"
        class="mr-4"
      ></WsIcon>
      {{searchBtnText}}
    </template>
  </WsStateInput>
</template>

<script>
export default {
  methods: {
    $_onInput($event) {
      this.state.search = $event;
    },
    $_onSearch() {
      this.$emit("input", this.state.search);
      this.$emit("search", this.state.search);
    },
    $_livesearchCheck() {
      clearTimeout(this.timeout.search);
      this.timeout.search = setTimeout(() => {
        this.$_onSearch();
      }, this.livesearchInterval);
    },
  },
  data() {
    return {
      timeout: {
        search: null,
      },
      state: {
        search: "",
      },
    };
  },
  computed: {
    _beforeIcon() {
      if (this.mode == "default") {
        return;
      } else {
        return "icon-md-search";
      }
    },
  },
  props: {
    livesearchInterval: {
      type: Number,
      default: 0,
    },
    mode: {
      type: String,
      default: "default",
    },
    maxWidth: {
      type: String,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: null,
    },
    note: {
      type: String,
      default: null,
    },
    isOk: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: [String, Array],
      default: null,
    },
    value: {
      type: [Number, String],
      default: null,
    },
    maxlength: {
      type: [Number, String],
    },
    stateData: {
      type: Object,
    },
    suffixText: {
      type: String,
    },
    searchOnClick: {
      type: Boolean,
      default: true,
    },
    searchBtnText: {
      type: String,
    },
  },
  watch: {
    "state.search": {
      handler() {
        if (this.mode == "livesearch") {
          this.$_livesearchCheck();
        }
      },
    },
  },
};
</script>

<style>
</style>