<template>
  <tr @drag="$_onDrag($event)">
    <td>
      <WsText>{{ _fileExtention }}</WsText>
    </td>
    <td>
      <WsText>{{ file_name }}</WsText>
    </td>
    <td>
      <WsText>{{ file_size }}</WsText>
    </td>
    <td>
      <WsText>{{ updated_at }}</WsText>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    file_name: {},
    file_size: {},
    updated_at: {},
  },
  methods: {
    $_onDrag($event) {
      console.log("$_onDrag", $event);
    },
  },
  computed: {
    _fileExtention() {
      return this.$o_o.$h.file.getFileExtension(this.file_name);
    },
  },
};
</script>

<style>
</style>