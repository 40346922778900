<template>
  <div class="ws-part-return-card">
    <WsText
      size="20"
      class="mt-6"
    >{{label}}</WsText>
    <WsCard class="mt-6">
      <table>
        <tr>
          <td
            v-if="selectable"
            class="check"
          >
            <WsState
              type="checkbox"
              :value="_tableCheckValue"
              @input="$_onTableCheckInput($event)"
            ></WsState>
          </td>
          <td
            v-for="(field,fieldKey) in fields"
            :key="fieldKey+'label'"
          >
            {{field.label}}
          </td>
        </tr>
        <tr
          v-for="(submitItem,submitIndex) in modelData"
          :key="submitItem[submitDataKey]"
        >
          <td
            v-if="selectable"
            class="check"
          >
            <!-- :style="[{'border-top-color':_tdBorderColor}]" -->
            <WsState
              type="checkbox"
              :value="submitItem.selected"
              @input="$_onRowCheckInput(submitIndex)"
            ></WsState>
          </td>
          <td
            v-for="(field,fieldKey) in fields"
            :key="fieldKey"
            :style="[{'border-top-color':_tdBorderColor}]"
          >
            <WsStateForm
              v-if="field.type==='state'"
              :fields="field.fields"
              :value="submitItem"
              @input="$_onFilterSelectFormInput($event,submitIndex)"
            ></WsStateForm>
            <WsInfoForm
              v-if="field.type==='info'"
              :fields="field.fields"
              :value="submitItem"
            ></WsInfoForm>
            <WsClickPopupBtn
              v-show="field.type==='btn-popup'&& submitItem.selected"
              :fields="field.fields"
              :popupTitle="$t('填寫退貨備註')"
              :value="submitItem"
              :title="$t('填寫')"
              @input="$_onFilterSelectFormInput($event,submitIndex)"
            ></WsClickPopupBtn>
            <span
              v-show="field.type==='btn-popup'&& !submitItem.selected"
              class=""
            >-</span>
          </td>
        </tr>
      </table>
      <WsText size="20">
        {{$t('退訂總金額')}} {{_allPrice|moneySign}}
      </WsText>
      <WsFlex>
        <WsBtn @click="$_cancel()">{{$t('取消')}}</WsBtn>
        <WsBtn @click="$_submit()">{{$t('送出')}}</WsBtn>
      </WsFlex>
    </WsCard>
  </div>
</template>
<script>
export default {
  name: "WsPartReturnCardUpdate",
  data() {
    return {
      modelData: [],
      loading: {
        submit: false,
        fetch: false,
      },
    };
  },
  filters: {
    moneySign(value) {
      if (value) {
        return "NT$ " + value;
      } else {
        return "NT$ 0";
      }
    },
  },
  methods: {
    async $_fetchData() {
      //get api data
      try {
        const url = this.modelDataUrl;
        const params = this.modelDataParams;
        const res = await this.$axios.get(url, { params });
        this.modelData = res.data.data;
        this.modelData.forEach((item) => {
          item.selected = true;
        });
        this.$emit("input", this.modelData);
        this.loading.fetch = false;
      } catch (err) {
        alert(err);
        this.loading.fetch = false;
      }
    },
    $_returnOrderNumberList(num) {
      let _numList = [];
      for (let i = 0; i <= num; i++) {
        _numList.push({
          text: i,
          value: i,
        });
      }
      return _numList;
    },
    $_returnSingleAllPrice(price, num) {
      return price * num;
    },
    $_onRowCheckInput(index) {
      this.$_onFilterSelectFormInput(
        { selected: !this.modelData[index].selected },
        index
      );
    },
    async $_submit() {
      if (!this._selected.length || !this.modelData.length) return;
      // post data
      try {
        let postArr = [];
        let newSelected = [];
        if (this.postTransform) {
          newSelected = this.postTransform(this._selected);
        } else {
          newSelected = this._selected;
        }
        newSelected.forEach((item) => {
          let data = item;
          const url = `/${this.modelName}/${data.id}`;
          postArr.push(this.$axios.patch(url, data));
        });
        await Promise.all(postArr);
        //change order status
        await this.$_changeOrderStatus();
      } catch (err) {
        alert(err);
      } finally {
        this.loading.submit = false;
        this.$router.push(`/${this.parentModelName}/${this._id}`);
      }
    },
    async $_changeOrderStatus() {
      try {
        const url = `/${this.parentModelName}/${this._id}`;
        await this.$axios.patch(url, {
          status: "return-part-apply",
        });
      } catch (err) {
        alert(err);
      }
    },
    $_onFilterSelectFormInput($event, index) {
      const data = { ...this.modelData[index], ...$event };
      this.$set(this.modelData, index, data);
    },
    $_onTableCheckInput($event) {
      for (let i = 0; i < this.modelData.length; i++) {
        this.$_onFilterSelectFormInput({ selected: $event }, i);
      }
    },
    $_cancel() {
      this.$router.push(this.previousUrl);
    },
  },
  created() {
    this.$_fetchData();
  },
  computed: {
    _id() {
      return this.$route.params.id;
    },
    _allPrice() {
      let total = 0;
      this.modelData.forEach((item) => {
        if (item.selected && item.price && item.count) {
          total += this.$_returnSingleAllPrice(item.price, item.count);
        }
      });
      return total;
    },
    _tableCheckValue() {
      if (this._selected.length) {
        if (this._selected.length == this.modelData.length) {
          return true;
        } else {
          return "-";
        }
      } else {
        return false;
      }
    },
    _tdBorderColor() {
      if (this.tdBorderColor) {
        return this.tdBorderColor;
      } else {
        return this.$color.gray;
      }
    },
    _selected() {
      if (this.modelData.length) {
        return this.modelData.filter((item) => {
          return item.selected;
        });
      } else {
        return [];
      }
    },
  },
  props: {
    fields: {
      type: Object,
      default() {
        return {};
      },
    },
    label: {
      type: String,
      default: "",
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    submitDataKey: {
      type: String,
      default: "id",
    },
    previousUrl: {
      type: String,
      default: "",
    },
    modelDataUrl: {
      type: String,
      default: "",
    },
    parentModelName: {
      type: String,
      default: "",
    },
    returnModelName: {
      type: String,
      default: "",
    },
    modelDataParams: {
      type: Object,
      default() {
        return {};
      },
    },
    postTransform: {
      type: Function,
    },
    modelName: {
      type: String,
    },
  },
};
</script>
<style lang="scss">
// @import "@/";
table {
  border-collapse: collapse;
}
td {
}
</style>