<template>
  <WsMain>
    <WsVideo :src="src"></WsVideo>
    <div>
      <WsBtn @click="$_onUploadClick()">{{$t('上傳影片')}}</WsBtn>
    </div>
    <input
      v-show="false"
      @change="$_onChange($event)"
      type="file"
      ref="fileInput"
      accept="video/mp4,video/x-m4v,video/*"
    >
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      src: null,
      file: null,
      url: null,
    };
  },
  methods: {
    async $_onChange($event) {
      this.file = $event.target.files[0];
      this.src = URL.createObjectURL(this.file);
    },
    $_onUploadClick() {
      this.$refs.fileInput.click();
    },
    async submit() {
      if (!this.file) {
        return;
      }
      const res = await this.$o_o.$s._m.general_video.upload(
        this.file,
        this.file.name
      );
      this.$emit("submit", res);
    },
  },
};
</script>

<style>
</style>