<template>
  <SlickList
    v-if="classItems.length"
    :value="classItems"
    @input="$_onInput($event)"
    :useDragHandle="true"
  >
    <WsCmsCrudClassifyItem
      class="mb-8"
      v-for="(classItem,index) in classItems"
      :key="index"
      :index="index"
      :hasIsActive="hasIsActive"
      :isActive="classItem.is_active"
      :classItem="classItem"
      :modelName="modelName"
      :updateFields="updateFields"
      :childLayerCount="_childLayerCount"
      :label="label"
      :ableMoveUp="$_getAbleMoveUp()"
      :ableMoveDown="$_getAbleMoveDown(index)"
      :childKeyName="childKeyName"
      :extendPostData="extendPostData"
      :customTableActions="customTableActions"
      @edit="$_onEdit(classItem,index)"
      @delete="$_onDelete(classItem,index)"
      @update="$_onUpdateClassItem($event,classItem,index)"
      @movedown="$_onMoveDown(classItem,index)"
      @moveup="$_onMoveUp(classItem,index)"
      @moveupclassitem="$_onMoveUpClassItem($event,classItem,index)"
      @custom-table-action="$emit('custom-table-action', $event)"
    ></WsCmsCrudClassifyItem>
    <WsDialogUpdate
      ref="WsDialog"
      type="short-panel"
      :title="label"
      @submit="$_onDialogSubmit()"
    >
      <template #content>
        <WsStateForm
          :fields="updateFields"
          v-model="dialog.state"
        ></WsStateForm>
      </template>
    </WsDialogUpdate>
  </SlickList>
</template>

<script>
export default {
  data() {
    return {
      dialog: {
        item: null,
        state: {},
      },
    };
  },
  methods: {
    $_onMoveUpClassItem($event, classItem, index) {
      const _childClassItem = { ...$event.classItem };
      const _childIndex = $event.index;
      const _classItems = [...this.classItems];
      _childClassItem[this._parentKeyName] = this.parentClassItem
        ? {
            id: this.parentClassItem.id,
            name: this.parentClassItem.name,
          }
        : null;
      _classItems.splice(index + 1, 0, _childClassItem);
      _classItems[index][this._childKeyName].splice(_childIndex, 1);
      this.$_updateClassItemRemote(_childClassItem.id, {
        [this._parentKeyName]: this.parentClassItem
          ? this.parentClassItem.id
          : null,
        name: $event.classItem.name || "",
      });
      this.$emit("update:classItems", _classItems);
    },
    $_onMoveUp(classItem, index) {
      this.$emit("moveupclassitem", {
        classItem,
        index,
      });
    },
    $_onMoveDown(classItem, index) {
      this.$axios.patch(`/${this.modelName}/${classItem.id}`, {
        [this._parentKeyName]: this.classItems[index - 1].id,
        name: this.classItems[index].name,
        ...(this.extendPostData || {}),
      });
      const _classItems = [...this.classItems];
      const _prevClassItem = _classItems[index - 1];
      if (!_prevClassItem[this._childKeyName]) {
        _prevClassItem[this._childKeyName] = [];
      }
      const _childClassItems = [...classItem[this._childKeyName]];
      classItem[this._childKeyName] = [];
      _prevClassItem[this._childKeyName].push(classItem);
      _classItems.splice(index, 1);
      this.$_updateClassItemRemote(classItem.id, {
        [this._parentKeyName]: _prevClassItem.id,
      });
      if (this._childLayerCount - 1 <= 0) {
        _childClassItems.forEach((item) => {
          _prevClassItem[this._childKeyName].push(item);
          this.$_updateClassItemRemote(item.id, {
            [this._parentKeyName]: _prevClassItem.id,
          });
        });
        classItem[this._childKeyName] = [];
      }
      this.$emit("update:classItems", _classItems);
    },
    $_getAbleMoveUp() {
      if (this.parentClassItem) {
        return true;
      } else {
        return false;
      }
    },
    $_getAbleMoveDown(index) {
      if (index == 0) {
        return false;
      } else {
        return true;
      }
    },
    async $_onDialogSubmit() {
      const _state = this.$o_o.$h.model.getFormatedStates(
        this.updateFields,
        this.dialog.state
      );
      this.$_updateClassItemStateStatic(this.dialog.item.id, this.dialog.state);
      this.$_updateClassItemRemote(this.dialog.item.id, _state);
      this.$refs.WsDialog.close();
    },
    $_onInput($event) {
      // SlickList Bug
      if ($event[$event.length - 1] == undefined) {
        return;
      }
      this.$emit("update:classItems", $event);
    },
    async $_onEdit(classItem) {
      this.dialog.item = classItem;
      this.dialog.state = { ...classItem };
      this.$refs.WsDialog.open();
    },
    async $_onUpdateClassItem($event, classItem) {
      this.$_updateClassItemStateStatic(classItem.id, $event);
      this.$_updateClassItemRemote(classItem.id, $event);
    },
    $_updateClassItemStateStatic(id, newVal) {
      const _index = this.classItems.findIndex((e) => {
        return e.id == id;
      });
      const _classItems = [...this.classItems];
      _classItems[_index] = {
        ...this.classItems[_index],
        ...newVal,
      };
      this.$emit("update:classItems", _classItems);
    },
    $_updateClassItemRemote(id, newVal) {
      let postData = {
        ...newVal,
        ...(this.extendPostData || {}),
      };
      this.$axios.patch(`/${this.modelName}/${id}`, postData);
    },
    async $_onDelete(classItem, index) {
      const _classItems = [...this.classItems];
      _classItems.splice(index, 1);
      this.$emit("update:classItems", _classItems);
      this.$axios.delete(`/${this.modelName}/${classItem.id}`);
    },
  },
  computed: {
    _childLayerCount() {
      if (this.childLayerCount != undefined) {
        return this.childLayerCount - 1;
      } else {
        return this.childLayerCount;
      }
    },
    _parentKeyName() {
      return `${this.modelName}`;
    },
    _childKeyName() {
      return this.childKeyName || `${this.modelName}s`;
    },
  },
  props: {
    modelName: {},
    classItems: {},
    hasIsActive: {},
    index: {},
    label: {},
    parentClassItem: {},
    updateFields: {},
    childLayerCount: {},
    extendPostData: {},
    childKeyName: String,
    customTableActions: Array,
  },
};
</script>

<style>
</style>