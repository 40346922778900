<template>
  <div class="ws-no-data-message">
    <WsText>
      <slot></slot>
    </WsText>
  </div>
</template>

<script>
export default {};
</script>
