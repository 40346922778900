<template>
  <div
    class="ws-state-textarea"
    @click="$_inputBlockOnClick()"
    v-click-outside="$_inputBlockOnBlur"
  >
    <!-- <textarea-autosize -->
    <textarea
      ref="textarea"
      :placeholder="placeholder"
      :min-height="100"
      :max-height="500"
      :autofocus="autofocus"
      :value="value"
      @input="$_onInput($event)"
      :disabled="disabled"
    />
    <div
      class="ws-state-textarea__bg"
      :style="[
        {'background-color':_backgroundColor}
      ]"
    ></div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isFocus: false,
  }),
  computed: {
    _backgroundColor() {
      if (this.backgroundColor) {
        return this.backgroundColor;
      } else {
        return null;
      }
    },
  },
  props: {
    autofocus: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: "...",
    },
    name: {
      type: String,
      default: null,
    },
    value: {},
    autoGrow: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
    },
    minHeight: {
      type: Number,
      default: 100,
    },
    maxHeight: {
      type: Number,
      default: 500,
    },
  },
  methods: {
    $_onInput($event) {
      this.$emit("input", $event.target.value);
      this.$_resizeCheck();
    },
    $_resizeCheck() {
      const element = this.$refs.textarea;
      element.style.height = this.minHeight + "px";
      if (element.scrollHeight + 4 > this.minHeight) {
        if (element.scrollHeight > this.maxHeight) {
          element.style.height = this.maxHeight + "px";
        } else {
          element.style.height = element.scrollHeight + 4 + "px";
        }
      }
    },
    $_inputBlockOnClick() {
      if (this.disabled) {
        return;
      }
      this.isFocus = true;
    },
    $_inputBlockOnBlur() {
      this.isFocus = false;
    },
    $_onFocus() {},
    $_onBlur() {},
    $_onKeyup($event) {
      this.$emit("keyup", $event);
    },
  },
  watch: {
    isFocus: {
      handler() {
        if (this.isFocus) {
          this.$refs.textarea.focus();
          this.$emit("onFocus");
        } else {
          this.$refs.textarea.blur();
          this.$emit("onBlur");
        }
      },
    },
  },
  mounted(){
    this.$_resizeCheck()
  }
};
</script>