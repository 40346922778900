import H_Router from '@/__stone/helpers/router'
import H_Module from '@/__stone/helpers/module'


const routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: ['create', 'update'],
    modelName: 'xc_project',
    middleware: 'auth',
    meta: {
      // requiredScopes: ['news-admin'],
    },
  }),
  {
    path: '/xc_project',
    name: 'my',
    component: () => import('@/__modules/xc_project/views/XcProject/Index.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/xc_project/:id',
    name: 'xc_project_redirect',
    redirect: to => {
      return {
        path: `/xc_project/${to.params.id}/xc_task`,
      }
    },
  },
  {
    path: '/xc_project/:id',
    name: 'xc_project',
    component: () => import('@/__modules/xc_project/views/XcProject/Read.vue'),
    meta: {
      // requiredScopes: ['xc_project-admin'],
    },
    children: [
      {
        path: 'xc_task',
        name: 'Task',
        component: () => import('@/__modules/xc_project/views/XcProject/XcTask/Index.vue'),
        meta: {
          middleware: 'auth',
        },
      },
      {
        path: 'xc_task/scheduling',
        name: 'Task_scheduling',
        component: () => import('@/__modules/xc_project/views/XcProject/XcTask/Scheduling.vue'),
        meta: {
          middleware: 'auth',
        },
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: () => import('@/__modules/xc_project/views/XcProject/Calendar.vue'),
        meta: {
          middleware: 'auth',
        },
      },
      {
        path: 'info',
        name: 'Info',
        component: () => import('@/__modules/xc_project/views/XcProject/Info.vue'),
        meta: {
          middleware: 'auth',
        },
      },
      {
        path: 'member',
        name: 'Member',
        component: () => import('@/__modules/xc_project/views/XcProject/Member.vue'),
        meta: {
          middleware: 'auth',
        },
      },
      ...H_Module.extendModuleHelperAction(
        "xc_project",
        "xc_project.js",
        "getXcProjectExtendRoutes",
        {}
      )
    ]
  },
  // {
  //   path: '/my/project',
  //   name: 'my',
  //   component: () => import('@/__modules/xc_project/views/MyProject/Index.vue'),
  //   meta: {
  //     middleware: 'auth'
  //   }
  // },
  // {
  //   path: '/my/project/create',
  //   name: 'my',
  //   component: () => import('@/__modules/xc_project/views/MyProject/Create.vue'),
  //   meta: {
  //     middleware: 'auth'
  //   }
  // },
  // {
  //   path: '/my/project/:id',
  //   name: 'my_project_redirect',
  //   redirect: to => {
  //     return {
  //       path: `/my/project/${to.params.id}/calendar`,
  //     }
  //   },
  // },
  // {
  //   path: '/my/project/:id',
  //   name: 'my_project',
  //   component: () => import('@/__modules/xc_project/views/MyProject/Read.vue'),
  //   meta: {
  //     requiredScopes: ['xc_project-admin'],
  //   },
  //   children: [
  //     {
  //       path: 'task',
  //       name: 'Task',
  //       component: () => import('@/__modules/xc_project/views/MyProject/Task.vue'),
  //       meta: {
  //         middleware: 'auth',
  //       },
  //     },
  //     {
  //       path: 'calendar',
  //       name: 'Calendar',
  //       component: () => import('@/__modules/xc_project/views/MyProject/Calendar.vue'),
  //       meta: {
  //         middleware: 'auth',
  //       },
  //     },
  //     {
  //       path: 'info',
  //       name: 'Info',
  //       component: () => import('@/__modules/xc_project/views/MyProject/Info.vue'),
  //       meta: {
  //         middleware: 'auth',
  //       },
  //     },
  //     {
  //       path: 'member',
  //       name: 'Member',
  //       component: () => import('@/__modules/xc_project/views/MyProject/Member.vue'),
  //       meta: {
  //         middleware: 'auth',
  //       },
  //     },
  //   ]
  // },
]
export default routes