<template>
  <div>
    <WsStateRadio
      :nullable="false"
      :items="items"
      v-model="state.type"
      flexDirection="column"
    ></WsStateRadio>
    <WsFlex
      v-if="state.type==1"
      class="pl-20"
    >
      <WsState
        type="date"
        placeholder="選擇起日"
        label="開始時間"
        rules="required"
        @input="$_onInputStartDate($event)"
        :value="value.startDate"
      ></WsState>
      <WsState
        class="mt-0 ml-20"
        type="date"
        label="結束時間"
        placeholder="選擇迄日"
        rules="required"
        @input="$_onInputEndDate($event)"
        :value="value.endDate"
      ></WsState>
    </WsFlex>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {
        type: 0,
        startDate: null,
        endDate: null,
      },
      items: [
        {
          label: "無期限",
          value: 0,
        },
        {
          label: "日期區間",
          value: 1,
        },
      ],
    };
  },
  methods: {
    $_onInputStartDate($event) {
      const _value = {
        ...this.value,
        startDate: $event,
      };
      this.$emit("input", _value);
    },
    $_onInputEndDate($event) {
      const _value = {
        ...this.value,
        endDate: $event,
      };
      this.$emit("input", _value);
    },
    $_checkTypeOnValue() {
      if (!this.value) {
        this.state.type = 0;
      } else if (this.value.startDate && this.value.endDate) {
        this.state.type = 1;
      } else {
        this.state.type = 0;
      }
    },
  },
  watch: {
    "state.type": {
      handler() {
        if (this.state.type) {
          const _value = this.value ? { ...this.value } : {};
          if (!_value.startDate) {
            _value.startDate = null;
          }
          if (!_value.endDate) {
            _value.endDate = null;
          }
          this.$emit('input',_value)
        } else {
          this.$emit("input", {});
        }
      },
    },
  },
  mounted() {
    this.$_checkTypeOnValue();
  },
  props: {
    value: {
      type: Object,
    },
  },
};
</script>

<style>
</style>