<template>
  <div class="layout-print">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>