export default {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "quotation_des_item_template",
  label: "報價單說明項目公版",
  fields: {
    content: {
      type: 'textarea',
      label: '內容文字'
    }
  }
}