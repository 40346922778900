import H_Module from './module'
import $c from '@/__config'
import Vue from 'vue'
import store from '@/store';

export default {
  getFilteredAdminScopes(baseScopes = []) {
    const scopes = this.getAdminScopes(baseScopes)
    const authScopes = store.state.auth.scopes
    return this.filterScopeItems(scopes, authScopes)
  },
  getAdminScopes() {
    let scopes = [
      {
        text: '登入',
        value: $c.auth.name ? $c.auth.name : 'admin'
      },
      ...this.getModuleScopes()
    ]
    if ($c.scopes && $c.scopes.extend) {
      scopes = [
        ...scopes,
        ...$c.scopes.extend,
      ]
    }
    return scopes
  },
  filterScopeItems(scopeItems, userScopes) {
    const _scopeItems = []
    scopeItems.forEach(scopeItem => {
      if (scopeItem.requireScopes) {
        if (this.hasScope(scopeItem.requireScopes, userScopes)) {
          _scopeItems.push(scopeItem)
        }
      } else {
        _scopeItems.push(scopeItem)
      }
    });
    return _scopeItems
  },
  getModuleScopes() {
    const moduleScopes = []
    const moduleFiles = this.getModulesJson()
    const wsmodule = Vue.prototype.$config.wsmodule
    if (!moduleFiles) {
      return
    }
    for (const moduleKey in moduleFiles) {
      if (!$c.wsmodule[moduleKey]) {
        continue
      }
      if (!moduleFiles[moduleKey].scopes) {
        continue
      }
      for (const wsmoduleKey in wsmodule) {
        if (moduleKey == wsmoduleKey && wsmodule[wsmoduleKey]) {
          moduleScopes.push(
            ...moduleFiles[moduleKey].scopes
          )
        }
      }
    }
    return moduleScopes
  },
  getModulesContext() {
    try {
      return require.context('@' + '/__modules/', true, /\.js/i);
    } catch (error) {
      return
    }
  },
  getModulesJson() {
    const modules = H_Module.getModulesContext()
    if (!modules) {
      return
    }
    const moduleFiles = {}
    modules.keys().forEach(modulePath => {
      const keyArr = modulePath.split('/')
      const key = keyArr[1]
      if (!moduleFiles[key]) {
        moduleFiles[key] = {}
      }
      if (!moduleFiles[key][keyArr[2]]) {
        moduleFiles[key][keyArr[2]] = {}
      }
      if (keyArr[2] == 'scopes.js') {
        moduleFiles[key].scopes = modules(modulePath).default
      }
    });
    return moduleFiles
  },
  hasScope(requireScopes = [], authScopes) {
    if (!requireScopes || !requireScopes.length) {
      return true
    }
    let hasScope = false
    authScopes.forEach(myScope => {
      if (requireScopes.includes(myScope)) {
        hasScope = true
      }
    });
    return hasScope
  },
}