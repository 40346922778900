<template>
  <WsAnalyticsCard :title="title">
    <template v-slot:content>
      <WsText
        size="48"
        class="py-66"
        bold
        align="center"
      >{{value}}</WsText>
    </template>
  </WsAnalyticsCard>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    value: {
      type: String,
    },
  },
};
</script>

<style>
</style>