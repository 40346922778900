<template>
  <WsMain>
    <WsAudioPicker
      ref="WsAudioPicker"
      @submit="$_onSubmit($event)"
      :filesable="false"
    ></WsAudioPicker>
  </WsMain>
</template>

<script>
export default {
  methods: {
    async $_onSubmit() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>