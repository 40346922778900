<template>
  <video
    class="ws-video"
    v-if="src"
    :src="src"
    ref="video"
    :controls="hasControls"
  ></video>
</template>

<script>
export default {
  methods: {
    $_load() {
      if (this.$refs.video) {
        this.$refs.video.parent()[0].load();
      }
    },
  },
  beforeMount() {
    URL.revokeObjectURL(this.src);
  },
  mounted() {
    this.$_load();
  },
  props: {
    src: {},
    hasControls: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>
</style>