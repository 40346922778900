const routes = [
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/__modules/calendar/views/Calendar/Index.vue'),
    meta: {
      middleware: 'auth'
    }
  }
]
export default routes