import H_Router from '@/__stone/helpers/router'

const routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: ['create', 'update'],
    modelName: 'xc_task',
    middleware: 'auth',
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index', 'read', 'create', 'update'],
    modelName: 'xc_task_template',
    middleware: 'auth',
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index', 'read', 'create', 'update'],
    modelName: 'xc_task_execute_ref_item',
    middleware: 'auth',
  }),
  {
    path: '/xc_task',
    name: 'xc_task',
    component: () => import('@/__modules/xc_task/views/XcTask/Index.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/my/task',
    name: 'my_task',
    component: () => import('@/__modules/xc_task/views/XcTask/MyTask.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/my/owntask',
    name: 'myown_task',
    component: () => import('@/__modules/xc_task/views/XcTask/MyOwnTask.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/xc_task/:id/tr',
    name: 'xc_task_tr',
    component: () => import('@/__modules/xc_task/views/XcTask/TR.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/xc_task/:id/done',
    name: 'xc_task_done',
    component: () => import('@/__modules/xc_task/views/XcTask/Done.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/xc_task/scheduling',
    name: 'atask_scheduling',
    component: () => import('@/__modules/xc_task/views/XcTask/Scheduling.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/xc_task/:id',
    name: 'xc_task_read',
    component: () => import('@/__modules/xc_task/views/XcTask/Read.vue'),
    meta: {
      middleware: 'auth'
    }
  },
]
export default routes