<template>
  <div
    class="ws-col"
    :class="[`col-${cols}`,`md-col-${mdcols}`,`sm-col-${smcols}`,]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    cols: {
      type: [Number, String],
    },
    mdcols: {
      type: [Number, String],
    },
    smcols: {
      type: [Number, String],
    },
  },
};
</script>