<template>
  <div class="ws-state-image">
    <WsFlex>
      <WsLink
        v-if="value"
        class="mb-10 mt-10"
        :href="value"
      >{{_fileName}}</WsLink>
      <WsIconBtn
        @click="$_onClear()"
        v-if="value"
        class="file-remove-btn ml-10"
        name="icon-md-delete"
      />
    </WsFlex>
    <WsPocketFilePicker
      @submit="$_onPocketSubmit($event)"
      v-model="dialog"
      :signed="signed"
      :getUploadUrl="getUploadUrl"
      :pocketable="pocketable"
      :uploadable="uploadable"
      :linkable="linkable"
    ></WsPocketFilePicker>
    <WsBtn @click="$_onPickDialogOpen()">選擇檔案</WsBtn>
  </div>
</template>

<script>
import S_File from "@/__stone/service/app/file";
import PocketImageService from "@/__stone/service/api/v1/pocket_image";
export default {
  data: () => ({
    dialog: false,
    fetchedItems: [],
  }),

  computed: {
    _fileName() {
      return S_File.getFileNameByUrl(this.value);
    },
  },

  methods: {
    $_onClear() {
      this.$emit("input", null);
    },
    $_onPickDialogOpen() {
      this.dialog = true;
    },
    $_onPocketSubmit($event) {
      this.$emit("input", PocketImageService.getSrc($event));
    },
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    signed: {
      type: Boolean,
      default: false,
    },
    getUploadUrl: {
      type: String,
      default: undefined,
    },
    previewMaxWidth: {
      type: String,
      default: "100%",
    },
    previewMaxHeight: {
      type: String,
      default: "100%",
    },
    pocketable: {
      type: Boolean,
    },
    uploadable: {
      type: Boolean,
    },
    linkable: {
      type: Boolean,
    },
  },
};
</script>

<style>
</style>