<template>
  <div>
    <WsTabs
      :items="_tabs"
      :value="_tabsValue"
      @input="$_onInput($event)"
    >
    </WsTabs>
  </div>
</template>

<script>
// import { params } from "vee-validate/dist/types/rules/alpha";
export default {
  data() {
    return {
      modeldatas: [],
    };
  },
  computed: {
    _tabsValue() {
      if (!this.value) {
        return "__all";
      } else {
        const _tab = this._tabs.find((e) => {
          return e.value == this.value[this.paramsKey];
        });
        if (_tab) {
          return _tab.value;
        } else {
          return "__all";
        }
      }
    },
    _tabs() {
      const _tabs = [
        {
          text: "全部",
          value: "__all",
          // params: {},
        },
      ];
      this.modeldatas.forEach((modeldata) => {
        _tabs.push({
          text: modeldata[this.textKey],
          value: modeldata[this.valueKey],
        });
      });
      return _tabs;
    },
  },
  methods: {
    $_onInput($event) {
      let _value = {
        ...(this.value ? this.value : {}),
      };
      if ($event == "__all") {
        delete _value[this.paramsKey];
      } else {
        _value = {
          ..._value,
          [this.paramsKey]: $event,
        };
      }
      this.$emit("input", _value);
    },
    async $_modelSet() {
      const res = await this.$axios.get(this.modelName, {
        params: {
          getall: 1,
        },
      });
      this.modeldatas = res.data.data;
    },
  },
  mounted() {
    this.$_modelSet();
  },
  props: {
    modelName: {
      type: String,
    },
    paramsKey: {
      type: String,
    },
    valueKey: {
      type: String,
      default: "id",
    },
    textKey: {
      type: String,
      default: "name",
    },
    value: {
      type: Object,
    },
  },
};
</script>

<style>
</style>