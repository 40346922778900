<template>
  <WsInfiniteScroll
    v-slot="{ items }"
    modelName="image"
    class="file_system-file-picker-image"
    :params="_params"
  >
    <WsFlexGallery>
      <WsFlexGalleryItem
        v-for="(fileImage, itemIndex) in items"
        :key="itemIndex"
        @click.native="$_onImagePickerClick(fileImage, itemIndex)"
        :class="{ selected: $_isSelected(fileImage) }"
        class="file_system-file-picker-image__item"
      >
        <div
          class="file_system-file-picker-image__item__image"
          v-lazy:background-image="fileImage.public_url"
        ></div>
        <div class="file_system-file-picker-image__item__bg"></div>
      </WsFlexGalleryItem>
    </WsFlexGallery>
  </WsInfiniteScroll>
</template>

<script>
export default {
  data: () => ({
    focusIndex: null,
    focusItem: null,
    params: {
      order_by: "updated_at",
      order_way: "desc",
    },
  }),

  props: {
    multiple: {
      type: Boolean,
    },
    storageTarget: {},
  },

  computed: {
    _params() {
      const _params = {
        order_by: "updated_at",
        order_way: "desc",
      };
      if (this.storageTarget) {
        _params.tags = [this.storageTarget];
      } else {
        _params.tags = ["general"];
      }
      return _params;
    },
  },

  methods: {
    $_isSelected(fileImage) {
      if (this.multiple) {
        if (!this.focusItem || !this.focusItem.length) {
          return false;
        }
        const _item = this.focusItem.find((e) => {
          return e.id == fileImage.id;
        });
        if (_item) {
          return true;
        } else {
          return false;
        }
      } else {
        if (!this.focusItem) {
          return false;
        }
        if (this.focusItem.id == fileImage.id) {
          return true;
        } else {
          return false;
        }
      }
    },
    $_onImagePickerClick(fileImage, fileImageIndex) {
      if (this.multiple) {
        if (!this.focusItem) {
          this.focusItem = [];
        }
        const _itemIndex = this.focusItem.findIndex((e) => {
          return e.id == fileImage.id;
        });
        if (_itemIndex >= 0) {
          this.focusItem.splice(_itemIndex, 1);
        } else {
          this.focusItem.push(fileImage);
        }
      } else {
        this.focusItem = fileImage;
        this.focusIndex = fileImageIndex;
      }
    },
    submit() {
      let _value;
      if (this.multiple) {
        _value = this.focusItem.map((item) => {
          return item.public_url;
        });
      } else {
        _value = this.focusItem.public_url;
      }
      this.$emit("submit", _value);
      // if (this.focusItem.signed) {
      //   this.$emit("submit", this.focusItem);
      // } else {
      //   this.$emit("submit", this.focusItem);
      // }
    },
  },
};
</script>

<style lang="scss">
.file_system-file-picker-image {
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;

  .file_system-file-picker-image__item {
    position: relative;
    cursor: pointer;

    &.selected {
      .file_system-file-picker-image__item__image {
        border: solid 13px var(--primary);
      }
    }

    .file_system-file-picker-image__item__image {
      transition: all 0.2s;
      position: absolute;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      top: 4px;
      left: 4px;
      border: solid 1px var(--gray);
      background-size: contain;
      z-index: 1;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
</style>