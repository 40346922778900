<template>
  <div
    class="ws-btn-color"
    v-tooltip.top-center="tooltip"
  >
    <div
      v-if="iconBtn"
      class="iconBtn"
    >
      <WsIconBtn
        :name="iconBtnName"
        :backgroundColor="backgroundColor"
        :iconColor="iconColor"
        @click="$_toggleShowColorPicker()"
      />
      <SketchColorPicker
        v-if="showColorPicker"
        :value="colorPickerValue"
        @input="colorPickerValue = $event.hex8"
        class="sketch_color_picker"
      />
      <div
        v-if="showColorPicker"
        class="sketch_color_picker_bg"
        @click="$_toggleShowColorPicker()"
      ></div>
    </div>
    <input
      v-else
      type="color"
      @input="$emit('input',$event)"
      :value="value"
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      lastColorPickerValue: "",
      colorPickerValue: "",
      showColorPicker: false,
    };
  },
  props: {
    value: {},
    tooltip: {
      type: String,
    },
    iconBtn: {
      type: Boolean,
      default: false,
    },
    iconBtnName: {
      type: String,
      default: "icon-md-format-color-text",
    },
    forHighlight: {
      type: Boolean,
      default: false,
    },
    forTextColor: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  watch: {
    showColorPicker(newValue) {
      if (newValue == true) {
        this.$_initColorPickerValue();
      }
    },
  },
  methods: {
    $_initColorPickerValue() {
      if (this.forTextColor) {
        if (this.iconColor) {
          this.colorPickerValue = this.iconColor;
        } else {
          this.colorPickerValue = this.$color.white5d;
        }
      }
      if (this.forHighlight) {
        if (this.backgroundColor) {
          this.colorPickerValue = this.backgroundColor;
        } else {
          this.colorPickerValue = "000000ff";
        }
      }
    },
    $_toggleShowColorPicker() {
      this.showColorPicker = !this.showColorPicker;
      if (!this.showColorPicker) {
        // 關閉ColorPickere才emit，可以避免value值一直變動，ColorPicker輸入框才可用
        this.$emit("input", this.colorPickerValue);
      }
    },
  },
  mounted() {
    if (this.iconBtn) this.colorPickerValue = this.value ? this.value : "#000";
  },
};
</script>

<style>
</style>