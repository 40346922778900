export default {
  loadModulesComponents(app) {
    const moduleFiles = this.getModulesJson()
    for (const moduleKey in moduleFiles) {
      const moduleFile = moduleFiles[moduleKey]
      if (moduleFile.components) {
        for (const componentKey in moduleFile.components) {
          app.component(componentKey, moduleFile.components[componentKey])
        }
      }
    }
  },
  getModulesContext() {
    try {
      return require.context('@' + '/__modules/', true, /\.vue/i);
    } catch (error) {
      return
    }
  },
  getModulesJson() {
    const modules = this.getModulesContext()
    if (!modules) {
      return
    }
    const moduleFiles = {}
    modules.keys().forEach(modulePath => {
      const keyArr = modulePath.split('/')
      const key = keyArr[1]
      if (!moduleFiles[key]) {
        moduleFiles[key] = {}
      }
      if (!moduleFiles[key][keyArr[2]]) {
        moduleFiles[key][keyArr[2]] = {}
      }
      if (keyArr[2] == 'components') {
        const modelKey = keyArr[3].split('.')[0]
        moduleFiles[key][keyArr[2]][modelKey] = modules(modulePath).default
      }
    });
    return moduleFiles
  },
  getExtendModulesJson() {
    const modules = this.getModulesContext()
    if (!modules) {
      return
    }
    const moduleFiles = {}
    modules.keys().forEach(modulePath => {
      const keyArr = modulePath.split('/')
      const key = keyArr[1]
      if (!moduleFiles[key]) {
        moduleFiles[key] = {}
      }
      if (!moduleFiles[key][keyArr[2]]) {
        moduleFiles[key][keyArr[2]] = {}
      }
      if (!moduleFiles[key][keyArr[2]][keyArr[4]]) {
        moduleFiles[key][keyArr[2]][keyArr[4]] = {}
      }
      if (
        keyArr[2] == 'extend_modules' &&
        keyArr[4] == 'components'
      ) {
        const modelKey = keyArr[5].split('.')[0]
        moduleFiles[key][keyArr[2]][keyArr[4]][modelKey] = modules(modulePath).default
      }
    })
    return moduleFiles
  },
  // 註冊 extend_modules 內的 components
  loadExtendModulesComponents(app) {
    const moduleFiles = this.getExtendModulesJson()
    for (const moduleKey in moduleFiles) {
      const moduleFile = moduleFiles[moduleKey]
      if (moduleFile.extend_modules?.components) {
        let _extendModulesComponents = moduleFile.extend_modules.components
        for (const componentKey in _extendModulesComponents) {
          app.component(componentKey,_extendModulesComponents[componentKey])
        }
      }
    }
  },
}