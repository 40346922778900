<template>
  <div>
    <WsTabs
      v-model="state.tabs"
      :items="_itemsTab"
    ></WsTabs>
    <WsFilePickerUpload
      v-if="state.tabs=='upload'"
      ref="WsFilePickerUpload"
    ></WsFilePickerUpload>
    <!-- <WsFilePickerLink
      v-else-if="state.tabs=='link'"
      ref="WsFilePickerLink"
    ></WsFilePickerLink> -->
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          @click="$router.go(-1)"
          outlined
        >{{$t('cancel')}}</WsBtn>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {
        tabs: "upload",
      },
      items: {
        tabs: [],
      },
    };
  },
  methods: {
    async $_onSubmit() {
      this.$store.dispatch("app/startPageLoading");
      try {
        // if (this.state.tabs == "pocket") {
        //   // this.$refs.WsPocketFilePickerPocket.submit();
        // } else
        if (this.state.tabs == "upload") {
          await this.$refs.WsFilePickerUpload.submit();
        } else if (this.state.tabs == "link") {
          await this.$refs.WsFilePickerLink.submit();
        }
        this.$router.go(-1);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _itemsTab() {
      const _items = [];
      if (this.uploadable) {
        _items.push({
          text: "上傳檔案",
          value: "upload",
        });
      }
      // if (this.linkable) {
      //   _items.push({
      //     text: "來源連結",
      //     value: "link",
      //   });
      // }
      return _items;
    },
  },
  props: {
    uploadable: {
      type: Boolean,
      default: true,
    },
    linkable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>
</style>