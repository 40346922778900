import config from '@/__config'
export default {
	namespaced: true,
	state: {
		currentUser: null,
		scopes: []
	},
	mutations: {
		SET_CURRENT_USER(state, data) {
			const userKey = config.auth.user && config.auth.user.userKey ? config.auth.user.userKey : 'user'
			state.currentUser = data[userKey]
			state.scopes = data.scopes
		},
		REMOVE_CURRENT_USER(state) {
			state.currentUser = null
			state.scopes = null
		},
	},
	actions: {
		setCurrentUser({ commit }, data) {
			commit('SET_CURRENT_USER', data)
		},
		removeCurrentUser({ commit }) {
			commit('REMOVE_CURRENT_USER')
		},
	}
}