export default {
  xc_financial_calc: {
    icon: 'icon-md-trending-up',
    title: '統計分析',
    link: '/calc/xc_financial',
    scopes: ['xc_financial-admin'],
  },
  xc_double_entry_record: {
    icon: 'icon-md-attach-money',
    title: '帳款紀錄',
    link: '/xc_double_entry_record',
    scopes: ['xc_financial-admin'],
  },
  xc_financial_item: {
    icon: 'icon-md-chrome-reader-mode',
    title: '自訂帳款項目',
    link: '/xc_financial_item',
    scopes: ['xc_financial-admin'],
  },
  xc_financial_first_grade: {
    icon: 'icon-ll-nav-board',
    title: '一級科目',
    link: '/xc_financial_first_grade',
    scopes: ['xc_financial-admin'],
  },
}