export default {
  fieldsToArrayFields(fields) {
    const _arrayFields = [];
    for (let fieldKey in fields) {
      const fieldItem = fields[fieldKey];
      const _fieldItem = {};
      _fieldItem.name = fieldKey;
      for (let fieldItemKey in fieldItem) {
        _fieldItem[fieldItemKey] = fieldItem[fieldItemKey];
      }
      _arrayFields.push(_fieldItem);
    }
    return _arrayFields;
  },
  arrayFieldsToFields(arrayFields) {
    const _fields = {};
    arrayFields.forEach((arrayField) => {
      _fields[arrayField.name] = {};
      for (let fieldKey in arrayField) {
        if (fieldKey == "name") {
          continue;
        }
        _fields[arrayField.name][fieldKey] = arrayField[fieldKey];
      }
    });
    return _fields;
  },
}