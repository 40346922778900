export default {
  getXcProjectMenu(data, res) {
    res.splice(1, 0, {
      title: "Milestone",
      link: `/xc_project/${data.id}/xc_milestone`,
    });
    return res
  },
  getXcProjectExtendRoutes() {
    return [
      {
        path: 'xc_milestone',
        name: 'XcMilestone',
        component: () => import('@/__modules/xc_milestone/extend_modules/xc_project/views/XcProject/XcMilestone.vue'),
        meta: {
          middleware: 'auth',
        },
      },
    ]
  }
}