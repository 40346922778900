<template>
  <div>
    <WsLoadingDots v-if="type=='dots'"></WsLoadingDots>
    <WsLoadingLine v-if="type=='line'"></WsLoadingLine>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      default: "dots",
    },
  },
};
</script>
