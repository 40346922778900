<template>
  <WsCalendarTopBar
    @next="$emit('next')"
    @prev="$emit('prev')"
  >
    <template v-slot:center>
      <select
        v-model="state.C_year"
        @change="$emit('update:year',$event.target.value)"
      >
        <option
          v-for="n in 10"
          :key="`year-${n}`"
          :value="year-5+n"
        >
          {{year-5+n}}
        </option>
      </select>
      <p>年</p>
      <select
        v-model="state.C_month"
        @change="$emit('update:month',$event.target.value)"
      >
        <option
          v-for="n in 12"
          :key="`month-${n}`"
        >
          {{n}}
        </option>
      </select>
      <p>月</p>
    </template>
  </WsCalendarTopBar>

</template>

<script>
export default {
  data() {
    return {
      state: {
        C_year: null,
        C_month: null,
      },
    };
  },
  methods: {
    $_init() {
      this.state.C_year = this.year;
      this.state.C_month = this.month;
    },
  },
  mounted() {
    this.$_init();
  },
  watch: {
    year: {
      handler() {
        this.state.C_year = this.year;
      },
    },
    month: {
      handler() {
        this.state.C_month = this.month;
      },
    },
  },
  props: {
    year: {},
    month: {},
  },
};
</script>

<style>
</style>