import Vue from 'vue';
import VueI18n from 'vue-i18n';
import config from '@/__config';
import H_locale from '@/__stone/helpers/locale';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const stoneLocales = require.context(
    '@/__stone/locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  );
  let locales = {};
  let appLocales = {};
  try {
    locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  } catch (e) {
    locales = null;
  }
  try {
    appLocales = require.context(
      '@/__app/locales',
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i,
    );
  } catch (e) {
    appLocales = null;
  }
  const allLocales = [stoneLocales, locales, appLocales].filter(Boolean);

  const messages = {};
  allLocales.forEach((localeSource) => {
    localeSource.keys().forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        if (!messages[locale]) messages[locale] = {};

        let localeResource = localeSource(key);
        messages[locale] = {
          ...messages[locale],
          ...localeResource,
        };
      }
    });
  });
  return messages;
}

const localeCode = H_locale.getLocaleCodeForI18n(navigator.language || config.locale.lang || 'zh_tw')

export default new VueI18n({
  locale: localeCode,
  fallbackLocale: localeCode,
  // locale: process.env.VUE_APP_I18N_LOCALE || config.locale,
  // fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || config.locale,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  messages: loadLocaleMessages()
})
