<template>
  <div>
    <WsState
      v-for="n in 7"
      :key="`available-${n}`"
      type="list"
      :label="`${$moment().locale($i18n.locale).startOf('week').add(n,'day').format('ddd')} 可預約時間`"
      textKey="start_time"
      :fields="fields"
      :showFields="showFields"
      :value="$_getValue(n)"
      @input="$_onInput($event,n)"
    ></WsState>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        start_time: {
          type: "text",
          label: "起始時間 (0000)",
          maxlength: 4,
          autofocus: true,
        },
        end_time: {
          type: "text",
          label: "結束時間 (0000)",
          maxlength: 4,
        },
        count: {
          type: "number",
          label: "可預約人數",
        },
      },
      showFields: ["start_time", "end_time", "count"],
    };
  },
  methods: {
    $_getValue(number) {
      if (!this.value) {
        return null;
      } else {
        return this.value[
          this.$moment()
            .startOf("week")
            .add("days", number)
            .format("ddd")
            .toLowerCase()
        ];
      }
    },
    $_onInput($event, number) {
      const _value = {
        ...this.value,
      };
      _value[
        this.$moment()
          .startOf("week")
          .add("days", number)
          .format("ddd")
          .toLowerCase()
      ] = $event;
      this.$emit("input", _value);
    },
  },
  props: {
    value: {
      type: Object,
    },
  },
};
</script>

<style>
</style>