<template>
  <component
    class="ws-text"
    :is="_htmlTag"
    :class="[{bold:bold},`size-${size}`,{anti:anti}]"
    :style="[
      {'text-align':align},
      {'color':color},
    ]"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  props: {
    h1: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
    size: {
      type: [Number, String],
      default: 16,
    },
    align: {
      type: String,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    anti: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _htmlTag() {
      if (this.h1) {
        return "h1";
      } else {
        return "div";
      }
    },
  },
};
</script>

<style>
</style>