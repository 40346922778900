export default {
  getErrorMessageFromRes(error) {
    let errorMessage = ''
    if (
      error.response &&
      error.response.data
    ) {
      if (error.response.data.message) {
        if (
          error.response.data.message == 'The custom no has already been taken.'
        ) {
          errorMessage = '此活動自訂編號已被使用';
          return errorMessage;
        } else {
          errorMessage = error.response.data.message;
        }
      }
      if (error.response.data.errors) {
        for (const key in error.response.data.errors) {
          errorMessage += `\n${error.response.data.errors[key]}`
        }
      }
    } else {
      errorMessage = 'error'
    }
    return errorMessage
  }
}