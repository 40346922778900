import i18n from '@/i18n';
export default {
  admin: {
    icon: 'icon-md-groups',
    title:  i18n.t('後台使用者管理'),
    titleInLocale: true,
    menu: [
      {
        title:  i18n.t('後台使用者紀錄'),
        link: '/cms_log',
        scopes: ['cms_log-admin'],
      },
    ],
  },
};
