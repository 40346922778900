<template>
  <div class="ws-state-checkboxs">
    <WsStateCheckbox
      v-for="(item, itemIndex) in items"
      :key="itemIndex"
      :text="item.text"
      :value="$_activeCheck(item.value)"
      @input="$_onInput(item.value)"
    ></WsStateCheckbox>
  </div>
</template>

<script>
export default {
  methods: {
    $_onInput(itemValue) {
      const _value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      if (_value.includes(itemValue)) {
        const _index = _value.findIndex((e) => {
          return e == itemValue;
        });
        _value.splice(_index, 1);
      } else {
        _value.push(itemValue);
      }
      this.$emit("input", _value);
    },
    $_activeCheck(itemValue) {
      if (!this.value) {
        return false;
      } else {
        return this.value.includes(itemValue);
      }
    },
  },

  props: {
    value: {
      type: Array,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style>
</style>