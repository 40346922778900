<template>
  <div>
    <WsDialogCRUDUpdate
      ref="WsDialogCRUDUpdate"
      :title="$t('部分退訂')"
      :fields="_fields"
      modelName="shop_order"
      v-model="state"
      @modelDataInit="$_onModelDataInit($event)"
      :formatSubmitPostData="$_formatSubmitPostData"
      @complete="$_onComplete($event)"
      emitInput
      :extendPostData="extendPostData"
      @close="$_onDialogClose()"
    >
    </WsDialogCRUDUpdate>
  </div>
</template>
<script>
export default {
  name: "WsDialogShopReturnPart",
  data() {
    return {
      state: {},
      modelData: null,
      fields: {
        return_reason: {
          type: "select",
          label: "退貨原因",
          placeholder: "選擇退貨原因",
          items: [
            {
              text: this.$t("欠品"),
              value: "欠品",
            },
            {
              text: this.$t("貨物狀況不佳"),
              value: "貨物狀況不佳",
            },
          ],
          rules: "required",
          col: 12,
        },
        return_remark: {
          type: "textarea",
          label: "退訂備註",
          placeholder: "輸入...",
          rules: "required",
          col: 12,
        },
        return_price: {
          type: "number",
          label: "退訂金額",
          placeholder: "輸入...",
          rules: "required",
          defaultValue: 10,
          col: 12,
        },
        shop_return_records: {
          type: "table",
          fields: {
            name: {
              type: "custom",
              info: true,
              label: this.$t("商品名稱"),
              getValue(item) {
                return item.shop_order_shop_product.name || "-";
              },
            },
            shop_campaigns_name: {
              type: "custom",
              info: true,
              color: "red",
              label: "商品促銷活動",
              getValue(item) {
                const _arr = [];
                item.shop_order_shop_product.shop_campaigns.forEach(
                  (shop_campaign) => {
                    _arr.push(shop_campaign.name);
                  }
                );
                return _arr.join(",");
              },
            },
            no: {
              type: "custom",
              info: true,
              label: this.$t("商品編號"),
              getValue(item) {
                return item.shop_order_shop_product.no || "-";
              },
            },
            price: {
              type: "custom",
              info: true,
              label: this.$t("單價"),
              getValue(item) {
                if (item.shop_order_shop_product.price_campaigned) {
                  return `<del>${item.shop_order_shop_product.price}</del> ${item.shop_order_shop_product.price_campaigned}`;
                } else {
                  return item.shop_order_shop_product.price;
                }
              },
            },
            count: {
              type: "select",
              label: this.$t("退訂數量"),
              getItems(item) {
                const _items = [];
                _items.push({
                  text: 0,
                  value: 0,
                });
                for (
                  let index = 0;
                  index < item.shop_order_shop_product.count;
                  index++
                ) {
                  _items.push({
                    text: index + 1,
                    value: index + 1,
                  });
                }
                return _items;
              },
            },
            total: {
              type: "custom",
              info: true,
              label: this.$t("單品小計"),
              getValue(item) {
                if (item.count && item.shop_order_shop_product.price) {
                  return `NT$ ${
                    parseInt(item.count) *
                    parseInt(item.shop_order_shop_product.price)
                  }`;
                } else {
                  return "NT$ 0";
                }
              },
            },
          },
        },
      },
      extendPostData: {
        status: "return-part-apply",
      },
    };
  },
  computed: {
    _fields() {
      const showFields = [
        "products_price",
        "shop_product_campaign_deduct",
        "bonus_points_deduct",
        "campaign_deduct",
        "invite_no_deduct",
        "freight",
        "order_price",
        "pay_type",
        "pay_at",
        "bonus_points",
      ];
      const _fields = {
        ...this.fields,
      };
      showFields.forEach((showField) => {
        _fields[showField] = {
          ...this.$store.state.stone_model.shop_order.fields[showField],
          info: true,
        };
      });
      _fields.return_cost = {
        type: "custom",
        label: "退訂小計",
        info: true,
        getValue: (item) => {
          if (!item.shop_return_records || !item.shop_return_records.length) {
            return 0;
          }
          let _return_cost = 0;
          item.shop_return_records.forEach((shop_return_record) => {
            _return_cost +=
              shop_return_record.count *
              shop_return_record.shop_order_shop_product.price;
          });
          return _return_cost;
        },
      };
      return _fields;
    },
  },
  watch: {
    "state.shop_return_records": {
      handler(newVal, oldVal) {
        const check = this.$_checkShopReturnRecordsUpdate(newVal, oldVal);
        if (!check) {
          return;
        }
        this.state.return_price = this.$_getReturnPriceFromReturnRecords(
          this.state.shop_return_records
        );
      },
      deep: true,
    },
  },
  methods: {
    $_checkShopReturnRecordsUpdate(newVal, oldVal) {
      if (!newVal || !oldVal) {
        return 0;
      }
      let check = 0;
      for (let index = 0; index < newVal.length; index++) {
        if (newVal[index].count != oldVal[index].count) {
          check = 1;
        }
      }
      return check;
    },
    $_getReturnPriceFromReturnRecords(shop_return_records) {
      if (!shop_return_records) {
        return 0;
      }
      let returnPrice = 0;
      shop_return_records.forEach((shop_return_record) => {
        returnPrice +=
          shop_return_record.count *
          shop_return_record.shop_order_shop_product.price;
      });
      return returnPrice;
    },
    $_clear() {
      this.state = {};
      this.modelData = null;
    },
    $_onDialogClose() {
      this.$_clear();
    },
    $_onComplete($event) {
      this.$emit("complete", $event);
    },
    $_formatSubmitPostData(postData) {
      const _shop_return_records = [];
      postData.shop_return_records.forEach((shop_return_record) => {
        _shop_return_records.push({
          id: shop_return_record.id,
          count: shop_return_record.count,
          shop_product:
            shop_return_record.shop_order_shop_product.shop_product.id,
          shop_order_shop_product:
            shop_return_record.shop_order_shop_product.id,
          user: this.modelData.user.id,
          shop_order: this.modelData.id,
        });
      });
      const _postData = {
        ...postData,
        shop_return_records: _shop_return_records,
      };
      return _postData;
    },
    $_returnRecordInit(shopOrder) {
      if (
        shopOrder.shop_order_shop_products.length ==
        shopOrder.shop_return_records.length
      ) {
        this.state = {
          ...this.state,
          shop_return_records: shopOrder.shop_return_records,
        };
        return;
      }
      const _returnRecord = [];
      shopOrder.shop_order_shop_products.forEach((shop_order_shop_product) => {
        _returnRecord.push({
          count: 0,
          shop_order_shop_product: shop_order_shop_product,
        });
      });
      this.state = {
        ...this.state,
        shop_return_records: _returnRecord,
      };
    },
    $_onModelDataInit(shopOrder) {
      this.modelData = shopOrder;
      this.$_returnRecordInit(shopOrder);
    },
    open(id) {
      this.$refs.WsDialogCRUDUpdate.open(id);
    },
  },
};
</script>