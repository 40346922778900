<template>
  <th
    class="no-select"
    :style="[
      { width: `${width}px` }, 
      { 'max-width': `${width}px` }, 
      { 'min-width': `${width}px` }
    ]"
  >
    <WsText size="12">{{ text }}</WsText>
  </th>
</template>

<script>
export default {
  props: {
    width: {
      default: 100,
    },
    text: {},
  },
};
</script>