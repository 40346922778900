import zh_tw from './zh_tw';
import en from './en';

function configMessages() {
  try {
    let module = require('@/__app/message/index').default;
    return module;
  } catch (error) {
    return {};
  }
}

export default {
  zh_tw: zh_tw,
  en: en,
  ...configMessages(),
};