<template>
  <WsPopupAlert
    ref="WsPopupAlert"
    @submit="$_onSubmit()"
    :title="''"
    :submitBtnText="$t('匯出')"
  >
    <template #content>
      <WsText
        bold
        size="18"
      >
        {{$t('確定匯出所有商品銷量嗎？')}}
      </WsText>
    </template>
  </WsPopupAlert>
</template>

<script>
export default {
  methods: {
    async $_onSubmit() {
      const url = "shop_product/export/excel/signedurl";
      let params = {
        ...this.params,
        sale_only: true,
      };
      delete params.tax;
      try {
        const res = await this.$axios.get(url, {
          params: params,
        });
        window.open(res.data, "_blank");
      } catch (err) {
        alert(err);
      }
    },
    open() {
      this.$refs.WsPopupAlert.open();
    },
  },
  props: {
    params: {
      type: Object,
    },
  },
  data() {
    return {
      fields: {
        created_at: {
          label: this.$t("篩選日期區間"),
          type: "date-range",
        },
      },
    };
  },
};
</script>

<style>
</style>