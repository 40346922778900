<template>
  <div class="ws-reply-input">
    <WsFlex>
      <WsAvatar
        size="32"
        :src="_currentUser.avatar"
      ></WsAvatar>
      <WsCard class="ml-8 pt-0 ws-reply-input__card">
        <WsStateEditor
          v-model="state.reply"
          @focus="isFocus=true"
          @blur="isFocus=false"
          :iconNoBorder="true"
          :toolbarActions="toolbarActions"
          :autofocus="autofocus"
        ></WsStateEditor>
      </WsCard>
    </WsFlex>
    <WsFlex class="mt-12">
      <WsSpacer></WsSpacer>
      <WsBtn
        outlined
        v-if="cancelable"
        class="mr-12"
        @click="$_onCancel()"
      >取消</WsBtn>
      <WsBtn
        @click="$_onSubmit()"
        :disabled="!state.reply"
      >送出</WsBtn>
    </WsFlex>
  </div>
</template>
<script>
export default {
  data() {
    return {
      state: {
        reply: "",
      },
      isFocus: false,
      toolbarActions: [
        "code",
        "text-h1",
        "text-h2",
        "text-h3",
        "text-quote",
        "text-list",
        "link",
        "image",
      ],
    };
  },
  computed: {
    _currentUser() {
      return this.$store.state.auth.currentUser;
    },
  },
  props: {
    cancelable: {},
    value: {},
    autofocus: {},
  },
  methods: {
    $_init() {
      if (this.value) {
        this.state.reply = this.value;
      }
    },
    $_onCancel() {
      this.$emit("cancel");
    },
    $_onSubmit() {
      if (!this.state.reply) {
        return;
      }
      this.$emit("submit", this.state.reply);
    },
    $_keydownHandler($event) {
      if ($event.metaKey && $event.key == "Enter") {
        this.$_onSubmit($event);
      }
    },
    clear() {
      this.state.reply = "";
    },
  },
  mounted() {
    this.$_init();
  },
  created() {
    window.addEventListener("keydown", this.$_keydownHandler);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.$_keydownHandler);
  },
};
</script>