<template>
  <div class="ws-info-icon">
    <WsIcon
      :size="size"
      :color="color"
      :name="$_getDisplayText()"
    ></WsIcon>
  </div>
</template>
<script>
export default {
  name: "WsInfoIcon",
  props: {
    size: {
      type: [Number, String],
      default: 24,
    },
    getDisplayText: {
      type: Function,
    },
    modelData: {
      type: Object,
      default: null,
    },
    color: {
      type: String,
    },
  },
  methods: {
    $_getDisplayText() {
      if (!this.modelData) {
        return "";
      }
      if (this.getDisplayText) {
        return this.getDisplayText(this.modelData)
      } else if(this.modelData.icon) {
        return this.modelData.icon ? this.modelData.icon : ''
      } else {
        return "";
      }
    },
  },
};
</script>