<template>
  <LineChartGenerator
    :chart-options="_chartOptions"
    :chart-data="_chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  name: 'WsChartLine',
  components: {
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    labels: {},
    datasets: {},
  },
  computed: {
    _chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets,
      }
    },
    _chartOptions() {
      return {
        scales: {
          y: {
            ticks: { color: this.$color.white5d, beginAtZero: true }
          },
          x: {
            ticks: { color: this.$color.white5d, beginAtZero: true }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    },
  },
  data() {
    return {
    }
  }
}
</script>
