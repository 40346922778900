import config from '@/__config';

export default {
  options: {
    id: true,
    timestamp: true,
    tags: true,
  },
  modelName: 'course_record',
  label: '編輯歷程',
  fields: {
    action: {
      type: 'radio',
      label: 'Action',
      readonly: true,
      items: [
        {
          label: 'Create',
          value: 'create',
        },
        {
          label: 'Update',
          value: 'update',
        },
        {
          label: 'Delete',
          value: 'delete',
        },
        {
          label: 'TR Request',
          value: 'tr_request',
        },
        {
          label: 'TR',
          value: 'tr',
        },
        {
          label: 'Open',
          value: 'open',
        },
        {
          label: 'Due',
          value: 'due_date',
        },
        {
          label: 'Start At',
          value: 'start_at',
        },
        {
          label: 'Date Reset',
          value: 'date_reset',
        },
        {
          label: 'Done',
          value: 'done',
        },
        {
          label: 'Reject',
          value: 'reject',
        },
        {
          label: 'Close',
          value: 'close',
        },
        {
          label: 'Pending',
          value: 'pending',
        },
        {
          label: 'ReOpen',
          value: 'reopen',
        },
      ]
    },
    admin: {
      type: 'belongsTo',
      label: 'Admin',
      textKey: 'name',
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
      readonly: true,
    },
  }
}