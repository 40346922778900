<template>
  <div class="ws-crud-filter">
    <!-- <WsGroupState> -->
    <div class="w-full mb-12">
      <WsState
        type="search"
        ref="searchInput"
        v-model="C_searching"
        :placeholder="`${$t('search')}...`"
        :searchBtnText="`${$t('search')}...`"
        class="search"
        @search="$emit('search',$event)"
      ></WsState>
      <slot name="filters"></slot>
    </div>
    <!-- </WsGroupState> -->
    <WsIconBtn
      @click="$_onFilterOpenClick()"
      class="filter-btn"
      name="icon-md-filter-list"
    />
    <slot name="filterSelects"></slot>
    <WsBtn
      v-if="clearable"
      class="ml-10"
      @click="$_onClearClick()"
      outlined
    >{{$t('clear')}}</WsBtn>
    <WsState
      class="order-select ml-10 sm-ml-0 sm-mt-6"
      type="select"
      :value="order"
      :items="orderItems"
      @input="$emit('update:order',$event)"
    ></WsState>
    <WsCrudFilterDialog ref="WsCrudFilterDialog">
      <WsState
        type="select"
        :value="order"
        :items="orderItems"
        @input="$emit('update:order',$event)"
      ></WsState>
    </WsCrudFilterDialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    C_searching: "",
    fetchTimeout: null,
  }),
  props: {
    clearable: {
      type: Boolean,
      default: false,
    },
    searching: {
      type: String,
      default: "",
    },
    order: {
      type: String,
      default: "last_update",
    },
    orderItems: {
      type: Array,
      default() {
        return [
          {
            value: "last_update",
            text: "最近更新",
          },
          {
            value: "last_created",
            text: "最新建立",
          },
          {
            value: "sequence",
            text: "排序欄位",
          },
        ];
      },
    },
  },
  mounted() {
    if (this.searching) {
      this.C_searching = this.searching;
    }
  },
  watch: {
    // C_searching: {
    //   handler() {
    //     clearTimeout(this.fetchTimeout);
    //     this.fetchTimeout = setTimeout(() => {
    //       this.$emit("search", this.C_searching);
    //     }, 200);
    //   },
    // },
    searching: {
      handler() {
        this.C_searching = this.searching;
      },
    },
  },
  methods: {
    // $_onSearch($event) {
    //   this.$emit("search", this.C_searching);
    // },
    $_onFilterOpenClick() {
      this.$refs.WsCrudFilterDialog.open();
    },
    $_getOrderQuerys(order) {
      const orderQuerys = {
        order_way: "",
        order_by: "",
      };
      const orderArr = order.split("&");
      orderArr.forEach((orderItem) => {
        const orderItemArr = orderItem.split("=");
        if (orderItemArr[0] in orderQuerys) {
          orderQuerys[orderItemArr[0]] = orderItemArr[1];
        }
      });
      return orderQuerys;
    },
    getValue() {
      return {
        search: this.searching,
        order: this.order,
      };
    },
    $_onClearClick() {
      this.C_searching = "";
      this.$emit("search");
    },
  },
};
</script>