import H_Model from '@/__stone/helpers/model';

const stone_models = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {},
};

const moduleModels = H_Model.getModuleModels();
if (moduleModels) {
  stone_models.state = {
    ...stone_models.state,
    ...moduleModels,
  };
}

export default stone_models;
