export default {
  modelName: "system_event",
  label: "",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    name: {
      type: 'text',
      label: '名稱',
    },
    start_date: {
      type: 'date',
      label: '日期'
    },
    start_at: {
      type: 'datetime',
      label: '開始時間'
    },
    hours: {
      type: 'number',
      label: '時數'
    },
    days: {
      type: 'number',
      label: '天數',
      defaultValue: 1
    },
    note: {
      type: 'textarea',
      label: '備註'
    }
  },
}
