<template>
  <div class="ws-click-popup">
    <WsBtn @click="$_PopupOpen()">
      {{title}}
    </WsBtn>
    <WsPopup ref="popup">
      <template #title>
        <WsTitle class="my-20 ml-20">
          {{popupTitle}}
        </WsTitle>
      </template>
      <template #content>
        {{popupContent}}
        <WsStateForm
          :fields="fields"
          :value="state"
          @input="$_onFilterSelectFormInput($event)"
        >
        </WsStateForm>
      </template>
      <template #rightActions>
        <WsBtn @click="$_PopupClose()">取消</WsBtn>
        <WsBtn @click="$_submit()">送出</WsBtn>
      </template>
    </WsPopup>
  </div>
</template>
<script>
export default {
  name: "WsClickPopupBtn",
  data: () => ({
    state: {},
  }),
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    popupTitle: {
      type: String,
      default: "",
    },
    popupContent: {
      type: String,
      default: "",
    },
    fields: {
      type: Object,
      default() {
        return {};
      },
    },
    title: {
      type: String,
      default: "編輯",
    },
  },
  methods: {
    $_PopupOpen() {
      this.$refs.popup.open();
    },
    $_PopupClose() {
      this.$refs.popup.close();
    },
    $_onFilterSelectFormInput($event) {
      this.state = { ...this.value, ...$event };
    },
    $_submit() {
      this.$emit("input", this.state);
      this.$_PopupClose();
    },
  },
  watch: {
    value: {
      handler() {
        this.state = this.value;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
</style>