<template>
  <WsMain class="ws-search-result-item"  :class="[{'bottom-line':bottomLine}]">
    <WsLink :to="linkTo">
    <WsText size="20">{{title}}</WsText>
    </WsLink>
    <WsBreadcrumbs
      :menu="breadcrumbs"
      class="mt-20"
      :bottomLine='false'
    ></WsBreadcrumbs>
    <WsText
      size="12"
      class="mt-18"
    >{{content}}</WsText>
  </WsMain>
</template>
<script>
export default {
  name: "WsSearchResultItem",
  props: {
    bottomLine: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default:
        "",
    },
    linkTo: {
      type: String,
      default: '/'
    },
    breadcrumbs: {
      type: Array,
      default() {
        return [];
      },
    },
    content: {
      type: String,
      default:
        "",
    },
  },
};
</script>