<template>
  <div class="ws-snack">
    <slide-y-down-transition :group="true">
      <WsCard
        v-for="(snackItem, itemIndex) in _snack"
        :key="itemIndex"
        class="mb-12"
      >
        {{snackItem}}
      </WsCard>
    </slide-y-down-transition>
  </div>
</template>

<script>
export default {
  computed: {
    _snack() {
      return this.$store.state.app.snack;
    },
  },
};
</script>

<style>
</style>