<template>
  <WsCard class="files-list-board">
    <WsText size="16">常用檔案</WsText>
    <WsFlex flexDirection="column" class="files-list-board-main-content py-16">
      <WsFlex
        v-for="(item, index) in list"
        :key="index"
        alignItems="center"
        justifyContent="space-between"
        class="files-list-board-file-row py-4"
      >
        <WsText size="14">{{ item.title }}</WsText>
        <WsIconBtn name="icon-md-file-download" size="38" @click="$_onFileDownload(item.url)" />
      </WsFlex>
    </WsFlex>
    <WsLink to="/file" class="py-8">
      <WsFlex justifyContent="flex-end" alignItems="center" gap="8px">
        <WsText size="14" :color="$color.gray2d">查看所有檔案</WsText>
        <WsIcon name="icon-ws-outline-arrow-right" :color="$color.gray2d" />
      </WsFlex>
    </WsLink>
  </WsCard>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: '某某檔案名稱.pdf',
          url: 'https://storage.googleapis.com/ws_showroom_gcp_storage/course/172552802753NB7/5_row_test_data.xlsx?GoogleAccessId=ws-showroom-cms-api%40wasa-testing-sites.iam.gserviceaccount.com&Expires=1725614959&Signature=L%2FJTaqo4YqaOM0D0APAz%2BA3Q9zPM2VF5UL%2Bckm%2B%2FFQNgV4gqol4tROXbqfInZbdLxF6dCIZDEHDKk7b9mDxtG14UHVPE4p9mFGIjlrtEdpwGQa8Bran7MI9j4k6a7CG7l5E3mbja0AODRpeYzfTz3ag9H5g8hYkT9Jm9xOgH6gCZXGw8prqQcUsRGU9fwaMsS04RZX339rpfHq8PgYHU7ySnG5R5g3PlCnHBoPk%2FO3q667H9rxCsx%2B2GEjFRdDJJMcR1mUnWeu9ddYoujDgz1IgpyaE%2BVoxK8s47MEYHowFCLYt%2FMiq5SZDC0vyzk0tJY%2Bb1gt59y%2Brzy9BwR9MZSw%3D%3D',
        },
        {
          title: '神奇表單.xlsx',
          url: 'https://storage.googleapis.com/ws_showroom_gcp_storage/course/1725528015bRlTF/10%E8%A1%8C%E8%B3%87%E6%96%99-%E6%B8%AC%E8%A9%A6%E6%AA%94%E6%A1%88.xlsx?GoogleAccessId=ws-showroom-cms-api%40wasa-testing-sites.iam.gserviceaccount.com&Expires=1725614984&Signature=rUFXM1rKkf9HqMLh2b8cO2%2BNddlCCIXrVP25UUH06VtVltWpgLNihNd0Y0oUpORt1RmJMXhtmsb7iMGY7Bm0zj1B1DTOH1%2FICh7NxaTaLB3VEEexf30XmoF8141VEccLPmD7%2FNX%2BMQWqFNJGqfSUE%2Ferfzkddh0DZFL5ZSfvOPhdEAd%2FYAEBU%2Ffpbnaz93ZzfBLJE0yk4kLxUDB%2Fto%2BOtr2%2B%2FlQ8DFotksdufqe%2Bv3y8z1qzMVTI6r9%2F%2Fm%2BvQCXcqmMpi2upqHN84Ta5YHBFEgqggKzHF8as7OsXHx7Y5T5G28OenyOtFeHJGl%2FEiGv9DnwEvp4rDZtYnjYi%2FVshNw%3D%3D'
        }
      ]
    }
  },
  methods: {
    $_onFileDownload(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss">
.files-list-board {
  .files-list-board-main-content {
    .files-list-board-file-row {
      border-bottom: 1px solid var(--gray5d);
      &:last-child {
        border-bottom: unset;
      }
    }
  }
}
</style>