<template>
  <WsMain>
    <WsCrud
      label=""
      :modelName="$store.state.stone_model.xc_project.modelName"
      :fields="$store.state.stone_model.xc_project.fields"
      :showFields="showFields"
      :filterTabs="filterTabs"
      :creatable="_creatable"
      :updatable="false"
      :deletable="false"
      :inRowBtnRead="true"
      :rowClickRead="false"
      :pageMode="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      showFields: ["name", "status"],
      filterTabs: [
        {
          text: '進行中',
          value: 'ing',
          params: {
            status: 'ing',
          },
        },
        {
          text: '準備中',
          value: 'prepare',
          params: {
            status: 'prepare',
          },
        },
        {
          text: '暫停',
          value: 'suspend',
          params: {
            status: 'suspend',
          },
        },
        {
          text: '結案',
          value: 'complete',
          params: {
            status: 'complete',
          },
        },
        {
          text: '全部',
          value: 'all',
          params: {
          },
        },
      ],
    };
  },
  methods: {
    $_getReadUrl(item) {
      return `/my/project/${item.id}`;
    },
  },
  computed: {
    _creatable() {
      return this.$o_o.$h.auth.hasScope([
        "xc_project-admin",
        "xc_project-create",
      ]);
    },
  },
};
</script>

<style>
</style>