<template>
  <div class="ws-popup-model">
    <WsDialog
      ref="WsDialog"
      @open="$_onDialogOpen()"
      :title="_value"
    >
      <template v-slot:content>
        <WsLoading v-if="loading"></WsLoading>
        <WsEasyTable
          v-else-if="modelData"
          :fields="fields"
          :modelData="modelData"
        />
      </template>
    </WsDialog>
    <WsLink
      @click="$_onPopupOpenClick()"
      class="ws-popup-model__button link--text"
    >{{_value}}</WsLink>
  </div>
</template>

<script>
export default {
  computed: {
    _value() {
      if (!this.value) {
        return null;
      }
      if (this.getText) {
        return this.getText(this.value);
      } else {
        return this.value[this.textKey];
      }
    },
  },
  data: () => ({
    loading: false,
    modelData: null,
  }),
  methods: {
    async $_onDialogOpen() {
      this.loading = true;
      try {
        const res = await this.$axios.get(`${this.modelName}/${this.id}`);
        this.modelData = res.data.data;
      } catch (error) {
        alert("取得資料錯誤");
      } finally {
        this.loading = false;
      }
    },
    $_onPopupOpenClick() {
      this.$refs.WsDialog.open();
    },
  },
  props: {
    getText: {
      type: Function,
      default: null,
    },
    fields: {
      type: Object,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    modelName: {
      type: String,
      required: true,
    },
    id: {
      type: [Number, String],
      required: true,
    },
    textKey: {
      type: String,
      default: null,
    },
  },
};
</script>