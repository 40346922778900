export default {
  my_off_day: {
    icon: 'icon-md-near-me',
    title: '我的 請假',
    link: '/my/off_day',
  },
  my_wfh: {
    icon: 'icon-md-weekend',
    title: '我的 一日宅宅',
    link: '/my/wfh',
  },
  off_day: {
    icon: 'icon-md-near-me',
    title: '請假',
    link: '/off_day',
    scopes: ['admin_event-admin']
  },
  wfh: {
    icon: 'icon-md-weekend',
    title: '一日宅宅',
    link: '/wfh',
    scopes: ['admin_event-admin']
  },
};