export default {
  // login: {
  //   url: '/auth/signin'
  // },
  // logout: {
  //   url: '/auth/signout'
  // },
  // scope: {
  //   list: scopes
  // },
  // admin_blur: true,
  // admin_group: true,
  // admin_role: true,
  // log: true,
}