<template>
  <div>
    <WsModelFetchShow
      ref="WsModelFetchShow"
      modelName="quotation"
      :id="$route.params.id"
      @fetched="$_onFetched($event)"
    >
    </WsModelFetchShow>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          @click="$router.go(-1)"
          outlined
        >{{ $t('cancel') }}</WsBtn>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{ $t('submit') }}</WsBtn>
      </template>
    </WsBottomNav>
    <StateQuotation v-model="state"></StateQuotation>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {}
    }
  },
  methods: {
    async $_onSubmit() {
      this.$store.dispatch("app/startPageLoading");
      try {
        await this.$o_o.$s._m.quotation.patch(this.$route.params.id, this.state)
        this.$router.go(-1)
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onFetched($event) {
      this.state = {
        ...$event
      }
    }
  }
}
</script>