import i18n from '@/i18n';

export default {
  system_setting: {
    icon: 'icon-md-settings',
    title: i18n.t('系統設定'),
    menu: [
      {
        title: i18n.t('SMTP'),
        link: '/system_setting/smtp',
        scopes: ['system_setting_smtp-admin'],
      },
      {
        title: i18n.t('SMTP 測試'),
        link: '/system_setting/smtp_mailtest',
        scopes: ['system_setting_smtp-admin'],
      },
      {
        title: i18n.t('CMS 系統設定'),
        link: '/system_setting/cms',
        scopes: ['system_setting_cms-admin'],
      },
      {
        title: i18n.t('CMS 通知設定'),
        link: '/system_setting/cms_notification',
        scopes: ['system_setting_cms-admin'],
      },
      {
        title: i18n.t('通用設定'),
        link: '/system_setting/general',
        scopes: ['system_setting_general-admin'],
      },
    ],
    scopes: [
      'system_setting_smtp-admin',
      'system_setting_cms-admin',
      'system_setting_general-admin',
    ],
  },
  website_content: {
    menu: [
      {
        title: i18n.t('Header'),
        link: '/system_setting/web/header',
        scopes: ['system_setting_web_header-admin'],
      },
      {
        title: i18n.t('Footer'),
        link: '/system_setting/web/footer',
        scopes: ['system_setting_web_footer-admin'],
      },
      {
        title: i18n.t('樣式相關'),
        link: '/system_setting/web/config',
        scopes: ['system_setting_web_config-admin'],
      },
      {
        title: i18n.t('系統資源'),
        link: '/system_setting/resource',
        scopes: ['system_setting_resource-admin'],
      },
      {
        title: i18n.t('網頁設定'),
        link: '/system_setting/web/other',
        scopes: ['system_setting_web-admin'],
      },
    ],
  },
};