<template>
  <div
    v-if="menu"
    class="ws-breadcrumbs"
    :class="[{'bottom-line':bottomLine}]"
  >
    <div
      v-for="(menuItem,menuIndex) in menu"
      :key="menuIndex"
      class="ws-breadcrumbs__item"
    >
      <WsIcon
        v-if="menuIndex>0"
        class="ws-breadcrumbs__item__arrow"
        name="icon-md-chevron-right"
      />
      <WsLink
        v-if="menuItem.to"
        :to="menuItem.to"
      >{{menuItem.text}}</WsLink>
      <WsText
        size="12"
        v-else
      >{{menuItem.text}}</WsText>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bottomLine: {
      type: Boolean,
      default: true,
    },
    menu: {
      type: Array,
      default: null,
    },
  },
};
</script>