<template>
  <div
    class="ws-content-tab"
    :class="{activiated:activiated,wide:wide}"
    :style="{'min-width':contentWidth+'px'}"
    @click.stop="onclick()"
  >
    {{ textLabel }}
    <p
      v-if="num"
      class="num"
    >
      {{ num }}
    </p>
    <p
      v-if="doneIcon"
      class="done-icon"
    >
      <i class="icon-check-circle-filled" />
    </p>
  </div>
</template>

<script>
export default {
  name: "WsContentTab",
  props: {
    doneIcon: {
      type: Boolean,
      default: false,
    },
    textLabel: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    activiated: {
      type: Boolean,
      default: false,
    },
    num: {
      type: Number,
      default: 0,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    contentWidth: {
      type: Number,
      default: 122,
    },
  },
  methods: {
    onclick() {
      this.$emit("click");
    },
  },
};
</script>