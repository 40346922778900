export default {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_friend",
  label: "好朋友",
  fields: {
    name: {
      type: 'text',
      label: '名稱',
      rules: 'required'
    },
    company_name: {
      type: 'text',
      label: '公司名稱'
    },
    title: {
      type: 'text',
      label: '職位'
    },
    tel: {
      type: 'text',
      label: '電話'
    },
    email: {
      type: 'email',
      label: '信箱'
    },
    fb: {
      type: 'text',
      label: 'Facebook'
    },
    line: {
      type: 'text',
      label: 'LINE'
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
  },
  crudSetting: {
    index: {
      showFields: [
        'name',
        'tel',
        'email',
      ],
    },
  }
}