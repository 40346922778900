import { render, staticRenderFns } from "./XcTaskCardList.vue?vue&type=template&id=59d4080e"
import script from "./XcTaskCardList.vue?vue&type=script&lang=js"
export * from "./XcTaskCardList.vue?vue&type=script&lang=js"
import style0 from "./XcTaskCardList.vue?vue&type=style&index=0&id=59d4080e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports