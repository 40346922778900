<template>
  <component :is="comTag">
    <slot
      :modeldatas="modeldatas"
      :meta="meta"
      :loading="loading.fetch"
    ></slot>
  </component>
</template>

<script>
export default {
  data() {
    return {
      loading: {
        fetch: false,
      },
      meta: null,
      modeldatas: null,
      cancelTokenSource: null,
    };
  },
  methods: {
    $_release() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
    },
    async $_fetchModel() {
      this.loading.fetch = true;
      try {
        this.$_release()
        this.cancelTokenSource = this.$axios.CancelToken.source();
        const res = await this.$axios.get(this._fetchUrl, {
          params: this._params,
          cancelToken: this.cancelTokenSource.token,
        });
        if (res.data.meta) {
          this.modeldatas = res.data.data;
          this.meta = res.data.meta;
          this.$emit("fetch", res.data.data);
        } else {
          this.modeldatas = res.data;
          this.$emit("fetch", res.data);
        }
      } catch (error) {
        if (error.__CANCEL__) {
          return;
        }
        alert(this.$o_o.$h.http.getErrorMessageFromRes(error));
      } finally {
        this.loading.fetch = false;
      }
    },
    reFetch() {
      this.$_fetchModel();
    },
    updateModeldata($event) {
      const _modeldatas = [...this.modeldatas];
      const _index = _modeldatas.findIndex((e) => {
        return e.id == $event.id;
      });
      if (_index >= 0) {
        _modeldatas.splice(_index, 1);
        _modeldatas.splice(_index, 0, $event);
      }
      this.modeldatas = _modeldatas;
    },
  },
  mounted() {
    this.$_fetchModel();
  },
  watch: {
    _params: {
      handler() {
        this.$_fetchModel();
      },
    },
  },
  computed: {
    _params() {
      let _params = {};
      if (this.params) {
        _params = {
          ..._params,
          ...this.params,
        };
        if (this.extendParams) {
          _params = {
            ..._params,
            ...this.extendParams,
          };
        }
      }
      return _params;
    },
    _fetchUrl() {
      if (this.fetchUrl) {
        return this.fetchUrl;
      } else {
        return `/${this.modelName}`;
      }
    },
  },
  props: {
    modelName: {},
    params: {},
    fetchUrl: {},
    extendParams: {},
    comTag: {
      default: "div",
    },
  },

  beforeDestroy() {
    this.$_release();
  },
};
</script>

<style>
</style>