<template>
  <div>
    <WsDialogCRUDUpdate
      ref="WsDialogCRUDUpdate"
      :title="$t('填寫客服備註')"
      :fields="fields"
      modelName="shop_order"
    ></WsDialogCRUDUpdate>
  </div>
</template>

<script>
export default {
  methods: {
    open(id) {
      this.$refs.WsDialogCRUDUpdate.open(id);
    },
  },
  data() {
    return {
      fields: {
        customer_service_remark: {
          type: "textarea",
          rules: "required",
          autofocus: true,
        },
      },
    };
  },
};
</script>

<style>
</style>