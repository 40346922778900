<template>
  <div class="ws-calendar__top-bar">
    <button @click="$emit('prev')">Prev</button>
    <div class="ws-calendar__top-bar__center">
      <slot name="center"></slot>
    </div>
    <button @click="$emit('next')">Next</button>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>