<template>
  <WsAnalyticsCard :title="title">
    <template v-slot:titleright>
      <WsStateForm
        v-model="filter"
        :fields="fields"
      ></WsStateForm>
    </template>
    <template v-slot:content>
      <WsActionApi
        :fetchUrl="fetchUrl"
        :params="_params"
        v-slot="{ res, isFetching }"
      >
        <WsLoading v-if="isFetching"></WsLoading>
        <WsText
          v-else
          size="48"
          class="py-66"
          align="center"
        >{{ $_getFormatedRes(res) }}</WsText>
      </WsActionApi>
    </template>
  </WsAnalyticsCard>
</template>

<script>
export default {
  data() {
    return {
      filter: {},
    };
  },
  methods: {
    $_getFormatedRes(res) {
      if (this.format) {
        return this.$numeral(res).format(this.format);
      } else {
        return res;
      }
    },
  },
  computed: {
    _params() {
      return {
        ...this.$o_o.$h.model.getFormatedStates(this.fields, this.filter),
        ...this.params,
      };
    },
  },
  props: {
    title: {},
    fields: {},
    fetchUrl: {},
    params: {},
    format: {},
  },
};
</script>