<template>
  <div
    class="ws-state-select"
    :class="[
      {active:active.menu},
      {'is-error':errors&&errors.length},
      _type
    ]"
    v-click-outside="$_onClickOutside"
  >
    <WsBtn
      class="w-full"
      maxWidth="unset"
      outlined
      @click="$_onOpenClick()"
    >
      <WsFlex
        class="w-full"
        justifyContent="space-between"
        alignItems="center"
      >
        <template>
          <WsText
            v-if="_hasValue"
            class="w-full"
            size="14"
          >{{_valueText}}</WsText>
          <WsDes
            v-else
            size="14"
          >{{placeholder}}</WsDes>
        </template>
        <WsIcon name="icon-md-arrow-drop-down" />
      </WsFlex>
    </WsBtn>
    <div
      class="ws-state-tags__tags"
      v-if="showTags"
    >
      <a
        class="ws-state-tags__tag"
        v-for="(_selectedItem, _selectedItemIndex) in _selectedItems"
        :key="_selectedItemIndex"
      >
        <div class="ws-state-tags__tag__text">{{$_getSelectedItemFullName(_selectedItem)}}</div>
        <WsIcon
          @click="$_onRemoveTag(_selectedItemIndex)"
          name="icon-md-cancel"
        />
      </a>
    </div>
    <div
      v-if="active.menu"
      class="ws-state-select__menu"
    >
      <div class="pa-10">
        <WsState
          type="search"
          :placeholder="`${$t('search')}...`"
          :value="searching"
          @input="$_onSearching($event)"
          autofocus
        ></WsState>
      </div>
      <div
        class="ws-state-select__options"
        @scroll="$_onOptionsScroll($event)"
      >
        <div
          v-for="(item, itemIndex) in items"
          :key="itemIndex"
        >
          <WsStateSelectOptionItem
            :items="item.items"
            :checkbox="true"
            :value="$_getItemsValue(value,item.value)"
            @click="$_onOptionItemClick(value,items,item.value)"
            :active="$_getStatus(value,items,item.value)"
            @input="$_onOptionItemInput($event,value,items,item.value)"
          >{{item.label}}</WsStateSelectOptionItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: {
        menu: false,
      },
      searching: "",
    };
  },
  methods: {
    $_onOptionsScroll($event) {
      if (
        $event.srcElement.offsetHeight +
          $event.srcElement.scrollTop +
          this.scrollCheckDis >=
        $event.srcElement.scrollHeight
      ) {
        this.$emit("scroll-to-bottom");
      }
    },
    $_getSelectedItemFullName(item) {
      let _name = item.label;
      if (item.path) {
        _name = `${_name}-${this.$_getSelectedItemFullName(item.path)}`;
      }
      return _name;
    },
    $_onRemoveTag(index) {
      const _selectedItem = this._selectedItems[index];
      const _value = this.value ? [...this.value] : [];
      this.$_removeItemFromValue(_selectedItem, _value);
      this.$emit("input", _value);
    },
    $_removeItemFromValue(path, value) {
      if (!value) {
        return;
      }
      const _target = value.find((e) => {
        return e.value == path.value;
      });
      if (!_target) {
        return;
      }
      if (path.path) {
        this.$_removeItemFromValue(path.path, _target.itemsValue);
      } else {
        const _targetIndex = value.findIndex((e) => {
          return e.value == path.value;
        });
        value.splice(_targetIndex, 1);
      }
    },
    $_onOptionItemInput($event, value, items, itemValue) {
      let _value = value ? [...value] : [];
      const valueIndex = _value.findIndex((e) => {
        if (typeof e.value === "object" && typeof itemValue === "object") {
          return e.value.id == itemValue.id;
        } else {
          return e.value == itemValue;
        }
      });
      if (valueIndex < 0) {
        _value.push({
          value: itemValue,
          itemsValue: [],
        });
      }
      const _valueItem = _value.find((e) => {
        // return e.value == itemValue;
        if (typeof e.value === "object" && typeof itemValue === "object") {
          return e.value.id == itemValue.id;
        } else {
          return e.value == itemValue;
        }
      });
      if (!$event || !$event.length) {
        const _valueIndex = _value.findIndex((e) => {
          // return e.value == itemValue;
          if (typeof e.value === "object" && typeof itemValue === "object") {
            return e.value.id == itemValue.id;
          } else {
            return e.value == itemValue;
          }
        });
        _value.splice(_valueIndex, 1);
      } else {
        _valueItem.itemsValue = $event;
      }
      this.$emit("input", _value);
    },
    $_onOptionItemClick(value, items, itemValue) {
      let _value = value ? [...value] : [];
      const status = this.$_getStatus(value, items, itemValue);
      const targetIndex = _value.findIndex((e) => {
        if (typeof e.value === "object" && typeof itemValue === "object") {
          return e.value.id == itemValue.id;
        } else {
          return e.value == itemValue;
        }
      });
      const fullValue = this.$_getFullValueFromItemValue(items, itemValue);
      if (status === true) {
        _value.splice(targetIndex, 1);
      } else if (status == "-") {
        _value.splice(targetIndex, 1, fullValue);
      } else {
        if (targetIndex >= 0) {
          _value.splice(targetIndex, 1);
        }
        _value.push(fullValue);
      }
      this.$emit("input", _value);
    },
    $_getFullValueFromItemValue(items, itemValue) {
      const _fullValue = {
        value: itemValue,
      };
      const targetItem = items.find((e) => {
        if (typeof e.value === "object" && typeof itemValue === "object") {
          return e.value.id == itemValue.id;
        } else {
          return e.value == itemValue;
        }
      });
      if (targetItem.items) {
        _fullValue.itemsValue = [];
        targetItem.items.forEach((targetItemItem) => {
          _fullValue.itemsValue.push(
            this.$_getFullValueFromItemValue(
              targetItem.items,
              targetItemItem.value
            )
          );
        });
      }
      return _fullValue;
    },
    $_getItemsValue(value, itemValue) {
      if (!value) {
        return;
      }
      const targetValue = value.find((e) => {
        if (typeof e.value === "object" && typeof itemValue === "object") {
          return e.value.id == itemValue.id;
        } else {
          return e.value == itemValue;
        }
      });
      if (!targetValue) {
        return;
      }
      return targetValue.itemsValue;
    },
    $_getStatus(value, items, itemValue) {
      if (!value) {
        return false;
      }
      const targetValue = value.find((e) => {
        if (typeof e.value === "object" && typeof itemValue === "object") {
          return e.value.id == itemValue.id;
        } else {
          return e.value == itemValue;
        }
      });
      if (!targetValue) {
        return false;
      }
      if (targetValue && items) {
        const targetItem = items.find((e) => {
          if (typeof e.value === "object" && typeof itemValue === "object") {
            return e.value.id == itemValue.id;
          } else {
            return e.value == itemValue;
          }
        });
        if (targetItem.items) {
          if (targetValue.itemsValue && targetValue.itemsValue.length) {
            let allOk = 1;
            let hasOk = 0;
            targetItem.items.forEach((item) => {
              if (
                this.$_getStatus(
                  targetValue.itemsValue,
                  targetItem.items,
                  item.value
                ) !== true
              ) {
                allOk = 0;
              } else {
                hasOk = 1;
              }
            });
            if (allOk) {
              return true;
            } else {
              if (hasOk) {
                return "-";
              } else {
                return false;
              }
            }
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    },
    $_onOpenClick() {
      this.active.menu = !this.active.menu;
    },
    $_onClickOutside() {
      this.active.menu = false;
    },
    $_getSelectedChildren(value, items) {
      if (!value) {
        return [];
      }
      const _selectedTextChildren = [];
      value.forEach((valueItem) => {
        const tarItem = items.find((e) => {
          if (
            typeof e.value === "object" &&
            typeof valueItem.value === "object"
          ) {
            return e.value.id == valueItem.value.id;
          } else {
            return e.value == valueItem.value;
          }
        });

        if (valueItem.itemsValue && tarItem.items) {
          const _selectedChildren = this.$_getSelectedChildren(
            valueItem.itemsValue,
            tarItem.items
          );
          _selectedChildren.forEach((_selectedChild) => {
            _selectedTextChildren.push({
              value: valueItem.value,
              label: tarItem.label,
              path: _selectedChild,
            });
          });
        } else {
          _selectedTextChildren.push({
            value: valueItem.value,
            label: tarItem.label,
          });
        }
      });
      return _selectedTextChildren;
    },
    $_onSearching($event) {
      this.searching = $event;
      this.$emit("search", $event);
    },
  },
  computed: {
    _selectedItems() {
      return this.$_getSelectedChildren(this.value, this.items);
    },
    _valueText() {
      if (this.value === undefined) {
        return null;
      } else {
        let _valueText = "";
        this._selectedItems.forEach((_selectedItem, _selectedItemIndex) => {
          _valueText += this.$_getSelectedItemFullName(_selectedItem);
          if (_selectedItemIndex < this._selectedItems.length - 1) {
            _valueText += ", ";
          }
        });
        return _valueText;
      }
    },
    _type() {
      if (this.light) {
        return "light";
      } else {
        return "round";
      }
    },
    _hasValue() {
      if (this.value && this.value.length) {
        return true;
      } else {
        return false;
      }
    },
  },
  props: {
    showTags: {
      type: Boolean,
      default: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: null,
    },
    placeholder: {
      type: String,
      default: "select",
    },
    items: {
      type: Array,
    },
    value: {
      type: Array,
    },
    scrollCheckDis: {
      type: Number,
      default: 100,
    },
    // searching: {
    //   type: String,
    //   default: "",
    // },
  },
};
</script>