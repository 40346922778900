import serviceAuth from '@/__stone/service/api/v1/auth'
import axios from 'axios';
import moment from 'moment';
import numeral from 'numeral';
import config from '@/__config'
import message from '@/__stone/message'
import * as Case from "change-case";
import $o_o from '@/__stone'
import $h_router from '@/__stone/helpers/router'

export default (app) => {
  app.prototype.$auth = serviceAuth;
  axios.defaults.baseURL = $h_router.getApiUrl()
  app.prototype.$axios = axios;
  app.prototype.$moment = moment;
  app.prototype.$numeral = numeral;
  app.prototype.$config = config;
  app.prototype.$message = message[config.locale.lang];
  app.prototype.$case = Case;
  app.prototype.$o_o = $o_o;
}