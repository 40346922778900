<template>
  <XcTaskIndex
    label="我的 Task"
    :showFields="showFields"
    :params="_params"
    :filterTabs="_filterTabs"
    :avaiableActions="avaiableActions"
    :filterSelects="_filterSelects"
    getUrl="/xc_task/index/my"
  ></XcTaskIndex>
</template>

<script>
export default {
  data() {
    return {
      showFields: [
        "id",
        "taker_status",
        "status",
        "due_date",
        "xc_project",
        "name",
      ],
      avaiableActions: ["tr", "done", "taker-in-progress", "taker-stay"],
    };
  },
  methods: {},
  computed: {
    _filterSelects() {
      let _filterSelects = {};
      _filterSelects = this.$o_o.$h.module.extendModuleHelperAction(
        "xc_task",
        "xc_task.js",
        "getIndexFilterSelects",
        {
          filterSelects: _filterSelects,
        }
      );
      return _filterSelects;
    },
    _params() {
      return {
        taker: this.$store.state.auth.currentUser.id,
      };
    },
    _filterTabs() {
      return [
        {
          text: "待處理",
          value: 4,
          params: {
            status: "2,4,11",
          },
        },
        {
          text: "全部",
          value: "all",
          params: {
            status: "__all",
          },
        },
      ];
    },
  },
};
</script>

<style></style>