import H_Module from './module'

export default {
  getModuleHelpers() {
    let modelesJson = []
    const moduleFiles = this.getModulesJson()
    if (!moduleFiles) {
      return
    }
    for (const moduleKey in moduleFiles) {
      if (!moduleFiles[moduleKey].helpers) {
        continue
      }
      for (const modelKey in moduleFiles[moduleKey].helpers) {
        modelesJson = {
          ...modelesJson,
          [modelKey]: moduleFiles[moduleKey].helpers[modelKey]
        }
      }
    }
    
    return modelesJson
  },
  getExtendModulesJson() {
    const modules = H_Module.getModulesContext()
    if (!modules) {
      return
    }
    const extendModuleFiles = {}
    modules.keys().forEach(modulePath => {
      const keyArr = modulePath.split('/')
      const moduleKey = keyArr[1]
      if (!extendModuleFiles[moduleKey]) {
        extendModuleFiles[moduleKey] = {}
      }
      if (!extendModuleFiles[moduleKey][keyArr[2]]) {
        extendModuleFiles[moduleKey][keyArr[2]] = {}
      }
      if (
        keyArr[2] == 'extend_modules' &&
        keyArr[4] == 'helpers'
      ) {
        const extendModuleName = keyArr[3]
        const extendModelName = keyArr[5].split('.')[0]
        if (!extendModuleFiles[moduleKey][keyArr[2]][extendModuleName]) {
          extendModuleFiles[moduleKey][keyArr[2]][extendModuleName] = {}
        }
        if (!extendModuleFiles[moduleKey][keyArr[2]][extendModuleName].helpers) {
          extendModuleFiles[moduleKey][keyArr[2]][extendModuleName].helpers = {}
        }
        extendModuleFiles[moduleKey][keyArr[2]][extendModuleName].helpers[extendModelName] = modules(modulePath).default
      }
    });
    return extendModuleFiles
  },
  getModulesJson() {
    const modules = H_Module.getModulesContext()
    if (!modules) {
      return
    }
    const moduleFiles = {}
    modules.keys().forEach(modulePath => {
      const keyArr = modulePath.split('/')
      const key = keyArr[1]
      if (!moduleFiles[key]) {
        moduleFiles[key] = {}
      }
      if (!moduleFiles[key][keyArr[2]]) {
        moduleFiles[key][keyArr[2]] = {}
      }
      if (keyArr[2] == 'helpers') {
        const modelKey = keyArr[3].split('.')[0]
        moduleFiles[key][keyArr[2]][modelKey] = modules(modulePath).default
      }
    });
    return moduleFiles
  },
}