import axios from 'axios'
export default {
  upload(
    video,
    fileName,
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get('/video/general/upload_url', {
          params: {
            name: fileName,
          }
        })
        .then(res => {
          const uploadUrl = res.data
          axios
            .put(uploadUrl, video, {
              transformRequest: [(data, headers) => {
                delete headers.common.Authorization;
                return data
              }]
            })
            .finally(() => {
              const _url = uploadUrl.split("?")[0]
              this.complete(_url)
                .then(() => {
                  resolve(_url)
                })
            })
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  complete(url) {
    return new Promise((resolve, reject) => {
      axios
        .post('/video/general/upload_complete', {
          url: url
        })
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}