var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ws-logo",style:([
    {width:`${_vm.size}px`},
    {height:`${_vm.size}px`},
  ])},[_c('img',{style:([
      {width:`${_vm.size}px`},
      {height:`${_vm.size}px`},
    ]),attrs:{"src":_vm._logo,"alt":""}})])
}
var staticRenderFns = []

export { render, staticRenderFns }