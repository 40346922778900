<template>
  <div>
    {{_value}}
  </div>
</template>

<script>
export default {
  data() {
    return {
      sufixes: ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    };
  },
  computed: {
    _value() {
      return this.$_getBytes(this.value);
    },
  },
  methods: {
    $_getBytes(bytes) {
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      return (
        (!bytes && "0 Bytes") ||
        (bytes / Math.pow(1024, i)).toFixed(2) + " " + this.sufixes[i]
      );
    },
  },
  props: {
    value: {
      type: [String, Number],
    },
  },
};
</script>

<style>
</style>