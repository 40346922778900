<template>
  <div class="ws-crud-tabs">
    <WsText
      v-for="(item, itemIndex) in items"
      :key="itemIndex"
      class="ws-crud-tab trans-02 flex-center"
      :class="{active:item.value==state}"
      @click.native="$_onItemClick(item)"
    >{{item.text}}</WsText>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: null,
    };
  },
  methods: {
    $_getParamsFromItemValue(value) {
      const _target = this.items.find((item) => {
        return item.value == value;
      });
      return _target.params;
    },
    $_getItemFromValue(value) {
      const _target = this.items.find((e) => {
        const _params = e.params;
        if (!Object.keys(_params).length) {
          return;
        }
        let check = true;
        for (const paramKey in _params) {
          if (!value[paramKey] || value[paramKey] != _params[paramKey]) {
            check = false;
          }
        }
        return check;
      });
      return _target;
    },
    $_onItemClick(item) {
      this.$_setValue(item);
    },
    $_setValue(item) {
      const _state = this.state;
      this.state = item.value;
      let _value = {
        ...this.value,
      };
      if (_state) {
        const _params = this.$_getParamsFromItemValue(_state);
        for (const _paramsKey in _params) {
          delete _value[_paramsKey];
        }
      }
      _value = {
        ..._value,
        ...item.params,
      };
      this.$emit("input", _value);
    },
    $_init() {
      const _targetItem = this.$_getItemFromValue(this.value);
      if (!_targetItem) {
        const _item = this.items[0];
        this.$_setValue(_item);
      } else {
        this.state = _targetItem.value;
      }
    },
  },
  mounted() {
    this.$_init();
  },

  props: {
    value: {},
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.ws-crud-tabs {
  display: flex;
  width: 100%;
  .ws-crud-tab {
    padding: 0 12px;
    position: relative;
    min-height: 50px;
    cursor: pointer;
    &:hover {
      &:before {
        opacity: 0.08;
      }
    }
    &:before {
      transition: all 0.2s;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      opacity: 0;
      pointer-events: none;
    }
    &:after {
      transition: all 0.2s;
      content: "";
      position: absolute;
      width: calc(100% - 24px);
      height: 2px;
      bottom: 0;
      left: 12px;
      z-index: 1;
      visibility: hidden;
      opacity: 0;
      background-color: var(--primary);
    }
    &.active {
      color: var(--primary);
      &:after {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
</style>