import H_Router from '@/__stone/helpers/router'

const routes = [
  {
    path: '/my/punch',
    name: 'my_punch',
    component: () =>
      import('@/__modules/punch/views/MyPunch.vue'),
  },
  ...H_Router.getCrudTemplateRoutes({
    routes: [
      'index',
      'read',
      'create',
      'update',
    ],
    modelName: 'punch',
    middleware: 'auth',
    requireScopes: [
      'punch-admin'
    ]
  }),
]
export default routes