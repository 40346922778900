<template>
  <div
    class="ws-state-select"
    :class="[{active:menuActive},{'is-error':errors&&errors.length},{'is-disabled':_disabled},_type]"
    v-click-outside="$_onClickOutside"
    ref="wsStateSelect"
  >
    <WsBtn
      class="w-full"
      maxWidth="unset"
      outlined
      @click="$_onOpenClick()"
    >
      <WsFlex
        class="w-full"
        justifyContent="space-between"
        alignItems="center"
      >
        <WsText
          class="w-full"
          v-if="(value||value==0)||_valueText"
          size="14"
        >{{_valueText}}</WsText>
        <WsDes
          class="w-full"
          v-else
          size="14"
        >{{placeholder}}</WsDes>
        <WsIcon
          v-if="_clearable"
          @click.stop="$_onClear()"
          name="icon-md-clear"
        />
        <WsIcon name="icon-md-arrow-drop-down" />
      </WsFlex>
    </WsBtn>
    <div
      class="ws-state-tags__tags"
      v-if="showTags"
    >
      <a
        class="ws-state-tags__tag"
        v-for="(tag, tagIndex) in value"
        :key="tagIndex"
      >
        <div class="ws-state-tags__tag__text">{{tag[textKey]}}</div>
        <WsIcon
          @click="$_onRemove(tagIndex)"
          name="icon-md-cancel"
        />
      </a>
    </div>
    <div
      v-show="menuActive"
      class="ws-state-select__menu"
      ref="wsStateSelectMenu"
    >
      <div class="pa-10">
        <WsState
          type="search"
          :placeholder="`${$t('search')}...`"
          v-model="searching"
          autofocus
        ></WsState>
      </div>
      <div
        class="ws-state-select__options"
        @scroll="$_onScroll($event)"
      >
        <a
          v-for="(item,itemIndex) in _items"
          :key="itemIndex"
          href="#"
          @click.prevent="$_onOptionClick(itemIndex)"
          :class="{active:$_activeCheck(itemIndex)}"
        >
          <div
            v-show="multiple"
            class="checkbox"
          >
            <WsIcon
              :class="{active:$_activeCheck(itemIndex)}"
              name="icon-md-check-box"
            />
            <WsIcon
              :class="{active:!$_activeCheck(itemIndex)}"
              name="icon-md-check-box-outline-blank"
            />
          </div>
          <WsText size="14">{{item.text}}</WsText>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    menuActive: false,
    loading: false,
    modelDatas: [],
    searching: "",
    searchTimeout: null,
    page: 0,
    lastPage: 100,
    fetchAll: false,
  }),
  updated() {
    this.$_setMenuPosition();
  },
  methods: {
    $_onClear() {
      this.$emit("input", null);
    },
    $_onScroll($event) {
      if (
        $event.srcElement.offsetHeight +
          $event.srcElement.scrollTop +
          this.scrollCheckDis >=
        $event.srcElement.scrollHeight
      ) {
        this.$_fetchItems();
      }
    },
    $_modelDatasReset() {
      this.modelDatas = [];
      this.page = 0;
      this.lastPage = 100;
      this.fetchAll = false;
    },
    $_fetchPrepare() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.$_modelDatasReset();
        this.$_fetchItems();
      }, 200);
    },
    async $_fetchItems() {
      if (this.loading || this.page >= this.lastPage || this.fetchAll) {
        return;
      }
      this.loading = true;
      this.page++;
      let params = {
        search: this.searching,
        page: this.page,
        // order_by: "updated_at",
        // order_way: "desc",
      };
      if (this.conditions) {
        this.conditions.forEach((condition) => {
          if (
            condition.type &&
            condition.type == "array" &&
            condition.value &&
            Array.isArray(condition.value)
          ) {
            params[condition.key] = condition.value.join();
          } else {
            params[condition.key] = condition.value;
          }
        });
      }
      if (this.extendParams) {
        params = {
          ...params,
          ...this.extendParams,
        };
      }
      try {
        const res = await this.$axios.get(this._fetchUrl, {
          params: params,
        });
        if (res.data.meta) {
          this.lastPage = res.data.meta.last_page;
        } else {
          this.fetchAll = true;
        }
        this.modelDatas.push(...res.data.data);
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    $_onOpenClick() {
      this.menuActive = !this.menuActive;
    },
    $_activeCheck(itemIndex) {
      if (!this.value) {
        return;
      }
      if (this.multiple) {
        const _target = this.modelDatas[itemIndex];
        return this.value.find((e) => {
          return e[this.idKey] == _target[this.idKey];
        });
      } else {
        return this.value;
      }
    },
    $_onOptionClick(itemIndex) {
      if (this.multiple) {
        const _value = this.value ? [...this.value] : [];
        const _target = this.modelDatas[itemIndex];
        const _targetIndex = _value.findIndex((e) => {
          return e[this.idKey] == _target[this.idKey];
        });
        if (_targetIndex >= 0) {
          _value.splice(_targetIndex, 1);
        } else {
          _value.push(_target);
        }
        this.$emit("input", _value);
      } else {
        const _item = this._items[itemIndex];
        if (_item.value) {
          const _modelData = this.modelDatas.find((e) => {
            return e.id == _item.value;
          });
          this.$emit("input", _modelData);
        } else {
          this.$emit("input", _item.value);
        }

        this.menuActive = false;
      }
    },
    $_onClickOutside() {
      this.menuActive = false;
    },
    $_onRemove(index) {
      const _value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      _value.splice(index, 1);
      this.$emit("input", _value);
    },
    $_setMenuPosition() {
      if (!this.menuActive) return;
      if (
        this.$refs.wsStateSelect.getBoundingClientRect().bottom +
          this.$refs.wsStateSelectMenu.clientHeight >=
        screen.availHeight
      ) {
        // "原本top的數值 - 此input的高 - 選單高度 px"
        this.$refs.wsStateSelectMenu.style.top = `${
          45 - 56 - this.$refs.wsStateSelectMenu.clientHeight
        }px`;
      } else {
        this.$refs.wsStateSelectMenu.style.top = `${45}px`;
      }
    },
    $_clear() {
      this.modelDatas = [];
      this.page = 0;
      this.dataTotalCount = 0;
      this.lastPage = 1;
      this.fetchAll = false;
    },
    $_reset() {
      this.$_clear();
      setTimeout(() => {
        this.$_fetchItems();
      }, 0);
    },
  },
  computed: {
    _fetchUrl() {
      if (this.fetchUrl) {
        return this.fetchUrl;
      } else if (this.parentState && this.parent) {
        return `/${this.parent}/${this.parentState}/${this.modelName}`;
      } else {
        return `/${this.modelName}`;
      }
    },
    _clearable() {
      if (this.optionAll && !this.multiple) {
        return false;
      } else {
        return this.clearable;
      }
    },
    _extendParams() {
      if (this.extendParams) {
        return {
          ...this.extendParams,
        };
      } else {
        return null;
      }
    },
    _items() {
      if (this.items) {
        return this.items;
      } else {
        const _items = [];
        if (this.optionAll && !this.multiple) {
          _items.push({
            text: "全部",
            value: null,
          });
        }
        this.modelDatas.forEach((modelData) => {
          const _itemData = { value: modelData[this.idKey] };
          _itemData.text = this.getText
            ? this.getText(modelData)
            : modelData[this.textKey];
          _items.push(_itemData);
        });
        return _items;
      }
    },
    _type() {
      if (this.light) {
        return "light";
      } else {
        return "round";
      }
    },
    _disabled() {
      if (this.disabled) {
        return true;
      } else if (this.parent && !this.parentState) {
        return true;
      } else if (
        this.requiredField &&
        (!this.requiredFieldState || this.requiredFieldState.length == 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    _valueText() {
      if (this.value === undefined || this.value == null) {
        if (this.optionAll) {
          return "全部";
        } else {
          return null;
        }
      } else {
        if (this.multiple) {
          let _valueText = "";
          this.value.forEach((valueItem) => {
            if (_valueText) {
              if (this.getText) {
                _valueText += `, ${this.getText(valueItem)}`;
              } else {
                _valueText += `, ${valueItem[this.textKey]}`;
              }
            } else {
              if (this.getText) {
                _valueText += this.getText(valueItem);
              } else {
                _valueText += valueItem[this.textKey];
              }
            }
          });
          return _valueText;
        } else {
          if (this.getText) {
            return this.getText(this.value);
          } else {
            return this.value[this.textKey];
          }
        }
      }
    },
  },
  mounted() {
    this.$_fetchItems();
  },
  watch: {
    extendParams: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.$_modelDatasReset();
          this.$_fetchItems();
          if (this.multiple) {
            this.$emit("input", []);
          } else {
            this.$emit("input", null);
          }
        }
      },
    },
    fetchUrl: {
      handler() {
        this.$_reset();
      },
    },
    searching: {
      handler() {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.$_reset();
        }, 500);
      },
    },
  },
  props: {
    optionAll: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    extendParams: {
      type: Object,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    menuBackgroundColor: {
      type: String,
    },
    light: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "select",
    },
    value: {
      type: [Object, Array],
      default: null,
    },
    items: {
      type: Array,
    },
    requiredField: {
      default: null,
    },
    requiredFieldState: {
      default: null,
    },
    modelName: {
      type: String,
    },
    textKey: {
      type: String,
      default: "name",
    },
    idKey: {
      type: String,
      default: "id",
    },
    scrollCheckDis: {
      type: Number,
      default: 100,
    },
    conditions: {
      type: Array,
    },
    getText: {
      type: Function,
    },
    showTags: {
      type: Boolean,
      default: false,
    },
    fetchUrl: {},
  },
};
</script>