<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.stone_model.system_event.modelName"
      label="額外休息日"
      :fields="_fields"
      :paramsOnCrud="_paramsOnCrud"
      :showFields="showFields"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      showFields: ["start_date", "name"],
    };
  },
  computed: {
    _paramsOnCrud() {
      return {
        type: "additional_off_day",
      };
    },
    _fields() {
      return this.$o_o.$h.model.getFieldsByKeys(
        this.$store.state.stone_model.system_event.fields,
        ["id", "name", "start_date", "days", "note"]
      );
    },
  },
};
</script>