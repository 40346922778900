<template>
  <WsMain v-if="isMounted">
    <WsInfoForm
      :fields="fields"
      v-model="state.content"
    ></WsInfoForm>
    <WsBtn
      @click="$_onCheck()"
      class="mt-20"
    >檢查更新</WsBtn>
  </WsMain>
</template>

<script>
import S_SystemInfo from "@/__modules/system/services/system_info";
export default {
  data() {
    return {
      isMounted: false,
      state: {
        content: {},
      },
      fields: {
        version_web: {
          label: this.$t("前台網站版本"),
        },
        version_api: {
          label: this.$t("前台 API 版本"),
        },
        version_cms: {
          label: this.$t("後台版本"),
        },
        version_cms_api: {
          label: this.$t("後台 API 版本"),
        },
      },
    };
  },
  methods: {
    async $_onCheck() {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.system_version.check_and_update_version();
        await this.$_valueSet()
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_valueSet() {
      const res = await S_SystemInfo.show();
      this.state.content = res.content;
      this.isMounted = true;
    },
  },
  mounted() {
    this.$_valueSet();
  },
};
</script>

<style>
</style>