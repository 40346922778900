<template>
  <WsMain>
    <WsCrud
      :label="label"
      :modelName="belongsToManyModelName"
      :fields="belongsToManyFields"
      :showFields="belongsToManyShowFields"
      :infiniteScroll="true"
      :showDataTotalCount="false"
      :inRowBtnDelete="false"
      :inRowBtnUpdate="false"
      :inRowBtnRead="true"
      :showExpand="false"
      :expandable="false"
      :filterUniqueSection="true"
      :deletable="false"
      :creatable="false"
      :selectable="false"
      :params="_params"
      :titleBarCustomBtns="_titleBarCustomBtns"
      @title-bar-custom-btn-click="$_titleBarCustomBtnOnClick($event)"
      :value="state.selected"
      @input="$_onInputCrud($event)"
    >
    </WsCrud>
    <WsModelPickerDialog
      ref="indexDialog"
      :modelName="belongsToManyModelName"
      :fields="belongsToManyFields"
      :showFields="belongsToManyPickShowFields"
      valueSync
      :filterSelects="belongsToManyPickFilterSelects"
      :value="state.selected"
      @submit="$_onSubmitPicker($event)"
    ></WsModelPickerDialog>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      loading: {
        fetch: false,
      },
      state: {
        // modeldatas: [],
        selected: [],
      },
    };
  },
  methods: {
    $_onInputCrud($event) {
      // this.state. = $event;
      this.state.selected = $event;
    },
    async $_onSubmitPicker($event) {
      this.state.selected = $event;
      if (!this.state.selected || !this.state.selected.length) {
        return;
      }
      const _ids = this.state.selected.map((item) => item.id);
      await this.$axios.patch(`/${this.modelName}/${this.id}`, {
        [this._belongsToManyKey]: _ids,
      });
      this.$refs.indexDialog.close();
    },
    $_titleBarCustomBtnOnClick($event) {
      if ($event === "update-btn-click") {
        this.$refs.indexDialog.open();
      }
    },
  },
  computed: {
    _paramsKey() {
      if (this.paramsKey) {
        return this.paramsKey;
      } else {
        return `${this.modelName}s`;
      }
    },
    _belongsToManyKey() {
      if (this.belongsToManyKey) {
        return this.belongsToManyKey;
      } else {
        return `${this.belongsToManyModelName}s`;
      }
    },
    _params() {
      return {
        [this._paramsKey]: this.id,
        getall: 1,
      };
    },
    _titleBarCustomBtns() {
      return [
        {
          label: this.updateBtnLabel,
          event: "update-btn-click",
        },
      ];
    },
  },
  props: {
    belongsToManyModelName: {},
    belongsToManyFields: {},
    belongsToManyShowFields: {},
    belongsToManyPickShowFields: {},
    belongsToManyPickFilterSelects: {},
    belongsToManyKey: {},
    modelName: {
      type: String,
    },
    paramsKey: {
      type: String,
    },
    id: {},
    updateBtnLabel: {
      type: String,
    },
    label: {
      type: String,
    },
  },
};
</script>

<style>
</style>