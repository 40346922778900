<template>
  <a class="ws-tag">
    <slot></slot>
  </a>
</template>

<script>
export default {
  computed: {},
};
</script>