<template>
  <div>
    <WsDialogUpdate
      ref="WsDialogUpdate"
      @close="$_onCancel()"
      @submit="$_onSubmit()"
      :contentHasPadding="false"
      :title="$t('pick_image')"
    >
      <template v-slot:content>
        <WsTabs
          v-model="focusTab"
          :items="_tabItems"
        ></WsTabs>
        <FileSystemFilePickerImageUpload
          v-if="focusTab == 'upload'"
          ref="FileSystemFilePickerImageUpload"
          :storageTarget="storageTarget"
          @submit="$_onInputSubmit($event)"
          :signed="signed"
          :multiple="multiple"
        ></FileSystemFilePickerImageUpload>
        <FileSystemFilePickerImagePocket
          v-if="focusTab == 'pocket'"
          ref="FileSystemFilePickerImagePocket"
          :storageTarget="storageTarget"
          :signed="signed"
          :multiple="multiple"
          @submit="$_onInputSubmit($event)"
        ></FileSystemFilePickerImagePocket>
      </template>
    </WsDialogUpdate>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      focusTab: "upload",
    };
  },
  mounted() {
    this.focusTab = this._tabItems[0].value;
  },
  methods: {
    $_onInputSubmit($event) {
      if (this.multiple) {
        // if (this.focusTab == "pocket") {
        //   this.$emit("submit", $event);
        // } else if (this.focusTab == 'upload') {]);
        // }
        this.$emit("submit", $event);
      } else {
        this.$emit("submit", $event);
      }
      this.close();
    },
    close() {
      this.$refs.WsDialogUpdate.close()
    },
    open() {
      this.$refs.WsDialogUpdate.open()
    },
    $_onDialogClose() {
      this.close();
    },
    $_onCancel() {
    },
    async $_onSubmit() {
      this.$store.dispatch("app/startPageLoading");
      try {
        if (this.focusTab == "pocket") {
          this.$refs.FileSystemFilePickerImagePocket.submit();
        } else if (this.focusTab == "upload") {
          await this.$refs.FileSystemFilePickerImageUpload.submit();
        }
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _getUploadUrl() {
      if (this.getUploadUrl) {
        return this.getUploadUrl
      } else {
        return `/image/${this.storageTarget}/upload_url`
      }
    },
    _tabItems() {
      let _arr = [];
      if (this.uploadable) {
        _arr.push({
          text: '上傳檔案',
          value: "upload",
        });
      }
      if (this.pocketable) {
        _arr.push({
          text: '文件庫',
          value: "pocket",
        });
      }
      // if (this.linkable) {
      //   _arr.push({
      //     text: this.$t("source"),
      //     value: "link",
      //   });
      // }
      return _arr;
    },
  },
  props: {
    multiple: {
      type: Boolean,
    },
    signed: {
      type: Boolean,
      default: false,
    },
    getUploadUrl: {
      type: String,
    },
    pocketable: {
      type: Boolean,
      default: true,
    },
    uploadable: {
      type: Boolean,
      default: true,
    },
    storageTarget: {
      default: "general"
    }
    // linkable: {
    //   type: Boolean,
    //   default: true,
    // },
  },
};
</script>

<style></style>