export default {
  getSystemSettingCmsNotificationFields(data, res) {
    let _res
    if (res) {
      _res = res
    } else {
      _res = {}
    }
    _res = {
      ..._res,
      notify_slack_channel_id_off_day: {
        type: "text",
        label: "請假通知 Slack Channel ID",
      },
      notify_slack_channel_id_wfh: {
        type: "text",
        label: "WFH 通知 Slack Channel ID",
      }
    }
    return _res
  },
  getSystemSettingCmsNotificationShowFields(data, res) {
    let _res
    if (res) {
      _res = res
    } else {
      _res = []
    }
    _res = [
      ..._res,
      "notify_slack_channel_id_off_day",
      "notify_slack_channel_id_wfh"
    ]
    return _res
  }
}