<template>
  <div class="ws-icon-btn-image" v-tooltip.top-center="tooltip">
    <WsIconBtn
      @click="$_onClickBtn()"
      name="icon-md-image"
      :noBorder="noBorder"
    >
    </WsIconBtn>
    <input
      class="hidden-el"
      @change="$_onChange($event)"
      type="file"
      ref="fileInput"
      accept="image/*"
    >
  </div>
</template>

<script>
export default {
  props: {
    noBorder: {},
    tooltip: {
      type: String,
    },
  },
  methods: {
    async $_onChange($event) {
      this.$emit("change", $event);
    },
    $_onClickBtn() {
      this.$refs.fileInput.click();
    },
  },
};
</script>