import initColor from '@/__stone/init/color'
import initComponent from '@/__stone/init/component'
import initProperty from '@/__stone/init/property'
import initPlugin from '@/__stone/init/plugin'
export default {
  install: (app) => {
    initColor(app)
    initComponent(app)
    initPlugin(app)
    initProperty(app)
  },
}