import i18n from '@/i18n';
const model = {
  options: {
    site_region: true,
    timestamp: true,
  },
  modelName: "cms_log",
  label:i18n.t( "後台使用者紀錄"),
  fields: {
    admin: {
      type: "belongsTo",
      label: i18n.t("後台使用者"),
      // relationPopup: true,
      textKey: "name",
      modelName: "admin"
    },
    'action': {
      type: 'text',
      label: i18n.t('行為'),
      readonly: true
    },
    'target': {
      type: 'text',
      label:i18n.t('目標'),
      width: '200px',
      readonly: true
    },
    'target_id': {
      type: 'text',
      label: i18n.t('目標ID'),
      readonly: true
    },
    'ip': {
      type: 'text',
      label: 'IP',
      width: '200px',
      readonly: true
    },
    'payload.remark': {
      type: 'text',
      label: i18n.t('備註'),
      width: '200px',
      readonly: true
    },
  },
  crudSetting: {
    index: {
      creatable: false,
      updatable: false,
      inRowBtnRead: false,
      deletable: false,
      showFields: [
        "admin",
        "action",
        "created_at",
        "target",
        "target_id",
        "ip",
      ],
    }
  }
}

export default model