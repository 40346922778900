<template>
  <WsModelCalendar
    ref="WsModelCalendar"
    v-bind="$props"
    @drop="$emit('drop', $event)"
    @eventClick="$_onEventClick($event)"
    @eventDragStop="$emit('eventDragStop', $event)"
    @eventDrop="$_onEventDrop($event)"
    @datesSet="$_onDateSet($event)"
    :events="_calendarEvents"
    @eventRemove="$_onEventRemove($event)"
    @onEventDropExtendModelsdatas="$emit('onEventDropExtendModelsdatas', $event)"
    :extendModelsdatas="extendModelsdatas"
    :additionalOnDays="_additionalOnDays"
    :additionalOffDays="_additionalOffDays"
    :modelSettings="_modelSettings"
    :hasToolbar="hasToolbar"
    :params="params"
  >
  </WsModelCalendar>
</template>

<script>
export default {
  data() {
    return {
      currentDateRange: {
        start: null,
        end: null,
      },
      fetchedDateRange: {
        start: null,
        end: null,
      },
      // admin_events: [],
      system_events: [],
      dialogRead: {
        currentModelName: "",
      },
    };
  },
  methods: {
    prev() {
      this.$refs.WsModelCalendar.prev();
    },
    next() {
      this.$refs.WsModelCalendar.next();
    },
    goDate($event) {
      this.$refs.WsModelCalendar.goDate($event);
    },
    changeView($event) {
      this.$refs.WsModelCalendar.changeView($event);
    },
    $_onEventClick($event) {
      const _modelName = $event.event.extendedProps?.modelName;
      if (!_modelName) {
        return;
      }
      this.dialogRead.currentModelName = $event.event.extendedProps?.modelName;
      setTimeout(() => {
        this.$refs.WsDialogCRUDRead.open($event.event.extendedProps.id);
      }, 0);
    },
    $_onEventDrop($event) {
      const _$event = {
        ...$event,
        cms: {
          additionalOnDays: this._additionalOnDays,
          additionalOffDays: this._additionalOffDays,
        },
      };
      this.$emit("eventDrop", _$event);
    },
    $_onEventRemove($event) {
      this.$emit("eventRemove", $event);
    },
    $_onDateSet($event) {
      this.$_setCurrentDateRange();
      this.$_setFetchedDateRangeFromCalendar();
      this.$emit("datesSet", $event);
    },
    $_getView() {
      return this.$refs.WsModelCalendar.getView();
    },
    $_setCurrentDateRange() {
      const _calendarView = this.$refs.WsModelCalendar.getView();
      this.currentDateRange = {
        start: this.$moment(_calendarView.currentStart).format("YYYY-MM-DD"),
        end: this.$moment(_calendarView.currentEnd).format("YYYY-MM-DD"),
      };
    },
    $_init() {
      this.$_setCurrentDateRange();
      this.$_setFetchedDateRangeFromCalendar();
      this.$_fetchCmsEvents(
        this.fetchedDateRange.start,
        this.fetchedDateRange.end
      );
    },
    $_setFetchedDateRangeFromCalendar() {
      const _calendarView = this.$refs.WsModelCalendar.getView();
      const start = this.$moment(_calendarView.currentStart).format(
        "YYYY-MM-DD"
      );
      const end = this.$moment(_calendarView.currentEnd).format("YYYY-MM-DD");
      if (!this.fetchedDateRange.start || this.fetchedDateRange.start > start) {
        this.fetchedDateRange.start = start;
      }
      if (!this.fetchedDateRange.end || this.fetchedDateRange.end < end) {
        this.fetchedDateRange.end = end;
      }
    },
    $_fetchCmsEvents(start, end) {
      this.$_systemEventSet(start, end);
    },
    async $_systemEventSet(start, end) {
      const res = await this.$o_o.$s._m.system_event.index_date(start, end);
      this.system_events.push(...res);
    },
  },
  computed: {
    _additionalOnDays() {
      return this.$o_o.$h._m.system_event.getDateArrFromSystemEvents(
        this.system_events,
        "additional_on_day"
      );
    },
    _additionalOffDays() {
      return this.$o_o.$h._m.system_event.getDateArrFromSystemEvents(
        this.system_events,
        "additional_off_day"
      );
    },
    _systemCalendarEvents() {
      const _systemCalendarEvents = [];
      this.system_events.forEach((system_event) => {
        _systemCalendarEvents.push(
          this.$o_o.$h._m.system_event.toCalendarEvent(system_event, 0.3)
        );
      });
      return _systemCalendarEvents;
    },
    _calendarEvents() {
      return [...this._systemCalendarEvents, ...this.events];
    },
    _dialogReadFields() {
      if (!this.dialogRead.currentModelName) {
        return {};
      }
      return this.$store.state.stone_model[this.dialogRead.currentModelName]
        .fields;
    },
    _dialogReadShowFields() {
      if (!this.dialogRead.currentModelName) {
        return [];
      }
      return this.dialogRead.showFields[this.dialogRead.currentModelName];
    },
    _modelSettings() {
      if (this.modelSettings) {
        return this.modelSettings;
      } else {
        return {
          xc_task: {
            onChange: (xc_task, $event) => {
              if (!$event.event.allDay) {
                if (xc_task.hour) {
                  this.$o_o.$s._m.xc_task.start_at(
                    xc_task.id,
                    $event.event.start
                  );
                }
              } else if ($event.event.allDay) {
                this.$o_o.$s._m.xc_task.due_date(
                  xc_task.id,
                  $event.event.start
                );
              }
            },
            updateModeldataOnEventDrop: (xc_task, $event) => {
              if (!$event.event.allDay) {
                if (!xc_task.hour) {
                  this.$store.dispatch(
                    "app/addSnack",
                    `排入時刻之任務需有預計執行時間`
                  );
                  return xc_task;
                } else {
                  const _xc_task = {
                    ...xc_task,
                    start_at: $event.event.start,
                    due_date: this.$moment($event.event.start).format(
                      "YYYY-MM-DD"
                    ),
                  };
                  return _xc_task;
                }
              } else if ($event.event.allDay) {
                const _xc_task = {
                  ...xc_task,
                  due_date: this.$moment($event.event.start).format(
                    "YYYY-MM-DD"
                  ),
                };
                return _xc_task;
              }
            },
            readDialog: {
              showFields: [
                "name",
                "due_date",
                "start_at",
                "reviewed_at",
                "status",
                "hour",
                "finish_hour",
                "time_review_at",
                "xc_project",
                "creator",
                "taker",
                "content",
              ],
            },
            pageReadRedirect: true,
          },
          xc_meeting: {
            readDialog: {
              showFields: ["start_at", "end_at", "name", "note", "members"],
            },
          },
          admin_event: {
            readDialog: {
              showFields: ["admin", "start_date", "days"],
            },
            index: {
              params: {
                type: "off_day_break,off_day_bereave,off_day_something,off_day_ill",
              },
            },
          },
        };
      }
    },
  },
  mounted() {
    this.$_init();
  },
  watch: {
    "fetchedDateRange.start": {
      handler(newVal, oldVal) {
        if (!oldVal) {
          return;
        }
        this.$_fetchCmsEvents(
          newVal,
          this.$moment(oldVal, "YYYY-MM-DD").add(-1, "day").format("YYYY-MM-DD")
        );
      },
    },
    "fetchedDateRange.end": {
      handler(newVal, oldVal) {
        if (!oldVal) {
          return;
        }
        this.$_fetchCmsEvents(
          this.$moment(oldVal, "YYYY-MM-DD")
            .add(+1, "day")
            .format("YYYY-MM-DD"),
          newVal
        );
      },
    },
  },
  props: {
    extendModelsdatas: {},
    modelSettings: {},
    height: {},
    droppable: {},
    editable: {},
    events: {
      default() {
        return [];
      },
    },
    hasToolbar: {},
    params: {},
  },
};
</script>