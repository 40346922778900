import H_Router from '@/__stone/helpers/router'

export default [
  {
    path: '/drive',
    name: 'drive',
    component: () =>
      import('@/__modules/file_system/views/Drive/Index.vue'),
  },
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'file',
    middleware: 'auth',
    meta: {
      requiredScopes: ['file_system-admin'],
    },
  }),
  ...H_Router.getResourceRoutes({
    routes: ['create'],
    modelName: 'file',
    viewsPath: '__modules/file_system/views',
    meta: {
      middleware: 'auth',
      requiredScopes: [
        'file_system-admin',
      ],
    },
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'image',
    middleware: 'auth',
    meta: {
      requiredScopes: ['file_system-admin'],
    },
  }),
  ...H_Router.getResourceRoutes({
    routes: ['create'],
    modelName: 'image',
    viewsPath: '__modules/file_system/views',
    meta: {
      middleware: 'auth',
      requiredScopes: [
        'file_system-admin',
      ],
    },
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'video',
    middleware: 'auth',
    meta: {
      requiredScopes: ['file_system-admin'],
    },
  }),
  ...H_Router.getResourceRoutes({
    routes: ['create'],
    modelName: 'video',
    viewsPath: '__modules/file_system/views',
    meta: {
      middleware: 'auth',
      requiredScopes: [
        'file_system-admin',
      ],
    },
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'audio',
    middleware: 'auth',
    meta: {
      requiredScopes: ['file_system-admin'],
    },
  }),
  ...H_Router.getResourceRoutes({
    routes: ['create'],
    modelName: 'audio',
    viewsPath: '__modules/file_system/views',
    meta: {
      middleware: 'auth',
      requiredScopes: [
        'file_system-admin',
      ],
    },
  }),
]