<template>
  <WsIconBtn
    class="ma-3"
    :noRadius="true"
    :size="32"
    v-bind="$props"
  >
    <slot></slot>
  </WsIconBtn>
</template>
<script>
export default {
  props: {
    tooltip: {},
    name: {},
    text: {},
  },
};
</script>