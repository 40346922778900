<template>
  <div class="ws-group-state-order">
    <WsStateSelect
      :value="state.order"
      :items="items"
      @input="$_onInputOrder($event)"
    ></WsStateSelect>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {
        order: null,
      },
    };
  },
  methods: {
    $_onInputOrder($event) {
      const _item = this.$_getOrderItemFromItemValue($event);
      this.$_setOrderValueFromItem(_item);
    },
    $_getOrderItemFromItemValue(value) {
      return this.items.find((e) => {
        return e.value == value;
      });
    },
    $_getOrderItemFromValue(value) {
      const _target = this.items.find((e) => {
        return e.order_by == value.order_by && e.order_way == value.order_way;
      });
      return _target;
    },
    $_getOrderSettingsFromItemValue(itemValue) {
      const _target = this.$_getOrderItemFromItemValue(itemValue);
      if (!_target) {
        return {};
      } else {
        return {
          order_by: _target.order_by,
          order_way: _target.order_way,
        };
      }
    },
    $_setOrderValueFromItem(item) {
      this.state.order = item.value;
      const _orderSettings = this.$_getOrderSettingsFromItemValue(item.value);
      let _value = {
        ...this.value,
        ..._orderSettings,
      };
      this.$emit("input", _value);
    },
    $_init() {
      const _targetItem = this.$_getOrderItemFromValue(this.value);
      if (!_targetItem || (!_targetItem.order_by && !_targetItem.order_way)) {
        const _item = this.items[0];
        this.$_setOrderValueFromItem(_item);
      } else {
        this.state.order = _targetItem.value;
      }
    },
  },
  mounted() {
    this.$_init();
  },
  props: {
    value: {},
    items: {},
  },
};
</script>