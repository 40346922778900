import axios from 'axios'

export default {
  upload({
    file,
    fileName,
    getUploadUrl,
  }) {
    return new Promise((resolve, reject) => {
      axios
        .get(getUploadUrl, {
          params: {
            name: fileName,
          }
        })
        .then(res => {
          const uploadUrl = res.data
          axios
            .put(uploadUrl, file, {
              transformRequest: [(data, headers) => {
                delete headers.common.Authorization;
                return data
              }]
            })
            .finally(() => {
              const _url = uploadUrl.split("?")[0]
              resolve(_url)
            })
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}