export default {
  $_returnMyBoardComponent() {
    return [
      {
        keepLocation: 'left', 
        componentName: 'OffDayFunctionBoard',
        sq: 1
      },
      {
        keepLocation: 'left', 
        componentName: 'OffDayBoard',
        sq: 2
      },
      {
        keepLocation: 'right', 
        componentName: 'NewHireApprovalBoard',
        sq: 1
      },
      {
        keepLocation: 'right', 
        componentName: 'RenewalApprovalBoard',
        sq: 2
      },
      {
        keepLocation: 'right', 
        componentName: 'OffDayApprovalBoard',
        sq: 3
      },
      {
        keepLocation: 'right', 
        componentName: 'OvertimeApprovalBoard',
        sq: 4
      },
      {
        keepLocation: 'right', 
        componentName: 'OnBusinessApprovalBoard',
        sq: 5
      },
      {
        keepLocation: 'right', 
        componentName: 'MyOffDayBoard',
        sq: 6
      },
      {
        keepLocation: 'right', 
        componentName: 'MyOvertimeBoard',
        sq: 7
      },
    ]
  }
}
