const routes = [
  {
    path: '/additional_on_day',
    name: 'additional_on_day',
    component: () => import('@/__modules/system_event/views/SystemAdditionalOnDay/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['system_event-admin'],
    }
  },
  {
    path: '/additional_off_day',
    name: 'additional_off_day',
    component: () => import('@/__modules/system_event/views/SystemAdditionalOffDay/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['system_event-admin'],
    }
  },
]

export default routes