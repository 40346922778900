<template>
  <WsStateSelect
    :items="_items"
    :value="value"
    @input="$emit('input',$event)"
  ></WsStateSelect>
</template>

<script>
export default {
  name: "WsStateYear",
  computed: {
    _items() {
      const _items = [];
      for (let index = this._range[1]; index >= this._range[0]; index--) {
        _items.push({
          text: index,
          value: index,
        });
      }
      return _items;
    },
    _range() {
      if (this.range) {
        return this.range;
      } else {
        return [this.$moment().year() - 5, this.$moment().year() + 5];
      }
    },
  },
  props: {
    value: {
      type: [String, Number],
    },
    range: {
      type: Array,
    },
  },
};
</script>

<style>
</style>