<template>
  <WsAnalyticsCard :title="title">
    <template v-slot:titleright>
      <WsStateSelect
        light
        class="flex-0"
        v-model="state.range"
        :items="rangeItems"
      ></WsStateSelect>
    </template>
    <template v-slot:content>
      <WsText
        size="1"
        class="py-66"
        center
      >{{_sum}}</WsText>
    </template>
  </WsAnalyticsCard>
</template>

<script>
export default {
  data: () => ({
    modelDatas: [],
    state: {
      range: "thismonth",
    },
    rangeItems: [
      {
        text: "本月份",
        value: "thismonth",
      },
      {
        text: "今年",
        value: "thisyear",
      },
      {
        text: "近7天",
        value: 7,
      },
      {
        text: "近30天",
        value: 30,
      },
      {
        text: "近90天",
        value: 90,
      },
    ],
  }),

  methods: {
    $_stateSet() {
      this.state.range = this.range;
    },
    async $_fetchDatas() {
      const params = {
        timeField: this.dateField,
        start_time: this._startTime,
        end_time: this._endTime,
        order_by: this.orderBy,
        order_way: this.orderWay,
      };
      const res = await this.$axios.get(`${this.modelName}/index/all`, {
        params: params,
      });
      this.modelDatas = res.data.data;
    },
  },

  computed: {
    _sum() {
      let _sum = 0;
      this.modelDatas.forEach((modelData) => {
        _sum += Math.round(modelData[this.countField]);
      });
      if (this.currency) {
        _sum = this.$numeral(_sum).format("$0,0");
      }
      return _sum;
    },
    _startTime() {
      let _startTime = this.$moment();
      if (this.state.range == "thismonth") {
        _startTime = _startTime.startOf("month");
      } else if (this.state.range == "thisyear") {
        _startTime = _startTime.startOf("year");
      } else if (Number.isInteger(this.state.range)) {
        _startTime = _startTime.add(this.state.range * -1, "days");
      }
      return _startTime.format("YYYY-MM-DD");
    },
    _endTime() {
      return this.$moment().format("YYYY-MM-DD");
    },
  },

  watch: {
    "state.range": {
      handler() {
        this.$_fetchDatas();
      },
    },
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    range: {
      type: String,
      default: "thismonth",
    },
    unitPreText: {
      type: String,
      default: null,
    },
    modelName: {
      type: String,
      default: null,
    },
    dateField: {
      type: String,
      default: "created_at",
    },
    countField: {
      type: String,
      default: "count",
    },
    orderBy: {
      type: String,
      default: "created_at",
    },
    orderWay: {
      type: String,
      default: "desc",
    },
    currency: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.$_stateSet();
    this.$_fetchDatas();
  },
};
</script>

<style>
</style>