export default {
  options: {
    id: true,
    timestamp: true,
    tags: true,
  },
  modelName: "xc_rfq",
  label: "RFQ",
  fields: {
    status: {
      type: 'radio',
      label: '狀態',
      defaultValue: 'negotiating',
      items: [
        {
          value: 'negotiating',
          label: '洽談中'
        },
        {
          value: 'quoting',
          label: '報價中'
        },
        {
          value: 'readytosign',
          label: '準備簽約'
        },
        {
          value: 'deal',
          label: '成交'
        },
        {
          value: 'endtracking',
          label: '結束追蹤'
        },
      ]
    },
    title: {
      type: 'text',
      label: '需求主旨',
      rules: 'required'
    },
    request_content: {
      type: 'editor',
      label: '需求說明',
    },
    attachments: {
      type: 'file_files',
      label: '附件',
      storageTarget: 'xc_rfq'
    },
    budget: {
      type: 'text',
      label: '預算',
    },
    contact_at: {
      type: 'datetime',
      label: '聯絡時間',
    },
    contract_links: {
      type: 'list',
      label: '合約連結',
      fields: {
        name: {
          type: 'text',
          label: '名稱',
          rules: 'required'
        },
        link: {
          type: 'link',
          label: '連結',
          rules: 'required'
        },
        remark: {
          type: 'textarea',
          label: '備註',
        },
      },
    },
    source: {
      type: 'text',
      label: '來源',
    },
    source_domain: {
      type: 'text',
      label: '來源網域',
    }
  },
  crudSetting: {
    index: {
      pageMode: true,
      showFields: [
        'status',
        'title',
        'budget',
        'contact_at',
      ],
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'status',
                'title',
                'request_content',
                'attachments',
                'budget',
                'contact_at',
                'contract_links',
              ],
            },
          ],
        },
      ],
    },
    update: {
      submitToIndex: true,
      titleKey: 'title',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "status",
                "title",
                "request_content",
                "attachments",
                "budget",
                "contact_at",
                "contract_links",
              ],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: [
        "status",
        "title",
        "request_content",
        "attachments",
        "budget",
        "contact_at",
        "contract_links",
        "source",
        "source_domain",
      ],
      titleKey: 'name',
    },
  }
}