<template>
  <div class="ws-info-html">
    <div
      :class="[
        _infoClass,
        {'img-cursor-pointer': clickImgOpenPopup}
      ]"
      v-html="value"
      @click="$_onClick($event)"
    />
  </div>
</template>

<script>
export default {
  methods: {
    $_onClick($event) {
      if (!this.clickImgOpenPopup) {
        return
      }
      if ($event.target.tagName === 'IMG') {
        this.$store.dispatch('app/setImagePreviewPopup', {
          imageUrl: $event.target.src
        })
      }
    },
    $_closePopup() {
      this.$refs.WsPopup.close()
    }
  },
  computed: {
    _infoClass() {
      if (!this.noStyle) {
        return "ProseMirror";
      } else {
        return null;
      }
    },
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    noStyle: {
      type: Boolean,
    },
    clickImgOpenPopup: {
      type: Boolean,
      default: false
    }
  },
};
</script>