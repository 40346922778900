<template>
  <img
    class="ws-info-image"
    v-if="value"
    v-lazy="value"
    alt=""
    :style="`max-width:${_previewMaxWidth};max-height:${_previewMaxHeight}`"
  />
</template>

<script>
export default {
  computed: {
    _previewMaxWidth() {
      if (typeof this.previewMaxWidth === "number") {
        return `${this.previewMaxWidth}px`;
      } else {
        return this.previewMaxWidth;
      }
    },
    _previewMaxHeight() {
      if (typeof this.previewMaxHeight === "number") {
        return `${this.previewMaxHeight}px`;
      } else {
        return this.previewMaxHeight;
      }
    },
  },

  props: {
    value: {
      type: String,
    },
    previewMaxWidth: {
      type: String,
      default: "100%",
    },
    previewMaxHeight: {
      type: String,
      default: "100%",
    },
  },
};
</script>

<style>
</style>