<template>
  <div>
    <WsStateSelect
      :multiple="true"
      :items="_items"
      :value="value"
      :searchable="searchable"
      :selectAll="selectAll"
      @input="$emit('input',$event)"
    >
    </WsStateSelect>
  </div>
</template>

<script>
export default {
  computed: {
    _items() {
      if (this.items) {
        return this.items;
      } else {
        return this.$o_o.$h.scope.getFilteredAdminScopes();
      }
    },
  },
  props: {
    value: {
      type: Array,
    },
    searchable: {
      type: Boolean,
      dafeult: true,
    },
    selectAll: {
      type: Boolean,
      dafeult: true,
    },
    items: {},
  },
};
</script>

<style>
</style>