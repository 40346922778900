import i18n from '@/i18n';

export default {
  system_info: {
    icon: 'icon-md-computer',
    title: i18n.t('系統資訊'),
    link: '/system_info',
    scopes: ['system_info-read', 'system_info-admin'],
  },
};
