<template>
  <WsMain :key="_id">
    <WsReadSection
      ref="WsReadSection"
      :modelName="modelName"
      :urlModelName="urlModelName"
      :label="label"
      :fields="fields"
      v-bind="_crudSetting[bindingKey]"
      :id="_id"
      :value="_value"
      @input="$_onInput($event)"
      @title-bar-custom-btn-click="$emit('title-bar-custom-btn-click',$event)"
      @title-custom-dropdown-menu-click="$emit('title-custom-dropdown-menu-click',$event)"
    >
    </WsReadSection>
    <WsTabs
      v-if="_tabsDisplay"
      v-model="tabValue"
      :items="_tabs"
      class="my-10"
    />
    <div v-if="tabValue" :class="{ 'mt-40': !_tabsDisplay }">
      <CmsLogCrud
        v-if="tabValue == 'cms_log'"
        :target="modelName"
        :targetId="_id"
      />
      <WsCrud
        v-else
        :key="tabValue"
        v-bind="_childCrudValue"
        :modelName="_childCrudModel.modelName"
        :label="_childCrudModel.label"
        :fields="_childCrudModel.fields"
        :params="_childCrudParams"
        :createData="_childCrudParams"
        :updateData="_childCrudParams"
        :getUrlFunction="_childCrudModel.crudSetting?.index?.getUrlFunction || null"
      />
    </div>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `${this.label}`,
    };
  },
  data() {
    return {
      state: null,
      tabValue: null
    };
  },
  methods: {
    $_onInput($event) {
      this.state = $event;
      this.$emit("input", $event);
    },
    $_fetchModelData() {
      this.$refs.WsReadSection.$_fetchModelData()
    }
  },
  computed: {
    _crudSetting() {
      if (this.crudSetting) {
        return this.crudSetting;
      } else {
        return {
          [this.bindingKey]: {
            leftFields: this.$o_o.$h.model.getShowFieldsFromFields(this.fields),
          },
        };
      }
    },
    _childCrudParams() {
      return {
        [this.modelName]: this._id,
      };
    },
    _value() {
      if (this.value) {
        return this.value;
      } else {
        return this.state;
      }
    },
    _id() {
      if (this.id) {
        return this.id;
      } else {
        return this.$route.params.id;
      }
    },
    _tabsDisplay() {
      return this._tabs?.length > 1 ? true : false
    },
    _tabs() {
      let _tabs = []
      let childCruds = this._crudSetting[this.bindingKey].childCruds
      if (childCruds) {
        childCruds?.forEach(model => {
          _tabs.push({
            text: this.$store.state.stone_model[model.modelName].label,
            value: model.modelName,
          })
        })
      }
      if (this._crudSetting[this.bindingKey].cmsLog) {
        _tabs.push({
          text: '編輯歷程',
          value: 'cms_log',
        })
      }
      return _tabs
    },
    _childCrudValue() {
      let childCruds = this._crudSetting[this.bindingKey].childCruds
      if (!childCruds)
        return null
      return this._crudSetting[this.bindingKey].childCruds.find(item => 
        item.modelName == this.tabValue
      )
    },
    _childCrudModel() {
      return this.$store.state.stone_model[this.tabValue]
    },
  },
  mounted() {
    if (this._tabs) {
      this.tabValue = this._tabs[0].value
    } else {
      this.tabValue = null
    }
  },
  props: {
    modelName: {
      type: String,
    },
    urlModelName: {
      type: String,
    },
    label: {
      type: String,
    },
    fields: {
      type: Object,
    },
    id: {
      type: [Number, String],
    },
    crudSetting: {
      type: Object,
    },
    childCruds: {},
    bindingKey: {
      type: String,
      default: "read",
    },
  },
};
</script>