var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(_vm.tooltip),expression:"tooltip",modifiers:{"top-center":true}}],staticClass:"ws-icon-btn",class:[
    {'no-border':_vm.noBorder},
    {'no-radius':_vm.noRadius},
    {'is-round':_vm.isRound},
    {disabled:_vm.disabled},
    {hide:_vm.hide},
  ],style:([
    {width:`${_vm.size}px`},
    {height:`${_vm.size}px`},
    {'background-color':_vm.backgroundColor},
  ]),on:{"click":function($event){return _vm.$emit('click',$event)}}},[(_vm.to)?_c('RouterLink',{staticClass:"ws-icon-btn__link",attrs:{"to":_vm.to}}):_vm._e(),(_vm.text)?_c('WsText',{attrs:{"size":"14"}},[_vm._t("default")],2):_c('WsIcon',{attrs:{"size":_vm._iconSize,"name":_vm.name,"color":_vm.iconColor}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }