<template>
  <div class="ws-state-image">
    <WsImg
      v-if="_value"
      :url="_value"
      alt=""
      class="mb-10"
      :wireBackground="true"
      :previewMaxWidth="previewMaxWidth"
      :previewMaxHeight="previewMaxHeight"
    />
    <WsPocketImagePicker
      v-model="dialog"
      :signed="signed"
      :getUploadUrl="getUploadUrl"
      :pocketable="pocketable"
      :uploadable="uploadable"
      :linkable="linkable"
      @submit="$_onPocketImageSubmit($event)"
    />
    <WsBtn @click="$_onPickDialogOpen()">{{$t('pick_image')}}</WsBtn>
    <WsIconBtn
      @click="$_onClear()"
      v-if="_value"
      class="remove-btn"
      backgroundColor="#000"
      name="icon-md-delete"
    />
  </div>
</template>

<script>
import PocketImageService from "@/__stone/service/api/v1/pocket_image";
export default {
  data: () => ({
    dialog: false,
    fetchedItems: [],
  }),

  computed: {
    _value() {
      if (!this.value) {
        return null;
      } else {
        return PocketImageService.getSrc(this.value);
      }
    },
  },

  methods: {
    $_onClear() {
      this.$emit("input", null);
    },
    $_onPickDialogOpen() {
      this.dialog = true;
    },
    $_getUrl(pocketImage) {
      return PocketImageService.getSrc(pocketImage);
    },
    $_onPocketImageSubmit($event) {
      this.$_addItemToFetchedItems($event);
      this.$emit("input", $event);
    },
    $_originValueToFetchedItems() {
      if (!this.originValue) {
        return;
      }
      this.$_addItemToFetchedItems(this.originValue);
    },
    $_addItemToFetchedItems(item) {
      const _item = this.fetchedItems.find((e) => {
        return e.value == item.id;
      });
      if (!_item) {
        this.fetchedItems.push({
          value: item.id,
          url: this.$_getUrl(item),
        });
      }
    },
  },

  mounted() {
    this.$_originValueToFetchedItems();
  },

  props: {
    originValue: {
      type: Object,
      default: null,
    },
    value: {
      type: [Number, String],
      default: null,
    },
    signed: {
      type: Boolean,
      default: false,
    },
    getUploadUrl: {
      type: String,
      default: undefined,
    },
    pocketable: {
      type: Boolean,
    },
    uploadable: {
      type: Boolean,
    },
    linkable: {
      type: Boolean,
    },
    previewMaxWidth: {
      type: String,
    },
    previewMaxHeight: {
      type: String,
    },
  },
};
</script>

<style>
</style>