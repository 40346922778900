<template>
  <WsCard class="off-day-board">
    <WsFlex flexDirection="column" gap="16px">
      <WsText size="16">可用休假餘額</WsText>
      <WsFlex flexDirection="column" gap="8px" class="off-day-board-main-content pl-8">
        <WsFlex
          v-for="(item, index) in list"
          :key="index"
          justifyContent="space-between"
          gap="16px"
        >
          <WsText size="16">{{ item.label }}</WsText>
          <WsText>{{ item.time }}</WsText>
        </WsFlex>
      </WsFlex>
      <WsLink class="py-8">
        <WsFlex justifyContent="flex-end" alignItems="center" gap="8px">
          <WsText size="14" :color="$color.gray2d">查看所有假別餘額</WsText>
          <WsIcon name="icon-ws-outline-arrow-right" :color="$color.gray2d" />
        </WsFlex>
      </WsLink>
    </WsFlex>
  </WsCard>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          label: '特休',
          time: '8 天 7.5 小時'
        },
        {
          label: '加班補休',
          time: '19 天 0.5 小時'
        },
        {
          label: '病假',
          time: '30 天 3 小時'
        },
        {
          label: '事假',
          time: '15 天'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.off-day-board {
  .off-day-board-main-content {
    border-left: 1px solid var(--primary1l);
  }
}
</style>