<template>
  <WsCard>
    <WsCard
      v-for="(contentItem, contentItemIndex) in value"
      :key="contentItemIndex"
      color="#333"
      class="mt-20"
    >
      <WsText>{{contentItem.name}}</WsText>
      <WsInfoForm
        class="mt-20"
        :fields="contentItem.fields"
        :value="$_getTulpaSectionContent(contentItem.defaultContent,contentItem.content)"
      ></WsInfoForm>
    </WsCard>
  </WsCard>
</template>

<script>
export default {
  methods: {
    $_getTulpaSectionContent(defaultContent, content) {
      const _content = {};
      for (const key in defaultContent) {
        if (content[key] == null || content.length === 0) {
          _content[key] = defaultContent[key];
        } else {
          _content[key] = content[key];
        }
      }
      return _content;
    },
  },
  props: {
    value: {
      type: [Array, Object],
      default: null,
    },
  },
};
</script>