<template>
  <div class="ws-state-list-infinite">
    <div
      v-for="(item,itemIndex) in value"
      :key="itemIndex"
    >
      <WsCard>
        <WsStateForm
          v-if="item"
          :fields="fields[0].fields"
          :value="item"
          @input="$_onInput($event,itemIndex)"
        ></WsStateForm>
        <WsStateListInfinite
          v-if="_innerFields.length"
          :createBtnText="_innerFields[0].createBtnText"
          :fields="_innerFields"
          :modelName="_innerFields[0].modelName"
          :value="item[_innerFields[0].modelName]"
          @input="$_innerOnInput($event,itemIndex)"
        >
        </WsStateListInfinite>
      </WsCard>
      <WsBtn
        v-if="value&&value.length>1"
        class="ml-16"
        @click="$_deleteState(itemIndex)"
        alert
      >
        {{$t('刪除')}}
      </WsBtn>
    </div>
    <WsCard>
      <WsBtn
        v-if="_showAddBtn"
        @click="$_addState()"
      >{{createBtnText}}</WsBtn>
    </WsCard>

  </div>
</template>
<script>
export default {
  name: "WsStateListInfinite",
  mounted() {
    this.$_initState();
  },
  methods: {
    $_onInput($event, index) {
      let _value = [...this.value];
      _value[index] = { ..._value[index], ...$event };
      this.$emit("input", _value);
    },
    $_addState() {
      let _value = [];
      let _state = {};
      if (this.value && this.value.length) {
        _value = [...this.value];
      }
      for (let itemKey in this.fields) {
        _state[itemKey] = null;
      }
      _value.push(_state);
      this.$emit("input", _value);
    },
    $_initState() {
      let _value = [];
      if (this.value && this.value.length) {
        _value = [...this.value];
        let i = 0;
        for (i = 0; i < this.value.length; i++) {
          for (let itemKey in this.fields) {
            if (_value[i][itemKey] !== 0 && !_value[i][itemKey]) {
              _value[i][itemKey] = null;
            }
          }
        }
      }
      const valueLength =
        this.value && this.value.length ? this.value.length : 0;
      let i = 0;
      if (this.initValueLength - valueLength > 0) {
        for (i = 0; i < this.initValueLength - valueLength; i++) {
          let _state = {};
          for (let itemKey in this.fields) {
            _state[itemKey] = null;
          }
          _value.push(_state);
        }
      }
      this.$emit("input", _value);
    },
    $_deleteState(index) {
      let _value = [...this.value];
      _value.splice(index, 1);
      this.$emit("input", _value);
    },
    $_innerOnInput($event, index) {
      let _value = [...this.value];
      _value[index][this._innerFields[0].modelName] = $event;
    },
  },
  computed: {
    _showAddBtn() {
      if (!this.creatable) {
        if (this.value && this.initValueLength > this.value.length) {
          return true;
        } else {
          return false;
        }
      }
      if (this.valueLengthLimit !== 0 && !this.valueLengthLimit) return true;
      if (
        this.value &&
        this.value.length &&
        this.valueLengthLimit > this.value.length
      ) {
        return true;
      }
      return true;
    },
    _innerFields() {
      if (!this.fields || !this.fields.length) return [];
      let _innerFields = [...this.fields];
      _innerFields.splice(0, 1);
      return _innerFields;
    },
  },
  props: {
    value: {
      type: Array,
    },
    createBtnText: {
      type: String,
      default() {
        return this.$t("新增");
      },
    },
    fields: {
      type: Array,
    },
    creatable: {
      type: Boolean,
      default: true,
    },
    initValueLength: {
      type: Number,
      default: 1,
    },
    valueLengthLimit: {
      type: Number,
    },
    modelName: {
      type: String,
    },
  },
};
</script>
<style lang="scss">
</style>