<template>
  <WsInfiniteScroll
    v-slot="{items}"
    modelName="audio"
  >
    <table>
      <tr
        v-for="(item, itemIndex) in items"
        :key="itemIndex"
      >
        <td class="pa-4">
          <WsBtn @click="$_onPick(item)">選擇</WsBtn>
        </td>
        <td class="pa-4">{{item.file_name}}</td>
        <td class="pa-4">{{item.tags}}</td>
        <td class="pa-4">
          <WsInfo
            type="audio"
            :value="item.public_url"
          ></WsInfo>
        </td>
      </tr>
    </table>
  </WsInfiniteScroll>
</template>

<script>
export default {
  methods: {
    $_onPick(item) {
      this.$emit("submit", item);
    },
  },
  props: {
    fields: {},
  },
};
</script>

<style>
</style>