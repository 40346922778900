<template>
  <div>
    <WsDialogCRUDUpdate
      ref="WsDialogCRUDUpdate"
      :title="$t('編輯發票狀態')"
      :fields="fields"
      modelName="shop_order"
      @complete="$emit('complete',$event)"
    ></WsDialogCRUDUpdate>
  </div>
</template>

<script>
export default {
  methods: {
    open(id) {
      this.$refs.WsDialogCRUDUpdate.open(id);
    },
  },
  data() {
    return {
      fields: {
        invoice_status: {
          type: "select",
          label: "發票狀態",
          items: [
            {
              text: "開立失敗",
              value: "fail",
            },
            {
              text: "手動開立成功",
              value: "done-hand",
            },
          ],
        },
      },
    };
  },
};
</script>

<style>
</style>