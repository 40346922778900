<template>
  <div class="ws-state-locale-message">
    <!-- <WsRow
      v-for="(itemKey, itemIndex) in items"
      :key="itemIndex"
    >
      <WsCol class="col-6">
        <WsText>{{itemKey}}</WsText>
      </WsCol>
      <WsCol class="col-6">
        <WsStateInput
          :value="value[itemKey]"
          @input="$_onInput($event,itemKey)"
        ></WsStateInput>
      </WsCol>
    </WsRow> -->

    <table>
      <tr
        v-for="(itemKey, itemIndex) in items"
        :key="itemIndex"
      >
        <td>
          <WsText>{{itemKey}}</WsText>
        </td>
        <td>
          <input
            type="text"
            :value="value[itemKey]"
            @input="$_onInput($event,itemKey)"
          >
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    $_onInput($event, itemKey) {
      const _value = {
        ...this.value,
        [itemKey]: $event.target.value,
      };
      this.$emit("input", _value);
    },
  },
};
</script>

<style>
</style>