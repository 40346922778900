<template>
  <img
    class="ws-img"
    v-lazy="url"
    :alt="alt"
    :class="[
      {'wire-bg':wireBackground}
    ]"
    :style="`max-width:${previewMaxWidth};max-height:${previewMaxHeight}`"
  >
</template>

<script>
export default {
  props: {
    url: {
      type: String,
    },
    alt: {
      type: String,
      default: "",
    },
    wireBackground: {
      type: Boolean,
      default: false,
    },
    previewMaxWidth: {
      type: String,
      default: "100%",
    },
    previewMaxHeight: {
      type: String,
      default: "100%",
    },
  },
};
</script>

<style>
</style>