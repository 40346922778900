export default {
  my_punch: {
    icon: 'icon-md-alarm-on',
    title: '打卡',
    link: '/my/punch',
  },
  punch: {
    icon: 'icon-md-alarm-on',
    title: '打卡管理',
    link: '/punch',
    scopes: ['punch-admin'],
  },
}