<template>
  <WsCard :color="_color">
    <WsText>{{name}}</WsText>
    <WsText>{{content}}</WsText>
    <WsText class="mt-8">{{$moment(created_at).format('YYYY-MM-DD HH:mm:ss')}}</WsText>
  </WsCard>
</template>
<script>
export default {
  name: "WsPostReply",
  data: () => ({}),
  computed: {
    _color() {
      if (this.mode == "2") {
        return this.$color.gray6d;
      } else {
        return this.$color.black3l;
      }
    },
  },
  props: {
    name: {
      type: String,
    },
    mode: {
      type: String,
    },
    content: {
      type: String,
    },
    created_at: {
      type: String,
    },
  },
};
</script>