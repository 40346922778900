<template>
  <div v-tooltip.top-center="tooltip">
    <WsIconBtn
      @click="$_onClickBtn()"
      name="icon-md-video-library"
      :noBorder="noBorder"
    />
    <input
      v-show="false"
      @change="$_onChange($event)"
      type="file"
      ref="fileInput"
      accept="video/mp4,video/x-m4v,video/*"
    >
  </div>
</template>

<script>
export default {
  props: {
    noBorder: {},
    tooltip: String,
  },
  methods: {
    async $_onChange($event) {
      this.$emit('change', $event)
    },
    $_onClickBtn() {
      this.$refs.fileInput.click()
    },
  }
}
</script>