const model = {
  label: "系統設定",
  fields: {
    favicon: {
      type: "image",
      label: "Favicon (網站頁籤 icon) (32 * 32)",
    },
    color: {
      type: 'form',
      label:'Color',
      defaultValue: {
        primary: "#6f9630",
        secondary: "#E3A11C",
        black: "#000000",
        white: "#ffffff",
        gray: "#ABB8C8",
        success: "#41cf5f",
        danger: "#f04526",
        warning: "#ffcf21",
        link: "#848fa0",
        disabled: "#abb8c8"
      },
      fields: {
        primary: {
          type: 'color',
          label: 'Primary',
          col: 3
        },
        secondary: {
          type: 'color',
          label: 'Secondary',
          col: 3
        },
        black: {
          type: 'color',
          label: 'Black',
          col: 3
        },
        white: {
          type: 'color',
          label: 'White',
          col: 3
        },
        gray: {
          type: 'color',
          label: 'Gray',
          col: 3
        },
        success: {
          type: 'color',
          label: 'Success',
          col: 3
        },
        danger: {
          type: 'color',
          label: 'Danger',
          col: 3
        },
        warning: {
          type: 'color',
          label: 'Warning',
          col: 3
        },
        link: {
          type: 'color',
          label: 'Link',
          col: 3
        },
        disabled: {
          type: 'color',
          label: 'Disabled',
          col: 3
        },
      }
    }
  },
}

export default model