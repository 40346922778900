<template>
  <WsMain>
    <div v-if="file">
      <WsText class="mb-20">{{file.name}}</WsText>
    </div>
    <WsBtn @click="$_onUploadClick()">上傳檔案</WsBtn>
    <input
      v-show="false"
      @change="$_onChange($event)"
      type="file"
      ref="fileInput"
      accept="*/*"
    >
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      file: null,
    };
  },
  methods: {
    async $_onChange($event) {
      this.file = $event.target.files[0];
    },
    $_onUploadClick() {
      this.$refs.fileInput.click();
    },
    async submit() {
      if (!this.file) {
        return;
      }
      const res = await this.$o_o.$s._m.general_file.upload(
        this.file,
        this.file.name
      );
      this.$emit("submit", res);
    },
  },
};
</script>

<style>
</style>