import i18n from '@/i18n.js';
import config from '@/__config';

export default {
  modelName: config.wsmodule.admin.admin_blur ? 'cmser_group' : "admin_group",
  label: "後台使用者分群",
  fields: {
    name: {
      type: "text",
      label: i18n.t("名稱"),
      autofocus: true,
      rules: "required",
      maxlength: 30
    },
  },
}
