export default {
  my_xc_work_overtime: {
    icon: 'icon-md-local-gas-station',
    title: '我的 加班申請',
    link: '/my/xc_work_overtime',
  },
  xc_work_overtime: {
    icon: 'icon-md-local-gas-station',
    title: '加班申請',
    link: '/xc_work_overtime',
    scopes: ['xc_work_overtime-admin'],
  },
}