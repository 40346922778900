<template>
  <div class="ws-model-page-editor">

  </div>
</template>

<script>
export default {};
</script>

<style>
</style>