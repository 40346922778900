export default {
  my_xc_billing_statement: {
    icon: 'icon-ws-officialweb-about',
    title: '我的 請款',
    link: '/my/xc_billing_statement',
  },
  xc_billing_statement: {
    icon: 'icon-md-monetization-on',
    title: '請款',
    link: '/xc_billing_statement',
    scopes: ['xc_billing_statement-admin'],
  },
}