<template>
  <div class="ws-state-select-two-layer">
    <p
      v-if="title"
      class="title"
    >
      {{ title }}<span
        v-if="required"
        class="red"
      >*</span>
    </p>
    <ValidationProvider
      v-slot="{ errors }"
      class="ws-state-select-two-layer_content-wrap"
      :rules="rules"
    >
      <div
        v-click-outside="dropdownClose"
        class="ws-state-select-two-layer_content"
        :class="{outline: outline,disabled:disabled,error:errors.length}"
      >
        <i
          v-if="leadingIcon"
          class="icon leading-icon"
          :class="leadingIcon"
        />
        <WsBtn
          full
          outlined
          @click="onclick()"
        >
          <p :class="{active:value&&value.length > 0}">
            {{ _showText }}
          </p><span v-if="_allBtnIcon === 'icon-ws-filled-indeterminate-check-box'">{{ value.length }}</span>
        </WsBtn>
        <i
          v-if="outline"
          class="icon trailing-icon outline icon-md-chevron-up"
          :class="{show: dropdown_menu_show}"
        />
        <i
          v-if="disabled"
          class="icon trailing-icon icon-md-chevron-down"
        />
        <i
          v-else-if="!outline && !dropdown_menu_show"
          class="icon trailing-icon icon-md-arrow-drop-down-circle"
        />
        <i
          v-else-if="!outline && dropdown_menu_show"
          class="icon trailing-icon icon-md-arrow-drop-up-circle"
        />
        <transition mode="out-in">
          <div
            v-if="dropdown_menu_show"
            class="dropdown-menu"
          >
            <div class="dropdown-scrollable-content">
              <div>
                <WsBtn
                  full
                  outlined
                  @click="selectAll()"
                >
                  <i :class="_allBtnIcon" /> {{ allSelectText }}
                </WsBtn>
              </div>
              <div class="dropdown-scrollable-content_list ps">
                <!-- <perfect-scrollbar class="dropdown-scrollable-content_list ps"> -->
                <div
                  v-for="(firstLayer,firstLayerKey) in menu"
                  :key="firstLayerKey"
                >
                  <!-- First Layer -->
                  <div
                    class="classification-title"
                    @click="onFirstLayerClick(firstLayer)"
                  >
                    <!-- <span>{{ getFirstLayerStatus(firstLayer.menu,value) }}</span> -->
                    <i :class="getFirstLayerStatus(firstLayer.menu,value)" />
                    <span>{{ firstLayer.title }}</span>
                  </div>
                  <div class="classification-subtitle">
                    <!-- Second Layer -->
                    <div
                      v-for="(secondLayer,secondLayerKey) in firstLayer.menu"
                      :key="secondLayerKey"
                    >
                      <label>
                        <input
                          type="checkbox"
                          :checked="value&&value.includes(secondLayer.value)"
                          @change="onChange($event,secondLayer.value)"
                        >
                        <div class="checkbox-icon">
                          <i class="checked icon-md-check-box" />
                          <i class="unchecked icon-md-check-box-outline-blank" />
                        </div>
                        <span>{{ secondLayer.text }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div
        v-if="errors&&errors.length > 0"
        class="invalid-text"
      >
        <i class="icon-warning invalid-icon" />
        <p
          v-for="(text,textIndex) in errors"
          :key="textIndex"
          class="text"
        >
          {{ text }}
        </p>
      </div>
      <input
        type="hidden"
        :value="value"
      >
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "此項目為必填",
});
export default {
  name: "WsStateSelectTwoLayer",
  components: {
    ValidationProvider,
  },
  props: {
    menu: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: {
      type: String,
      default() {
        return this.$t("所有領域");
      },
    },
    leadingIcon: {
      type: String,
      default: "",
    },
    outline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    allSelectText: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    dropdown_menu_show: false,
    templateContent: null,
    allBtnIcon: "icon-md-check-box",
  }),
  computed: {
    _value() {
      if (this.value) {
        return this.value;
      } else {
        return [];
      }
    },
    _showText() {
      const allValue = [];
      this.menu.forEach((menu) => {
        menu.menu.forEach((item) => {
          allValue.push(item);
        });
      });
      if (this._value.length === allValue.length) {
        if (this.allSelectText) {
          return this.allSelectText;
        } else {
          return this.$t("所有選項");
        }
      } else if (this._value.length == 0) {
        return this.placeholder;
      } else {
        const selectValue = [];
        allValue.forEach((value) => {
          if (this._value.indexOf(value.value) > -1) {
            selectValue.push(value);
          }
        });
        const selectValueText = selectValue.map((select) => select.text);
        return selectValueText.join("、");
      }
    },
    _allBtnIcon() {
      const allValue = [];
      this.menu.forEach((menu) => {
        menu.menu.forEach((item) => {
          allValue.push(item);
        });
      });
      if (this._value.length < allValue.length && this._value.length > 0) {
        return "icon-ws-filled-indeterminate-check-box";
      } else if (this._value.length === 0) {
        return "icon-md-check-box-outline-blank";
      }
      return "icon-md-check-box";
    },
  },
  methods: {
    onclick() {
      if (this.disabled) {
        return;
      }
      this.toggleDropdownMenuShow();
    },
    toggleDropdownMenuShow() {
      this.dropdown_menu_show = !this.dropdown_menu_show;
    },
    dropdownClose() {
      this.dropdown_menu_show = false;
    },
    isValueInSameLayer(value) {
      let inSameLayer = true;
      value.forEach((valueItemA) => {
        const layerTitleA = this.getLayerTitleByValue(valueItemA);
        value.forEach((valueItemB) => {
          const layerTitleB = this.getLayerTitleByValue(valueItemB);
          if (layerTitleA != layerTitleB) {
            inSameLayer = false;
          }
        });
      });
      return inSameLayer;
    },
    getLayerTitleByValue(value) {
      let title = "";
      this.menu.forEach((firstLayer) => {
        firstLayer.menu.forEach((secondLayer) => {
          if (secondLayer.value == value) {
            title = firstLayer.title;
          }
        });
      });
      return title;
    },
    findTextByValue(value) {
      let text = "";
      this.menu.forEach((firstLayer) => {
        firstLayer.menu.forEach((secondLayer) => {
          if (secondLayer.value == value) {
            text = secondLayer.text;
          }
        });
      });
      return text;
    },
    selectAll() {
      const allArr = [];
      const allValue = [];
      this.menu.forEach((menu) => {
        menu.menu.forEach((item) => {
          allValue.push(item);
        });
      });
      if (this._value.length < allValue.length) {
        this.menu.forEach((firstLayer) => {
          firstLayer.menu.forEach((secondLayer) => {
            allArr.push(secondLayer.value);
          });
        });
      }
      this.$emit("input", allArr);
      this.$emit("click");
    },
    onFirstLayerClick(firstLayer) {
      const layerStatus = this.getFirstLayerStatus(
        firstLayer.menu,
        this._value
      );
      const tarArr = [];
      firstLayer.menu.forEach((menuItem) => {
        tarArr.push(menuItem.value);
      });
      let newValue = JSON.parse(JSON.stringify(this._value));
      if (
        layerStatus == "icon-md-check-box-outline-blank" ||
        layerStatus == "icon-ws-filled-indeterminate-check-box"
      ) {
        newValue = newValue.concat(tarArr);
        newValue = newValue.filter(
          (item, pos) => newValue.indexOf(item) === pos
        );
      } else if (layerStatus == "icon-md-check-box") {
        newValue = newValue.filter((el) => {
          return !tarArr.includes(el);
        });
      }
      this.$emit("input", newValue);
      this.$emit("click");
    },
    onChange($event, value) {
      let newValue = JSON.parse(JSON.stringify(this._value));
      if ($event.target.checked) {
        if (newValue && !newValue.includes(value)) {
          newValue.push(value);
        }
      } else {
        newValue = newValue.filter((item) => item !== value);
      }
      this.$emit("input", newValue);
      this.$emit("click");
    },
    getFirstLayerStatus(layerMenu) {
      const tarArr = [];
      layerMenu.forEach((menuItem) => {
        tarArr.push(menuItem.value);
      });
      if (tarArr.every((v) => this._value.includes(v))) {
        return "icon-md-check-box";
      } else if (tarArr.some((r) => this._value.includes(r))) {
        return "icon-ws-filled-indeterminate-check-box";
      } else {
        return "icon-md-check-box-outline-blank";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/__stone/scss/@components/ws-state-select-two-layer";
</style>
