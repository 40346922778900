<template>
  <div>
    <WsText v-if="value.startDate&&value.endDate">{{$moment(value.startDate).format("YYYY-MM-DD")}} - {{$moment(value.endDate).format("YYYY-MM-DD")}}</WsText>
    <WsText v-else>無期限</WsText>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },
};
</script>

<style>
</style>