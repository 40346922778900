<template>
  <div>
    <WsInfoAudio
      v-if="value"
      :value="value"
    ></WsInfoAudio>
    <WsBtn @click="$_onClickSelect()">選擇檔案</WsBtn>
    <WsDialog ref="WsDialog">
      <template v-slot:content>
        <WsAudioPicker @submit="$_onSubmit($event)"></WsAudioPicker>
      </template>
    </WsDialog>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    value: {},
  },
  methods: {
    $_onSubmit($event) {
      this.$refs.WsDialog.close();
      this.$emit("input", $event.public_url);
    },
    $_onClickSelect() {
      this.$refs.WsDialog.open();
    },
  },
};
</script>

<style>
</style>