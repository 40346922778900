<template>
  <div>
    <WsModelIndex
      ref="WsModelIndex"
      :label="$store.state.stone_model[modelName].label"
      :modelName="$store.state.stone_model[modelName].modelName"
      :fields="$store.state.stone_model[modelName].fields"
      :filterTabs="filterTabs"
      :showFields="$store.state.stone_model[modelName].crudSetting.index.showFields"
      :creatable="true"
      :updatable="$_checkUpdatable"
      :deletable="$_checkDeletable"
      :dialogCreate="true"
      :dialogUpdate="true"
      :customTableActions="customTableActions"
      @reset-password="$_onResetPassword($event)"
      :updateFields="_updateFields"
      :actionWidth="140"
    ></WsModelIndex>
    <WsDialogAdminPasswordUpdate ref="WsDialogAdminPasswordUpdate">
    </WsDialogAdminPasswordUpdate>
  </div>
</template>

<script>
import S_APP_General from "@/__stone/service/app/general";
export default {
  metaInfo() {
    return {
      title: this.$store.state.stone_model[this.modelName].label,
    };
  },

  methods: {
    $_checkUpdatable(item) {
      if (item.is_universe) {
        return false;
      } else {
        return true;
      }
    },
    $_checkDeletable(item) {
      if (item.is_universe) {
        return false;
      } else {
        return true;
      }
    },
    $_onResetPassword($event) {
      this.$refs.WsDialogAdminPasswordUpdate.open($event.item.id);
    },
  },

  data() {
    return {
      modelName: "admin",
      updateHideFields: ["password"],
      filterTabs: [
        {
          text: "啟用中",
          value: 1,
          params: {
            is_active: 1,
          },
        },
        {
          text: "停用",
          value: 2,
          params: {
            is_active: 0,
          },
        },
        {
          text: "全部",
          value: 3,
          params: {},
        },
      ],
      customTableActions: [
        {
          icon: "icon-md-vpn-key",
          emit: "reset-password",
          tooltip: this.$t("更改密碼"),
          displayCheck: (item) => {
            if (item.is_universe) {
              return false;
            } else {
              return true;
            }
          },
        },
      ],
    };
  },

  computed: {
    _updateFields() {
      return S_APP_General.getUpdateFields(
        this.$store.state.stone_model[this.modelName].fields,
        this.updateHideFields
      );
    },
  },
};
</script>