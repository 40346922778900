import app from '@/__stone/store/app'
import auth from '@/__stone/store/auth'
import locale from '@/__stone/store/locale'
import stone_model from '@/__stone/store/model'
import Vue from 'vue'
import Vuex from 'vuex'
import model from "./model";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    global_data: {}
  },
  mutations: {
    SET_GLOBAL_DATA(state, data) {
      state.global_data = data
    }
  },
  actions: {
    setGlobalData({ commit }, data) {
      commit('SET_GLOBAL_DATA', data)
    },
  },
  modules: {
    auth,
    app,
    locale,
    model,
    stone_model,
  }
})
