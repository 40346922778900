import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// CMS Stone
import cms from './__stone/plugins/stone'
import H_module from './__stone/helpers/module'

import i18n from './i18n'
Vue.use(cms)


Vue.config.productionTip = false
Vue.config.devtools = false


const start = async () => {
  await H_module.init()
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}
start()