<template>
  <WsFlex alignItems="center">
    <WsAvatar
      :size="size"
      :src="value.avatar"
      class="mr-4"
    ></WsAvatar>
    <WsText size="14">{{value.name}}</WsText>
  </WsFlex>
</template>

<script>
export default {
  props: {
    value: {},
    size: {
      type: [Number, String],
      default: 32,
    },
  },
};
</script>