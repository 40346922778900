<template>
  <WsRow>
    <WsCol
      v-for="(item, itemKey) in items"
      :key="itemKey"
      :cols="$_getCols(item)"
    >
      <WsAnalytics
        v-bind="item"
        :params="$_getParams(item)"
      ></WsAnalytics>
    </WsCol>
  </WsRow>
</template>

<script>
export default {
  methods: {
    $_getParams(item) {
      return {
        ...item.params,
        ...this.params,
      };
    },
    $_getCols(item) {
      if (item.cols) {
        return item.cols;
      } else {
        return 6;
      }
    },
  },
  props: {
    items: {},
    params: {},
  },
};
</script>