<template>
  <div class="ws-analytics">
    <WsAnalyticsText
      v-if="type == 'text'"
      :title="title"
      :value="value"
    ></WsAnalyticsText>
    <WsAnalyticsValue
      v-if="type == 'value'"
      v-bind="$props"
    ></WsAnalyticsValue>
    <WsAnalyticsSum
      v-if="type == 'sum'"
      v-bind="$props"
    ></WsAnalyticsSum>
    <WsAnalyticsTrending
      v-if="type == 'trending'"
      v-bind="$props"
    ></WsAnalyticsTrending>
    <WsAnalyticsSumMass
      v-if="type == 'sum_mass'"
      v-bind="$props"
    ></WsAnalyticsSumMass>
    <WsAnalyticsTrendingMass
      v-if="type == 'trending_mass'"
      v-bind="$props"
    ></WsAnalyticsTrendingMass>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    title: {},
    value: {},
    fetchUrl: {},
    fields: {},
    params: {},
    format: {},
    date_field: {},
  },
};
</script>

<style></style>