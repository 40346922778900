<template>
  <WsAlert
    :title="$store.state.app.alert.title"
    :description="$store.state.app.alert.description"
    @submit="$_onSubmit()"
    @close="$_onClose()"
    :value="_active"
  ></WsAlert>
</template>

<script>
export default {
  data() {
    return {
      // active: false,
    };
  },
  computed: {
    _active() {
      return this.$store.state.app.alert.title ? true : false;
    },
  },
  methods: {
    $_onSubmit() {
      if (this.$store.state.app.alert.action) {
        this.$store.state.app.alert.action(this.$store.state.app.alert.data);
      }
      this.$_onClose();
    },
    $_onClose() {
      this.$store.dispatch("app/clearAlert");
    },
  },
};
</script>

<style></style>