<template>
  <div>
    <WsModelFetchShow
      ref="WsModelFetchShow"
      modelName="quotation"
      :id="$route.params.id"
    >
      <template v-slot="{ modeldata }">
        <InfoQuotation v-bind="modeldata"></InfoQuotation>
      </template>
    </WsModelFetchShow>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // state: {}
    }
  },
}
</script>