<template>
  <div
    ref="WsStateJson"
    class="ws-state-json"
  >
  </div>
</template>

<script>
import JsonEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.min.css";
export default {
  data() {
    return {
      editor: null,
    };
  },
  props: {
    value: {},
  },
  methods: {
    $_onChange() {
      try {
        const _value = this.editor.get();
        this.$emit("input", _value);
      } catch (error) {
        this.$emit("valid-is-false");
        return error;
      }
    },
    $_init() {
      const self = this;
      this.editor = new JsonEditor(this.$refs.WsStateJson, {
        mode: "code",
        onChangeJSON: self.$_onChange,
        onChange: self.$_onChange,
      });
      if (this.value) {
        this.editor.set(this.value);
        this.$_onChange()
      }
    },
  },
  mounted() {
    this.$_init();
  },
  components: {},
};
</script>