<template>
  <WsFlex class="flex-full">
    <WsMain>
      <CalendarControllPanel
        @prev="$refs.WsCmsCalendar.prev()"
        @next="$refs.WsCmsCalendar.next()"
        @goDate="$refs.WsCmsCalendar.goDate($event)"
        @changeView="$refs.WsCmsCalendar.changeView($event)"
      ></CalendarControllPanel>
      <WsStateForm
        :fields="filter.fields"
        v-model="filter.state"
      ></WsStateForm>
    </WsMain>
    <WsCmsCalendar
      ref="WsCmsCalendar"
      class="mr-12"
      height="100%"
      :droppable="false"
      :editable="false"
      :hasToolbar="false"
      :params="_params"
    ></WsCmsCalendar>
  </WsFlex>
</template>

<script>
export default {
  data() {
    return {
      filter: {
        fields: {
          taker: {
            type: "belongsTo",
            label: "執行人",
            textKey: "name",
            modelName: this.$o_o.$c.wsmodule.admin.admin_blur
              ? "cmser"
              : "admin",
            extendParams: {
              is_active: 1,
            },
          },
        },
        state: {},
      },
    };
  },
  computed: {
    _params() {
      return this.$o_o.$h.model.getFormatedStates(
        this.filter.fields,
        this.filter.state
      );
    },
  },
};
</script>

<style></style>