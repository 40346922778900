import axios from 'axios'

export default {
  index_date(start, end, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin_event/index/date`, {
          params: {
            ...params,
            start: start,
            end: end,
          }
        })
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  ok(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin_event/${id}/ok`)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  no(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin_event/${id}/no`)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}