import Message from './message'
import config from '@/__config'
export const message = Message[config.locale.lang]
import $h from '@/__stone/helpers'
import $s from '@/__stone/services'


export default {
  $h,
  $s,
  $c: config
}