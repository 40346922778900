export default {
  getIndexFilterSelects(data, res) {
    let _res
    if (res) {
      _res = res
    } else {
      _res = data.filterSelects
    }
    _res = {
      ..._res,
      xc_project: {
        type: 'belongsTo',
        label: '專案',
        textKey: "name",
        modelName: "xc_project",
        col: 3,
      }
    }
    return _res
  }
}