import H_Router from '@/__stone/helpers/router'

const routes = [
  // ...H_Router.getResourceRoutes({
  //   routes: ['index'],
  //   modelName: 'cms_log',
  //   pathName: 'cms_log',
  //   viewsPath: '__modules/cms_log/views',
  //   meta: {
  //     middleware: 'auth',
  //     requiredScopes: ['admin-admin'],
  //   },
  // }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'cms_log',
    middleware: 'auth',
    meta: {
      requiredScopes: ['cms_log-admin'],
    },
  }),
]


export default routes