const model = {
  fields: {
    slack_id: {
      type: 'text',
      label: 'Slack ID'
    }
  },
  crudSetting: {
    index: {
      showFields: [
      ]
    }
  }
}
export default model