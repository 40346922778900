import config from '@/__config';
import moment from 'moment';

const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_double_entry_record",
  label: "帳款紀錄",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    updated_at: {
      type: "date",
      label: "更新時間",
      readonly: true,
      labelInLocale: true,
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      readonly: true,
      labelInLocale: true,
    },
    date: {
      type: 'date',
      label: '日期',
      defaultValue: moment().format('YYYY-MM-DD'),
      col: 3
    },
    name: {
      type: 'text',
      label: '摘要',
      autofocus: true,
      col: 9,
      width: 500
    },
    xc_financial_item: {
      type: "belongsTo",
      label: "自訂帳款項目",
      textKey: "name",
      modelName: 'xc_financial_item',
      col: 12,
      setStateOnInput(state, $event) {
        const keys = [
          'debit_xc_financial_first_grade',
          'debit_xc_financial_second_grade',
          'credit_xc_financial_first_grade',
          'credit_xc_financial_second_grade',
        ];
        if ($event) {
          keys.forEach((key) => {
            if ($event[key]) {
              state[key] = $event[key];
            }
          });
        }
        return state
      }
    },
    debit_amount: {
      type: 'currency',
      label: '借方金額',
      col: 3
    },
    debit_xc_financial_first_grade: {
      type: "belongsTo",
      label: "借方一級科目",
      textKey: "name",
      modelName: 'xc_financial_first_grade',
      extendParams: {
        type: 1
      },
      col: 3
    },
    debit_xc_financial_second_grade: {
      type: "belongsTo",
      label: "借方二級科目",
      textKey: "name",
      modelName: 'xc_financial_second_grade',
      col: 6
    },
    credit_amount: {
      type: 'currency',
      label: '貸方金額',
      col: 3
    },
    credit_xc_financial_first_grade: {
      type: "belongsTo",
      label: "貸方一級科目",
      textKey: "name",
      modelName: 'xc_financial_first_grade',
      extendParams: {
        type: 2
      },
      col: 3
    },
    credit_xc_financial_second_grade: {
      type: "belongsTo",
      label: "貸方二級科目",
      textKey: "name",
      modelName: 'xc_financial_second_grade',
      col: 6
    },
    creator: {
      type: "belongsTo",
      label: "建立者",
      textKey: "name",
      readonly: true,
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
    },
    remark: {
      type: 'textarea',
      label: '備註',
    },
    attached_files: {
      type: 'file_files',
      label: '附件檔案',
      storageTarget: 'xc_double_entry_record'
    },
    attached_images: {
      type: 'file_images',
      label: '附件圖片',
      storageTarget: 'xc_double_entry_record'
    },
  },
  crudSetting: {
    index: {
      importable: true,
      exportable: true,
      showFields: [
        'date',
        'name',
        'xc_financial_item',
        'debit_xc_financial_first_grade',
        'debit_xc_financial_second_grade',
        'debit_amount',
        'credit_xc_financial_first_grade',
        'credit_xc_financial_second_grade',
        'credit_amount',
      ],
      filterSelects: {
        date: {
          type: "date-range",
          placeholder: "篩選日期區間",
          col: 2,
        },
        debit_xc_financial_first_grade: {
          type: "belongsToMany",
          placeholder: "借方一級科目",
          modelName: 'xc_financial_first_grade',
          col: 2,
          extendParams: {
            type: 1
          }
        },
        debit_xc_financial_second_grade: {
          type: "belongsToMany",
          placeholder: "借方二級科目",
          modelName: 'xc_financial_second_grade',
          col: 2,
          extendParams: {
            type: 1
          }
        },
        credit_xc_financial_first_grade: {
          type: "belongsToMany",
          placeholder: "貸方一級科目",
          modelName: 'xc_financial_first_grade',
          col: 2,
          extendParams: {
            type: 2
          }
        },
        credit_xc_financial_second_grade: {
          type: "belongsToMany",
          placeholder: "貸方二級科目",
          modelName: 'xc_financial_second_grade',
          col: 2,
          extendParams: {
            type: 2
          }
        },
        debit_amount: {
          type: "number",
          placeholder: "借方金額",
          col: 2,
        },
      },
      extendOrderItems: [
        {
          value: 'date_desc',
          text: '依日期 (新到舊)',
          order_by: 'date',
          order_way: 'desc',
        },
        {
          value: 'date_asc',
          text: '依日期 (舊到新)',
          order_by: 'date',
          order_way: 'asc',
        },
      ],
      order: 'date_desc',
      pageMode: true,
    },
    read: {
      titleKey: 'id',
      leftFields: [
        'id',
        'created_at',
        'updated_at',
        'date',
        'name',
        'xc_financial_item',
        'debit_amount',
        'debit_xc_financial_first_grade',
        'debit_xc_financial_second_grade',
        'credit_amount',
        'credit_xc_financial_first_grade',
        'credit_xc_financial_second_grade',
        'creator',
        'remark',
        'attached_files',
        'attached_images'
      ],
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              getFields: (formValue) => {
                return model.getFieldsFromFormValue(formValue);
              },
            },
          ],
        },
      ],
    },
    update: {
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              getFields: (formValue) => {
                return model.getFieldsFromFormValue(formValue);
              },
            },
          ],
        },
      ],
    },
  },
  getFieldsFromFormValue(formValue) {
    let _fields = [
      'date',
      'name',
      'xc_financial_item',
      'debit_xc_financial_first_grade',
      'debit_xc_financial_second_grade',
      'debit_amount',
      'credit_xc_financial_first_grade',
      'credit_xc_financial_second_grade',
      'credit_amount',
      'remark',
      'attached_files',
      'attached_images',
    ];
    if (formValue.xc_financial_item) {
      console.log('123')
      const fieldsToDisable = [
        'debit_xc_financial_first_grade',
        'debit_xc_financial_second_grade',
        'credit_xc_financial_first_grade',
        'credit_xc_financial_second_grade',
      ];
      fieldsToDisable.forEach((field) => {
        model.fields[field].disabled = true;
      });
    } else {
      console.log('456')
      const fieldsToAble = [
        'debit_xc_financial_first_grade',
        'debit_xc_financial_second_grade',
        'credit_xc_financial_first_grade',
        'credit_xc_financial_second_grade',
      ];
      fieldsToAble.forEach((field) => {
        console.log('eeee')
        model.fields[field].disabled = false;
        console.log(model);
      });
    }
    if (!formValue.debit_xc_financial_first_grade) {
      model.fields.debit_xc_financial_first_grade.col = 9;
      const index = _fields.indexOf('debit_xc_financial_second_grade');
      if (index > -1) {
        _fields.splice(index, 1);
      }
    } else {
      model.fields.debit_xc_financial_first_grade.col = 3;
      model.fields.debit_xc_financial_second_grade.extendParams = {
        xc_financial_first_grade: formValue.debit_xc_financial_first_grade.id,
      };
    }
    if (!formValue.credit_xc_financial_first_grade) {
      model.fields.credit_xc_financial_first_grade.col = 9;
      const index = _fields.indexOf('credit_xc_financial_second_grade');
      if (index > -1) {
        _fields.splice(index, 1);
      }
    } else {
      model.fields.credit_xc_financial_first_grade.col = 3;
      model.fields.credit_xc_financial_second_grade.extendParams = {
        xc_financial_first_grade: formValue.credit_xc_financial_first_grade.id,
      };
    }
    return _fields;
  },
};

export default model;
