<template>
  <div>
    <WsBtn @click="$_onClick()">{{_pickerLabel}}</WsBtn>
    <WsModelPickerDialog
      ref="WsModelPickerDialog"
      @submit="$_onModelPickerSubmit($event)"
      :modelName="modelName"
      :fields="_fields"
      :showFields="showFields"
    ></WsModelPickerDialog>
  </div>
</template>

<script>
export default {
  methods: {
    $_onClick() {
      this.$refs.WsModelPickerDialog.open();
    },
    $_onModelPickerSubmit($event) {
      this.$emit("submit", $event);
    },
  },
  computed: {
    _model() {
      return this.$store.state.stone_model[this.modelName];
    },
    _fields() {
      if (this.fields) {
        return this.fields;
      } else {
        return this.$store.state.stone_model[this.modelName].fields;
      }
    },
    _pickerLabel() {
      if (this.pickerLabel) {
        return this.pickerLabel;
      } else if (this._model) {
        return `從 ${this._model.label} 新增`;
      } else {
        return "";
      }
    },
  },
  props: {
    pickerLabel: {},
    modelName: {},
    showFields: {},
  },
};
</script>

<style>
</style>