<template>
  <WsMain>
    <XcTaskRead
      :hideExtendBtns="true"
      :id="_id"
      :fields="_fields"
    >
      <template v-slot:sideInfoPre>
        <div class="mb-40">
          <WsStateForm
            v-model="state"
            :fields="_fieldsHour"
          ></WsStateForm>
          <WsBtn
            class="mt-8"
            @click="$_onSubmit()"
          >送出</WsBtn>
        </div>
      </template>
    </XcTaskRead>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      state: {
        hour: 1,
      },
    };
  },
  methods: {
    async $_onSubmit() {
      this.$store.dispatch("app/startPageLoading");
      try {
        await this.$o_o.$s._m.xc_task.tr(this._id, this.state.hour);
        this.$router.push("/my/task");
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _fieldsHour() {
      return {
        hour: this.$store.state.stone_model.xc_task.fields.hour,
      };
    },
    _fields() {
      const _fields = {
        status: this.$store.state.stone_model.xc_task.fields.status,
        taker: {
          ...this.$store.state.stone_model.xc_task.fields.taker,
          type: "user_label",
        },
        id: this.$store.state.stone_model.xc_task.fields.id,
        xc_project: this.$store.state.stone_model.xc_task.fields.xc_project,
      };
      return _fields;
    },
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>