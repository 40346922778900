<template>
  <div class="ws-state-select__option__item">
    <a
      href="#"
      :class="{active:active}"
      @click.prevent="$_onClick($event)"
    >
      <div
        v-if="checkbox"
        class="checkbox"
      >
        <WsIcon
          :class="{active:active==true}"
          name="icon-md-check-box"
        />
        <WsIcon
          :class="{active:active==false}"
          name="icon-md-check-box-outline-blank"
        />
        <WsIcon
          :class="{active:active=='-'}"
          name="icon-ws-filled-indeterminate-check-box"
        />
      </div>
      <WsText size="14">
        <slot></slot>
      </WsText>
    </a>
    <div
      v-if="items&&items.length"
      class="checkbox-items ml-16"
    >
      <div
        v-for="(item, itemIndex) in items"
        :key="itemIndex"
      >
        <WsStateSelectOptionItem
          :items="item.items"
          :checkbox="checkbox"
          :value="$_getItemsValue(value,item.value)"
          @click="$_onOptionItemClick(value,items,item.value)"
          :active="$_getStatus(value,items,item.value)"
          @input="$_onOptionItemInput($event,value,items,item.value)"
        >{{item.label}}</WsStateSelectOptionItem>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WsStateSelectOptionItem",
  methods: {
    $_onOptionItemInput($event, value, items, itemValue) {
      let _value = value ? [...value] : [];
      const valueIndex = _value.findIndex((e) => {
        return e.value == itemValue;
      });
      if (valueIndex < 0) {
        _value.push({
          value: itemValue,
          itemsValue: [],
        });
      }
      const _valueItem = _value.find((e) => {
        return e.value == itemValue;
      });
      if (!$event || !$event.length) {
        const _valueIndex = _value.findIndex((e) => {
          return e.value == itemValue;
        });
        _value.splice(_valueIndex, 1);
      } else {
        _valueItem.itemsValue = $event;
      }
      this.$emit("input", _value);
    },
    $_getItemsValue(value, itemValue) {
      if (!value) {
        return;
      }
      const targetValue = value.find((e) => {
        return e.value == itemValue;
      });
      if (!targetValue) {
        return;
      }
      return targetValue.itemsValue;
    },
    $_onOptionItemClick(value, items, itemValue) {
      let _value = value ? [...value] : [];
      const status = this.$_getStatus(value, items, itemValue);
      const targetIndex = _value.findIndex((e) => {
        if (typeof e.value === "object" && typeof itemValue === "object") {
          return e.value.id == itemValue.id;
        } else {
          return e.value == itemValue;
        }
      });
      const fullValue = this.$_getFullValueFromItemValue(items, itemValue);
      if (status === true) {
        _value.splice(targetIndex, 1);
      } else if (status == "-") {
        _value.splice(targetIndex, 1, fullValue);
      } else {
        if (targetIndex >= 0) {
          _value.splice(targetIndex, 1);
        }
        _value.push(fullValue);
      }
      this.$emit("input", _value);
    },
    $_getFullValueFromItemValue(items, itemValue) {
      const _fullValue = {
        value: itemValue,
      };

      const targetItem = items.find((e) => {
        return e.value == itemValue;
      });
      if (targetItem.items) {
        _fullValue.itemsValue = [];
        targetItem.items.forEach((targetItemItem) => {
          _fullValue.itemsValue.push(
            this.$_getFullValueFromItemValue(
              targetItem.items,
              targetItemItem.value
            )
          );
        });
      }
      return _fullValue;
    },
    $_onClick() {
      this.$emit("click");
    },
    $_getStatus(value, items, itemValue) {
      if (!value) {
        return false;
      }
      const targetValue = value.find((e) => {
        if (typeof e.value === "object" && typeof itemValue === "object") {
          return e.value.id == itemValue.id;
        } else {
          return e.value == itemValue;
        }
      });
      if (!targetValue) {
        return false;
      }
      if (targetValue && items) {
        const targetItem = items.find((e) => {
          if (typeof e.value === "object" && typeof itemValue === "object") {
            return e.value.id == itemValue.id;
          } else {
            return e.value == itemValue;
          }
        });
        if (targetItem.items) {
          if (targetValue.itemsValue && targetValue.itemsValue.length) {
            let allOk = 1;
            let hasOk = 0;
            targetItem.items.forEach((item) => {
              if (
                this.$_getStatus(
                  targetValue.itemsValue,
                  targetItem.items,
                  item.value
                ) !== true
              ) {
                allOk = 0;
              } else {
                hasOk = 1;
              }
            });
            if (allOk) {
              return true;
            } else {
              if (hasOk) {
                return "-";
              } else {
                return false;
              }
            }
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    },
  },
  props: {
    active: {
      type: [Boolean, Number, String],
    },
    checkbox: {
      type: Boolean,
    },
    items: {
      type: Array,
    },
    value: {
      type: Array,
    },
  },
};
</script>