<template>
  <div class="ws-file-input-btn">
    <WsBtn
      :loading="loading"
      outlined
      @click="$_onUploadClick()"
    >{{_btnText}}</WsBtn>
    <input
      @change="$_onInputChange($event)"
      ref="fileInput"
      type="file"
      accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    />
    <h3
      class="mb-13 mt-20"
      v-if="value"
    >{{value.name}}</h3>
  </div>
</template>

<script>
export default {
  methods: {
    $_onInputChange($event) {
      this.$emit("input", $event.target.files[0]);
    },
    $_onUploadClick() {
      this.$refs.fileInput.click();
    },
  },

  watch: {
    value: {
      handler() {
        if (!this.value) {
          this.$refs.fileInput.value = null;
        }
      },
    },
  },

  computed: {
    _btnText() {
      if (this.value) {
        return this.changeText;
      } else {
        return this.placeholder;
      }
    },
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: File,
      default: null,
    },
    placeholder: {
      type: String,
      default: "選擇檔案",
    },
    changeText: {
      type: String,
      default: "更換檔案",
    },
  },
};
</script>

<style>
</style>