<template>
  <div class="calendar-control-panel">
    <WsFlex>
      <CalendarControllPanelIconBtn
        tooltip="往前"
        name="icon-ws-outline-chevron-back"
        @click.native="$emit('prev')"
      ></CalendarControllPanelIconBtn>
      <CalendarControllPanelIconBtn
        tooltip="往後"
        name="icon-ws-outline-chevron-forward"
        @click.native="$emit('next')"
      ></CalendarControllPanelIconBtn>
      <CalendarControllPanelIconBtn
        tooltip="回到今天"
        name="icon-md-refresh"
        @click.native="$emit('goDate',$moment().toDate())"
      ></CalendarControllPanelIconBtn>
      <CalendarControllPanelIconBtn
        :text="true"
        @click.native="$emit('changeView','timeGridDay')"
      >日</CalendarControllPanelIconBtn>
      <CalendarControllPanelIconBtn
        :text="true"
        @click.native="$emit('changeView','timeGridWeek')"
      >週</CalendarControllPanelIconBtn>
      <CalendarControllPanelIconBtn
        :text="true"
        @click.native="$emit('changeView','dayGridMonth')"
      >月</CalendarControllPanelIconBtn>
    </WsFlex>
  </div>
</template>

<script>
export default {};
</script>

<style>
.calendar-control-panel {
  width: 240px;
  flex: 0 0 auto;
}
</style>