<template>
  <WsMain>
    <WsAnalyticsGroup :items="analytics"></WsAnalyticsGroup>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      analytics: [
        {
          title: "當前現金",
          type: "sum",
          format: "$0,0",
          fetchUrl: "/calc/sum/xc_double_entry_record/cash_amount",
        },
        {
          title: "當前銀行存款",
          type: "sum",
          format: "$0,0",
          fetchUrl: "/calc/sum/xc_double_entry_record/deposit_amount",
        },
        {
          title: "當前應收款項",
          type: "sum_mass",
          format: "$0,0",
          fetchUrl: "calc/sum_mass/xc_double_entry_record/receivable_amount",
        },
        // {
        //   title: "當前應收款項",
        //   type: "sum",
        //   format: "$0,0",
        //   fetchUrl: "/calc/sum/xc_double_entry_record/receivable_amount",
        // },
        {
          title: "當前應付款項",
          type: "sum",
          format: "$0,0",
          fetchUrl: "/calc/sum/xc_double_entry_record/payable_amount",
        },
        {
          title: "收入",
          type: "sum",
          format: "$0,0",
          fetchUrl: "/calc/sum/xc_double_entry_record/income_amount",
          fields: {
            date: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "收入",
          type: "trending",
          fetchUrl: "/calc/trending/xc_double_entry_record/income_amount",
          fields: {
            date: {
              type: "date-range",
              defaultValue: [
                this.$moment()
                  .add(-6, "month")
                  .startOf("month")
                  .format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "支出",
          type: "sum",
          format: "$0,0",
          fetchUrl: "/calc/sum/xc_double_entry_record/expenditure_amount",
          fields: {
            date: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "支出",
          type: "trending",
          fetchUrl: "/calc/trending/xc_double_entry_record/expenditure_amount",
          fields: {
            date: {
              type: "date-range",
              defaultValue: [
                this.$moment()
                  .add(-6, "month")
                  .startOf("month")
                  .format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "通用二級科目",
          type: "sum",
          format: "$0,0",
          fetchUrl: "/calc/sum/xc_double_entry_record/amount",
          fields: {
            debit_xc_financial_second_grade: {
              type: "belongsToMany",
              modelName: "xc_financial_second_grade",
              placeholder: "選擇二級借科",
              extendParams: {
                type: 1,
              },
            },
            credit_xc_financial_second_grade: {
              type: "belongsToMany",
              modelName: "xc_financial_second_grade",
              placeholder: "選擇二級貸科",
              extendParams: {
                type: 2,
              },
            },
            date: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "通用二級科目",
          type: "trending",
          fetchUrl: "/calc/trending/xc_double_entry_record/amount",
          fields: {
            debit_xc_financial_second_grade: {
              type: "belongsToMany",
              modelName: "xc_financial_second_grade",
              placeholder: "選擇二級借科",
              rules: "required",
              optionAll: true,
              extendParams: {
                type: 1,
              },
            },
            credit_xc_financial_second_grade: {
              type: "belongsToMany",
              modelName: "xc_financial_second_grade",
              placeholder: "選擇二級貸科",
              extendParams: {
                type: 2,
              },
            },
            date: {
              type: "date-range",
              defaultValue: [
                this.$moment()
                  .add(-6, "month")
                  .startOf("month")
                  .format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
      ],
    };
  },
};
</script>