<template>
  <WsModelFetchIndex
    :modelName="_modelName"
    :params="_params"
    :extendParams="extendParams"
    @fetch="$_onFetch($event)"
    ref="WsModelFetchIndex"
  >
    <WsStateSelectMultiLayers
      :items="_items"
      :placeholder="placeholder"
      @input="$_onInput($event)"
      :value="_value"
      @scroll-to-bottom="$_fetchItems()"
      :showTags="showTags"
      @search="$_onSearching($event)"
    />
  </WsModelFetchIndex>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      modelDatas: [],
      page: 1,
      lastPage: 100,
      fetchAll: false,
      searching: "",
    };
  },
  methods: {
    $_onFetch($data) {
      if ($data.meta) {
        this.lastPage = $data.meta.last_page
      } else {
        this.fetchAll = true
      }
      this.modelDatas.push(...$data.data)
      this.loading = false
    },
    $_onInput($event) {
      let _value = this.value ? { ...this.value } : {};
      this.layerFields.forEach((layerField) => {
        _value[layerField.stateKey] = [];
      });
      _value = this.$_setValueFromSelectEvent(_value, this.layerFields, $event);
      this.$emit("input", _value);
    },
    $_setValueFromSelectEvent(value, layerFields, $event) {
      let _value = value ? { ...value } : {};
      const _layerFields = [...layerFields];
      _layerFields.splice(0, 1);
      $event.forEach((eventItem) => {
        const _exist = _value[layerFields[0].stateKey].find((e) => {
          return e == eventItem.value;
        });
        if (!_exist) {
          _value[layerFields[0].stateKey].push(eventItem.value);
        }
        if (layerFields.length > 1 && eventItem.itemsValue) {
          _value = this.$_setValueFromSelectEvent(
            _value,
            _layerFields,
            eventItem.itemsValue
          );
        }
      });
      return _value;
    },
    async $_fetchItems() {
      if (this.loading || this.page >= this.lastPage || ( this.fetchAll && this.searching == '')) {
        return
      }
      this.page++
      this.loading = true
      this.$refs.WsModelFetchIndex.$_fetchModel()
    },
    $_modelDatasReset() {
      this.modelDatas = [];
      this.page = 0;
      this.lastPage = 100;
      this.fetchAll = false;
    },
    $_getLayerItems(modelDatas, layerFields) {
      const _layerItems = [];
      const layerField = layerFields[0];
      // const valueKey = layerField.valueKey ? layerField.valueKey : "id";
      const labelKey = layerField.labelKey ? layerField.labelKey : "name";

      modelDatas.forEach((modelData) => {
        const _layerItem = {
          value: modelData,
          label: modelData[labelKey],
        };
        const _layerFields = [...layerFields];
        _layerFields.splice(0, 1);
        if (
          layerFields.length > 1 &&
          modelData[layerFields[1].modelKey] &&
          modelData[layerFields[1].modelKey].length
        ) {
          const nextItemKey = layerFields[1].modelKey;
          _layerItem.items = this.$_getLayerItems(
            modelData[nextItemKey],
            _layerFields
          );
        }
        _layerItems.push(_layerItem);
      });
      return _layerItems;
    },
    $_getSelectValue(value, layerFields, items) {
      if (!value) {
        return null;
      }
      const stateKey = layerFields[0].stateKey;
      if (!value[stateKey]) {
        return null;
      }
      const _selectValue = [];
      value[stateKey].forEach((valueItem) => {
        const exist = items.find((e) => {
          return e.value.id == valueItem.id;
        });
        if (!exist) {
          return;
        }
        const _selectValueItem = {
          value: valueItem,
        };
        if (layerFields.length > 1) {
          const _layerFields = [...layerFields];
          _layerFields.splice(0, 1);
          const tarItem = items.find((e) => {
            return e.value.id == valueItem.id;
          });
          if (tarItem.items) {
            _selectValueItem.itemsValue = this.$_getSelectValue(
              value,
              _layerFields,
              tarItem.items
            );
          }
        }
        _selectValue.push(_selectValueItem);
      });
      return _selectValue;
    },
    $_onSearching($event) {
      this.searching = $event;
      if(this.searching == '')
        this.$_modelDatasReset()
    },
  },
  computed: {
    _value() {
      return this.$_getSelectValue(this.value, this.layerFields, this._items);
    },
    _items() {
      return this.$_getLayerItems(this.modelDatas, this.layerFields);
    },
    _modelName() {
      return this.layerFields[0].modelName;
    },
    _params() {
      let params = {
        search: this.searching,
        page: this.page,
      }
      return params
    }
  },
  watch: {
    extendParams: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.$_modelDatasReset();
          this.$_fetchItems();
          this.$emit("input", {});
        }
      },
    },
    searching: {
      handler() {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.modelDatas = [];
          this.page = 0;
          this.dataTotalCount = 0;
          this.lastPage = 1;
          this.$_fetchItems();
        }, 500);
      },
    },
  },
  props: {
    showTags: {
      type: Boolean,
      default: false,
    },
    extendParams: {
      type: Object,
    },
    layerFields: {
      type: Array,
    },
    value: {
      type: Object,
    },
    placeholder: {
      type: String,
    },
  },
};
</script>