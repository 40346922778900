export default {
  xc_task: {
    icon: 'icon-fa-score',
    title: 'Task',
    link: '/xc_task',
    scopes: ['xc_task-admin'],
  },
  xc_task_template: {
    icon: 'icon-md-chrome-reader-mode',
    title: 'Task Template',
    link: '/xc_task_template',
    scopes: ['xc_task-admin'],
  },
  xc_task_execute_ref_item: {
    icon: 'icon-md-border-all',
    title: 'Task 執行參考項目',
    link: '/xc_task_execute_ref_item',
    scopes: ['xc_task-admin'],
  },
  my_xc_task: {
    icon: 'icon-fa-score',
    title: '我的 Task',
    link: '/my/task',
  },
  myown_xc_task: {
    icon: 'icon-fa-score',
    title: '我的 管理 Task',
    link: '/my/owntask',
    scopes: ['xc_task-admin']
  },
}