
const routes = [
  {
    path: '/system_info',
    name: 'system_info',
    component: () =>
      import('@/__modules/system/views/SystemInfo/Read.vue'),
  },
  {
    path: '/system_info/update',
    name: 'system_info/update',
    component: () =>
      import('@/__modules/system/views/SystemInfo/Update.vue'),
  },
]
export default routes