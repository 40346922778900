const model = {
  modelName: 'image',
  label: '圖片',
  options: {
    id: true,
    timestamp: true,
  },
  fields: {
    // service: {
    //   type: 'text',
    //   label: 'Service',
    //   readonly: true
    // },
    // storage_url: {
    //   type: 'text',
    //   label: 'Storage URL',
    //   readonly: true
    // },
    public_url: {
      type: 'image',
      label: 'Image',
      readonly: true
    },
    // signed: {
    //   type: 'switch',
    //   label: 'Signed',
    // },
    type: {
      type: 'radio',
      label: 'Type',
      readonly: true,
      items: [
        {
          value: 'file',
          label: 'File'
        },
        {
          value: 'image',
          label: 'Image'
        },
      ]
    },
    file_name: {
      type: 'text',
      label: 'File Name',
      // readonly: true,
    },
    // file_type: {
    //   type: 'text',
    //   label: 'File Type',
    //   readonly: true,
    // },
    file_size: {
      type: 'file-size',
      label: 'File Size',
      readonly: true,
    },
    tags: {
      type: 'tags',
      label: 'Tags',
    },
    content_type: {
      type: 'text',
      label: 'Content Type',
      readonly: true
    }
  },
  crudSetting: {
    index: {
      liveSearching: false,
      showFields: ['created_at', 'file_name', 'public_url', 'tags'],
      // creatable: false,
      pageMode: true,
      dialogUpdate: true,
      dialogRead: true,
    },
  },
};

export default model;
