export default {
  xc_service_order_calc: {
    icon: 'icon-md-trending-up',
    title: '統計分析',
    link: '/calc/xc_service_order',
    scopes: ['xc_service_order-admin'],
  },
  xc_service_order: {
    icon: 'icon-md-style',
    title: '訂單',
    link: '/xc_service_order',
    scopes: ['xc_service_order-admin'],
  },
  xc_service_order_stage: {
    icon: 'icon-md-style',
    title: '付款階段',
    link: '/xc_service_order_stage',
    scopes: ['xc_service_order-admin'],
  },
}