<template>
  <div class="ws-state-wasa-intro">
    <WsFlexWrap>
      <WsBtn @click="$_onCreateOpen()">新增內容區塊</WsBtn>
      <WsBtn
        class="ml-10"
        @click="$_onSortOpen()"
      >{{$t('adjust_the_order')}}</WsBtn>
      <WsSortDialog
        ref="WsSortDialog"
        @input="$emit('input',$event)"
        :value="value"
      ></WsSortDialog>
    </WsFlexWrap>
    <WsCard
      color="#333"
      class="ws-state-wasa-intro__card mt-20"
      v-for="(introItem, introIndex) in value"
      :key="introIndex"
    >
      <WsState
        :label="$t('區塊名稱')"
        v-model="introItem.name"
      ></WsState>
      <WsState
        label="區塊錨點（同一頁面不能使用相同命名，僅限輸入英文及數字）"
        v-model="introItem.anchorId"
        type="text"
      />
      <WsIconBtn
        @click="$_onRemove(introIndex)"
        class="ws-state-wasa-intro__card__remove-btn"
        name="icon-md-close"
      />
      <WsText
        class="mt-20"
        size=6
      >{{$t('section_content')}}</WsText>
      <WsFlex class="mt-10">
        <WsFull
          v-for="(col, colIndex) in introItem.cols"
          :key="colIndex"
        >
          <WsCard v-if="col.type=='image'">
            <WsState
              type="image"
              :label="$t('圖片')"
              :value="col.state.image"
              @input="$_onColStateInput(introIndex,colIndex,'image',$event,)"
            ></WsState>
          </WsCard>
          <WsCard v-else-if="col.type=='intro'">
            <WsState
              type="image"
              :label="$t('圖片')"
              :value="col.state.image"
              @input="$_onColStateInput(introIndex,colIndex,'image',$event,)"
            />
            <WsState
              type="text"
              :label="$t('標題')"
              :value="col.state.h2"
              @input="$_onColStateInput(introIndex,colIndex,'h2',$event,)"
            ></WsState>
            <WsState
              type="text"
              :label="$t('副標題')"
              :value="col.state.h3"
              @input="$_onColStateInput(introIndex,colIndex,'h3',$event,)"
            ></WsState>
            <WsState
              type="textarea"
              :label="$t('說明')"
              :value="col.state.text"
              @input="$_onColStateInput(introIndex,colIndex,'text',$event,)"
            ></WsState>
            <WsState
              type="link"
              :label="$t('外部連結')"
              :value="col.state.link"
              @input="$_onColStateInput(introIndex,colIndex,'link',$event,)"
            ></WsState>
            <WsState
              type="text"
              :label="$t('按鈕文字')"
              :value="col.state.btntext"
              @input="$_onColStateInput(introIndex,colIndex,'btntext',$event,)"
            ></WsState>
          </WsCard>
        </WsFull>
      </WsFlex>
    </WsCard>
    <WsDialogPicker
      ref="WsDialogPicker"
      :title="$t('選擇類型')"
      @submit="$_onPickerSubmit($event)"
      :items="sectionTypeItems"
    ></WsDialogPicker>
    <WsSortDialog
      ref="WsSortDialog"
      @input="$emit('input',$event)"
      :value="value"
    ></WsSortDialog>
  </div>
</template>

<script>
export default {
  methods: {
    $_onRemove(introIndex) {
      const _value = [...this.value];
      _value.splice(introIndex, 1);
      this.$emit("input", _value);
    },
    $_onColStateInput(introIndex, colIndex, target, $event) {
      const _value = JSON.parse(JSON.stringify(this.value));
      _value[introIndex].cols[colIndex].state[target] = $event;
      this.$emit("input", _value);
    },
    $_onPickerSubmit($event) {
      const _sectionContent = [];
      const imageContet = {
        type: "image",
        state: {
          image: "",
        },
      };
      const introContet = {
        type: "intro",
        state: {
          image: "",
          h2: "",
          h3: "",
          text: "",
          link: "",
          btntext: "",
        },
      };
      if ($event.value == 0) {
        _sectionContent.push(imageContet);
      } else if ($event.value == 1) {
        _sectionContent.push(introContet);
      } else if ($event.value == 2) {
        _sectionContent.push(introContet);
        _sectionContent.push(imageContet);
      } else if ($event.value == 3) {
        _sectionContent.push(imageContet);
        _sectionContent.push(introContet);
      } else if ($event.value == 4) {
        _sectionContent.push(introContet);
        _sectionContent.push(introContet);
      } else if ($event.value == 5) {
        _sectionContent.push(imageContet);
        _sectionContent.push(imageContet);
      }
      const _value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      _value.push({
        name: "",
        cols: _sectionContent,
      });
      this.$emit("input", _value);
    },
    $_onCreateOpen() {
      this.$refs.WsDialogPicker.open();
    },
    $_onSortOpen() {
      this.$refs.WsSortDialog.open();
    },
  },

  props: {
    value: {
      type: [Object, Array],
      default: null,
    },
    sectionTypeItems: {
      type: Array,
      default() {
        return [
          {
            text: this.$t("滿版圖"),
            value: 0,
          },
          {
            text: this.$t("版滿內容"),
            value: 1,
          },
          {
            text: '左圖文 右圖',
            value: 2,
          },
          {
            text: '左圖 右圖文',
            value: 3,
          },
          {
            text: '左圖文 右圖文',
            value: 4,
          },
          {
            text: '左圖 右圖',
            value: 5,
          },
        ];
      },
    },
  },
};
</script>

<style>
</style>