<template>
  <div class="ws-model-index__selects">
    <WsStateForm
      class="ma-0 py-4 px-8"
      :fields="_fields"
      :value="value"
      @input="$emit('input',$event)"
    ></WsStateForm>
  </div>
</template>

<script>
export default {
  props: {
    fields: {},
    value: {},
    orderItems: {},
  },
  computed: {
    _fields() {
      const _fields = {};
      for (const fieldKey in this.fields) {
        const _field = this.fields[fieldKey];
        _fields[fieldKey] = {
          ..._field,
          label: "",
          placeholder: _field.label ? _field.label : _field.placeholder,
          col: _field.col ? _field.col : 3,
        };
      }
      return {
        search: {
          type: "search",
          col: 3,
        },
        order: {
          groupstate: true,
          type: "order",
          items: this.orderItems,
          col: 3,
        },
        ..._fields,
      };
    },
  },
};
</script>

<style lang="scss">
.ws-model-index__selects {
  background-color: var(--white2d);
}
</style>