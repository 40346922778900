import store from '@/store'

export default {
  hasScope(scopes = []) {
    if (!scopes || !scopes.length) {
      return true
    }
    let hasScope = false
    const myScopes = store.state.auth.scopes
    myScopes.forEach(myScope => {
      if (scopes.includes(myScope)) {
        hasScope = true
      }
    });
    return hasScope
  },
}