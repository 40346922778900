const model = {
  fields: {
    xc_rfq: {
      type: 'belongsTo',
      label: 'RFQ',
      textKey: 'title',
      relationPopup: true,
      modelName: 'xc_rfq'
    },
  },
  crudSetting: {
    index: {
      // showFields: [
      // ]
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "xc_rfq",
              ],
            },
          ],
        },
      ],
    },
    update: {
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "xc_rfq",
              ],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: [
        "xc_rfq",
      ],
    }
  }
}
export default model