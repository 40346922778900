<template>
  <div>
    <WsText
      size="16"
      class="mt-6"
    >
      從以下網站尋找與設定樣式，將設定參數貼於下方
    </WsText>
    <WsLink
      href="https://mapstyle.withgoogle.com"
      target="_blank"
    >
      Withgoogle
    </WsLink>
    <br>
    <WsLink
      href="https://snazzymaps.com/explore?sort=popular"
      target="_blank"
    >
      Snazzymaps
    </WsLink>
    <WsStateJson
      :value="value"
      @input="$_input"
      @valid-is-false="$_validIsFalse"
    ></WsStateJson>
    <WsText
      size="16"
      class="mt-6"
      :color="isValid ? $color.success : $color.danger"
    >
      驗證：{{ isValid?'通過':'不通過，請檢查是否有複製完整參數並再次複製貼上' }}
    </WsText>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isValid: null
    }
  },
  props: {
    value: {},
  },
  methods: {
    $_input(_value) {
      this.$emit("input", _value)
      this.isValid = true
    },
    $_validIsFalse(){
      this.isValid = false
    }
  },
  mounted() {
  },
}
</script>