export default {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "quotation_execute_item_template",
  label: "報價單執行項目公版",
  fields: {
    name: {
      type: 'text',
      label: '名稱'
    },
    // execute_day: {
    //   type: 'number',
    //   label: "執行天數"
    // },
    price: {
      type: 'number',
      label: '價格'
    },
    content: {
      type: 'textarea',
      label: '內容文字'
    }
  }
}