<template>
  <div class="ws-calendar-month__container__table__week">
    <div class="ws-calendar-month__container__table__week__bg">
      <div
        class="ws-calendar-month__container__table__week__bg__date"
        v-for="showWeekday in showWeekdays"
        :key="`ws-calendar-month__container__table__week__bg__date-${showWeekday}`"
        :class="[
          {'is-out-month':$_checkIsOutMonth(showWeekday)}
        ]"
      ></div>
    </div>
    <div class="ws-calendar-month__container__table__week__info">
      <div
        class="ws-calendar-month__container__table__week__info__date"
        v-for="showWeekday in showWeekdays"
        :key="`ws-calendar-month__container__table__week__info-${showWeekday}`"
        :class="[
          {'is-today':$_checkIsToday(showWeekday)}
        ]"
      >
        <div class="ws-calendar-month__container__table__week__info__date__text">
          {{$_getCalendarItemMoment(showWeekday).format('D')}}
        </div>
      </div>
    </div>
    <div class="ws-calendar-month__container__table__week__events">
      <WsCalendarMonthWeekEventsDate
        v-for="showWeekday in showWeekdays"
        :key="`ws-calendar-month__container__table__week__events-${showWeekday}`"
        :events="eventsInKey[$_getCalendarItemMoment(showWeekday).format('YYYY-MM-DD')].events"
        :eventHeight="eventHeight"
        :maxEventDisplayCount="maxEventDisplayCount"
      />
      <!-- <div
        class="ws-calendar-month__container__table__week__events__date"
        v-for="showWeekday in showWeekdays"
        :key="`ws-calendar-month__container__table__week__events-${showWeekday}`"
      >
        <div
          class="ws-calendar-month__container__table__week__events__date__item"
          v-for="(event,eventIndex) in eventsInKey[$_getCalendarItemMoment(showWeekday).format('YYYY-MM-DD')].events"
          :key="eventIndex"
          :style="[
            {'height':`${eventHeight}px`}
          ]"
        >
          <div class="ws-calendar-month__container__table__week__events__date__item-label">
            {{item.label}}
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    $_getCalendarItemMoment(showWeekday) {
      return this.$moment(this.startDateWithShowWeekdays).add(
        showWeekday - this.showWeekdays[0],
        "day"
      );
    },
    $_checkIsToday(showWeekday) {
      const itemMoment = this.$_getCalendarItemMoment(showWeekday);
      if (
        itemMoment.get("year") == this.todayMoment.get("year") &&
        itemMoment.get("month") == this.todayMoment.get("month") &&
        itemMoment.get("date") == this.todayMoment.get("date")
      ) {
        return true;
      } else {
        return false;
      }
    },
    $_checkIsOutMonth(showWeekday) {
      const itemMoment = this.$_getCalendarItemMoment(showWeekday);
      if (itemMoment.get("month") == this.stateMoment.get("month")) {
        return false;
      } else {
        return true;
      }
    },
  },
  props: {
    showWeekdays: {},
    startDateWithShowWeekdays: {},
    todayMoment: {},
    stateMoment: {},
    eventsInKey: {},
    eventHeight: {},
    maxEventDisplayCount: {},
  },
};
</script>

<style>
</style>