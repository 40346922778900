<template>
  <div class="ws-read-section">
    <WsAlert
      ref="deleteAlert"
      :title="$t('sure_to_delete')"
      :description="$t('delete_cannot_be_recovered')"
      @submit="$_onDeleteSubmit($event)"
    ></WsAlert>
    <WsLoading v-if="loadingFetch"></WsLoading>
    <template v-else-if="modelData">
      <WsBreadcrumbs
        :menu="_breadcrumbsItems"
        v-if="showBreadcrumbs"
      ></WsBreadcrumbs>
      <div
        v-if="_title || _updatable || _deletable"
        class="ws-read-section__titlebar mt-20"
      >
        <WsText
          size="20"
          class="pt-6"
        >{{_title}}</WsText>
        <div class="ws-read-section__titlebar__btns">
          <WsBtn
            v-if="_updatable"
            outlined
            :to="`/${_urlModelName}/${id}/update`"
          >{{$t('edit')}}</WsBtn>
          <WsBtn
            v-if="_deletable"
            class="ml-10"
            alert
            :outlined="false"
            :loading="loadingDelete"
            @click="$_onDelete()"
          >{{$t('delete')}}</WsBtn>
        </div>
      </div>
      <WsFlex class="mt-12">
        <WsBtn
          v-for="(customBtn, customBtnIndex) in customBtns"
          :key="`btn${customBtnIndex}`"
          class="mr-20"
          :to="customBtn.to"
          :disabled="customBtn.disabled"
          @click="$_onCustomBtnClick(customBtn)"
        >
          <WsIcon
            v-if="customBtn.icon&&customBtn.icon.left"
            :size="customBtn.icon.size||'24'"
            :name="customBtn.icon.name||'icon-md-menu'"
            class="mr-3"
          />
          {{customBtn.label}}
          <WsIcon
            v-if="customBtn.icon&&customBtn.icon.right"
            :size="customBtn.icon.size||'24'"
            :name="customBtn.icon.name||'icon-md-menu'"
            class="mr-3"
          />
        </WsBtn>
        <WsStateSelect
          v-for="(dropdownItem,dropdownIndex) in customDropdownMenu"
          :key="`dropdown${dropdownIndex}`"
          isEmitItem
          :items="dropdownItem.items"
          :value="dropdownItem.value"
          :modelName="dropdownItem.modelName"
          :textKey="dropdownItem.textKey"
          :valueKey="dropdownItem.valueKey"
          :placeholder="dropdownItem.placeholder"
          @input="$_onCustomDropdownMenuClick($event,dropdownItem)"
        ></WsStateSelect>
      </WsFlex>
      <WsCard class="mt-20">
        <WsInfoForm
          v-if="showFields"
          :fields="_fields"
          :value="modelData"
        ></WsInfoForm>
        <div
          v-else
          class="ws-row"
        >
          <div class="ws-col sm-col-12 col-6">
            <!-- <div class="ws-row pa-8"> -->
            <slot name="leftInfoForm">
              <WsInfoForm
                :fields="_leftFields"
                :value="modelData"
              ></WsInfoForm>
            </slot>
            <!-- </div> -->
          </div>
          <div class="ws-col sm-col-12 col-6">
            <!-- <div class="ws-row pa-8"> -->
            <slot name="rightInfoForm">
              <WsInfoForm
                :fields="_rightFields"
                :value="modelData"
              ></WsInfoForm>
            </slot>
            <!-- </div> -->
          </div>
        </div>
      </WsCard>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    modelData: null,
    loadingFetch: false,
    loadingDelete: false,
    updateData: null,
  }),

  methods: {
    async $_onDeleteSubmit() {
      try {
        this.loadingDelete = true;
        await this.$axios.delete(`/${this.modelName}/${this.id}`);
        this.$router.push(`/${this._urlModelName}`);
      } catch (error) {
        console.error(error);
        alert("刪除錯誤");
      } finally {
        this.loadingDelete = false;
      }
    },
    $_onDelete() {
      if (this.emitDelete) {
        this.$emit('delete', this.modelData)
        return
      }
      this.$refs.deleteAlert.open();
    },
    async $_fetchModelData() {
      try {
        this.loadingFetch = true;
        const res = await this.$axios.get(`${this.modelName}/${this.id}`);
        this.modelData = res.data.data;
        this.$emit("input", this.modelData);
      } catch (error) {
        alert("取得資料錯誤");
      } finally {
        this.loadingFetch = false;
      }
    },
    $_onCustomBtnClick(btn) {
      if (btn.to) {
        return;
      } else {
        this.$emit("title-bar-custom-btn-click", btn.event);
      }
    },
    $_onCustomDropdownMenuClick($event, dropdownItem) {
      const item = {
        id: dropdownItem.id,
        value: $event,
      };
      this.$emit("title-custom-dropdown-menu-click", item);
    },
    async $_onInputUpdate($event) {
      //post api
      try {
        await this.$axios.patch(`/${this.modelName}/${this._id}`, $event);
        this.modelData = { ...this.modelData, ...$event };
      } catch (err) {
        alert(err);
      }
    },
    $_getPopupText(field, popupText, popupFunction) {
      if (field[popupText]) {
        return field[popupText];
      } else if (field[popupFunction]) {
        return field[popupFunction](this.modelData);
      } else {
        return "";
      }
    },
  },

  computed: {
    _id() {
      return this.$route.params.id;
    },
    _fields() {
      let _fields = {};
      if (this.showFields) {
        this.showFields.forEach((fieldKey) => {
          if (this.fields[fieldKey]) {
            _fields[fieldKey] = this.fields[fieldKey];
          }
        });
      } else {
        _fields = { ...this.fields };
      }
      if (
        !this.$o_o.$h.auth.hasScope(["site_region_cross-admin"]) &&
        _fields.site_region
      ) {
        delete _fields.site_region;
      }
      return _fields;
    },
    _leftFields() {
      let showFields = [];
      if (this.getFields) {
        showFields = this.getFields(this.modelData);
      } else if (!this.leftFields) {
        return {};
      } else {
        showFields = this.leftFields;
      }
      const _leftFields = {};
      showFields.forEach((fieldKey) => {
        if (this._fields[fieldKey]) {
          _leftFields[fieldKey] = this._fields[fieldKey];
        }
      });
      return _leftFields;
    },
    _rightFields() {
      if (!this.rightFields) {
        return {};
      }
      const rightFields = [...this.rightFields];
      if (
        this.$o_o.$h.auth.hasScope(["site_region_cross-admin"]) &&
        !rightFields.includes("site_region")
      ) {
        rightFields.push('site_region')
      }
      const _rightFields = {};
      rightFields.forEach((fieldKey) => {
        if (this._fields[fieldKey]) {
          _rightFields[fieldKey] = this._fields[fieldKey];
        }
      });
      return _rightFields;
    },
    _breadcrumbsItems() {
      const _breadcrumbsItems = [];
      if (this.returnModelName) {
        _breadcrumbsItems.push({
          text: this.$t(this.label),
          to: `/${this.returnModelName}`,
          disabled: false,
        });
      } else {
        _breadcrumbsItems.push({
          text: this.$t(this.label),
          to: `/${this._urlModelName}`,
          disabled: false,
        });
      }

      _breadcrumbsItems.push({
        text: this._title,
        disabled: false,
      });
      return _breadcrumbsItems;
    },
    _title() {
      if (this.title) {
        return this.title;
      } else if (!this.modelData) {
        return null;
      } else {
        return this.modelData[this.titleKey];
      }
    },
    _urlModelName() {
      return this.urlModelName ? this.urlModelName : this.modelName;
    },
    _updatable() {
      if (typeof this.updatable === 'boolean') {
        return this.updatable
      } else if (typeof this.updatable === 'function') {
        return this.updatable(this.value)
      } else {
        return true
      }
    },
    _deletable() {
      if (typeof this.deletable === 'boolean') {
        return this.deletable
      } else if (typeof this.deletable === 'function') {
        return this.deletable(this.value)
      } else {
        return true
      }
    }
  },

  props: {
    label: {
      type: String,
      default: null,
    },
    modelName: {
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    titleKey: {
      type: String,
      default: "name",
    },
    fields: {
      type: Object,
      required: true,
    },
    leftFields: {
      type: Array,
    },
    rightFields: {
      type: Array,
    },
    updatable: {
      type: [Boolean, Function],
      default: true,
    },
    deletable: {
      type: [Boolean, Function],
      default: true,
    },
    value: {
      type: [Object, Array],
    },
    customBtns: {
      type: Array,
      default() {
        return [];
      },
    },
    customDropdownMenu: {
      type: Array,
      default() {
        return [];
      },
    },
    showBreadcrumbs: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    showFields: {
      type: Array,
    },
    returnModelName: {
      type: String,
      default: "",
    },
    urlModelName: String,
    getFields: {
      type: Function,
    },
    emitDelete: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    modelData: {
      handler() {
        this.$emit("input", this.modelData);
      },
    },
    value: {
      handler() {
        this.modelData = this.value;
      },
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    this.$_fetchModelData();
  },
};
</script>

<style>
</style>