<template>
  <WsModelFetchIndex
    :modelName="modelName"
    :params="params"
    @fetch="$_onFetch($event)"
  >
    <WsReply
      class="my-12"
      v-for="(reply, replyIndex) in replies"
      :id="reply.id"
      :key="replyIndex"
      v-bind="reply"
      :user="reply.admin"
      :params="params"
      :modelName="modelName"
      @update="$_onReplyUpdate(replyIndex, $event)"
      @delete="$_onReplyDelete(replyIndex)"
    ></WsReply>
    <WsReplyInput
      ref="WsReplyInput"
      @submit="$_onReplySubmit($event)"
    />
  </WsModelFetchIndex>
</template>
<script>
export default {
  data() {
    return {
      replies: [],
    };
  },
  methods: {
    $_onReplyDelete(replyIndex) {
      const _replies = [...this.replies];
      _replies[replyIndex] = {
        ..._replies[replyIndex],
        content: null,
        user_deleted_at: this.$moment().format("YYYY-MM-DD hh:mm:ss"),
      };
      // _replies.splice(replyIndex, 1);
      this.replies = _replies;
    },
    $_onReplyUpdate(replyIndex, $event) {
      const _replies = [...this.replies];
      _replies.splice(replyIndex, 1);
      _replies.splice(replyIndex, 0, {
        ...$event,
        edit_at: this.$moment().format("YYYY-MM-DD hh:mm:ss"),
      });
      this.replies = _replies;
    },
    async $_onReplySubmit($event) {
      if (this.$store.state.app.pageLoading) {
        return;
      }
      this.$store.dispatch("app/startPageLoading");
      try {
        const res = await this.$axios.post(`${this.modelName}`, {
          ...this.params,
          content: $event,
        });
        this.replies.push(res.data.data);
        this.$refs.WsReplyInput?.clear();
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onFetch($event) {
      this.replies = $event.data;
      this.$nextTick(() => {
        this.$emit("scrollToHash");
      });
    },
  },
  computed: {},
  props: {
    modelName: {},
    params: {},
    extendPostData: {},
  },
};
</script>