<template>
  <!-- <WsStateRadio
    :items="items"
    :value="value"
    @input="$emit('input',$event)"
  ></WsStateRadio> -->
  <WsState
    type="checkbox"
    @input="$emit('input',$event)"
    :value="value"
    :text="label"
  ></WsState>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    label: {
      type: String,
    },
  },
};
</script>

<style>
</style>