// toolbarActions
//  - step-record
//  - text-color
//  - text-hightlight
//  - text-align
//  - text-style
//  - code
//  - text-h1
//  - text-h2
//  - text-h3
//  - text-h4
//  - text-h5
//  - text-h6
//  - text-quote
//  - text-list
//  - link
//  - image
//  - table
//  - superscript
//  - subscript

<template>
  <div
    @drop="$_onDrop"
    @paste="$_onPaste"
    v-on:dragover.prevent
    v-if="editor"
    class="ws-state-editor"
    ref="wsStateEditor"
  >
    <component
      :is="'style'"
      type="text/css"
    >
      {{ global_style }}
    </component>
    <div
      v-if="toolbarActions"
      class="menubar"
    >
      <div
        v-if="$_hasToolbarAction('input-bg-color')"
        class="toolbar darkmood-switch"
      >
        <WsState
          label="Dark Mode"
          type="switch"
          v-model="active.darkmode"
          activeText="on"
          inactiveText="off"
          arrange="row"
          showText
        ></WsState>
      </div>
      <div class="toolbar">
        <WsIconBtn
          tooltip="undo"
          v-if="$_hasToolbarAction('step-record')"
          @click="editor.chain().focus().undo().run()"
          :disabled="!editor.can().undo()"
          :noBorder="iconNoBorder"
          name="icon-md-undo"
        ></WsIconBtn>
        <WsIconBtn
          tooltip="redo"
          v-if="$_hasToolbarAction('step-record')"
          @click="editor.chain().focus().redo().run()"
          :disabled="!editor.can().redo()"
          :noBorder="iconNoBorder"
          name="icon-md-redo"
        />
        <WsBtnColor
          tooltip="文字顏色"
          v-if="$_hasToolbarAction('text-color')"
          @input="editor.chain().focus().setColor($event).run();editor.chain().blur()"
          :value="editor.getAttributes('textStyle').color"
          :noBorder="iconNoBorder"
          :iconColor="selectedTextColor"
          :forTextColor="true"
          iconBtn
        />
        <WsBtnColor
          tooltip="文字底色"
          v-if="$_hasToolbarAction('text-hightlight')"
          @input="$_onClickHighlightBtn($event)"
          value="#ffffff"
          :noBorder="iconNoBorder"
          :backgroundColor="selectedTextHighLight"
          :forHighlight="true"
          iconBtn
          iconBtnName="icon-md-font-download"
        />
        <WsIconBtn
          :tooltip="`文字底色${$color.primary}`"
          v-if="$_hasToolbarAction('text-hightlight') && $color.primary"
          @click="$_onClickHighlightBtn($color.primary)"
          name="icon-md-text-fields"
          :noBorder="iconNoBorder"
          :backgroundColor="$color.primary"
        />
        <WsIconBtn
          :tooltip="`文字底色${$color.secondary}`"
          v-if="$_hasToolbarAction('text-hightlight') && $color.secondary"
          @click="$_onClickHighlightBtn($color.secondary)"
          name="icon-md-text-fields"
          :noBorder="iconNoBorder"
          :backgroundColor="$color.secondary"
        />
        <WsIconBtn
          :tooltip="`文字底色${$color.success}`"
          v-if="$_hasToolbarAction('text-hightlight') && $color.success"
          @click="$_onClickHighlightBtn($color.success)"
          name="icon-md-text-fields"
          :noBorder="iconNoBorder"
          :backgroundColor="$color.success"
        />
        <WsIconBtn
          :tooltip="`文字底色${$color.danger}`"
          v-if="$_hasToolbarAction('text-hightlight') && $color.danger"
          @click="$_onClickHighlightBtn($color.danger)"
          name="icon-md-text-fields"
          :noBorder="iconNoBorder"
          :backgroundColor="$color.danger"
        />
        <WsIconBtn
          :tooltip="`文字底色${$color.warning}`"
          v-if="$_hasToolbarAction('text-hightlight') && $color.warning"
          @click="$_onClickHighlightBtn($color.warning)"
          name="icon-md-text-fields"
          :noBorder="iconNoBorder"
          :backgroundColor="$color.warning"
        />
        <WsIconBtn
          tooltip="靠左"
          v-if="$_hasToolbarAction('text-align')"
          @click="editor.chain().focus().setTextAlign('left').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
          :noBorder="iconNoBorder"
          name="icon-md-format-align-left"
        />
        <WsIconBtn
          tooltip="置中"
          v-if="$_hasToolbarAction('text-align')"
          @click="editor.chain().focus().setTextAlign('center').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
          :noBorder="iconNoBorder"
          name="icon-md-format-align-center"
        />
        <WsIconBtn
          tooltip="靠右"
          v-if="$_hasToolbarAction('text-align')"
          @click="editor.chain().focus().setTextAlign('right').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
          :noBorder="iconNoBorder"
          name="icon-md-format-align-right"
        />
        <WsIconBtn
          tooltip="粗體"
          v-if="$_hasToolbarAction('text-style')"
          @click="editor.chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
          :noBorder="iconNoBorder"
          name="icon-md-format-bold"
        />
        <WsIconBtn
          tooltip="斜體"
          v-if="$_hasToolbarAction('text-style')"
          @click="editor.chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
          :noBorder="iconNoBorder"
          name="icon-md-format-italic"
        />
        <WsIconBtn
          tooltip="刪除線"
          v-if="$_hasToolbarAction('text-style')"
          @click="editor.chain().focus().toggleStrike().run()"
          :class="{ 'is-active': editor.isActive('strike') }"
          :noBorder="iconNoBorder"
          name="icon-md-strikethrough-s"
        />
        <WsIconBtn
          tooltip="底線"
          v-if="$_hasToolbarAction('text-style')"
          @click="editor.chain().focus().toggleUnderline().run()"
          :class="{ 'is-active': editor.isActive('underline') }"
          :noBorder="iconNoBorder"
          name="icon-md-format-underlined"
        />
        <WsIconBtn
          tooltip="上標"
          text
          v-if="$_hasToolbarAction('superscript')"
          @click="editor.chain().focus().toggleSuperscript().run()"
          :class="{ 'is-active': editor.isActive('superscript') }"
          :noBorder="iconNoBorder"
        >
          <p>X<sup>2</sup></p>
        </WsIconBtn>
        <WsIconBtn
          tooltip="下標"
          text
          v-if="$_hasToolbarAction('superscript')"
          @click="editor.chain().focus().toggleSubscript().run()"
          :class="{ 'is-active': editor.isActive('subscript') }"
          :noBorder="iconNoBorder"
        >
          <p>X<sub>2</sub></p>
        </WsIconBtn>
        <WsIconBtn
          tooltip="code"
          v-if="$_hasToolbarAction('code')"
          @click="editor.chain().focus().toggleCode().run()"
          :class="{ 'is-active': editor.isActive('code') }"
          :noBorder="iconNoBorder"
          name="icon-md-code"
        />
        <WsIconBtn
          tooltip="H1"
          text
          v-if="$_hasToolbarAction('text-h1')"
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
          :noBorder="iconNoBorder"
        >H1</WsIconBtn>
        <WsIconBtn
          tooltip="H2"
          text
          v-if="$_hasToolbarAction('text-h2')"
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
          :noBorder="iconNoBorder"
        >H2</WsIconBtn>
        <WsIconBtn
          tooltip="H3"
          text
          v-if="$_hasToolbarAction('text-h3')"
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
          :noBorder="iconNoBorder"
        >H3</WsIconBtn>
        <WsIconBtn
          tooltip="H4"
          text
          v-if="$_hasToolbarAction('text-h4')"
          @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
          :noBorder="iconNoBorder"
        >H4</WsIconBtn>
        <WsIconBtn
          tooltip="H5"
          text
          v-if="$_hasToolbarAction('text-h5')"
          @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
          :noBorder="iconNoBorder"
        >H5</WsIconBtn>
        <WsIconBtn
          tooltip="H6"
          text
          v-if="$_hasToolbarAction('text-h6')"
          @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
          :noBorder="iconNoBorder"
        >H6</WsIconBtn>
        <WsIconBtn
          tooltip="quote"
          v-if="$_hasToolbarAction('text-quote')"
          @click="editor.chain().focus().toggleBlockquote().run()"
          :class="{ 'is-active': editor.isActive('blockquote') }"
          name="icon-md-format-quote"
          :noBorder="iconNoBorder"
        />
        <WsIconBtn
          tooltip="bulletList"
          v-if="$_hasToolbarAction('text-list')"
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
          name="icon-md-format-list-bulleted"
          :noBorder="iconNoBorder"
        />
        <WsIconBtn
          tooltip="orderedList"
          v-if="$_hasToolbarAction('text-list')"
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': editor.isActive('orderedList') }"
          name="icon-md-format-list-numbered"
          :noBorder="iconNoBorder"
        />
        <WsIconBtn
          tooltip="link"
          v-if="$_hasToolbarAction('link')"
          @click="$_linkSetOpen()"
          :class="{ 'is-active': editor.isActive('link') }"
          name="icon-md-link"
          :noBorder="iconNoBorder"
        />
        <WsIconBtnImage
          tooltip="image"
          v-if="$_hasToolbarAction('image')"
          @change="$_onChangeImageBtn($event)"
          :noBorder="iconNoBorder"
        ></WsIconBtnImage>
        <WsIconBtnVideo
          tooltip="video"
          @change="$_onChangeVideoBtn($event)"
          :noBorder="iconNoBorder"
        />
        <WsIconBtn
          tooltip="YouTube"
          @click="$refs.YouTubePopup.open()"
          name="icon-sm-youtube"
          :noBorder="iconNoBorder"
        />
        <WsIconBtn
          tooltip="複製格式"
          @click="$_copyFormat()"
          :noBorder="iconNoBorder"
          name="icon-md-format-paint_outline"
        ></WsIconBtn>
        <WsIconBtn
          tooltip="貼上格式"
          @click="$_pasteFormat()"
          :noBorder="iconNoBorder"
          name="icon-md-format-paint"
        />
        <WsStateSelect
          :items="fontsize_items"
          :defaultItem="defaultFormats.fontsize_item_value"
          v-model="state.fontsize"
          @input="$_setFontSize($event)"
          placeholder="Font Sizes"
        />
        <!-- <WsIconBtn
          v-if="$_hasToolbarAction('image')"
          @click="$_imageSetOpen()"
          name="icon-md-image"
        >
          <input
            v-show="false"
            @change="$_onChange($event)"
            type="file"
            ref="fileInput"
            accept="image/*"
          >
        </WsIconBtn> -->
      </div>
      <div
        v-if="$_hasToolbarAction('table')"
        class="toolbar"
      >
        <WsBtn
          outlined
          @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()"
        >
          {{$t('加入表格')}}
        </WsBtn>
        <WsBtn
          outlined
          @click="editor.chain().focus().addColumnBefore().run()"
          :disabled="!editor.can().addColumnBefore()"
        >
          {{$t('向左加入一列')}}
        </WsBtn>
        <WsBtn
          outlined
          @click="editor.chain().focus().addColumnAfter().run()"
          :disabled="!editor.can().addColumnAfter()"
        >
          {{$t('向右加入一列')}}
        </WsBtn>
        <WsBtn
          outlined
          @click="editor.chain().focus().deleteColumn().run()"
          :disabled="!editor.can().deleteColumn()"
        >
          {{$t('刪除列')}}
        </WsBtn>
        <WsBtn
          outlined
          @click="editor.chain().focus().addRowBefore().run()"
          :disabled="!editor.can().addRowBefore()"
        >
          {{$t('向上加入一行')}}
        </WsBtn>
        <WsBtn
          outlined
          @click="editor.chain().focus().addRowAfter().run()"
          :disabled="!editor.can().addRowAfter()"
        >
          {{$t('向下加入一行')}}
        </WsBtn>
        <WsBtn
          outlined
          @click="editor.chain().focus().deleteRow().run()"
          :disabled="!editor.can().deleteRow()"
        >
          {{$t('刪除行')}}
        </WsBtn>
        <WsBtn
          outlined
          @click="editor.chain().focus().deleteTable().run()"
          :disabled="!editor.can().deleteTable()"
        >
          {{$t('刪除表格')}}
        </WsBtn>
        <WsBtn
          outlined
          @click="editor.chain().focus().toggleHeaderCell().run()"
          :disabled="!editor.can().toggleHeaderCell()"
        >
          {{$t('設為表頭')}}
        </WsBtn>
        <WsBtn
          outlined
          @click="editor.chain().focus().mergeOrSplit().run()"
          :disabled="!editor.can().mergeOrSplit()"
        >
          {{$t('合併儲存格')}}
        </WsBtn>
      </div>
    </div>
    <WsMain
      @click.native="$_onContentClick()"
      class="ws-state-editor__content"
      :class="{ 
        'darkmode': active.darkmode,
        'non-darkmode': !active.darkmode,
      }"
    >
      <editor-content :editor="editor" />
    </WsMain>
    <WsPocketImagePicker
      v-model="active.pocketImagePicker"
      :signed="signed"
      :getUploadUrl="getUploadUrl"
      :uploadable="uploadable"
      :linkable="linkable"
      :pocketable="pocketable"
      @submit="$_onPocketImageSubmit($event)"
    />
    <WsPopup
      title="設定連結"
      ref="linkPopup"
    >
      <template v-slot:content>
        <WsState
          v-model="state.link"
          autofocus
        ></WsState>
        <!-- <WsStateInput
          v-model="state.link"
          autofocus
          full
        ></WsStateInput> -->
      </template>
      <template v-slot:rightActions>
        <WsBtn
          @click="$_onLinkClear()"
          :minWidth="100"
          color="textSecondary"
          outlined
        >{{$t('clear')}}</WsBtn>
        <WsBtn
          @click="$_onLinkCancel()"
          :minWidth="100"
          color="textSecondary"
          outlined
        >{{$t('cancel')}}</WsBtn>
        <WsBtn
          @click="$_onLinkSubmit()"
          :minWidth="100"
          color="primary"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsPopup>
    <WsPopup
      title="貼上YouTube連結"
      ref="YouTubePopup"
    >
      <template v-slot:content>
        <WsState
          v-model="state.youTubeLink"
          autofocus
        />
      </template>
      <template v-slot:rightActions>
        <WsBtn
          @click="$_onYoutubeLinkCancel()"
          :minWidth="100"
          outlined
        >{{$t('cancel')}}</WsBtn>
        <WsBtn
          @click="$_onYoutubeLinkSubmit()"
          :minWidth="100"
          color="primary"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsPopup>
  </div>
</template>

<script>
import S_App_Image from "@/__stone/service/app/image";
import { Editor, EditorContent } from "@tiptap/vue-2";
import { Color } from "@tiptap/extension-color";
import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Blockquote from "@tiptap/extension-blockquote";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import CodeBlock from "@tiptap/extension-code-block";
import Heading from "@tiptap/extension-heading";
import Image from "@tiptap/extension-image";
import Dropcursor from "@tiptap/extension-dropcursor";
import TextAlign from "@tiptap/extension-text-align";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";
import Youtube from "@tiptap/extension-youtube";
import Mention from "@tiptap/extension-mention";
import Superscript from "@tiptap/extension-superscript";
import Subscript from "@tiptap/extension-subscript";
import { Node, mergeAttributes } from "@tiptap/core";
import { VueRenderer } from "@tiptap/vue-2";
import WsStateEditorMentionList from "./WsStateEditorMentionList.vue";
import tippy from "tippy.js";
const Video = Node.create({
  name: "video",
  group: "block",
  selectable: true,
  atom: true,
  addAttributes() {
    return {
      src: {
        default: null,
      },
      width: {
        default: 640,
      },
      height: {
        default: 360,
      },
      controls: {
        default: true,
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: "video",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["video", mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return ({ node }) => {
      const div = document.createElement("div");
      const video = document.createElement("video");
      video.width = "640";
      video.height = "360";
      video.frameborder = "0";
      video.allowfullscreen = "";
      video.src = node.attrs.src;
      video.controls = true;
      div.append(video);
      return {
        dom: div,
      };
    };
  },
});

const TextStyleExtended = Node.create({
  name: "fontSize",
  addOptions() {
    return {
      types: ["textStyle"],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            parseHTML: (element) =>
              element.style.fontSize.replace(/['"]+/g, ""),
            renderHTML: (attributes) => {
              if (!attributes.fontSize) {
                return {};
              }
              return {
                style: `font-size: ${attributes.fontSize}`,
              };
            },
          },
        },
      },
    ];
  },
  addCommands() {
    return {
      setFontSize:
        (fontSize) =>
        ({ chain }) => {
          return chain()
            .setMark("textStyle", { fontSize: fontSize + "px" })
            .run();
        },
      unsetFontSize:
        () =>
        ({ chain }) => {
          return chain()
            .setMark("textStyle", { fontSize: null })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },
});

export default {
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: "",
    },
    signed: {
      type: Boolean,
      default: false,
    },
    getUploadUrl: {
      type: String,
    },
    valueType: {
      type: String,
      default: "html",
    },
    uploadable: {
      type: Boolean,
      default: true,
    },
    linkable: {
      type: Boolean,
      default: true,
    },
    pocketable: {
      type: Boolean,
      default: true,
    },
    imageClickable: {
      type: Boolean,
    },
    toolbarActions: {
      type: [Array, Boolean],
      default: true,
    },
    iconNoBorder: {},
    autofocus: {},
    mentionable: {},
  },

  data() {
    return {
      selectedTextColor: null,
      selectedTextHighLight: null,
      selectedTextFontSize: "",
      selectedTextFontSizeValue: null,
      defaultFormats: {
        color: "var(--white5d)",
        highlight: "#000000ff",
        fontSize: "20px",
        fontsize_item_value: 20,
      },
      copiedFormats: {
        typeIs: {
          bold: false,
          italic: false,
          strike: false,
          underline: false,
          superscript: false,
          subscript: false,
          blockquote: false,
          code: false,
        },
        typeAttr: {
          heading: null,
          textAlign: null,
          color: null,
          fontSize: null,
          highlight: null,
        },
      },
      isFocus: false,
      editor: null,
      state: {
        link: "",
      },
      active: {
        linkPopup: false,
        pocketImagePicker: false,
        darkmode: true,
      },
      test: null,
      global_style: null,
      fontsize_items: [
        {
          value: 8,
          text: "8px",
        },
        {
          value: 10,
          text: "10px",
        },
        {
          value: 12,
          text: "12px",
        },
        {
          value: 14,
          text: "14px",
        },
        {
          value: 18,
          text: "18px",
        },
        {
          value: 20,
          text: "20px",
        },
        {
          value: 22,
          text: "22px",
        },
        {
          value: 24,
          text: "24px",
        },
        {
          value: 36,
          text: "36px",
        },
      ],
    };
  },
  methods: {
    $_copyFormat() {
      Object.keys(this.copiedFormats.typeIs).forEach((format) => {
        this.copiedFormats.typeIs[format] = this.editor.isActive(format);
      });

      Object.keys(this.copiedFormats.typeAttr).forEach((format) => {
        if (format === "heading") {
          this.copiedFormats.typeAttr[format] =
            this.editor.getAttributes(format).level;
        } else if (format === "textAlign") {
          // Yaokuan: textAlign can not be get by getAttributes, this is a solution, using getAttributes("paragraph")
          this.copiedFormats.typeAttr[format] =
            this.editor.getAttributes("paragraph").textAlign;
        } else if (format === "color") {
          this.copiedFormats.typeAttr[format] =
            this.editor.getAttributes("textStyle").color;
        } else if (format === "fontSize") {
          this.copiedFormats.typeAttr[format] =
            this.editor.getAttributes("textStyle").fontSize;
        } else if (format === "highlight") {
          this.copiedFormats.typeAttr[format] =
            this.editor.getAttributes("highlight").color;
        }
      });
    },
    $_pasteFormat() {
      Object.keys(this.copiedFormats.typeIs).forEach((format) => {
        if (
          this.copiedFormats.typeIs[format] !== this.editor.isActive(format)
        ) {
          const methodName = `toggle${
            format.charAt(0).toUpperCase() + format.slice(1)
          }`;
          this.editor.chain().focus()[methodName]().run();
        }
      });

      Object.keys(this.copiedFormats.typeAttr).forEach((format) => {
        if (format === "heading") {
          if (this.copiedFormats.typeAttr.heading != null) {
            // 因為heading的轉換是用toggle 再次toggle同樣顏色會轉成沒有heading
            if (
              this.editor.getAttributes("heading").level !=
              this.copiedFormats.typeAttr.heading
            ) {
              this.editor
                .chain()
                .focus()
                .toggleHeading({ level: this.copiedFormats.typeAttr.heading })
                .run();
            }
          } else {
            if (this.editor.getAttributes("heading").level != null) {
              this.editor
                .chain()
                .focus()
                .toggleHeading({
                  level: this.editor.getAttributes("heading").level,
                })
                .run();
            }
          }
        } else if (format === "textAlign") {
          if (this.copiedFormats.typeAttr.textAlign != null) {
            this.editor
              .chain()
              .focus()
              .setTextAlign(this.copiedFormats.typeAttr.textAlign)
              .run();
          }
        } else if (format === "color") {
          if (this.copiedFormats.typeAttr.color != null) {
            this.editor
              .chain()
              .focus()
              .setColor(this.copiedFormats.typeAttr.color)
              .run();
          } else {
            this.editor
              .chain()
              .focus()
              .setColor(this.defaultFormats.color)
              .run();
          }
        } else if (format === "fontSize") {
          if (this.copiedFormats.typeAttr.fontSize != null) {
            this.editor
              .chain()
              .focus()
              .setMark("textStyle", {
                fontSize: this.copiedFormats.typeAttr.fontSize,
              })
              .run();
          } else {
            this.editor
              .chain()
              .focus()
              .setMark("textStyle", {
                fontSize: this.defaultFormats.fontSize,
              })
              .run();
          }
        } else if (format === "highlight") {
          if (this.copiedFormats.typeAttr.highlight != null) {
            // 因為highlight的轉換是用toggle 再次toggle同樣顏色會轉成沒有highlight
            if (
              this.editor.getAttributes("highlight").color !=
              this.copiedFormats.typeAttr.highlight
            ) {
              this.editor
                .chain()
                .focus()
                .toggleHighlight({
                  color: this.copiedFormats.typeAttr.highlight,
                })
                .run();
            }
          } else {
            this.editor
              .chain()
              .focus()
              .toggleHighlight({
                color: this.defaultFormats.highlight,
              })
              .run();
          }
        }
      });
    },
    $_initFormat(editor) {
      const jsonContent = editor.getJSON();
      const firstNode = jsonContent.content && jsonContent.content[0];
      if (firstNode && firstNode.attrs) {
        const styles = firstNode.attrs.style;
        const styleArray = styles
          .split(";")
          .map((style) => style.split(":").map((s) => s.trim()));
        styleArray.forEach(([property, value]) => {
          if (property === "font-size") {
            this.selectedTextFontSize = value;
          } else if (property === "color") {
            this.selectedTextColor = value;
          } else if (property === "background-color") {
            this.selectedTextHighLight = value;
          }
        });
      }
    },
    $_onClickHighlightBtn($event) {
      if ($event != this.editor.getAttributes("highlight").color) {
        this.editor.chain().focus().toggleHighlight({ color: $event }).run();
      }
    },
    async $_onChangeImageBtn($event) {
      const file = $event.target.files[0];
      try {
        this.$store.dispatch("app/startPageLoading");
        const res = await this.$o_o.$s._m.general_image.upload(file, file.name);
        this.editor.chain().focus().setImage({ src: res }).run();
      } catch (error) {
        alert("發生錯誤");
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onChangeVideoBtn($event) {
      const file = $event.target.files[0];
      const filetype = this.$o_o.$h.file.getGeneralFileTypeFromFileType(
        file.type
      );
      const serviceName = `general_${this.$o_o.$h.file.getGeneralFileTypeFromFileType(
        filetype
      )}`;
      try {
        this.$store.dispatch("app/startPageLoading");
        const res = await this.$o_o.$s._m[serviceName].upload(file, file.name);
        this.$_inserContent(res, filetype);
      } catch (error) {
        alert("發生錯誤");
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_hasToolbarAction(action) {
      if (this.toolbarActions === true) {
        return true;
      } else if (!this.toolbarActions) {
        return false;
      } else {
        return this.toolbarActions.includes(action);
      }
    },
    $_insertImage(imageUrl) {
      // This function should insert the image URL into your editor
      // The implementation will depend on the editor you are using
      const img = document.createElement("img");
      img.src = imageUrl;
      this.$refs.editor.appendChild(img);
    },
    async $_onPaste($event) {
      const clipboardData = $event.clipboardData || window.clipboardData;
      const items = clipboardData.items;

      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") === 0) {
          const file = items[i].getAsFile();
          const filetype = this.$o_o.$h.file.getGeneralFileTypeFromFileType(
            file.type
          );
          try {
            this.$store.dispatch("app/startPageLoading");
            const res = await this.$o_o.$s._m.general_image.upload(
              file,
              file.name
            );
            this.$_inserContent(res, filetype);
          } catch (error) {
            alert("發生錯誤");
            console.error(error);
          } finally {
            this.$store.dispatch("app/stopPageLoading");
          }
        }
      }
    },
    async $_onDrop($event) {
      $event.preventDefault();
      const file = $event.dataTransfer.files[0];
      const filetype = this.$o_o.$h.file.getGeneralFileTypeFromFileType(
        file.type
      );
      const serviceName = `general_${this.$o_o.$h.file.getGeneralFileTypeFromFileType(
        filetype
      )}`;
      try {
        this.$store.dispatch("app/startPageLoading");
        const res = await this.$o_o.$s._m[serviceName].upload(file, file.name);
        this.$_inserContent(res, filetype);
      } catch (error) {
        alert("發生錯誤");
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_inserContent(content, filetype) {
      if (filetype == "image") {
        this.editor.chain().focus().setImage({ src: content }).run();
      } else if (filetype == "video") {
        this.editor
          .chain()
          .focus()
          .insertContent(`<video src="${content}"/>`)
          .run();
      } else {
        this.editor
          .chain()
          .focus()
          .insertContent(`<a href="${content}">${content}</a>`)
          .run();
      }
    },
    $_onContentClick() {
      this.editor.view.dom.focus();
    },
    $_onLinkCancel() {
      this.state.link = "";
      this.$refs.linkPopup.close();
    },
    $_onLinkClear() {
      this.editor.chain().focus().unsetLink().run();
      this.state.link = "";
      this.$refs.linkPopup.close();
    },
    $_onLinkSubmit() {
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: this.state.link })
        .run();
      this.state.link = "";
      this.$refs.linkPopup.close();
    },
    $_linkSetOpen() {
      this.$refs.linkPopup.open();
    },
    async $_onPocketImageSubmit($event) {
      let src;
      if ($event.signed) {
        src = await S_App_Image.getBase64FromUrl($event.signed_url);
      } else {
        src = $event.url;
      }

      if (src) {
        this.editor.chain().focus().setImage({ src: src }).run();
      }
    },
    $_imageSetOpen() {
      // this.active.pocketImagePicker = true;
    },
    $_setFontSize($event) {
      this.state.fontSize = $event;
      this.editor
        .chain()
        .focus()
        .setMark("textStyle", { fontSize: $event + "px" })
        .run();
    },
    $_onFocus() {
      this.$emit("focus");
      this.isFocus = true;
    },
    $_onBlur() {
      this.$emit("blur");
      this.isFocus = false;
    },
    async $_init() {
      if (this.autofocus) {
        this.editor.commands.focus();
      }
      const res = await this.$axios.get("/system_setting/web");
      const global_style = res.data.data.global_style;
      const result = [];
      for (const rule of global_style.split("}")) {
        if (rule.trim() !== "") {
          const [selectors, declarations] = rule.split("{");
          const updatedSelectors = selectors
            .split(",")
            .map((selector) => `.ws-state-editor ${selector.trim()}`)
            .join(", ");
          result.push(`${updatedSelectors} {${declarations}`);
        }
      }
      this.global_style = result.join("}");
    },
    $_onYoutubeLinkCancel() {
      this.state.youTubeLink = "";
      this.$refs.YouTubePopup.close();
    },
    $_onYoutubeLinkSubmit() {
      if (this.state.youTubeLink) {
        this.editor.commands.setYoutubeVideo({
          src: this.state.youTubeLink,
          width: 560,
          height: 315,
        });
      }
      this.$_onYoutubeLinkCancel();
    },
  },
  watch: {
    value(value) {
      let isSame;
      if (this.valueType == "json") {
        isSame = this.editor.getJSON().toString() === value.toString();
      } else {
        isSame = this.editor.getHTML() === value;
      }
      if (isSame) {
        return;
      }
      this.editor.commands.setContent(this.value, false);
    },
  },
  mounted() {
    const CustomModEnter = BulletList.extend({
      addKeyboardShortcuts() {
        return {
          "Mod-Enter": () => this.editor.chain(),
        };
      },
    });

    const extensions = [
      StarterKit,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      Document,
      Paragraph,
      Text,
      Color,
      TextStyle,
      Highlight.configure({
        multicolor: true,
      }),
      Blockquote,
      // BulletList,
      ListItem,
      CodeBlock,
      Heading,
      Image,
      Dropcursor,
      OrderedList,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Bold,
      Italic,
      Strike,
      Underline,
      Link,
      CustomModEnter,
      Video,
      TextStyleExtended,
      Youtube,
      Superscript,
      Subscript,
    ];

    if (this.mentionable) {
      extensions.push(
        Mention.configure({
          HTMLAttributes: {
            class: "mention",
          },
          suggestion: {
            items: ({ query }) => {
              return [
                "Lea Thompson",
                "Cyndi Lauper",
                "Tom Cruise",
                "Madonna",
                "Jerry Hall",
                "Joan Collins",
                "Winona Ryder",
                "Christina Applegate",
                "Alyssa Milano",
                "Molly Ringwald",
                "Ally Sheedy",
                "Debbie Harry",
                "Olivia Newton-John",
                "Elton John",
                "Michael J. Fox",
                "Axl Rose",
                "Emilio Estevez",
                "Ralph Macchio",
                "Rob Lowe",
                "Jennifer Grey",
                "Mickey Rourke",
                "John Cusack",
                "Matthew Broderick",
                "Justine Bateman",
                "Lisa Bonet",
              ]
                .filter((item) =>
                  item.toLowerCase().startsWith(query.toLowerCase())
                )
                .slice(0, 5);
            },
            render: () => {
              let component;
              let popup;
              return {
                onStart: (props) => {
                  component = new VueRenderer(WsStateEditorMentionList, {
                    parent: this,
                    propsData: props,
                  });

                  if (!props.clientRect) {
                    return;
                  }
                  popup = tippy("body", {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: "manual",
                    placement: "bottom-start",
                  });
                },

                onUpdate(props) {
                  component.updateProps(props);

                  if (!props.clientRect) {
                    return;
                  }

                  popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                  });
                },

                onKeyDown(props) {
                  if (props.event.key === "Escape") {
                    popup[0].hide();

                    return true;
                  }

                  return component.ref?.onKeyDown(props);
                },

                onExit() {
                  popup[0].destroy();
                  component.destroy();
                },
              };
            },
          },
        })
      );
    }

    this.editor = new Editor({
      extensions: extensions,
      content: this.value,
      onUpdate: () => {
        if (this.valueType == "json") {
          if (
            !("content" in this.editor.getJSON().content[0]) &&
            this.editor.getJSON().content[0].type == "paragraph"
          ) {
            this.$emit("input", "");
          } else {
            this.$emit("input", this.editor.getJSON());
            this.test = this.editor.getJSON();
          }
        } else {
          this.$emit("input", this.editor.getHTML());
        }
      },
      onSelectionUpdate: () => {
        this.selectedTextColor =
          this.editor.getAttributes("textStyle").color ||
          this.defaultFormats.color;
        this.selectedTextHighLight =
          this.editor.getAttributes("highlight").color ||
          this.defaultFormats.highlight;
        this.selectedTextFontSize =
          this.editor.getAttributes("textStyle").fontSize || "";

        if (this.selectedTextFontSize != "") {
          this.state.fontsize = this.selectedTextFontSizeValue = parseInt(
            this.selectedTextFontSize.substring(
              0,
              this.selectedTextFontSize.length - 2
            )
          );
        } else {
          this.state.fontsize = this.selectedTextFontSizeValue = 20;
        }
      },
      onFocus: this.$_onFocus,
      onBlur: this.$_onBlur,
    });

    // this.$_initFormat();
    // this.$_initFormat(this.editor);

    setTimeout(() => {
      this.$_init();
    }, 0);
  },

  beforeUnmount() {
    this.editor.destroy();
  },

  created() {
    this.defaultFormats.color = this.active.darkmode
      ? this.$color.white5d
      : "000000";
  },
};
</script>