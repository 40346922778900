import H_color from '@/__stone/helpers/color'
import H_time from '@/__stone/helpers/time'

export default {
  toCalendarEvent(
    xc_task,
    additionalOnDays,
    additionalOffDays
  ) {
    const _event = {}
    if (!xc_task) {
      return
    }
    _event.title = xc_task.hour ? `[ ${xc_task.hour}h ] ${xc_task.name}` : xc_task.name
    let _color = xc_task.taker && xc_task.taker.color ? xc_task.taker.color : '#606060'
    let _opa = 1
    if (xc_task.status == 1) {
      _opa = .5;
    } else if (xc_task.status == 3) {
      _opa = .5;
    } else if (xc_task.status == 5) {
      _opa = .3;
    } else if (xc_task.status == 6) {
      _opa = .3;
    } else if (xc_task.status == 12) {
      _opa = .1;
    } else if (xc_task.status == 7) {
      _opa = .1;
    }
    _event.color = H_color.hexToRgb(_color, _opa)
    _event.extendedProps = {
      ...xc_task,
      modelName: 'xc_task'
    }
    if (xc_task.start_at && xc_task.hour) {
      _event.start = xc_task.start_at
      _event.end = H_time.getEndAtFromStartAt(
        xc_task.start_at,
        xc_task.hour,
        additionalOnDays,
        additionalOffDays
      )
    } else if (xc_task.due_date) {
      _event.start = xc_task.due_date
      _event.allDay = true
    }
    return _event
  }
}