import axios from 'axios'
export default {
  async complete(id) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/contact_request/${id}`, {
          status: 2
        })
        .then(res => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}