export default {
  options: {
    id: true,
    timestamp: true,
    tags: true,
  },
  modelName: "xc_project",
  label: "專案",
  fields: {
    // url: {
    //   type: 'link',
    //   label: '專案連結',
    //   displayInText: true,
    //   rules: 'required'
    // },
    name: {
      type: 'text',
      label: '專案名稱',
      rules: 'required',
    },
    slack_webhook_url: {
      label: 'Slack Webhook URL'
    },
    status: {
      type: 'radio',
      label: '狀態',
      defaultValue: 'prepare',
      items: [
        {
          value: 'prepare',
          label: '準備中'
        },
        {
          value: 'ing',
          label: '進行中'
        },
        {
          value: 'suspend',
          label: '暫停'
        },
        {
          value: 'complete',
          label: '結案'
        },
      ]
    },
    slack_team_id: {
      type: 'text',
      label: 'Slack Team ID'
    },
    slack_channel_id: {
      type: 'text',
      label: 'Slack Channel Id'
    },
    invision: {
      type: 'text',
      label: 'Invision 帳號'
    },
    zeplin: {
      type: 'text',
      label: 'Zeplin 帳號'
    },
    gitlab: {
      type: 'link',
      label: 'Gitlab 連結'
    },
    github: {
      type: 'link',
      label: 'Github 連結'
    },
    google_drive: {
      type: 'link',
      label: 'Google Drive 連結'
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    links: {
      type: 'list',
      label: '相關連結',
      fields: {
        name: {
          type: 'text',
          label: '名稱',
          rules: 'required'
        },
        link: {
          type: 'link',
          label: '連結',
          rules: 'required'
        },
        remark: {
          type: 'textarea',
          label: '備註',
        },
      },
    },
    infos: {
      type: 'list',
      label: '相關資訊',
      sm: 12,
      fields: {
        name: {
          type: 'text',
          label: '資訊名稱',
          rules: 'required'
        },
        remark: {
          type: 'textarea',
          label: '資訊內容',
        },
      },
    },
    private_infos: {
      type: 'list',
      label: '加密資訊',
      sm: 12,
      fields: {
        name: {
          type: 'text',
          label: '資訊名稱',
          rules: 'required'
        },
        remark: {
          type: 'textarea',
          label: '資訊內容',
        },
      },
    },
    gitlab_project_ids: {
      type: 'text_array',
      label: 'Gitlab Project IDs'
    }
  },
  crudSetting: {
    index: {
      pageMode: true,
      showFields: [
        'name',
        'status',
      ],
      inRowBtnRead: true,
      updatable: false,
      deletable: false,
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
                'slack_webhook_url',
                'status',
                'slack_team_id',
                'slack_channel_id',
                'gitlab_project_ids',
                'invision',
                'zeplin',
                'gitlab',
                'github',
                'google_drive',
                'remark',
                'links',
                'infos',
                'private_infos',
              ],
            },
          ],
        },
      ],
    },
    update: {
      submitToIndex: true,
      titleKey: 'name',
      getCompleteRedirectUrl: (id) => {
        return `/xc_project/${id}/info`
      },
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
                'slack_webhook_url',
                'status',
                'slack_team_id',
                'slack_channel_id',
                'gitlab_project_ids',
                'invision',
                'zeplin',
                'gitlab',
                'github',
                'google_drive',
                'remark',
                'links',
                'infos',
                'private_infos',
              ],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: [
        'slack_webhook_url',
        'slack_team_id',
        'slack_channel_id',
        'gitlab_project_ids',
        'invision',
        'zeplin',
        'gitlab',
        'github',
        'google_drive',
        'remark',
        'links',
        'infos',
      ],
      rightFields: [
        'id',
        'status',
        'name',
        'updated_at',
        'created_at'
      ],
      titleKey: 'name',
    },
  }
}