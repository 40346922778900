const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_financial_first_grade",
  label: "一級科目",
  fields: {
    type: {
      type: 'radio',
      label: '類型',
      defaultValue: 1,
      items: [
        {
          label: '借方',
          value: 1
        },
        {
          label: '貸方',
          value: 2
        },
      ]
    },
    code: {
      type: 'text',
      label: '代碼',
    },
    name: {
      type: 'text',
      label: '名稱',
    },
  },
  crudSetting: {
    index: {
      showFields: [
        'type',
        'code',
        'name',
      ],
      pageMode: true,
      filterTabs: [
        {
          text: "全部",
          value: 'all',
          params: {
          },
        },
        {
          text: "借方",
          value: 1,
          params: {
            type: 1,
          },
        },
        {
          text: "貸方",
          value: 2,
          params: {
            type: 2,
          },
        },
      ]
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'type',
                'code',
                'name',
              ],
            },
          ],
        },
      ],
    },
    update: {
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'type',
                'code',
                'name',
              ],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: [
        'type',
        'code',
        'name',
      ],
      titleKey: 'name',
      childCruds: [
        {
          modelName: 'xc_financial_second_grade',
          // orderLayerFields: [],
          pageMode: false,
          inRowBtnRead: true,
          // dialogCreate: true,
          // dialogRead: true,
          // dialogUpdate: true,
        }
      ]
    }
  }
}

export default model