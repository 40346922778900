<template>
  <div
    class="ws-state-input"
    :class="[{'is-focus':isFocus},{'full':full},{'is-ok':isOk},{'is-error':_isError||(errors&&errors.length)},{'is-dislabled':disabled},{'has-before-icon':beforeIcon},{'has-after-icon':_hasAfterIcon},{small:small},{center:center},{'prefix-text':_prefix},{'suffix-text':suffix}]"
    :style="{'max-width':maxWidth}"
  >
    <div
      @click="$_inputBlockOnClick()"
      v-click-outside="$_inputBlockOnBlur"
      class="ws-state__block"
    >
      <div
        class="ws-state__block__before"
        :style="[
          {'background-color':_backgroundColor}
        ]"
      ></div>
      <WsIcon
        v-if="beforeIcon"
        size="20"
        class="before-icon"
        :name="beforeIcon"
      />
      <WsText
        v-if="_prefix"
        class="prefix-text ml-12"
      >{{prefix}}</WsText>
      <input
        @keyup="$_onKeyup"
        @focus="$_onFocus"
        @blur="$_onBlur"
        ref="input"
        :step="step"
        :autofocus="autofocus"
        :name="name"
        :type="_type"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="value"
        :maxlength="maxlength"
        @input="$emit('input',$event.target.value)"
        @keyup.enter="$_onEnter($event)"
      >
      <WsText
        v-if="suffix"
        class="suffix-text mr-12"
      >{{suffix}}</WsText>
      <WsIcon
        @click="$_passwordSeeOff()"
        v-if="type=='password'&&passwordSee"
        class="after-icon clickable"
        name="icon-md-visibility-off"
      />
      <WsIcon
        v-else-if="type=='password'&&!passwordSee"
        @click="$_passwordSeeOn()"
        class="after-icon clickable"
        name="icon-md-visibility"
      />
      <WsIcon
        v-else-if="isOk"
        class="after-icon"
        size="20"
        name="icon-md-check-circle"
      />
      <WsIcon
        v-else-if="type=='link'"
        class="after-icon"
        size="20"
        name="icon-md-link"
      />
      <WsIcon
        v-else-if="_isError"
        class="after-icon is-alert"
        size="20"
        name="icon-md-info"
      />
      <WsBtn
        v-if="$slots['after-btn']"
        class="after-btn"
        @click="$emit('after-btn-click',value)"
        :minWidth="32"
      >
        <slot name="after-btn"></slot>
      </WsBtn>
    </div>
    <WsText
      v-if="suffixText"
      class="ml-4"
    >{{suffixText}}</WsText>
  </div>
</template>

<script>
export default {
  data: () => ({
    isFocus: false,
    passwordSee: false,
  }),
  mounted() {
    if (this.value !== 0 && !this.value && this.defaultValue) {
      this.$emit("input", this.defaultValue(this.stateData));
    }
    if (this.autofocus) {
      this.$refs.input.focus();
    }
    // this.$_input()
  },
  props: {
    full: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: null,
    },
    maxWidth: {
      type: String,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    note: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: [String, Array],
      default: null,
    },
    isOk: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String],
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    backgroundColor: {
      type: String,
    },
    maxlength: {
      type: [Number, String],
    },
    step: {
      type: Number,
      default: 0.00001,
    },
    stateData: {},
    defaultValue: {
      type: Function,
    },
    suffixText: {
      type: String,
    },
    beforeIcon: {
      type: String,
    },
    prefix: {
      type: String,
    },
    suffix: {
      type: String,
    },
  },

  methods: {
    $_onFocus() {
      this.isFocus = true;
    },
    $_onBlur() {
      this.isFocus = false;
    },
    focus() {
      setTimeout(() => {
        this.isFocus = true;
      }, 0);
    },
    $_onKeyup($event) {
      this.$emit("keyup", $event);
    },
    $_passwordSeeOff() {
      this.passwordSee = false;
    },
    $_passwordSeeOn() {
      this.passwordSee = true;
    },
    $_inputBlockOnClick() {
      if (this.disabled) {
        return;
      }
      this.isFocus = true;
    },
    $_inputBlockOnBlur() {
      this.isFocus = false;
    },
    $_onEnter($event) {
      this.$emit("enter", $event.target.value);
    },
    blur() {
      this.$refs.input.blur();
      this.$emit("onBlur");
    },
  },

  computed: {
    _prefix() {
      if (
        (this.prefix && this.value && !this.value.startsWith(this.prefix)) ||
        (this.prefix && !this.value)
      ) {
        return true;
      } else return false;
    },
    _backgroundColor() {
      if (this.backgroundColor) {
        return this.backgroundColor;
      } else {
        return null;
      }
    },
    _hasAfterIcon() {
      if (this.type == "password") {
        return true;
      } else if (this.isOk) {
        return true;
      } else if (this._isError) {
        return true;
      } else {
        return false;
      }
    },
    _hasBeforeIcon() {
      if (this.type == "search") {
        return true;
      } else {
        return false;
      }
    },
    _isError() {
      if (this.errorMessage || (this.errors && this.errors.length)) {
        return true;
      } else {
        return false;
      }
    },
    _type() {
      if (this.type == "password") {
        if (this.passwordSee) {
          return "text";
        } else {
          return "password";
        }
      } else {
        return this.type;
      }
    },
  },

  watch: {
    isFocus: {
      handler() {
        if (this.isFocus) {
          this.$refs.input.focus();
          this.$emit("onFocus");
        } else {
          this.$refs.input.blur();
          this.$emit("onBlur");
        }
      },
    },
  },
};
</script>