<template>
  <div>
    <WsModelIndex
      ref="WsModelIndex"
      :label="$store.state.stone_model[modelName].label"
      :modelName="$store.state.stone_model[modelName].modelName"
      :fields="$store.state.stone_model[modelName].fields"
      :filterTabs="filterTabs"
      :showFields="$store.state.stone_model[modelName].crudSetting.index.showFields"
      :creatable="true"
      :readable="true"
      :deletable="true"
      :updatable="true"
      :actionWidth="140"
    ></WsModelIndex>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$store.state.stone_model[this.modelName].label,
    };
  },

  data() {
    return {
      modelName: "xc_rfq",
      filterTabs: [
        {
          text: "洽談中",
          value: 1,
          params: {
            status: "negotiating",
          },
        },
        {
          text: "報價中",
          value: 2,
          params: {
            status: "quoting",
          },
        },
        {
          text: "準備簽約",
          value: 3,
          params: {
            status: "readytosign",
          },
        },
        {
          text: "成交",
          value: 4,
          params: {
            status: "deal",
          },
        },
        {
          text: "結束追蹤",
          value: 5,
          params: {
            status: "endtracking",
          },
        },
        {
          text: "全部",
          value: 6,
          params: {
            status: "",
          },
        },
      ],
    };
  },

};
</script>