import config from '@/__config'

const model = {
  modelName: "xc_work_overtime",
  label: "加班申請",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    start_date: {
      type: 'date',
      label: '加班日'
    },
    hour: {
      type: "number",
      label: "加班時數",
    },
    remark: {
      type: 'textarea',
      label: '備註',
    },
    review_status: {
      type: 'select',
      label: '覆核狀態',
      defaultValue: 0,
      items: [
        {
          value: 0,
          text: '申請中'
        },
        {
          value: 1,
          text: 'OK'
        },
        {
          value: 2,
          text: '打妹'
        },
      ]
    },
    review_remark: {
      type: 'textarea',
      label: '覆核備註'
    },
    admin: {
      type: "belongsTo",
      label: "CMS使用者",
      textKey: "name",
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
      readonly: true,
    },
    reviewer: {
      type: "belongsTo",
      label: "覆核人",
      textKey: "name",
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
      readonly: true,
    },
    updated_at: {
      type: "date",
      label: "更新時間",
      readonly: true,
      labelInLocale: true,
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      readonly: true,
      labelInLocale: true,
    },
  },
  crudSetting: {
    index: {
      showFields: [
        'id',
        'start_date',
        'hour',
        'admin',
        'review_status',
      ],
      pageMode: false,
      creatable: false,
      showFieldsUpdate: [
        "review_status",
        "review_remark",
      ]
      // updatable: false,
    },
    index_my: {
      showFields: [
        'id',
        'start_date',
        'hour',
        'admin',
        'review_status',
      ],
      pageMode: true,
      updatable: false,
      dialogRead: true,
    },
    create_my: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'start_date',
                'hour',
                'remark',
              ],
            },
          ],
        },
      ],
    },
    read: {

    }
  }
}

export default model