import H_Router from '@/__stone/helpers/router'

const routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: [
      'index',
    ],
    modelName: 'xc_out_resource',
    middleware: 'auth',
  }),
]
export default routes